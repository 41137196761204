var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ui-fields-controller"},[_c(_setup.draggable,{class:{
      'ui-fields-controller__draggable': true,
      'ui-fields-controller__draggable--row': _vm.inRow,
      'ui-fields-controller__draggable--search': _vm.withSearch,
    },attrs:{"disabled":_setup.searchStr.length,"item-key":"id","ghost-class":"ui-fields-controller__drag-ghost","handle":".handle","move":(e) => _setup.onMove(e)},scopedSlots:_vm._u([(_vm.withSearch)?{key:"header",fn:function(){return [_c('div',{staticClass:"ui-fields-controller__search-box"},[_c('div',{staticClass:"ui-fields-controller__search"},[_c(_setup.UiSearchInput,{attrs:{"model-value":_setup.searchStr,"label":"Search","with-clear":""},on:{"update:modelValue":function($event){_setup.searchStr=$event},"update:model-value":function($event){_setup.searchStr=$event}}})],1)])]},proxy:true}:null],null,true),model:{value:(_setup.fields),callback:function ($$v) {_setup.fields=$$v},expression:"fields"}},_vm._l((_setup.fields),function(field){return _c('div',{key:field.id,class:{
          'draggable': true,
          'ui-fields-controller__item-box': true,
          'ui-fields-controller__item-box--no-top-border': _vm.withSearch,
        }},[_c('v-icon',{staticClass:"ui-fields-controller__handle handle"},[_vm._v(" mdi-drag-vertical ")]),_c(_setup.XCheckbox,{attrs:{"value":field.enabled,"label":field.name,"disabled":field.frozen},on:{"input":() => _setup.toggleField({ ...field, enabled: !field.enabled })}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }