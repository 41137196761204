<template>
  <div class="bands mb-3">
    <XSelect
        :value="cellularBands"
        label="Bands"
        :items="computedItems"
        multiple
        @input="changeBand"
    />
  </div>
</template>
<script>

import {getfieldAttrInput} from "@/js/helper.js";
import XSelect from '@/components/basic/XSelect';
import options from "@/cfg/options.json";

const anyValue = 'MM_MODEM_BAND_ANY';

export default {
  components: {
    XSelect
  },
  props: ["field", "fieldAttributes", "result", "valueLine", "isValid"],

  data() {
    return {
      options: options,
      disabledItems: [],
    };
  },
  mounted() { // to show on new or existing value
    this.changeBand(this.cellularBands);
  },
  methods: {
    changeBand(value) {
      let initial = [];
      if (value.indexOf(anyValue) !== -1 && value.length > 0 || value[0]?.value === anyValue) {
        this.bandOptions.forEach((item) => {
          if (item[0] != anyValue) {
            initial.push(item[0]);
          }
        });
        this.disabledItems = initial;
      } else if (value.length == 0) {
        this.disabledItems = [];
      } else {
        this.disabledItems = [anyValue];
      }
      this.$emit('input', value);
    },
  },
  computed: {
    computedItems() {
      return this.bandOptions.map(item => {
        return {
          text: item[1],
          value: item[0],
          disabled: this.disabledItems.includes(item[0])
        }
      })
    },
    bandOptions() {
      return this.options.cellularBands;
    },
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    cellularBands: {
      get() {
        let cellularBandsVal = [];//default
        if (typeof this.valueLine.radio != "undefined" &&
            typeof this.valueLine.radio.bands != "undefined" &&
            this.valueLine.radio.bands.length != 0
        ) {
          cellularBandsVal = this.valueLine.radio.bands;
        }
        return cellularBandsVal;
      },
      set(newVal) {
        this.$set(this.valueLine.radio, "bands", newVal);
      },
    },
  },
};
</script>