<template>
  <AddField
    path="serve.php?f=testing&f2=testsExecuteApi"
    :showDialog="dialog"
    :additionalAttributes="{
      function: 'getStartTime',
      selected: selectedTestCases,
      toBeRunFunction: 'executeTest',
      toBeRunFunctionName: 'Run',
    }"
    :dialogAttributes="{}"
    v-on="$listeners">
  </AddField>
</template>
  
<script>
import AddField from '@/commonComponents/addField.vue';
import {shaKey} from '@/js/helper';

export default {
  name: 'SetTimeAndRunDialog',
  components: { AddField },
  props: {
    testCasesIds: Array,
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      selectedTestCases: [],
    };
  },
  methods: {
    shaKey(msg) {
      return shaKey(msg);
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.testCasesIds) return;
        this.dialog = value;
      }
    },
    testCasesIds: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.selectedTestCases = value;
      }
    },
  },
}
</script>
