<template>
  <div :id="rowCustomID" :class="expectColor">
    <template
      v-if="
        row['rawType'] == 'DIAMETER' &&
        row.typePacketInfo != null &&
        isset(row.typePacketInfo.packetinfo) &&
        isset(row.typePacketInfo.packetinfo['dia_cmd'])
      "
    >
      <template v-if="row['signDirection'] == 'out'">
        <span :title="command['c_longRequestName']">{{ row["c_type"] }}</span>
        <span
          v-if="typeof row.c_packet_out.templateName != 'undefined'"
          title="Template Name"
        >
          <br />
          {{ row.c_packet_out.templateName }}
        </span>
      </template>
      <span
        v-else-if="row['signDirection'] == 'in'"
        :title="command['c_longAnswerName']"
        >{{ row["c_type"] }}
      </span>
    </template>
    <template
      v-else-if="
        row['rawType'] == 'RADIUS_ACCESS' ||
        row['rawType'] == 'RADIUS_ACCOUNTING'
      "
    >
      <template v-if="row['signDirection'] == 'out'">
        <span v-html="row['c_type'].replace(/,/g, '<br />')"></span>
        <br />
        <span
          v-if="typeof row.c_packet_out.templateName != 'undefined'"
          title="Template Name"
          >{{ row.c_packet_out.templateName }}</span
        >
      </template>
      <template v-else-if="row['signDirection'] == 'in'">
        <p v-html="row['c_type'].replace(/,/g, '<br />')"></p>
      </template>
    </template>
    <template v-else-if="row['rawType'] == 'LDAP'">
      <template v-if="row['signDirection'] == 'out'">
        {{ row["rawType"] }}-<span
          v-if="typeof row.c_packet_out.type != 'undefined'"
          title="Operator"
          class="text-capitalize"
      >{{ row.c_packet_out.type }}
      </span>
        <span v-if="typeof row.c_packet_out.base != 'undefined'" title="Base">
          <br />
          {{ row.c_packet_out.base }}
        </span>
        <span
          v-if="typeof row.c_packet_out.filter != 'undefined'"
          title="Filter"
        >{{ldapFilter}}
        </span>
      </template>
      <template v-else-if="row['signDirection'] == 'in'">
<!--
         {{language.componentLabel.labelLdapR}}
-->
          LDAP-<span style="text-transform: capitalize">{{ldapResponse}}</span>
        <span
          v-if="typeof row.c_packet_in.LDAP_status != 'undefined'"
          title="Status"
        >
          <br />
          {{ row.c_packet_in.LDAP_status }}
        </span>
      </template>
    </template>
    <template v-else-if="row['rawType'] == 'HTTP'">
      <template v-if="row['n_packetType'] == 2">
        <template v-if="row['signDirection'] == 'in'">
          <span title="Type"> {{ row["rawType"] }} </span>
        <span
            v-if="typeof row.c_packet_in.version != 'undefined'"
            title="Version"
            >{{ row.c_packet_in.version }}
          </span>
          <span v-if="row.c_packet_in.method != 'undefined'" title="Method"
            >{{ row.c_packet_in.method }}
          </span>
          <span
            v-if="typeof row.c_packet_in.templateName != 'undefined'"
            title="Template Name"
          >
            <br />
            {{ row.c_packet_in.templateName }}
          </span>
        </template>
        <template v-else-if="row['signDirection'] == 'out'">
            {{language.componentLabel.labelHttpR}}
          <span
            v-if="typeof row['c_packet_out_show'].status != 'undefined'"
            title="Status"
          >
            <br />
            {{ row["c_packet_out_show"].status }}
          </span>
        </template>
      </template>

      <template v-else>
        <template v-if="row['signDirection'] == 'out'">
          <span title="Type">{{ row["rawType"] }}</span>
          <span
            v-if="typeof row.c_packet_out.version != 'undefined'"
            title="Version"
          >
            {{ row.c_packet_out.version }}</span
          >
          <span
            v-if="typeof row.c_packet_out.method != 'undefined'"
            title="Method"
          >
            {{ row.c_packet_out.method }}</span
          >
          <span
            v-if="typeof row.c_packet_out.templateName != 'undefined'"
            title="Template Name"
          >
            <br />
            {{ row.c_packet_out.templateName }}
          </span>
        </template>
        <template v-else-if="row['signDirection'] == 'in'">
            {{language.componentLabel.labelHttpR}}
          <span
            v-if="typeof row['c_packet_in'].status != 'undefined'"
            title="Status"
          >
            <br />
            {{ row["c_packet_in"].status }}
          </span>
        </template>
      </template>
    </template>
    <template v-else-if="row['rawType'] == 'SSH'">
      {{ row["rawType"] }}
      <span
        v-if="typeof row.c_packet_out.templateName != 'undefined'"
        title="Template Name"
      >
        <br />
        {{row.c_packet_out.templateName}}
      </span>
    </template>
    <template v-else-if="row['rawType'] == 'RADIUS'">
      {{row["rawType"]}} <span class="ml-2">{{translatedCode}}</span>
    </template>
    <div v-else>{{ row['c_type'] }}</div>
  </div>
</template>
<script>
import {getExpectColor} from "@/js/helper.js";
import protocolsMapping from '@/cfg/protocolsMapping.json';


export default {
  props: ["row", "result"],
  data() {
    return {
      protocolsMapping: protocolsMapping,
    }
  },
  computed: {

   rowCustomID(){
       return "rowID_"+this.row['order'];
    },
    expectColor(){
      return getExpectColor(this.row);
    },
    translatedCode(){
       let result ="";
       if(this.row.signDirection==='in' &&
          this.row.c_packet_in_raw!==null &&
          typeof this.row.c_packet_in_raw!=='undefined' &&
          typeof this.row.c_packet_in_raw.code!=='undefined' &&
          this.row.c_packet_in_raw.code!==null){
           //result =  options.radiusCode.find(a => a[0] == this.row.c_packet_in_raw.code)[1];
           result =  this.getAttributeMapping("radiusCode",this.row.c_packet_in_raw.code);
       }else if(this.row.signDirection==='out' &&
           this.row.c_packet_out_show_raw!==null &&
           typeof this.row.c_packet_out_show_raw!=='undefined' &&
           typeof this.row.c_packet_out_show_raw.code !=='undefined'&&
           this.row.c_packet_out_show_raw.code!==null){
           result =  this.getAttributeMapping("radiusCode",this.row.c_packet_out_show_raw.code);
          //result =  options.radiusCode.find(a => a[0] == this.row.c_packet_out_show_raw.code)[1];
       }else if(this.row.signDirection==='in' &&
                typeof this.row.c_packet_in_raw ==='undefined' &&
                typeof this.row.c_packet_in_raw.code ==='undefined' &&
                this.row.c_messages==='Timeout' ){
                  result = "Response Timeout";
       }else if(this.row.signDirection==='out' &&
                typeof this.row.c_packet_out_show_raw ==='undefined' &&
                typeof this.row.c_packet_out_show_raw.code ==='undefined' &&
                this.row.c_messages==='Timeout' ){
                  result = "Request Timeout";
        }else if(this.row.signDirection==='in' &&
                typeof this.row.c_packet_in_raw !=='undefined' &&
                 this.row.c_packet_in_raw!==null &&
                 this.row.c_packet_in_raw.length===0 &&
                 this.row.c_messages==='Timeout' ){
                 result = "Response Timeout";
       }else if(this.row.signDirection==='out' &&
                typeof this.row.c_packet_out_show_raw !=='undefined' &&
                 this.row.c_packet_out_show_raw!==null &&
                 this.row.c_packet_out_show_raw.length===0 &&
                 this.row.c_messages==='Timeout' ){
                 result = "Request Timeout";
       }
       return result;
    },
    command() {
      let dia_cmd = this.row.typePacketInfo.packetinfo["dia_cmd"];
      let command = this.result["additional"]["diameterCommands"][dia_cmd];
      return command;
    },
    ldapFilter(){
      let ldapVal = "";
      if(this.row.c_packet_out.length > 0  && typeof this.row.c_packet_out['filter']!=="undefined"){
        ldapVal = this.row.c_packet_out['filter'];
      }
      return ldapVal;
    },
    ldapResponse(){
      let ldapResponse = "Response";
      if(this.row["c_packet_in_raw"]!==null &&
         typeof this.row["c_packet_in_raw"]!=="undefined" &&
         typeof Object.keys(this.row["c_packet_in_raw"])[0]!=="undefined" &&
         Object.keys(this.row["c_packet_in_raw"])[0]!=="status" &&
         Object.keys(this.row["c_packet_in_raw"])[0]!=="0"
      ){
        ldapResponse = Object.keys(this.row["c_packet_in_raw"])[0];
      }
       return ldapResponse;
    }
  },
};
</script>