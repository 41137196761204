<script setup>
  import { defineProps, defineEmits, defineExpose, ref } from "vue"
  import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue";
  import { debounce } from "lodash-es";

  // type Props = {
  //   label?: string
  //   isDebounced?: boolean
  //   modelValue: string
  // withClear?: boolean
  // }
  const DEBOUNCE_MS = 300;

  const props = defineProps({
    label: {
      type: String,
      default: "Search",
    },
    isDebounced: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
    withClear: {
      type: Boolean,
      default: false,
    },
    hints: {
      type: String,
      default: "",
    },
    errors: {
      type: String,
      default: "",
    },
    autocompleteText: {
      type: String,
      default: "",
    }
  });
  const emit = defineEmits(["update:modelValue"]);

  const inputEl = ref(null);
  const _update = (value) => {
    emit("update:modelValue", value);
  };

  const focus = () => {
    inputEl.value?.focus();
  };

  const blur = () => {
    inputEl.value?.blur();
  };

  const clear = () => {
    emit("update:modelValue", "")
    focus();
  }

  const updateModel = props.isDebounced
    ? debounce(_update, DEBOUNCE_MS)
    : _update;

  defineExpose({
    focus,
    blur,
    clear,
  })
</script>

<template>
  <UiInputWrapper
    class="ui-search-input"
    :label="label"
    :hints="hints"
    :errors="errors"
  >
    <input
      ref="inputEl"
      type="text"
      :value="modelValue"
      placeholder=" "
      @input="(e) => updateModel(e.target.value)"
    />

    <span class="ui-search-input__autocomplete">
      {{ autocompleteText }}
    </span>

    <template #input-slot-right>
      <v-fade-transition
        v-if="withClear"
        mode="out-in"
      >
        <span
          class="ui-search-input__ico-box"
          v-if="!modelValue.length"
        >
          <v-icon class="ui-search-input__ico">mdi-magnify</v-icon>
        </span>

        <button
          v-else
          class="ui-search-input__clear"
          type="button"
          @click="() => clear()"
        >
          <v-icon class="ui-search-input__ico">mdi-close</v-icon>
        </button>
      </v-fade-transition>

      <v-icon
        v-else
        class="ui-search-input__ico"
      >mdi-magnify</v-icon>
    </template>
  </UiInputWrapper>
</template>

<style lang="scss">
  .ui-search-input {
    position: relative;

    &__clear,
    &__ico-box {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    & &__ico {
      color: var(--input-wrapper-icon-color, rgba(0,0,0,.54));
    }

    &__autocomplete {
      position: absolute;
      left: 12px;
      top: 7px;
      display: inline-block;
      //font-size: 12px;
      color: var(--input-wrapper-hint-color, rgba(0,0,0,.54));
    }
  }
</style>
