<template>
    <div>
        <lineFieldsArray
                :fields="fields"
                :value="pairArray"
                @line-field-input="checkInput(...arguments)"
                :headerTitle="true"
                :addable="true"
                :dialogAdder="false"
                addButtTitle="Add Evaluation"
                :updateOnChange="true"
                :templateContent="result"
        ></lineFieldsArray>
    </div>
</template>
<script>
    import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
    import lineButtons from "@/cfg/lineButtons.json";
//import { setupAttribute } from "@/js/helper.js";

    export default {
        components: {lineFieldsArray},
        props: {
            attrsArray: {},
            isTemplate: {type: Boolean, default: false},
            isValid: {type: Boolean, default: false},
            radiusDynamicPars: {},
            result: {type: Object},
            source: {},
            value: {},
        },
        data() {
            return {
              //  reqAttributes:[]
            };
        },
        computed: {
            fields() {
                let fields = {
                    attribute: {
                        type: "combobox",
                        //associatedOption: this.reqAttributes,
                        inputFieldFormatter: "radiusTemplateAttributeInput",
                        name: "Type",
                        fieldAttrInput: {class: "required"},
                        sort: false,
                    },
                    operator: {
                        // input: false,
                        inputFieldFormatter: "attributesOperators",
                        //colAttrs: { style: "max-width: 23px;min-width: 35px;", class: "mt-1" }
                        name: "Operator",
                        fieldAttrInput: {class: "required"},
                    },
                    content: {
                        type: "text",
                        name: "Value",
                        inputFieldFormatter: "evalContentFormatter",
                        fieldAttrInput: {class: "required"},
                    },
            /*      info: {
                    type: "text",
                    name: "Info",
                    fieldAttrInput: { class: "" ,maxlength:'100' },
                  },*/
                    remove: lineButtons.remove,
                };
                return fields;
            },
            pairArray: {
                get() {
                    let pairArray = [];
                    let value = [];
                    if (typeof this.value.add != 'undefined' && Object.keys(this.value.add).length > 0) {
                        value = this.value.add
                    }
                    if (typeof this.value != 'undefined' && Object.keys(this.value).length > 0) {
                        value = this.value
                    }
                    for (const valueArray  of value) {
                        pairArray.push(valueArray);
                    }
                    return pairArray;
                },
            },
        },
        methods: {
 /*           getAttributes() {
                dictionaryService.getRadiusAttributes((attributes) => {
                    this.reqAttributes = setupAttribute(attributes);
                });
            },*/
            checkInput(newValueArray) {
                let request = [];
                newValueArray.forEach((line) => {
                    let insertedLine = {
                        content: line.content,
                        operator: line.operator,
                        attribute: line.attribute,
                       // info: line.info,
                    };
                    request.push(insertedLine);
                });
                this.$emit("input", request);
            },
        },
    };
</script>