<template>
  <div>
    <associatedOption
      type="select"
      :fieldAttrInput="{
        style: 'max-width:240px;',
        'menu-props': { maxHeight: '500' },
        id: 'explorerStatusFilter',
      }"
      :fieldAttributes="{
        name: 'Status Filter',
        associatedOption: result.explorerStatusList,
        ignoreEmptyAdd: true,
        sort: false,
      }"
      v-model="statusSelector"
    ></associatedOption>
    <standbyDialog :localLoadingDialog="loadingOverlay"></standbyDialog>
  </div>
</template>

<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import standbyDialog from "@/commonComponents/standbyDialog.vue";
import requests from '@/js/requests';
export default {
  props: ["currentTable", "result"],
  data() {
    return {
      disabled: false,
      loading: false,
      loadingOverlay: false,
    };
  },
  components: {
    associatedOption,
    standbyDialog,
  },
  created: function () {
    //this.$set(this.currentTable, "dataCount", this.result.dataCount);
    this.$root.$refs.explorerStatusFilter = this;
    this.getTableDataByInterval();
  },
  computed: {
    statusSelector: {
      get() {
        let value = "all";
        if (typeof this.currentTable.selectedStatus != "undefined") {
          value = this.currentTable.selectedStatus;
        }
        return value;
      },
      set(newVal) {
        let currentTable = this.currentTable;
        this.$set(currentTable, "selectedStatus", newVal);
        this.$store.commit("tableChanged", currentTable);
        this.getSelectedStatusDatawithOverLay(newVal);
      },
    },

    url() {
      let url = "";
      if (typeof this.currentTable["tableUrl"] != "undefined") {
        url = this.currentTable["tableUrl"];
      }
      return url;
    },

    id() {
      let id = "dataTable";
      if (typeof this.currentTable["id"] != "undefined") {
        id = this.currentTable["id"];
      }
      return id;
    },
    /*      localOptions: function () {
        let localOptions = [];
        localOptions.push(["all","All"]);
        let n = 7;
        let listOfStatus=  [...Array(n).keys()];
        var caller = this;
        listOfStatus.forEach(function (value) {
          var mapped = caller.getExplorersStatusName(value);
          if(typeof mapped=="undefined"){
            mapped = value;
          }
          localOptions.push([ value,mapped ]);
        });
        return localOptions;
      }*/
  },
  beforeDestroy() {
    this.cancelTableDataByInterval();
  },
  watch: {
    "currentTable.tableUrl": function () {
      //if (to.includes("?f=administration&f2=explorers&online=")) {
      this.getSelectedStatusDatawithOverLay(this.statusSelector);
      // }
    },
    "currentTable.groupFilterExplorer": function () {
      this.getSelectedStatusDatawithOverLay(this.statusSelector);
    },
  },
  methods: {
    getTableDataByInterval() {
      var caller = this;
      this.interval = setTimeout(function () {
        caller.getSelectedStatusData(caller.statusSelector);
        caller.getTableDataByInterval();
      }, 30000);
    },
    cancelTableDataByInterval() {
      clearInterval(this.interval);
    },
    getSelectedStatusDatawithOverLay(status) {
      this.loadingOverlay = true;
      this.getSelectedStatusData(status);
    },
    getSelectedStatusData(status) {
      this.disabled = true;
      if (!this.loading) {
        this.loading = true;
        var uri = this.url;
        var options = {
          function: "getStatusData",
          requestType: "ajax",
          statusType: status,
          testGroupValue:
            typeof this.currentTable.groupFilterExplorer != "undefined" &&
            this.currentTable.groupFilterExplorer != ""
              ? this.currentTable.groupFilterExplorer
              : "",
        };
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: uri,
          data: options,
        })
            .then(function (response) {
            let currentTable = caller.currentTable;
            if (typeof response.data.result != "undefined") {
              caller.$set(
                currentTable,
                "data",
                response.data.result.table.data
              );
              caller.$set(
                currentTable,
                "statusStatisics",
                response.data.result.table.statusStatisics
              );
              //caller.$set(currentTable, "dataCount", response.data.result.table.data.length);
              caller.$store.commit("tableChanged", currentTable);
            }
            caller.disabled = false;
            caller.loading = false;
            caller.loadingOverlay = false;
          })
          .catch(function (response) {
            console.log(response);
          });
      } else {
        this.loadingOverlay = false;
      }
    },
  },
};
</script>
<style>
/* #dataTableexplorers  .buttonsTd {
        min-width: 50px!important;
        max-width: 50px!important;
        width: 50px!important;
    }*/
/* 
@media screen and (max-width: 1700px) {
  /*   #dataTableexplorers  .buttonsTd {
            min-width: 60px!important;
            max-width: 60px!important;
            width: 60px!important;
        } 
  .explorerHeader {
    height: 128px !important;
  }
  .explorerTitle {
    min-width: fit-content;
  }
  #explorerStatusFilter_container {
    position: absolute;
    top: 75px;
    left: 24px;
  }
  .explorerTagFilterContainerClass {
    position: absolute;
    top: 36px;
    left: 264px;
    max-width: 258px;
  }
  .explorerSearch {
    position: relative;
    top: 0px;
    right: 14px;
    width: 100px;
  }
  .explorerClearButt {
    position: absolute;
    top: 12px;
    left: 356px;
  }
} */
</style>