<template>
  <div class="hss-object-adder">
    <v-card outlined>
      <v-toolbar outlined elevation="0" color="primary" dense>
        <v-toolbar-title class="subtitle-2 white--text">
          HSS Objects
        </v-toolbar-title>
      </v-toolbar>
      <XDataTable
          ref="xDataTableRef"
        v-if="loaded"
        class="table"
        :headers="headers"
        dialog-width="1800"
        no-elevation
        no-page-controls
        unpadded-dialog
        show-total
        history
        :refresh="refresh"
        :crud-requests="crudRequests"
        :items-request="testCaseInfo.getHSSObjects"
        :items-request-params="itemsRequestParams"
        :row-actions="rowActions"
        @update:item="item = $event"
        @refresh="$emit('refresh')">
        <template #[`item.key`]="{ value }">
          {{ value.replace(/(\w{2})/g, "$1 ").replace(/"/g, '') }}
        </template>
        <template #[`item.op`]="{ value }">
          {{ value.replace(/(\w{2})/g, "$1 ").replace(/"/g, '') }}
        </template>

      </XDataTable>
    </v-card>
    <HssObjectDialog v-model="hssDialog" :hssObject="hssObject" @save="refreshTable" />
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import HssObjectDialog from '@/components/specific/HssObjectDialog.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import testCaseInfo from "@/js/services/TestCaseInfoService";

export default {
  name: 'HssObjectAdder',
  components: {
    HssObjectDialog,
    XDataTable,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      loaded: false,
      headers: [
        {
          text: 'IMSI',
          value: 'imsi',
          sortable: true,
          width: 200,
        },
        {
          text: 'Key',
          value: 'key',
          sortable: true,
          // width: 1600,
        },
        {
          text: 'OP',
          value: 'op',
          sortable: true,
        },
      ],
      hssDialog: false,
      item: {},
      refresh: 0,
      subscriberId: 0,
    };
  },
  computed: {
    testCaseInfo() {
      return testCaseInfo
    },
    crudRequests() {
      return {
        delete: {
          request: testCaseInfoService.deleteHSSObject,
        }
      };
    },
    hssObject() {
      const hssObject = {};
      if (this.item !== undefined) {
        hssObject['id'] = this.item['id'];
        hssObject['imsi'] = this.item['imsi'] !== undefined ? this.item['imsi'] : '';
        hssObject['key'] = this.item['key'] !== undefined ? this.item['key'].toString().replace(/(\w{2})/g, "$1 ").replace(/"/g, '').trim() : '';
        hssObject['op'] = this.item['op'] !== undefined ? this.item['op'].toString().replace(/(\w{2})/g, "$1 ").replace(/"/g, '').trim() : '';
      }
      if (this.row) {
        hssObject['imsiItems'] = this.imsiNumbers;
        hssObject['msisdn'] = this.row.msisdn;
        hssObject['subscriberName'] = `${this.row.subscriber}` ? `${this.row.msisdn}: ${this.row.subscriber}` : `${this.row.msisdn}`;
        hssObject['subscriberId'] = this.row.subscriberId;
      }
      return hssObject;
    },
    itemsRequestParams() {
      return [this.subscriberId];
    },
    rowActions() {
      return [
        {
          icon: 'mdi-square-edit-outline',
          click: (item) => {
            this.openHSSDialog(item);
          },
        },
      ];
    },
    imsiNumbers() {
      const imsiNumbers = [];

      testCaseInfoService.getSubscriber(this.row.subscriberId, (subscriber) => {
        const subscriberImsi = JSON.parse(subscriber.imsi);
        for (const imsi of subscriberImsi) {
          imsiNumbers.push(imsi['value'].toString());
        }
      });
      return imsiNumbers;
    },
  },
  methods: {
    openHSSDialog(item) {
      this.item = item;
      this.hssDialog = true;
    },
    refreshTable() {
      console.log("Refreshing...");
      this.refresh = 2;

      // setz den Wert auf 0 zurück
      setTimeout(function() {
        this.refresh = 0;
      }, 3000); // nach 3 Sekunden
    },
  },
  watch: {
    row: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value !== undefined) {
          this.subscriberId = value.subscriberId;
          this.loaded = true;
        }
      }
    },
  }
}
</script>

<style scoped>
.table {
  padding-left: 20px;
}
</style>