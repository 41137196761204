
<template>
  <XDialog
    :value="dialog"
    :title="newSubscriber ? 'New Subscriber' : 'Edit Subscriber'"
    width="1200"
    :persistent="modified"
    @input="close"
    @save="$emit('save')"
    @click:outside="close">
    <template #dialog-content>
      <div>
        <v-form ref="subscriberEditForm" class="dialog-content" v-model="valid">
          <XSelect
            class="categories"
            v-model="dataSubscriber.type"
            :items="subscriberTypes"
            label="Type">
          </XSelect>

          <XTextField v-model="dataSubscriber.msisdn" label="Subscriber" />
          <XTextarea v-if="dataSubscriber.type === 0" v-model="subscriberList" label="Subscriber List" />
          <XTextarea v-model="dataSubscriber.description" label="Description" />
          <XSelect
            class="categories"
            v-model="subscriberCategory"
            :items="allCategories"
            label="Category"
            deletable-chips
            dense
            multiple
            combobox
            chips>
          </XSelect>
          
          <div class="d-flex flex-column" style="gap: 7px"  v-if="dataSubscriber.type === 1">
            <ImsiEditor v-model="subscriberImsi" />
            <v-divider  />
            <LdapEditor :row="row" @close-dialogs="close" />
            <v-divider />
            <HssEditor :row="row" @close-dialogs="close" />
          </div>
          <v-spacer></v-spacer>
        </v-form>
        <UnsavedChangedDialog v-model="unsavedChangesDialog" @yes="dialog = false"/>
      </div>
      </template>
      <template #dialog-bottom>
        <InputRow>
          <XTextField
              v-model="dataSubscriber.history.comment"
              label="Commit Message"
              placeholder="Note for versioning"
              class="commit-msg"/>
          <XBtn
            text="Save"
            color="save"
            :disabled="!valid"
            icon="mdi-content-save"
            @click="newSubscriber ? createSubscriber() : save()" />
          <XBtn
              v-if="!newSubscriber"
            text="Save as new"
            color="save"
            icon="mdi-content-save-all"
            @click="createSubscriber" />
          <XBtn
            text="Cancel"
            color="secondary"
            icon="mdi-cancel"
            @click="close" />
        </InputRow>
    </template>
  </XDialog>
</template>
  
<script>
import XBtn from '@/components/basic/XBtn.vue';
import XDialog from '@/components/basic/XDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XTextarea from '@/components/basic/XTextarea.vue';
import InputRow from '@/components/basic/InputRow.vue';
import ImsiEditor from '@/components/specific/ImsiEditor';
import LdapEditor from '@/components/specific/LdapEditor';
import HssEditor from '@/components/specific/HssEditor';
import UnsavedChangedDialog from '@/components/extended/UnsavedChangesDialog';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import {deepCopy} from '@/js/general';

export default {
  name: 'SubscriberEditDialog',
  components: {
    ImsiEditor,
    InputRow,
    HssEditor,
    LdapEditor,
    UnsavedChangedDialog,
    XDialog,
    XBtn,
    XSelect,
    XTextField,
    XTextarea,
  },
  props: {
    row: Object,
    value: Boolean,
    newSubscriber: Boolean,
    subscriberObject: Object,
  },
  data() {
    return {
      allCategories: [],
      dataSubscriber: {
        type: 1,
        imsi: "[]",
        category: "",
        history: {
          comment: "",
          createDate: "",
        },
      },
      dialog: false,
      modified: false,
      originalSubscriber: {}, // to store the original subscriber sent to dialog
      subscriberList: "",
      subscriberType: "",
      subscriberTypes: [{ text: "List", value: 0 }, { text: "Mobile Subscriber", value: 1 }, { text: "SIP", value: 2 }],
      unsavedChangesDialog: false,
      valid: false,
    };
  },
  mounted() {
    this.originalSubscriber = deepCopy(this.dataSubscriber);
  },
  computed: {
    subscriberCategory: {
      get() {
        return JSON.parse(this.dataSubscriber.category).filter((c) => c !== '');
      },
      set(newValue) {
        this.dataSubscriber.category = newValue.length > 0 ? JSON.stringify(newValue) : "[]";
      }
    },
    subscriberImsi: {
      get() {
        const imsiObject =  JSON.parse(this.dataSubscriber.imsi);
        const imsiNumbers = [];
        for (const imsi of imsiObject) {
          imsiNumbers.push({ value: imsi['value'].toString(), type: imsi['type'] !== undefined ? imsi['type'] : '', })
        }
        return imsiNumbers;
      },
      set(newValue) {
        this.dataSubscriber.imsi = newValue.length > 0 ? JSON.stringify(newValue) : "";
      }
    },
  },
  methods: {
    close() {
      if (!this.modified) {
        this.$emit('input', false);
        this.dialog = false;
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    getSubscriber(id) {
      testCaseInfoService.getSubscriber(id, (subscriber) => {
        console.log("Subscriber: ", subscriber);
        this.dataSubscriber = subscriber;
        this.dataSubscriber.history.comment = ""; // reset
        this.originalSubscriber = deepCopy(this.dataSubscriber);
        this.dialog = true; // only when the request is successful
      });
    },
    createSubscriber() {
      testCaseInfoService.newSubscriber(this.dataSubscriber, () => {
        this.modified = false;
        this.close();
        this.modified = false;
        this.$emit('update-subscriber');
      });
    },
    save() {
      console.log("Updating: ", this.dataSubscriber);
      testCaseInfoService.updateSubscriber(
        this.dataSubscriber,
        () => {
          console.log("Subscriber updated!");
          this.$emit('update-subscriber');
          this.modified = false;
          this.close();
        }
      );
    },
  },
  created() {
    testCaseInfoService.getSubscriberCategories((allCategories) => {
      this.allCategories = allCategories.filter(
      // remove whitespace elements
      (cat) => cat !== '').map(
        function(cats) {
          return cats.trim() // remove trailing whitespaces
        });
      // remove duplicates
      this.allCategories = [...new Set(this.allCategories)].sort(
          (a, b) => a.localeCompare(b) // sort strictly alphabetically
        );
    });
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.row || !value) return;
        console.log("Sub row: ", this.row);
        if (value) {
          this.getSubscriber(this.row.subscriberId);
        }
      }
    },
    dialog: {
      immediate: true,
      handler(value) {
        if (!value) this.$emit('input', false);
      }
    },
    dataSubscriber: {
      deep: true,
      handler(value) {
        // Using JSON.stringify() because they are still different objects in memory
        this.modified = JSON.stringify(this.originalSubscriber) !== JSON.stringify(value) && Object.keys(this.originalSubscriber).length > 0;
      }
    }
  }
}
</script>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>