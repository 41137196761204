<template>
  <div>
    <defaultFieldTypes
    v-if="valueLine.type=='echart'"
     :fieldAttributes="fieldAttributes"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :checkReadOnly="checkReadOnly"
      :value="value"
      :valueLine="valueLine"
      :hideDetails="hideDetails"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import requests from '@/js/requests';

export default {
  props: ["result", "fieldAttributes", "field","value","checkReadOnly","valueLine","hideDetails"],
  components: { defaultFieldTypes },
  watch: {
    value() {
      this.getMetric();
      //this.emptyFilter();
    },

  },
  methods: {
  /*  emptyFilter(){
        this.$set(this.valueLine, "filter", "");
    },*/
    getMetric() {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=administration&f2=dashboard',
        data: {
          requestType: 'ajax',
          function: 'getMetric',
          chartId: this.value,
        },
      })
          .then(function (response) {
          try {
            if(typeof response.data.result.json.metric!='undefined'){
              caller.$set(caller.valueLine, "metric", response.data.result.json.metric);
            }
             console.log(response.data.result.json.metric);
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
}
</script>