<template>
  <InputRow>
    <XTextField
        label="Start Within Minutes"
        :value="value.startWithinMinutes"
        type="int"
        :default="10"
        required
        @input="emitInput('startWithinMinutes', $event)"/>
    <XTextField
        label="Finished Within Minutes"
        :value="value.finishedWithinMinutes"
        type="int"
        :default="10"
        required
        @input="emitInput('finishedWithinMinutes', $event)"/>
    <XTextField
        label="Maximum Duration (s)"
        :value="value.maxDuration"
        type="int"
        :default="120"
        required
        @input="emitInput('maxDuration', $event)"/>
  </InputRow>
</template>

<script>
import InputRow from '@/components/basic/InputRow.vue';
import XTextField from '@/components/basic/XTextField.vue';

export default {
  name: 'StartFinishedMaximum',
  components: {
    XTextField,
    InputRow,
  },
  props: {
    value: Object,
  },
  methods: {
    emitInput(key, value) {
      this.$emit('input', {
        ...this.value,
        [key]: value,
      });
    },
  },
};
</script>

<style scoped>

</style>