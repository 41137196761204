<template>
  <fields :result="result" v-on="$listeners">
    <template v-slot:default="fieldsProps">
      <v-row
              class="ma-2 mt-3"
        ><v-col>
          <p>
              To convert your Single Board Computer into a Degust Explorer network probe, you need to configure and download the most current version of the Degust® Explorer OS appliance image.
          </p>
          <p style="margin: 0pt 0pt 0px; padding: 0pt;">
              Tags will be assigned to group the Explorers for testing and statistics. Tags may assign a location, a testing purpose, a tariff name, or anything else.
          </p>
          <p style="margin: 0pt 0pt 0px; padding: 0pt;">
              They may look like this:
          </p>
          <span class="mr-2" style="background-color: rgba(128,128,128,0.27);">location=cologne</span>
          <span class="mr-2" style="background-color: rgba(128,128,128,0.27);">department=QA</span>
          <span class="mr-2" style="background-color: rgba(128,128,128,0.27);">tariff=fibre500</span>
          <span class="mr-2" style="background-color: rgba(128,128,128,0.27);">iperf</span>


          <!--<v-chip
              class="ma-1"
              close
              filter
              label
      >location=cologne
      </v-chip>
          <v-chip
                  class="ma-1"
                  close
                  filter
                  label
          >department=QA
          </v-chip>
          <v-chip
                  class="ma-1"
                  close
                  filter
                  label
          >iperf
          </v-chip>-->
          <p>
              To enter a tag, just write the name and press the ENTER key or select a tag from the list of previously used tags, which pops up as soon you start typing.
          </p>
          <p>
              Explorers will get a numeric hostname with a 3-character prefix of your choice.
          </p>
          <a target="_blank" href="https://service.acctopus.com/portal/en/kb/articles/install-degust-explorer-on-sd-card">Read full instructions
          </a>
        </v-col>
        <v-col>
          <formTable
            :result="fieldsProps.result"
            :valueLine="fieldsProps.result.valueLine"
            :isValid="fieldsProps.isValid"
          ></formTable>
          <fieldsButtons
            :result="fieldsProps.result"
            :isValid="fieldsProps.isValid"
            @submitted="
              fieldsProps.submitted(...arguments);
              countDown();
            "
          ></fieldsButtons>
        </v-col>
      </v-row>

     <!-- <v-divider></v-divider>
      <p>
        Preparation may take up to 30 seconds and the download should start
        immediately. You will get a *.img file, which has to be copied onto an
        SD card for your Raspberry Pi 4 using a suitable tool for your system
        (e.g.
        <a target="_blank" href="https://win32diskimager.org/"
          >Win32 Disk Imager</a
        >,
        <a target="_blank" href="https://www.balena.io/etcher/">Balena Etcher</a
        >,
        <a target="_blank" href="https://www.raspberrypi.org/software/"
          >Raspbian Foundations Imager</a
        >, or even
        <a
          target="_blank"
          href="https://www.man7.org/linux/man-pages/man1/dd.1.html"
          >dd</a
        >
        on Linux).
      </p>

      <p>Any card with a size of 4 GB or bigger is fine.</p>

      <p class="red&#45;&#45;text">
        We highly recommend using a high or max endurance card of well-known
        brands. Cheap products or old cards may come up with errors after a
        while. As your Explorers are distributed over your network, for most
        operators even nationwide, you don’t want to call the system in for
        refurbishment due to a malfunction of your SD cards.
      </p>-->
      <v-overlay :absolute="true" :value="overlay">
        <div>
          <h1>
            <p class="text-center">
              We are customizing your Explorer OS image.
            </p>
            <br />
            <p class="text-center">Please be patient.</p>
            <br />
            <p class="text-center">
              You will get the download link in this browser tab.
            </p>
            <p class="text-center">
              Please do not use the back or reload function of your browser.
            </p>
            <div class="mt-12">
              <p class="text-center" style="font-size: 70px">
                {{ content }}
              </p>
            </div>
              <div style="text-align: center;" class="mt-12">
              <a target="_blank" href="https://service.acctopus.com/portal/en/kb/articles/install-degust-explorer-on-sd-card">Read full instructions
              </a>
          </div>
          </h1>
        </div>

      </v-overlay>
    </template>
    <template v-slot:fieldActions><div></div></template>
  </fields>
</template>
<script>
import fields from "@/commonComponents/dynamic/fields.vue";
import formTable from "@/commonComponents/formTable.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
export default {
  components: {
    fields,
    formTable,
    fieldsButtons,
  },

  props: ["result"],
  data() {
    return {
      content: "",
      totalTime: 120,
      overlay: false,
      // stillProccessing: false,
    };
  },
  created: function () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "dialogResultChanged") {
        this.overlay = false;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    countDown() {
      //this.stillProccessing = false;
      //console.log("coundt down");
      this.overlay = true;
      this.content = this.totalTime + "s";
      let clock = setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s";
        if (this.totalTime < 0) {
          //this.stillProccessing = true;
          window.clearInterval(clock);
          this.content = "Still processing.. few more seconds to wait";
          this.totalTime = 120;
          // this.overlay = false;
          //this.$store.commit("closeDialog", this.result.dialogRef);
        }
      }, 1000);
    },
  },
};
</script>