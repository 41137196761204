<template>
  <div>
    <v-card class="mb-6 mt-3" outlined>
      <v-list-item-title class="pa-2 subtitle-2">{{
          onEvent
        }}</v-list-item-title>
      <v-container class="d-flex">
        <lineFieldsContainer
            :fieldAttrRow="{}"
            :fields="fields"
            :valueLine="valueLine"
            @field-line-changed="checkEventValue(...arguments)"
            :templateContent="result"
        ></lineFieldsContainer>
        <HelpButton id="onTimeout_block"></HelpButton>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import lineFieldsContainer from "@/commonComponents/lineFieldsContainer.vue";
import HelpButton from "../basic/HelpButton.vue";
export default {
  components: {HelpButton, lineFieldsContainer },

  props: {
    resultArray: {
      type: Array,
      default: function () {
        return options.onTimeoutResult;
      },
    },
    onEvent: {},
    value: {},
    result: {},
  },
  computed: {
    valueLine() {
      if (
          typeof this.value != "undefined" &&
          Object.keys(this.value).length != 0
      ) {
        return this.value;
      } else {
        let line = { action: "stopTest", result: "runtime_error" };
        this.$emit("input", line);
        return line;
      }
    },
    fields() {
      let fields = {
        result: {
          type: "select",
          associatedOption: this.resultArray,
          name: "",
          default: "runtime_error",
          fieldAttrInput: { class: "required" },
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
      };
      return fields;
    },
  },
  methods: {
    checkEventValue(line) {
      this.$emit("input", line);
    },
  },
};
</script>