<template>
  <div class="x-tree">
    <div v-for="(header, i) of headers" :key="i">
      <div v-if="header.type === 'value'" class="x-tree-value">
        <v-icon>mdi-square-medium</v-icon>
        <div class="x-tree-text">{{ getFormattedValueByHeader(value, header) }}</div>
      </div>
      <div v-if="header.type === 'pair'" class="x-tree-pair">
        <v-icon>mdi-square-medium</v-icon>
        <div class="x-tree-pair-pair">
          <div class="x-tree-text">{{ header.text }}:</div>
          <div class="x-tree-pair-value">{{ getFormattedValueByHeader(value, header) }}</div>
        </div>
      </div>
      <div v-if="header.type === 'json'" class="x-tree-json">
        <div class="x-tree-json-headline" @click="toggleHeader(header)">
          <v-icon class="x-tree-expand-box">
            {{ `mdi-${!collapsedHeaders[header.value] ? 'minus' : 'plus'}-box-outline` }}
          </v-icon>
          <div class="x-tree-text">{{ header.text }}:</div>
        </div>
        <json-viewer
            v-if="!collapsedHeaders[header.value]"
            :value="JSON.parse(getFormattedValueByHeader(value, header))"
            :expand-depth="1000"
            copyable
            class="x-tree-json-viewer"/>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import { getFormattedValueByHeader } from "@/js/general";

export default {
  name: 'XTree',
  components: {
    JsonViewer,
  },
  props: {
    value: Object,
    headers: Array,
  },
  data() {
    return {
      collapsedHeaders: {},
      getFormattedValueByHeader: getFormattedValueByHeader,
    };
  },
  methods: {
    toggleHeader(header) {
      this.$set(this.collapsedHeaders, header.value, !this.collapsedHeaders[header.value]);
    },
  },
};
</script>

<style scoped>
.x-tree {
  display: flex;
  flex-direction: column;
  font-family: monospace;
}

.x-tree-text {
  font-weight: bold;
  font-size: 16px;
  color: var(--v-tree-text-base);
}

.x-tree-value,
.x-tree-pair {
  display: flex;
  align-items: center;
}

.x-tree-pair-pair {
  display: flex;
  gap: 10px;
}

.x-tree-pair-value {
  font-size: 16px;
  color: var(--v-tree-text-base);
}

.x-tree-json {
  display: flex;
  flex-direction: column;
}

.x-tree-json-headline {
  display: flex;
  cursor: pointer;
}

.x-tree-expand-box {
  font-size: 14px;
  width: 24px;
}

.x-tree-json-viewer.jv-container.jv-light {
  border: 1px solid var(--v-tree-content-border-base);
  background-color: var(--v-tree-content-background-base);
}

.x-tree-json-viewer.jv-container >>> .jv-code {
  padding: 10px 14px;
  max-height: 500px;
  overflow: auto;
}
</style>