<template>
  <div>
    <div class="additionalFields connectionContainer">
      <httpClientServer :contentID="stepType" :result="result" :stepStoredValue="localStoredStep"
                        :stepType="stepType" @revalidate="$emit('revalidate')"
                        @parameter-changed="updateKey('parameters', ...arguments)"></httpClientServer>
      <div :id="additionalId" class="httpContentWrapper">
        <div class="mainAttributesContainer">
          <v-card :outlined="true" elevation="0">
            <v-card-subtitle>Defined in Template</v-card-subtitle>
            <v-card-text>
              <HttpHeaderContent :disabled="true" :result="result" :value="mergedTemplate.parameters"/>
            </v-card-text>
          </v-card>
          <configContainer title="Request Packet Definition">
            <template v-slot:content>
              <HttpStepRequest :isValid="isValid" :mergedTemplate="mergedTemplate" :originalTemplate="originalTemplate"
                               :result="result" :stepID="stepID" :stepStoredValue="localStoredStep" :stepType="stepType"
                               packetType="HTTP" @revalidate="$emit('revalidate')" v-on="$listeners"
                               @header-changed="updateRequestKey('header', ...arguments)"
                               @body-changed="updateRequestKey('body', ...arguments)"
                               @parameter-changed="updateRequestKey('parameters', ...arguments)"/>
            </template>
          </configContainer>
        </div>
        <httpResponse :idPrefix="stepType + '_' + stepID + '_httpResponse'" :isValid="isValid"
                      :localStoredStep="localStoredStep" :result="result" v-on="$listeners"></httpResponse>
      </div>
    </div>
  </div>
</template>
<script>
import httpResponse from '@/components/legacy/httpResponse.vue';
import configContainer from '@/commonComponents/configContainer.vue';
import HttpHeaderContent from '@/components/legacy/HttpHeaderContent.vue';
import HttpStepRequest from '@/components/legacy/HttpStepRequest.vue';
import httpClientServer from '@/components/legacy/httpClientServer.vue';

import {buildHTTPStoredValuesTemplate} from '../../js/testStepsHelpers/commonHttp';
import {getRandomInt} from '../../js/helper';
import requests from '@/js/requests';

export default {
  components: {
    httpResponse,
    httpClientServer,
    HttpHeaderContent,
    HttpStepRequest,
    configContainer,
  },
  data: function () {
    return {
      originalTemplate: {},
    };
  },
  created() {
    if (typeof this.stepStoredValue.parameters != 'undefined') {
      this.getOriginalTemplate(this.stepStoredValue.parameters.templateId);
    }
  },
  watch: {
    'stepStoredValue.parameters.templateId': function (newVal) {
      this.getOriginalTemplate(newVal);
    },
  },
  computed: {
    localStoredStep: {
      get() {
        let localStoredStep = {
          parameters: {connectionId: '', templateId: ''},
          req: {
            body: {},
            header: {},
            parameters: [],
          },
          res: {
            onTimeout: {retry: '0', action: 'stopTest', result: 'error'},
            eval: {
              ExpectedStatusType: '200',
              OnWrongStatusType: {action: 'continue', result: 'error'},
              OnDecodeError: {action: 'continue', result: 'error'},
              DecodeAs: 'JSON',
              Header: {},
              Body: {},
            },
          },
        };
        if (typeof this.stepStoredValue != 'undefined') {
          localStoredStep = this.stepStoredValue;
          if (typeof localStoredStep['req'] == 'undefined') {
            this.$set(localStoredStep, 'req', {
              body: {},
              header: {},
              parameters: [],
            });
          }
          if (typeof localStoredStep['res'] == 'undefined') {
            this.$set(localStoredStep, 'res', {
              onTimeout: {retry: '0', action: 'stopTest', result: 'error'},
              eval: {
                ExpectedStatusType: '200',
                OnWrongStatusType: {action: 'continue', result: 'error'},
                OnDecodeError: {action: 'continue', result: 'error'},
                DecodeAs: 'JSON',
                Header: {},
                Body: {},
              },
            });
          }
        }
        return localStoredStep;
      },
      set(newValue) {
        console.log('new local value set');
        this.$emit('stored-value-changed', newValue);
      },
    },
    additionalId: function () {
      return 'additionalFields' + getRandomInt(0, 1000);
    },
    mergedTemplate() {
      let mergedTemplate = {};
      if (this.originalTemplate != null) {
        mergedTemplate = buildHTTPStoredValuesTemplate(
            this.originalTemplate,
            this.localStoredStep,
            this.stepType,
        );
      }
      return mergedTemplate;
    },
    templateId() {
      let templateId = '';
      if (
          typeof this.localStoredStep != 'undefined' &&
          typeof this.localStoredStep.parameters != 'undefined'
      ) {
        templateId = this.localStoredStep.parameters.templateId;
      }
      return templateId;
    },
  },
  methods: {
    getOriginalTemplate(templateId) {
      let originalTemplate = {};
      let additionalStepsInfo = this.result.additional.additionalStepsInfo;
      if (typeof additionalStepsInfo != 'undefined' && typeof additionalStepsInfo['HTTP'] != 'undefined'
          && typeof additionalStepsInfo['HTTP']['templates'] != 'undefined'
          && typeof additionalStepsInfo['HTTP']['templates'][templateId] != 'undefined') {
        this.originalTemplate = JSON.parse(additionalStepsInfo['HTTP']['templates'][templateId])['HTTP'];
      } else {
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: {
            function: 'getHTTPTemplate',
            templateId: templateId,
          },
        }).then(function (post) {
          let additionalStepsInfo = post.data.result.json;
          if (typeof additionalStepsInfo['HTTP']['templates'] != 'undefined') {
            try {
              originalTemplate = JSON.parse(additionalStepsInfo['HTTP']['templates'][caller.templateId])['HTTP'];
            } catch (error) {
              originalTemplate = {};
            }
            caller.originalTemplate = originalTemplate;
            if (originalTemplate.req && originalTemplate.req.parameters
                && originalTemplate.req.parameters['content-type'] !== 'JSON') {
              caller.updateRequestKey('body', originalTemplate.req.body);
            }
          }
          caller.$set(caller.result.additional.additionalStepsInfo, caller.stepType, {
                ...caller.result.additional.additionalStepsInfo.HTTP,
                ...additionalStepsInfo['HTTP'],
              },
          );
          caller.$store.commit('dialogResultChanged', caller.result);
          caller.$nextTick(function () {
            caller.$emit('revalidate');
          });
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    updateRequestKey(index, newVal) {
      if (typeof this.localStoredStep['req'] == 'undefined') {
        this.$set(this.localStoredStep, 'req', newVal);
      }
      this.$set(this.localStoredStep['req'], index, newVal);
      this.$emit('stored-value-changed', this.localStoredStep);
    },
    updateKey(index, newVal) {
      this.$set(this.localStoredStep, index, newVal);
      this.$emit('stored-value-changed', this.localStoredStep);
    },
  },
  props: [
    'result',
    'stepStoredValue',
    'additionalStepsInfo',
    'stepType',
    'stepID',
    'isValid',
  ],
};
</script>