<script setup>
  import UiDialog from "@/components/basic/dialog/UiDialog.vue"
  import { inject, defineProps } from "vue"
  import XBtn from "@/components/basic/XBtn.vue"

  defineProps({
    yesBtnText: {
      type: String,
      default: "Yes",
    },
    noBtnText: {
      type: String,
      default: "No",
    },
    title: {
      type: String,
      default: "Really close?",
    },
    question: {
      type: String,
      default: "You have unsaved changes, really close?",
    }
  })

  const { getCloseMethod } = inject("DialogsRoot")
  const closeModal = getCloseMethod("YesNoDialog2")
</script>

<template>
  <UiDialog
    :title="title"
    class="yes-no-dialog-2"
    size="x-small"
    @close-modal="() => closeModal({ isYes: false })"
  >
    <template #body>
      <p class="yes-no-dialog-2__question">
        {{ question }}
      </p>
    </template>

    <template #footer>
      <div class="yes-no-dialog-2__footer-box">
        <XBtn
          :text="yesBtnText"
          icon="mdi-check"
          color="primary"
          @click="() => closeModal({ isYes: true })"
        />

        <XBtn
          :text="noBtnText"
          icon="mdi-close"
          color="secondary"
          @click="() => closeModal({ isYes: false })"
        />
      </div>
    </template>
  </UiDialog>
</template>

<style lang="scss">
  .yes-no-dialog-2 {
    &__question {
      text-align: center;
    }

    &__footer-box {
      display: flex;
      justify-content: center;
      flex: 1;
      gap: 10px;
    }
  }
</style>
