<template>
  <div ref="listEditor" class="list-editor">
    <div v-for="(row, i) of dataValue" :key="i" class="list-editor-row">
      <ObjectInputRow
          :value="dataValue[i]"
          :id="i"
          :columns="columns"
          :validate-immediately="validateImmediately"
          :focused-element-index="focusedElementIndex"
          class="list-editor-input-row"
          @input="emitInput(i, $event)"
          @validate="emitValidate(i, $event)"
          :help="help"
      />

      <XBtn v-if="showDeleteButton()" :iconAfterText="true" color="red" icon="mdi-delete" @click="deleteRow(i)"/>

      <div v-else class="fixed-width" style="width: 36px; height: 36px"/>
    </div>

    <XBtn
        v-if="!full"
        :text="`Add ${rowName === undefined ? 'item' : rowName}`"
        color="primary"
        icon="mdi-plus"
        @click="addRow"/>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import XBtn from '@/components/basic/XBtn';
import ObjectInputRow from '@/components/basic/ObjectInputRow';
import {deepCopy, deepEquals} from '@/js/general';

export default defineComponent({
  name: 'ListEditor',
  components: {
    ObjectInputRow,
    XBtn,
  },
  props: {
    columns: Array,
    value: Array,
    rowName: String,
    min: [Number, String],
    max: [Number, String],
    persistentListLength: Boolean,
    focusedElementIndex: {
      type: Number,
      default: -1,
    },
    validateImmediately: Boolean,
    help: String,
  },
  data() {
    return {
      dataValue: [],
      dataMin: 0,
      dataMax: -1,
      validRows: {},
    };
  },
  created() {
    for (let i = this.dataValue.length; i < this.dataMin && !this.full; i++) {
      this.addRow();
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!deepEquals(value, this.dataValue)) {
          if (value) this.dataValue = deepCopy(value);
          else this.dataValue = [];
        }
      },
    },
    min: {
      immediate: true,
      handler(value) {
        value = parseInt(value);
        if (value !== this.dataMin) {
          if (isNaN(value)) value = 0;
          this.dataMin = value;
        }
      },
    },
    max: {
      immediate: true,
      handler(value) {
        value = parseInt(value);
        if (value !== this.dataMax) {
          if (isNaN(value)) value = -1;
          this.dataMax = value;
        }
      },
    },
  },
  computed: {
    full() {
      return this.dataMax !== -1 && this.dataValue.length >= this.dataMax || this.persistentListLength;
    },
  },
  methods: {
    addRow() {
      const row = {};
      for (const column of this.columns) {
        if (column.default !== undefined) row[column.value] = column.default;
        else {
          const type = typeof column.type === 'function' ? column.type() : column.type;
          switch (type) {
            case 'text':
            case 'select':
              row[column.value] = '';
              break;
            case 'int':
              row[column.value] = 0;
              break;
            case 'float':
              row[column.value] = 0.0;
              break;
            case 'switch':
              row[column.value] = false;
              break;
            case 'span':
            case 'spacer':
              break;
            default:
              console.error(`No default value for type '${column.type}'.`);
              break;
          }
        }
      }
      this.dataValue.push(row);
      this.$emit('input', deepCopy(this.dataValue));
    },
    deleteRow(i) {
      this.dataValue.splice(i, 1);
      this.$emit('input', deepCopy(this.dataValue));
      this.$emit('revalidate');
    },
    showDeleteButton() {
      return (this.dataMin === 0 || this.dataValue.length > this.dataMin) && !this.persistentListLength;
    },
    checkCondition(column, row, i) {
      return column.condition === undefined
          || typeof column.condition === 'boolean' && column.condition
          || typeof column.condition === 'function' && column.condition(row, i);
    },
    getColumnWidth() {
      return `calc((100% - ${12 * this.columns.length + 36}px) / ${this.columns.length}`;
    },
    emitInput(index, value) {
      const result = deepCopy(this.value);
      result[index] = value;
      this.dataValue = result;
      this.$emit('input', result);
    },
    emitValidate(index, value) {
      this.validRows[index] = Object.values(value).find(x => x === false) === undefined;
      this.$emit('validate', this.validRows);
    }
  },
});
</script>

<style scoped>
.list-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-editor-row {
  display: flex;
  gap: 10px;
}

.list-editor-input-row {
  width: 100%;
}

.fixed-width {
  flex: 0 0 auto;
}
</style>
