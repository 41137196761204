<template>
  <XDataTable
      title="Received Messages (Kafka)"
      :headers="headers"
      :items-request="subscriberMailbox.getReceivedMessagesKafka"
      search>
    <template #[`item.text`]="{value}">
      <pre class="kafka-text-area" v-html="prettyPrintJSON(value)"/>
    </template>
  </XDataTable>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import subscriberMailbox from "@/js/services/SubscriberMailboxService";
import { prettyPrintJSON, unixToDateTimeString } from '@/js/general';

export default {
  name: 'ReceivedMessagesKafkaView',
  computed: {
    subscriberMailbox() {
      return subscriberMailbox
    }
  },
  components: {
    XDataTable,
  },
  data() {
    return {
      headers: [
        {
          text: 'Time',
          value: 'time',
          formatter: value => unixToDateTimeString(value),
          sortable: true,
          width: 150,
        },
        {
          text: 'Topic',
          value: 'topic',
          sortable: true,
          width: 160,
        },
        {
          text: 'Message',
          value: 'text',
          sortable: true,
        },
      ],
      prettyPrintJSON: prettyPrintJSON,
    };
  },
};
</script>

<style scoped>
.kafka-text-area {
  width: 100%;
  height: 200px;
  overflow: auto;
}
</style>