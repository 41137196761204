<template>
  <HeadlineBox :headline="title">
    <template #after-headline>
      <XSwitch
          v-if="Object.keys(templateRows).length"
          v-model="showTemplates"
          :label="language.componentLabel.labelShowTemplate"
          class="show-template-switch"
          color="save"
          front-label/>
    </template>
    <div class="headline-box-content">
      <slot name="top"/>
      <div v-if="attributes.length" class="attribute-list-editor">
        <template v-if="anyTemplateRowsShown">
          <div v-for="(v, k, i) in templateRows" :key="`template-${i}`" class="attribute-list-rows">
            <template v-if="templateRows[k].length">
              <div v-for="(radiusAttribute, j) of templateRows[k]" :key="j" class="attribute-list-row">
                <template v-if="showTemplates || radiusAttribute.inputRequired || value.deactivated.includes(radiusAttribute.id)">
                  <RadiusRequestTemplateAttribute
                      :value="{attribute: parseInt(k), content: radiusAttribute.content,tag:radiusAttribute.tag}"
                      :attributes="attributes"
                      :deactivated="value.deactivated.includes(radiusAttribute.id)"
                      @input="emitByTemplate($event, radiusAttribute.id)"/>
                  <XSwitch
                      label="Deactivate"
                      :value="value.deactivated.includes(radiusAttribute.id)"
                      @input="updateDeactivatedTemplateRows(radiusAttribute.id, $event)"
                      class="last-row-element"/>
                </template>
              </div>
            </template>
          </div>
        </template>
        <div v-for="(v, k, i) in value.add" :key="i" class="attribute-list-rows">
          <div v-for="(radiusAttribute, j) of value.add[k]" :key="j" class="attribute-list-row">
            <RadiusAttribute
                :value="{attribute: parseInt(k), content: radiusAttribute.content,tag:radiusAttribute.tag}"
                @input="emitInput(k, j, $event)"
                :attributes="attributes"/>
            <XBtn icon="mdi-delete" color="red" :iconAfterText="true" @click="deleteAttribute(k, j)" class="last-row-element"/>
          </div>
        </div>
        <XBtn text="Add attribute" icon="mdi-plus" color="primary" @click="addAttribute"/>
      </div>
    </div>
  </HeadlineBox>
</template>

<script>
import RadiusAttribute from '@/components/specific/TestStep/RadiusRequestAttribute.vue';
import HeadlineBox from '@/components/basic/HeadlineBox.vue';
import XSwitch from '@/components/basic/XSwitch.vue';
import XBtn from '@/components/basic/XBtn.vue';
import RadiusRequestTemplateAttribute from '@/components/specific/TestStep/RadiusRequestTemplateAttribute.vue';
import dictionaryService from "@/js/services/DictionaryService";
import {deepCopy} from '@/js/general';

export default {
  name: 'RadiusPacket',
  components: {
    RadiusRequestTemplateAttribute,
    XBtn,
    XSwitch,
    HeadlineBox,
    RadiusAttribute,
  },
  props: {
    value: Object,
    title: {
      type: String,
      default: 'RADIUS Packet',
    },
    templateRows: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showTemplates: false,
      attributes: [],
    };
  },
  created() {
    dictionaryService.getRadiusAttributes((attributes) => {
      this.attributes = attributes;
    });
  },
  computed: {
    anyTemplateRowsShown() {
      if (this.showTemplates) return true;
      for (const templateRows of Object.values(this.templateRows)) {
        for (const templateRow of templateRows) {
          if (templateRow.inputRequired || this.value.deactivated.includes(templateRow.id)) return true;
        }
      }
      return false;
    },
  },
  methods: {
    emitInput(attribute, index, value) {
      attribute = parseInt(attribute);
      const newValue = deepCopy(this.value);
      if (value.attribute !== attribute) {
        newValue.add[attribute].splice(index, 1);
        if (!newValue.add[attribute].length) {
          delete newValue.add[attribute];
        }
        if (newValue.add[value.attribute]) {
          index = newValue.add[value.attribute].length;
        }
      }
      if (!newValue.add[value.attribute]) {
        newValue.add[value.attribute] = [];
        index = 0;
      }
      newValue.add[value.attribute][index] = {content: value.content,tag: value.tag};
      this.$emit('input', newValue);
    },
    addAttribute() {
      let index = 0;
      if (this.value.add[1]) index = this.value.add[1].length;
      this.emitInput(1, index, {
        attribute: 1,
        content: '',
      });
    },
    deleteAttribute(k, j) {
      const newValue = deepCopy(this.value);
      newValue.add[k].splice(j, 1);
      this.$emit('input', newValue);
    },
    emitByTemplate(value, id) {
      let index = 0;
      if (this.value.add[value.attribute]) index = this.value.add[value.attribute].length;

      const attribute = parseInt(value.attribute);
      const newValue = deepCopy(this.value);
      if (value.attribute !== attribute) {
        newValue.add[attribute].splice(index, 1);
        if (!newValue.add[attribute].length) {
          delete newValue.add[attribute];
        }
      }
      if (!newValue.add[value.attribute]) newValue.add[value.attribute] = [];
      newValue.add[value.attribute][index] = {content: value.content,tag: value.tag};

      if (value) newValue.deactivated.push(id);
      else newValue.deactivated = newValue.deactivated.filter(x => x !== id);

      this.$emit('input', newValue);
    },
    updateDeactivatedTemplateRows(id, value) {
      const newValue = deepCopy(this.value);
      if (value) newValue.deactivated.push(id);
      else newValue.deactivated = newValue.deactivated.filter(x => x !== id);
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
.show-template-switch {
  justify-content: flex-end;
}

.headline-box-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.attribute-list-editor,
.attribute-list-rows {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.attribute-list-row {
  display: flex;
  gap: 10px;
}

.radius-attribute {
  flex: 1 0 auto;
}

.last-row-element {
  width: 113px;
}
</style>