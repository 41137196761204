<template>
  <table class="test-info-table">
    <template v-if="!horizontal">
      <tr class="test-info-table__row test-info-table__row--head">
        <th
          v-for="(header, i) of headers"
          :key="i"
          class="test-info-table__cell test-info-table__cell--head"
        >
          {{ header.text }}
        </th>
      </tr>

      <tr
        v-for="(item, itemIndex) of items"
        :key="itemIndex"
        class="test-info-table__row"
      >
        <td
          v-for="(header, headerIndex) of headers"
          :key="headerIndex"
          class="test-info-table__cell"
        >
          <template v-if="!header.formatter">
            {{ item[header.value] }}
          </template>

          <template v-else>
            {{ header.formatter(item[header.value], item) }}
          </template>
        </td>
      </tr>
    </template>

    <template v-else>
      <!-- Horizontal Table Layout -->
      <tr
        v-for="(header, headerIndex) of headers"
        :key="headerIndex"
        class="test-info-table__row test-info-table__row--horizontal"
      >
        <th class="test-info-table__cell test-info-table__cell--head test-info-table__cell--horizontal">
          {{ header.text }}
        </th>

        <td
          v-for="(item, itemIndex) of items"
          :key="itemIndex"
          :class="{
            'test-info-table__cell': true,
            'test-info-table__cell--horizontal': true,
            'test-info-table__cell--horizontal-first-cell': headerIndex === 0,
          }"
        >
          <template v-if="!header.formatter">
            {{ valueMapping(item[header.value]) }}
          </template>

          <template v-else>
            {{ header.formatter(item[header.value], item) }}
          </template>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import { defineComponent, computed } from "vue"

export default defineComponent({
  name: "TestInfoTable",

  props: {
    headers: Array,
    items: Array,
    horizontal: Boolean,
    stepType: { String , default: undefined }
  },

  setup(props) {
    const styleClass = computed(() => {
      if (props.horizontal) {
        return "test-info-table horizontal"
      }
      return "test-info-table vertical"
    })

    return {
      styleClass,
    }
  },
  methods: {
    valueMapping(value){
      if(this.stepType=='Explorer_cellular_attach' || this.stepType=='Explorer_cell_info'){// in future make it common for all steps attr trans
        value = this.attributeTranslations(this.stepType,value)
      }
      return value;
    },
  }
});
</script>

<style lang="scss">
.test-info-table {
  $root: &;
  border-collapse: collapse;

  // TODO: Remove once getting rid of Vuetify
  // this styles are used to override Vuetify's default styles
  &__row {
    &:hover {
      #{$root}__cell {
        border-top-right-radius: 0px !important;
      }
    }
  }

  &__cell {
    padding: 4px;
    text-align: center;
    border: 1px solid var(--v-table-border-base);
    font-weight: 400;

    &--head {
      text-align: center;
      color: white;
      background-color: var(--v-primary-base);

      &:not(:last-child) {
        border-right: 1px solid white;
      }

      &:first-child {
        border-left: 1px solid var(--v-primary-base);
      }
    }

    &--head#{&}--horizontal {
      text-align: left;

      &:not(:last-child) {
        border-bottom: 1px solid white;
      }
    }

    &--horizontal-first-cell {
      color: white;
      background-color: var(--v-primary-base);
    }
  }
}
</style>
