<template>
  <div>
    <x-data-table
        :custom-valid="customValid"
        :headers="headers"
        :item="alertItem"
        :loading-item="loadingItem"
        :row-editing-disabled="rowEditingDisabled"
        :row-switch="true"
        dialog-width="1500"
        item-name="Alert"
        title="Alert Configuration"
        @dialog-reload="handleDialogReload"
        @dialog-open="handleDialogOpen"
        @dialog-before-save="prepareAlertForSaving">
      <template v-slot:form>
        <div>
          <div class="form-top">
            <div class="form-general">
              <div>
                <x-text-field v-model="alertItem.name" :rules="rules.name" counter="30" label="Name"/>
              </div>
              <div class="input-row">
                <x-select
                    v-model="alertItem.cfg.measurement"
                    :items="measurements"
                    :rules="rules.required"
                    class="second-row-input"
                    label="Measurement"/>
                <x-select
                    v-model="alertItem.cfg.groupBy"
                    :items="tags"
                    :rules="rules.groupBy"
                    class="second-row-input"
                    label="Group By"/>
              </div>
              <div>
                <duration-text-field
                    v-model="alertItem.cfg.observationPeriod" :rules="rules.duration" label="Tolerance period"/>
              </div>
            </div>
            <headline-box class="filter-box" headline="Filter">
              <div class="filter">
                <div v-if="alertItem.cfg.filter.length" class="filter-rows">
                  <div v-for="(filterRow, i) in alertItem.cfg.filter" :key="i" class="input-row">
                    <x-select
                        v-model="filterRow.tag" :items="tags" :rules="rules.required" class="filter-tag" label="Tag"/>
                    <x-select
                        v-model="filterRow.value"
                        :items="tagValues(filterRow.tag)"
                        :rules="rules.required"
                        class="filter-value"
                        item-text="name"
                        item-value="id"
                        label="Value"/>
                    <v-btn class="flex-none delete-button" color="red" icon @click="deleteFilterRow(i)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-btn class="add-filter primary" @click="addFilterRow">Add filter</v-btn>
              </div>
            </headline-box>
          </div>
        </div>
        <div :class="headlineBoxesClass" :style="headlineBoxesStyle" class="headline-boxes">
          <headline-box v-model="alertItem.cfg.warning.enabled" headline="Warning" toggleable>
            <div class="headline-box-content">
              <alert-condition
                  v-model="alertItem.cfg.warning.condition"
                  :measurement="alertItem.cfg.measurement"
                  :meta="meta"
                  :possible-units="possibleWarningConditionUnits"
                  :rules="rules"
                  class="alert-condition"
                  rule-key="warningValueAndUnit"/>
              <alert-actions v-model="alertItem.cfg.warning.actions" :meta="meta" :rules="rules" class="alert-actions"/>
            </div>
          </headline-box>
          <headline-box v-model="alertItem.cfg.critical.enabled" headline="Critical" toggleable>
            <div class="headline-box-content">
              <alert-condition
                  v-model="alertItem.cfg.critical.condition"
                  :measurement="alertItem.cfg.measurement"
                  :meta="meta"
                  :possible-units="possibleCriticalConditionUnits"
                  :rules="rules"
                  class="alert-condition"
                  rule-key="criticalValueAndUnit"/>
              <alert-actions
                  v-model="alertItem.cfg.critical.actions" :meta="meta" :rules="rules" class="alert-actions"/>
            </div>
          </headline-box>
          <headline-box v-model="alertItem.cfg.noData.enabled" headline="If No Data is Received" toggleable>
            <div class="headline-box-content">
              <duration-text-field
                  v-model="alertItem.cfg.noData.within"
                  :rules="rules.duration"
                  class="s-input flex-none"
                  label="Within"/>
              <alert-actions v-model="alertItem.cfg.noData.actions" :meta="meta" :rules="rules"/>
            </div>
          </headline-box>
        </div>
        <div v-if="error" class="alert-configuration-error" v-text="error"/>
      </template>
    </x-data-table>
    <div style="margin-top: 50px;">
      <headline-box headline="HeadlineBox" style="width: 300px;" toggleable>
        HeadlineBox content.
      </headline-box>
    </div>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/FrameworkDataTable';
import XTextField from '@/components/basic/XTextField';
import XSelect from '@/components/basic/XSelect';
import HeadlineBox from '@/components/basic/HeadlineBox';
import DurationTextField from '@/components/extended/DurationTextField';
import AlertCondition from '@/components/legacy/AlertCondition';
import AlertActions from '@/components/legacy/AlertActions';
import defaultValues from '@/cfg/AlertConfigurationDefaults.json';
import { deepCopy, getRequiredMessage, getRequiredRule } from '@/js/general';
import requests from "@/js/requests";

export default {
  name: 'AlertConfiguration',
  components: {
    XDataTable,
    XTextField,
    XSelect,
    DurationTextField,
    HeadlineBox,
    AlertCondition,
    AlertActions,
  },
  data() {
    return {
      alerts: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
      ],
      meta: {
        measurements: {},
        connectors: {},
      },
      loadedMeta: false,
      alertItem: this.createEmptyAlert(),
      rules: {},
      getRequiredRule: getRequiredRule,
      getRequiredMessage: getRequiredMessage,
      loadingItem: false,
      error: '',
      validationActive: false,
      headlineBoxesClass: '',
      valid: false,
      modified: false,
      unsavedChangesDialog: false,
      defaultValues: defaultValues,
      measurementWatchDisabled: false,
      conditionsCheckDisabled: false,
    };
  },
  watch: {
    alertItem: {
      deep: true,
      handler() {
        this.modified = true;
      },
    },
    'alertItem.cfg.measurement'(value) {
      if (this.measurementWatchDisabled) return;
      this.conditionsCheckDisabled = true;

      this.alertItem.cfg.groupBy = '';
      this.alertItem.cfg.filter = [];
      this.alertItem.cfg.warning = {
        setEnabled: false,
        condition: this.createEmptyCondition(),
        actions: this.createEmptyActions(),
      };
      this.alertItem.cfg.critical = {
        setEnabled: false,
        condition: this.createEmptyCondition(),
        actions: this.createEmptyActions(),
      };
      this.alertItem.cfg.noData = {
        setEnabled: false,
        within: '',
        actions: this.createEmptyActions(),
      };

      if (value) {
        const measurementDefaultValues = this.defaultValues.find(x => x.measurement === value);
        if (measurementDefaultValues) {
          this.error = '';
          this.$nextTick(() => {
            if ('warning' in measurementDefaultValues) {
              this.alertItem.cfg.warning.condition = measurementDefaultValues.warning.condition;
              this.alertItem.cfg.warning.enabled = true;
            }
            if ('critical' in measurementDefaultValues) {
              this.alertItem.cfg.critical.condition = measurementDefaultValues.critical.condition;
              this.alertItem.cfg.critical.enabled = true;
            }
            if ('noData' in measurementDefaultValues) {
              this.alertItem.cfg.noData.within = measurementDefaultValues.noData.within;
              this.alertItem.cfg.noData.enabled = true;
            }
            this.conditionsCheckDisabled = false;
            this.checkConditions();
          });
        } else {
          this.conditionsCheckDisabled = false;
        }
      } else {
        this.conditionsCheckDisabled = false;
      }
    },
    'alertItem.cfg.warning.enabled'() {
      this.checkConditions();
    },
    'alertItem.cfg.critical.enabled'() {
      this.checkConditions();
    },
    'alertItem.cfg.noData.enabled'() {
      this.checkConditions();
    },
    valid() {
      this.checkConditions();
    },
  },
  created() {
    this.getMeta();
    this.rules = this.getRules();
  },
  computed: {
    rowEditingDisabled() {
      return !this.loadedMeta;
    },
    measurements() {
      const measurements = [];
      if (this.meta.measurements) {
        for (const [key, value] of Object.entries(this.meta.measurements)) {
          measurements.push({
            value: key,
            text: value.mapping,
          });
        }
      }
      return measurements;
    },
    tags() {
      const tags = [];
      const measurement = this.alertItem.cfg.measurement;
      if (measurement && this.meta.measurements[measurement]) {
        for (const [key, value] of Object.entries(this.meta.measurements[measurement].tags)) {
          tags.push({
            value: key,
            text: value.mapping ? value.mapping : key,
          });
        }
      }
      return tags;
    },
    headline() {
      return this.itemId ? `Edit Alert '${this.alertItem.name}'` : 'New Alert';
    },
    headlineBoxesStyle() {
      if (this.error) return 'border: 2px solid #ff5722; border-radius: 13px;';
      return '';
    },
    possibleWarningConditionUnits() {
      return this.getPossibleUnits('warning');
    },
    possibleCriticalConditionUnits() {
      return this.getPossibleUnits('critical');
    },
    customValid() {
      return this.error === '';
    },
  },
  methods: {
    getMeta() {
      requests.frameworkGetRequest(this.apiUrl, 'getMeta', null, (meta) => {
        this.meta = meta;
        this.loadedMeta = true;
      });
    },
    createEmptyAlert() {
      this.checkConditions();
      return {
        id: 0,
        name: '',
        cfg: {
          orga: '',
          measurement: '',
          filter: [],
          groupBy: '',
          observationPeriod: '',
          warning: {
            enabled: false,
            condition: this.createEmptyCondition(),
            actions: this.createEmptyActions(),
          },
          critical: {
            enabled: false,
            condition: this.createEmptyCondition(),
            actions: this.createEmptyActions(),
          },
          noData: {
            enabled: false,
            within: '',
            actions: this.createEmptyActions(),
          },
        },
      };
    },
    addFilterRow() {
      if (!this.alertItem.cfg.filter) this.alertItem.cfg.filter = [];
      this.alertItem.cfg.filter.push({
        tag: '',
        value: '',
      });
    },
    deleteFilterRow(i) {
      this.alertItem.cfg.filter.splice(i, 1);
    },
    createEmptyCondition() {
      return {
        field: '',
        op: '',
        valueAndUnit: '',
        timerange: '',
      };
    },
    createEmptyActions() {
      return [
        {
          connector: 0,
          retry: '',
        },
      ];
    },
    tagValues(selectedTag) {
      if (!selectedTag) return [];
      const measurement = this.alertItem.cfg.measurement;
      if (!measurement) return [];
      const resultTagValues = [];
      const measurementTags = this.meta.measurements[measurement].tags;
      if (!(selectedTag in measurementTags)) return [];
      const selectedTagValues = measurementTags[selectedTag].values;
      for (const key in selectedTagValues) {
        resultTagValues.push({
          id: key,
          name: selectedTagValues[key],
        });
      }
      return resultTagValues;
    },
    getRules(empty = false) {
      if (!empty) {
        const warningValueAndUnitRules = this.getValueAndUnitRules(this.alertItem.cfg.warning.condition.field);
        const criticalValueAndUnitRules = this.getValueAndUnitRules(this.alertItem.cfg.critical.condition.field);
        return {
          required: [getRequiredRule()],
          groupBy: [v => !!v || !this.tags.length || this.getRequiredMessage()],
          duration: [
            getRequiredRule(),
            v => /^((\d{1,4}h)(\d{1,5}m)?(\d{1,6}s)?|(\d{1,4}h)?(\d{1,5}m)(\d{1,6}s)?|(\d{1,4}h)?(\d{1,5}m)?(\d{1,6}s))$/.test(
                    v)
                || 'Invalid duration format. Correct examples: 2h, 2h30m, 13m37s, 4m20s',
          ],
          name: [getRequiredRule(), v => v.length <= 30 || 'Max 30 characters.'],
          warningValueAndUnit: warningValueAndUnitRules,
          criticalValueAndUnit: criticalValueAndUnitRules,
        };
      }
      return {
        required: [],
        duration: [],
        groupBy: [],
        name: [],
        warningValueAndUnit: [],
        criticalValueAndUnit: [],
      };
    },
    getValueAndUnitRules(field) {
      let rules = [getRequiredRule(), () => !field || 'You must select a type first.'];
      const measurement = this.alertItem.cfg.measurement;
      if (measurement && field) {
        let possibleUnits = [];
        const measurementInfo = this.meta.measurements[measurement];
        if (!measurementInfo) return rules;
        deepCopy(measurementInfo.fields[field].possibleUnits, possibleUnits);
        if (possibleUnits.length) {
          const microIndex = possibleUnits.indexOf('µs');
          let exampleUnit = possibleUnits[0];
          if (exampleUnit === 'µs') exampleUnit = 'us';
          const errorString = `Invalid value format. Possible units are: ${possibleUnits.join(', ')
              .replace('µs', 'us (µs)')}. Example: 5${exampleUnit}`;
          if (microIndex >= 0) {
            possibleUnits.push('us');
          }
          const pattern = `\\d+(${possibleUnits.join('|')})`;
          rules = [getRequiredRule(), v => new RegExp(pattern).test(v) || errorString];
        }
      }
      return rules;
    },
    getPossibleUnits(condition) {
      const measurement = this.alertItem.cfg.measurement;
      if (!measurement) return [];
      const field = this.alertItem.cfg[condition].condition.field;
      if (!field) return [];
      let possibleUnits = [];
      const measurementInfo = this.meta.measurements[measurement];
      if (measurementInfo) deepCopy(measurementInfo.fields[field].possibleUnits, possibleUnits);
      return possibleUnits;
    },
    checkConditions() {
      if (this.conditionsCheckDisabled) return;
      if (this.alertItem != null
          && !this.alertItem.cfg.warning.enabled
          && !this.alertItem.cfg.critical.enabled
          && !this.alertItem.cfg.noData.enabled) {
        if (!this.error) {
          setTimeout(() => {
            this.headlineBoxesClass = 'shake';
            setTimeout(() => {
              this.headlineBoxesClass = '';
            }, 240);
          }, 383);
        }
        this.setAtLeastOneConditionRequiredError();
      } else {
        this.error = '';
      }
    },
    handleDialogReload(alertItem) {
      this.error = '';
      if (alertItem == null) {
        this.setAlertItem(null);
        return;
      }

      this.alertItem.cfg.measurement = alertItem.cfg.measurement;
      this.$nextTick(() => {
        alertItem.cfg.warning.condition.valueAndUnit =
            alertItem.cfg.warning.condition.value + alertItem.cfg.warning.condition.unit;
        delete alertItem.cfg.warning.condition.value;
        delete alertItem.cfg.warning.condition.unit;
        alertItem.cfg.critical.condition.valueAndUnit =
            alertItem.cfg.critical.condition.value + alertItem.cfg.critical.condition.unit;
        delete alertItem.cfg.critical.condition.value;
        delete alertItem.cfg.critical.condition.unit;

        if (!alertItem.cfg.warning.actions.length) alertItem.cfg.warning.actions = this.createEmptyActions();
        if (!alertItem.cfg.critical.actions.length) alertItem.cfg.critical.actions = this.createEmptyActions();
        if (!alertItem.cfg.noData.actions.length) alertItem.cfg.noData.actions = this.createEmptyActions();

        if (alertItem.cfg.observationPeriod === '0s') alertItem.cfg.observationPeriod = '';
        if (alertItem.cfg.warning.condition.valueAndUnit === '0') alertItem.cfg.warning.condition.valueAndUnit = '';
        if (alertItem.cfg.warning.condition.timerange === '0s') alertItem.cfg.warning.condition.timerange = '';
        for (const alertAction of alertItem.cfg.warning.actions) {
          if (alertAction.retry === '0s') alertAction.retry = '';
        }
        if (alertItem.cfg.critical.condition.valueAndUnit === '0') alertItem.cfg.critical.condition.valueAndUnit = '';
        if (alertItem.cfg.critical.condition.timerange === '0s') alertItem.cfg.critical.condition.timerange = '';
        for (const alertAction of alertItem.cfg.critical.actions) {
          if (alertAction.retry === '0s') alertAction.retry = '';
        }
        if (alertItem.cfg.noData.within === '0s') alertItem.cfg.noData.within = '';
        for (const alertAction of alertItem.cfg.noData.actions) {
          if (alertAction.retry === '0s') alertAction.retry = '';
        }

        this.setAlertItem(alertItem);
      });
    },
    setAtLeastOneConditionRequiredError() {
      this.error = 'At least one condition ("Warning", "Critical" or "If No Data is Received") has to be enabled.\n' +
          'If you want to temporarily disable this alert, use the corresponding switch in the table view.';
    },
    setAlertItem(alertItem) {
      if (alertItem == null) alertItem = this.createEmptyAlert();
      this.alertItem = alertItem;
      this.measurementWatchDisabled = false;
      this.getRules();
      this.checkConditions();
    },
    prepareAlertForSaving() {
      const valueRegex = /\d+/;
      const unitRegex = /(?!\d+).+/;

      let warningConditionValue = this.alertItem.cfg.warning.condition.valueAndUnit.match(valueRegex);
      let warningConditionUnit = this.alertItem.cfg.warning.condition.valueAndUnit.match(unitRegex);
      warningConditionValue = warningConditionValue ? warningConditionValue[0] : '';
      warningConditionUnit = warningConditionUnit ? warningConditionUnit[0] : '';
      this.alertItem.cfg.warning.condition.value = parseInt(warningConditionValue);
      this.alertItem.cfg.warning.condition.unit = warningConditionUnit;

      let criticalConditionValue = this.alertItem.cfg.critical.condition.valueAndUnit.match(valueRegex);
      let criticalConditionUnit = this.alertItem.cfg.critical.condition.valueAndUnit.match(unitRegex);
      criticalConditionValue = criticalConditionValue ? criticalConditionValue[0] : '';
      criticalConditionUnit = criticalConditionUnit ? criticalConditionUnit[0] : '';
      this.alertItem.cfg.critical.condition.value = parseInt(criticalConditionValue);
      this.alertItem.cfg.critical.condition.unit = criticalConditionUnit;
    },
    handleDialogOpen() {
      this.measurementWatchDisabled = true;
    },
  },
};
</script>

<style scoped>
.form-top {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.form-general {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-general, .filter-box {
  width: calc(50% - 10px);
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 20px;
}

.filter-rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.input-row {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.second-row-input {
  width: calc(50% - 10px);
}

.headline-box-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.customized-form .x-text-field.x-text-field--enclosed .x-text-field__details {
  margin-bottom: -5px;
}

.alert-configuration-error {
  color: #ff5722;
  font-size: 12px;
  padding: 1px 32px 0 32px;
}

.headline-boxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.delete-button {
  margin-top: 2px;
}

.filter-tag, .filter-value {
  width: calc(50% - 76px / 3);
}

.alert-condition {
  flex: 0 1 45%;
}

.alert-actions {
  flex: 0 1 55%;
}

.shake {
  animation: shake 0.24s;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  17%, 83% {
    transform: translate3d(-2px, 0, 0);
  }
  50% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>