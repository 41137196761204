<template>
  <XDataTable
      title="Received SMS"
      :headers="headers"
      :items-request="subscriberMailbox.getReceivedSms"
      search/>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import subscriberMailbox from '@/js/services/SubscriberMailboxService';
import {isoDateTimeStringToDateTimeString} from '@/js/general';

export default {
  name: 'ReceivedSmsView',
  computed: {
    subscriberMailbox() {
      return subscriberMailbox;
    },
  },
  components: {XDataTable},
  data() {
    return {
      isoDateTimeStringToDateTimeString: isoDateTimeStringToDateTimeString,
      headers: [
        {
          text: 'Timestamp',
          value: 'data.timestamp',
          formatter: value => this.isoDateTimeStringToDateTimeString(value),
          sortable: true,
        },
        {
          text: 'Subscriber',
          value: 'subscriber',
          sortable: true,
        },
        {
          text: 'Received at Explorer',
          value: 'origin',
          sortable: true,
        },
        {
          text: 'From',
          value: 'data.from',
          sortable: true,
        },
        {
          text: 'Message',
          value: 'data.text',
          sortable: true,
        },
        {
          text: 'State',
          value: 'data.state',
          sortable: true,
        },
        {
          text: 'Via SMSC',
          value: 'data.smsc',
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>