<script setup>
import {defineProps, defineEmits, ref, watch} from 'vue';
import OcsMainBlock from '@/components/dynamic/ocsMainBlock.vue';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import {shaKey} from '@/js/helper';
import {useRoute} from 'vue-router/composables';
import PhpServiceTableView from '@/components/basic/php-wrappers/PhpServiceTableView.vue';
import {useStore} from '@/store';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['result']);

const store = useStore();
const route = useRoute();

const _url = ref('');
const isShowingOcs = ref(false);
const isShowingHistory = ref(false);

watch(() => route.query, () => {
  const id = route.query['history-for'];
  let currentTableUrl = route.query['current-table-url'];

  if (!id || !currentTableUrl || typeof currentTableUrl !== 'string') {
    isShowingHistory.value = false;
    _url.value = props.url;
    isShowingOcs.value = true;
    return;
  }

  isShowingOcs.value = false;
  _url.value = `${currentTableUrl}&n_id=${id}&n_id_key=${shaKey(id)}&function=showsHistoryContent`;
  isShowingHistory.value = true;
}, {immediate: true});

const changeTable = (result) => {
  if (result.table) {
    store.commit('tableChanged', result.table);
  } else if (result.connectionInfo || result.ocsTemplateTableArray) {
    let connectionTables = [];
    if (result.connectionInfo) {
      connectionTables = Object.values(result.connectionInfo)
          .flatMap(info => info?.tablesData
              ? Object.values(info.tablesData)
              : [info]
          );
    }

    let templateTables = [];
    if (result.ocsTemplateTableArray) {
      templateTables = Object.values(result.ocsTemplateTableArray)
          .flatMap(template =>
              Object.values(template?.tablesData || template)
          );
    }

    [...connectionTables, ...templateTables].forEach(table => {
      store.commit('tableChanged', table);
    });
  }

  emit('result', result);
};

</script>

<template>
  <PhpServiceView v-if="isShowingOcs" :url="_url" #default="{result}" @result="changeTable">
    <OcsMainBlock :result="result"/>
  </PhpServiceView>
  <PhpServiceTableView v-else-if="isShowingHistory" :url="_url" @result="changeTable"/>
</template>

<style scoped>
</style>
