<template>
  <AddField
    path="serve.php?f=testing&f2=configureAndRunTests"
    :showDialog="dialog"
    :additionalAttributes="{
      updateTable: false,
      function: 'getCloudURL',
      n_id: testCase.id,
      n_id_key: shaKey(testCase.id),
      n_dataset_version: testCase.version,
    }"
    :dialogAttributes="{}"
    v-on="$listeners">
  </AddField>
</template>
  
<script>
import AddField from '@/commonComponents/addField.vue';
import {shaKey} from '@/js/helper';

export default {
  name: 'MessageSequenceChartDialog',
  components: { AddField },
  props: {
    testCase: Object,
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    shaKey(msg) {
      return shaKey(msg);
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.testCase) return;
        this.dialog = value;
      }
    }
  }
}
</script>
