<template>
  <div v-if="stencilInfoExist">
    <v-icon :title="stencilTitle"  style="cursor: pointer">mdi-stamper</v-icon>
   </div>
</template>
<script>
export default {
  props: ["row", "result"],
  computed: {
    stencilInfoExist(){
      let stencil = false;
      if(Object.keys(this.row["c_stencil_info"]).length){
        stencil = true;
      }
      return stencil;
    },
    stencilTitle(){
      return "   "+this.stencilName(this.row["c_stencil_info"].test_name)+"   |   Layer "+ this.stencilLayer(this.row["c_stencil_info"].layer)+"  ";
    }
  },
  methods: {
    stencilLayer(val){
       return val+1;
    },
    stencilName(stencil){
      const [name] = stencil.split(':');
      if(name.toLowerCase()!='stencil'){
         stencil = "Stencil:"+stencil;
      }
      return stencil;
    }
  }
};
</script>