<template>
  <div v-if="!meta || meta && meta[metric] && (meta[metric].tags || meta[metric].tagMap)">
    <HeadlineBox v-if="meta && !metric || meta[metric]" headline="Filters">
      <ListEditor :value="convertedValue" :columns="filterColumns" @input="emitInput"/>
    </HeadlineBox>
    <XThrobber v-else centered/>
  </div>
</template>

<script>
import HeadlineBox from '@/components/basic/HeadlineBox.vue';
import ListEditor from '@/components/basic/ListEditor.vue';
import XThrobber from '@/components/basic/XThrobber.vue';
import {arrayToObject, objectToArray} from '@/js/general';

export default {
  name: 'ChartFilterEditor',
  components: {
    XThrobber,
    ListEditor,
    HeadlineBox,
  },
  props: {
    value: Object,
    meta: Object,
    metric: String,
  },
  data() {
    return {
      filterColumns: [
        {
          label: 'Tag',
          type: 'select',
          value: 'tag',
          items: () => {
            if (!this.meta) return [];
            const selectedMetricInfo = this.meta[this.metric];
            return selectedMetricInfo.tagMap || Object.keys(selectedMetricInfo.tags || {});
          },
          itemText: v => typeof v === 'object' ? v.name : v,
          itemValue: v => typeof v === 'object' ? v.tag : v,
          required: true,
          noPreselect: true,
        },
        {
          label: 'Value',
          type: 'select',
          value: 'value',
          items: (row) => {
            if (!this.meta) return [];
            const selectedMetricInfo = this.meta[this.metric];
            if (selectedMetricInfo.tagMap) {
              const tagMap = selectedMetricInfo.tagMap.find(x => x.tag === row.tag);
              if (tagMap) return tagMap.value;
              return [];
            }
            if (selectedMetricInfo.tags) {
              return selectedMetricInfo.tags[row.tag] || [];
            }
            return [];
          },
          itemText: v => typeof v === 'object' ? v.name : v,
          itemValue: v => typeof v === 'object' ? v.value : v,
          required: () => this.metric !== 'test-step-bar',
          multiple: () => this.metric === 'test-step-bar',
          chips: () => this.metric === 'test-step-bar',
        },
      ],
      arrayToObject: arrayToObject,
      objectToArray: objectToArray,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) this.emitInput([]);
      },
    },
    metric(value, oldValue) {
      if (value && oldValue && value !== oldValue) {
        this.emitInput([]);
      }
    },
  },
  computed: {
    convertedValue() {
      const convertedValue = {...this.value};
      if (this.metric === 'test-step-bar') {
        for (const [k, v] of Object.entries(convertedValue)) {
          if (v && k === 'teststep') convertedValue[k] = v.split(',');
          else convertedValue[k] = v;
        }
      }
      return this.objectToArray(convertedValue || {}, 'tag');
    },
  },
  methods: {
    emitInput(value) {
      if (this.metric === 'test-step-bar') {
        for (let i = 0; i < value.length; i++) {
          if (value[i].tag === 'teststep' && Array.isArray(value[i].value)) {
            value[i].value = value[i].value.filter(x => x.trim() !== '');
            value[i].value = value[i].value.join(',');
          }
        }
      }
      this.$emit('input', this.arrayToObject(value, 'tag'));
    },
  },
};
</script>

<style scoped>

</style>