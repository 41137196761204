<script setup>
import { inject } from "vue";
import MenuLinkList from "@/components/specific/menu/MenuLinkList.vue";
import { useSuperAdminMenu } from "@/composition/menu/use-super-admin-menu";

const { openDialog } = inject("DialogsRoot");

const openSwitchUserDialog = async () => {
  await openDialog("SwitchUserModal", null, { isUiDialog: true });
};

const {
  superAdminLinks,
  clientManagementLinks,
  simManagementLinks
} = useSuperAdminMenu(new Map([["Switch User", () => openSwitchUserDialog()]]));
</script>

<template>
  <div class="super-admin-menu">
    <div class="left-side">
      <MenuLinkList
          title="SIM Management"
          :links="simManagementLinks"
      />

      <MenuLinkList
          title="Client Management"
          :links="clientManagementLinks"
      />
    </div>

    <div class="right-side">
      <MenuLinkList
          title="Super Admin"
          :links="superAdminLinks"
      />
    </div>
  </div>
</template>

<style scoped>
.super-admin-menu {
  display: flex;
  gap: 50px;
  padding: 20px 30px;
}

.left-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
