<template>
  <div>
    <v-row class>
      <v-col>
        <associatedOption
            :fieldAttributes="{
            name: 'Content-Type',
            associatedOption: contentTypes,
          }" type="select" :fieldAttrInput="{
            class: selectorClasses,
            disabled: !jsonEditorButtons,
            id: 'httpBodyContentType',
          }" v-model="localContentType" :templateContent="result"></associatedOption>
      </v-col>
      <v-col>
        <div v-if="contentType === 'JSON'">
          <div class="mr-2 d-inline-block">
            <primaryButton
                type="button"
                @click="importDialog = true"
                label="Import"
                icon
                color="primary"
                :disabled="!jsonEditorButtons"/>

            <confirmationDialog
                :value="importDialog"
                title="Import JSON"
                message
                @confirmation-ok="updateJsonData(...arguments)"
                :maxWidth="350">
              <fileReader v-model="file" @load="parseJsonData(...arguments)"/>
            </confirmationDialog>
          </div>
          <div class="mr-2 d-inline-block">
            <primaryButton
                type="button"
                @click="editorJsonFileCall()"
                label="Editor"
                icon
                color="primary"
                :disabled="!jsonEditorButtons"
                id="httpBodyContentJsonEditor"/>
            <div>
              <confirmationDialog
                  :value="editorDialog"
                  title="Edit JSON"
                  message
                  @confirmation-ok="updateJsonData(...arguments)"
                  buttonsIDPrefix="httpJsonEditor">
                <vue-json-editor v-model="jsonData" :expandedOnStart="true" mode="code"/>
                <template v-slot:additionalButtons>
                  <primaryButton
                      type="button"
                      @click="downloadJsonFile()"
                      label="Export"
                      :icon="'mdi-export-variant'"
                      color="primary"
                      :disabled="!jsonEditorButtons"/>
                </template>
              </confirmationDialog>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div class="mt-3">
      <attributeValueAdderContentParameterTab
          v-if="contentType === 'AVP'"
          :value="localStoredBody"
          :headerTitle="true"
          @input="updatebody(...arguments)"
          :attrSource="attrSource"
          :result="result"
          :isValid="isValid"
          :hideDetails="hideDetails"/>
      <div v-else-if="contentType === 'text'">
        <defaultFieldTypes
            :fieldAttributes="{
            name: 'Content',
            type: 'textarea',
          }"
            @input="updatebody(...arguments)"
            :templateContent="result"
            :value="localStoredBody"
            :isValid="isValid"
            :hideDetails="hideDetails"/>
      </div>
      <multiPart
          v-else-if="contentType === 'multipart'"
          v-on="$listeners"
          :storedBody="localStoredBody"
          :result="result"
          :isValid="isValid"
          :linePrefix="linePrefix"
          :draggable="draggable"/>
      <JsonPatchEditor
          v-else-if="contentType === 'JSON' && newEditor"
          v-model="jsonPatchBody"
          :original="convertedTemplateRequestBody"
          @input="updatebody"/>
      <jsonContent
          v-else-if="contentType === 'JSON'"
          :storedBody="localStoredBody"
          v-on="$listeners"
          :result="result"
          :isValid="isValid"/>
    </div>
  </div>
</template>
<script>
import vueJsonEditor from 'vue-json-editor';
import associatedOption from '@/commonComponents/associatedOption.vue';
import fileReader from '@/commonComponents/fileReader.vue';
import primaryButton from '@/commonComponents/primaryButton.vue';
import confirmationDialog from '@/commonComponents/confirmationDialog.vue';
import defaultFieldTypes from '@/commonComponents/defaultFieldTypes.vue';
import attributeValueAdderContentParameterTab from '@/components/legacy/attributeValueAdderContentParameterTab.vue';
import multiPart from '@/components/legacy/multiPart.vue';
import JsonPatchEditor from '@/components/basic/JsonPatchEditor.vue';
import JsonContent from "@/components/legacy/jsonContent.vue";
import { convertTemplateToRegular } from "@/js/general";

export default {
  components: {
    JsonContent,
    JsonPatchEditor,
    associatedOption,
    primaryButton,
    attributeValueAdderContentParameterTab,
    defaultFieldTypes,
    vueJsonEditor,
    multiPart,
    confirmationDialog,
    fileReader,
  },
  props: {
    draggable: {},
    hideDetails: {
      type: Boolean,
      default: false,
    },
    linePrefix: {},
    packetType: {},
    contentType: {},
    storedBody: {},
    jsonEditorButtons: {
      type: Boolean,
      default: true,
    },
    attrSource: {},
    result: {},
    isValid: {
      type: Boolean,
    },
    stepId: [Number, String],
    templateRequestBody: [Object, Array],
    newEditor: Boolean,
  },
  data() {
    return {
      convertTemplateToRegular: convertTemplateToRegular,
      jsonData: {},
      editorDialog: false,
      importDialog: false,
      file: null,
      jsonPatchBody: [],
    };
  },
  watch: {
    templateRequestBody: {
      immediate: true,
      handler() {
        if (!this.newEditor) return;
        let jsonPatchBody = [];
        if (this.result.valueLine.c_obj) {
          const steps = JSON.parse(this.result.valueLine.c_obj);
          if (steps[this.stepId].HTTP.req) {
            const valueLineBody = steps[this.stepId].HTTP.req.body;
            if (Array.isArray(valueLineBody)) jsonPatchBody = valueLineBody;
          }
        }
        this.jsonPatchBody = jsonPatchBody;
      },
    },
  },
  computed: {
    localStoredBody: {
      get() {
        let localStoredBody = this.storedBody;
        if (!Array.isArray(this.storedBody) && (this.contentType === 'AVP' || this.contentType === 'multipart')) {
          localStoredBody = [];
          this.updatebody(localStoredBody);
        } else if (typeof this.storedBody != 'object' && this.contentType === 'JSON') {
          localStoredBody = {};
          this.updatebody(localStoredBody);
        } else if (typeof this.storedBody != 'string' && this.contentType === 'text') {
          localStoredBody = '';
          this.updatebody(localStoredBody);
        }
        return localStoredBody;
      },
      set(newVal) {
        this.updatebody(newVal);
      },
    },
    localContentType: {
      get() {
        return this.contentType;
      },
      set(newValue) {
        this.$emit('content-type-changed', newValue);
      },
    },
    contentTypes() {
      let contentTypes = [
        ['AVP', 'AVP'],
        ['JSON', 'JSON'],
        ['text', 'Text'],
        ['multipart', 'multipart'],
      ];

      if (this.packetType === 'HTTP_RESPONSE') {
        contentTypes = ['JSON', ['text', 'Text'], 'multipart'];
      } else if (this.packetType === 'multipart') {
        contentTypes = ['JSON', ['text', 'Text']];
      }
      return contentTypes;
    },
    selectorClasses() {
      let selectorClasses = 'required httpContentTypeSelector';
      if (this.packetType === 'multipart') {
        selectorClasses = 'required httpContentTypeSelector multiPartSubType';
      }
      return selectorClasses;
    },
    convertedTemplateRequestBody() {
      console.log(this.convertTemplateToRegular(this.templateRequestBody));
      return this.convertTemplateToRegular(this.templateRequestBody);
    }
  },
  methods: {
    downloadJsonFile() {
      const element = document.createElement('a');
      element.setAttribute(
          'href',
          'data:application/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(this.jsonData, null, 2)),
      );
      element.setAttribute('download', 'jsonVariables.json');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    parseJsonData(value) {
      this.jsonData = JSON.parse(value);
    },
    updatebody(newVal) {
      this.$emit('stored-body-changed', newVal);
    },
    editorJsonFileCall() {
      this.jsonData = this.translatorJSON(this.localStoredBody);
      this.editorDialog = true;
    },
    translatorJSON(jsonBody) {
      let body;

      if (jsonBody.type === 'array') {
        body = [];
        console.log('--->', jsonBody.content);
        jsonBody.content.forEach((element) => {
          body.push(this.translatorJSON(element));
        });
      } else if (jsonBody.type === 'object') {
        body = {};
        for (let [index, element] of Object.entries(jsonBody.content)) {
          body[index] = this.translatorJSON(element);
        }
      } else {
        body = jsonBody.content;
      }
      return body;
    },
    updateJsonData(value) {
      if (value) {
        this.localStoredBody = this.constructNonArrayStructure(this.jsonData);
      }
      this.editorDialog = false;
      this.importDialog = false;
      this.jsonData = {};
      this.file = null;
    },

    constructNonArrayStructure(innerValue) {
      let baby;
      if (typeof innerValue != 'string' && typeof innerValue != 'number'
          && innerValue !== null && typeof innerValue !== 'boolean') {
        baby = {};

        let content;
        if (Array.isArray(innerValue)) {
          content = [];
          let caller = this;
          innerValue.forEach(function (value) {
            content.push(caller.constructNonArrayStructure(value));
          });
          baby['type'] = 'array';
        } else {
          content = {};
          for (let [innerIndex, value] of Object.entries(innerValue)) {
            content[innerIndex] = this.constructNonArrayStructure(value);
          }

          baby['type'] = 'object';
        }
        baby['content'] = content;
      } else {
        let type = 'string';
        if (typeof innerValue == 'boolean') {
          type = 'boolean';
        } else if (innerValue === null) {
          type = 'null';
        } else if (typeof innerValue == 'number') {
          type = 'number';
        }
        baby = {
          type: type,
          content: innerValue,
        };
      }
      return baby;
    },
  },
};
</script>
<style>
.jsoneditor-vue {
  height: 700px;
}
</style>