var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AddField',_vm._g({attrs:{"path":"serve.php?f=testing&f2=configureAndRunTests","showDialog":_vm.dialog,"additionalAttributes":{
    updateTable: false,
    function: 'getCloudURL',
    n_id: _vm.testCase.id,
    n_id_key: _vm.shaKey(_vm.testCase.id),
    n_dataset_version: _vm.testCase.version,
  },"dialogAttributes":{}}},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }