<template>
  <div style="max-width: 140px;margin-top:-2px" class="ml-1 mr-3">
    <a href="javascript:void(0);" @click="clearSelectedTest()">
      <v-icon
          color="red" title="Stop Selected"
          style="font-size: 30px;
          position: relative;
          bottom: 1px;
          margin-right: 4px !important;"
      >
        mdi-stop
      </v-icon>
      <span
          style="position: relative; top: 2px"
          v-if="$vuetify.breakpoint.mdAndUp">{{ language.componentLabel.titleStopSelected }}</span>
    </a>
  </div>
</template>
<script>
// import { clearTest } from "@/js/testHelpers.js";
import requests from '@/js/requests';
import testCaseInfoService from "@/js/services/TestCaseInfoService";

export default {
  methods: {
    clearSelectedTest() {
      let tcID = "";
      for (let [index] of Object.entries(this.selectedRows)) {
        tcID += (tcID.length > 0 ? ',' : '') + index;
        //  this.stopAndUpdateTest(index, value, currentTable, this);
      }
      if (tcID != "") {
        testCaseInfoService.stopTests([tcID], () => {
          this.showNotification(`Stopping ${Object.entries(this.selectedRows).length} tests.`, 3000);
        });
      }else {
        this.$emit("show-warning", true);
      }
    },
    stopAndUpdateTest(index, value, currentTable, caller) {
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=testsExecuteApi',
        data: {
          function: 'stopAndUpdateTest',
          n_id: index,
          n_id_key: value.n_id_key,
        },
      })
          .then(function (response) {
            caller.updateTable(response.data.result.table);
          })
          .catch(function (error) {
            console.log(error);
            //handle error
          });
    },
  },
  props: ['selectedRows', 'table'],
};

// function
</script>

<style scoped>
.clear-selected-group-disabled {
  color: var(--v-disabled-base);
  cursor: default;
}
</style>