<template>
  <div class="mb-2 pa-3">
    <div :style="{ 'max-width' :tableWidth+'px' }">
      <v-row class="primary white--text">
        <template v-for="(value,index) in newItems">
          <v-col
              :key="index"
              class="pa-1 table-index-column text-center"
              style=""
          > {{ customIndexName(index) }}
          </v-col>
        </template>
      </v-row>
      <v-row style="border: solid 1px #dbdbdb">
        <template v-for="(value,index) in newItems">
          <v-col
              :style="{ 'width' : colWidth+'px' }"
              :key="index"
              class="pa-1 table-value-column text-center"
          > {{ modemOutUniConversion(value, index) }}
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import {objectCustomSort, convertMs, convertByte2MB, convertMBits} from "@/js/helper.js";

export default {
  props: {
    item: Object,
    type: String
  },
  data() {
    return {
      tableWidth: "700",
      directSecondsAttr: ['dialingTime', 'connectionTime', 'actionAfter'],
      colWidth: "75",
    };
  },
  computed: {
    newItems() {
      this.customColWidth(this.type);
      var sortOrder = [];
      let itemList = {};
      if (this.type == "Explorer_USSD") {
        sortOrder = ['code', 'timeout'];
        itemList['code'] = typeof this.item['code'] !== 'undefined' ? this.item['code'] : ""
        itemList['timeout'] = typeof this.item['timeout'] !== 'undefined' ? this.item['timeout'] : ""
      } else if (this.type == 'Explorer_SMS') {
        sortOrder = ['targetNumber', 'smsc', 'text', 'statusRequest'];
        if (typeof this.item['statusRequest'] !== 'undefined' && this.item['statusRequest'].length == 0) {
          delete this.item['statusRequest'];
        }
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_voice_call') {
        sortOrder = ['type', 'targetNumber', 'dialingTime', 'connectionTime'];
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_receive_call') {
        sortOrder = ['type', 'number', 'connectionTime', 'actionAfter', 'action'];
        itemList = this.item;
      } /*else if (this.type == 'Explorer_cellular_check_received_sms') {
        sortOrder = ['type', 'number', 'connectionTime'];
        itemList = this.item;
        console.log(itemList);
      }*/ else if (this.type == 'Explorer_cellular_attach') {
        sortOrder = ['ratType', 'network', 'simType', 'slot'];
        let ratType = "";
        if (this.item.radio && this.item.radio['ratType']) {
          ratType = this.getAttributeMapping("modemRatType", this.item.radio['ratType']);
        }
        itemList['ratType'] = ratType
        itemList['network'] = this.item?.network ? this.item?.network : ""
        itemList['simType'] = this.item?.sim?.simType  ? this.item?.sim?.simType  : ""
       if(this.item?.sim?.slot){
             itemList['slot'] = this.item?.sim?.slot
           }
      }
      else if (this.type == 'Explorer_insert_sim') {
        sortOrder = ['simType', 'iccid','name'];
        itemList['simType'] = this.item?.simType  ? this.item?.simType  : ""
        itemList['iccid'] = this.item?.iccid  ? this.item?.iccid  : ""
       if(this.item?.name){
         itemList['name'] = this.item.name
       }
      }else if (this.type == 'Explorer_cellular_data_connect') {
        sortOrder = ['apn', 'user', 'password'];
        itemList['user'] = typeof this.item['user'] !== 'undefined' && this.item['user'].length > 0 ? this.item['user'] : "Not Set"
        itemList['password'] = typeof this.item['password'] !== 'undefined' && this.item['password'].length > 0 ? "*******" : "Not Set"
      } else if (this.type == 'Explorer_http_download') {
        sortOrder = ['host', 'downloadSize', 'streams'];
        if (typeof this.item['host'] != 'undefined' && this.item['host'].trim() == '') {
          delete this.item['host'];
        }
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_location') {
        //console.log(this.item);
        sortOrder = ['CI', 'TAC', 'rat'];
        itemList['CI'] = typeof this.item['CI'] !== 'undefined' ? this.item['CI'] : ""
        itemList['TAC'] = typeof this.item['TAC'] !== 'undefined' ? this.item['TAC'] : ""
        itemList['rat'] = typeof this.item['rat'] !== 'undefined' ? this.item['rat'] : ""
      } else if (this.type == 'Explorer_cellular_start_voice_mo') {
        sortOrder = ['targetNumber', 'callId', 'dialingTime', 'type'];
        itemList['targetNumber'] = typeof this.item['targetNumber'] !== 'undefined' ? this.item['targetNumber'] : ""
        itemList['callId'] = typeof this.item['callId'] !== 'undefined' ? this.item['callId'] : ""
        itemList['dialingTime'] = typeof this.item['dialingTime'] !== 'undefined' ? this.item['dialingTime'] : ""
        itemList['type'] = typeof this.item['type'] !== 'undefined' ? this.item['type'] : ""
      } else if (this.type == 'Explorer_play_audio') {
        sortOrder = ['text', 'iterations', 'pauseBetweenIterations'];
        itemList['text'] = typeof this.item['text'] !== 'undefined' ? this.item['text'] : ""
        itemList['iterations'] = typeof this.item['iterations'] !== 'undefined' ? this.item['iterations'] : ""
        itemList['pauseBetweenIterations'] = typeof this.item['pauseBetweenIterations'] !== 'undefined' ? this.item['pauseBetweenIterations'] : ""
      } else if (this.type == 'Explorer_cellular_hangup_call') {
        itemList['callIds'] = typeof this.item['callIds'] !== 'undefined' ? this.item['callIds'].toString() : ""
      } else {
        itemList = this.item;
      }
      //console.log(itemList);
      this.customWidth(this.type, itemList);
      return objectCustomSort(sortOrder, itemList);
    }
  },
  methods: {
    customIndexName(index) {
      let result = this.languagePack("basicModemTable", index);
      if (this.type == "Explorer_cellular_receive_call" && index == 'connectionTime') {
        result = "Hang Up After";
      }
      return result;
    },
    customColWidth(type) {
      if (type == 'Explorer_cellular_attach') {
        this.colWidth = "120";
      } else if (type == 'Explorer_http_download') {
        this.colWidth = "";// no min width
      }
    },
    modemOutUniConversion(value, index) {
      if (this.directSecondsAttr.includes(index)) {
        value = value == 1 ? value + " Second" : value + " Seconds";
      }
      if (this.type == 'Explorer_cellular_location') {
        value = this.getAttributeMapping("modemRatTypeActualMapping", value);
      } else if (this.type == 'Explorer_cellular_receive_call') {
        value = this.getAttributeMapping("voiceCallAction", value);
      } else if (this.type == 'Explorer_data_usage') {
        if (index == 'downloadSize') {
          value = convertByte2MB(value,'b',true);//binary and show "i" between
        } else if (index == 'limitBandwidth') {
          value = convertMBits(value);
        }
      }
      return value;
    },
    customWidth(type, itemList) {
      //console.log(Object.keys(itemList).length);
      let attrLength = Object.keys(itemList).length;
      this.tableWidth = attrLength * 200;
    },
    convertMs(value) {
      return convertMs(value);
    },
    convertByte2MB: convertByte2MB,
    convertMBits: convertMBits
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}

</style>