<template>
  <div :data-help="computedHelp" class="x-file-input">
    <v-file-input
        :value="value"
        outlined
        dense
        hide-details="auto"
        :label="label"
        :placeholder="placeholder"
        :accept="accept"
        :rules="rules"
        @change="$emit('input', $event)"/>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>

<script>
import HelpButton from '@/components/basic/HelpButton.vue';

export default {
  name: 'XFileInput',
  components: {HelpButton},
  props: {
    value: File,
    label: String,
    placeholder: String,
    accept: String,
    rules: Array,
    id: String,
    help: String,
  },
  computed: {
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
};
</script>

<style scoped>
.x-file-input {
  display: flex;
}

.x-file-input >>> .help-button {
  margin-top: 2px;
}
</style>