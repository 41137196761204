<template>
  <div class="explorer-http-download-step">
    <StartFinishedMaximum :value="localStoredValue" @input="emitInput"/>
    <TestStepTarget :value="stepStoredValue" @input="emitInput" :default="{type: 'static', host: ''}">
      <template #static="{value, emitInput}">
        <XTextField label="Target" :value="value.host" @input="emitInput('host', $event)" required
                    validate-immediately/>
      </template>
      <template #resource="{value, emitInput}">
        <InputRow>
          <XSelect
              label="Type"
              :value="value.resourceType"
              :items="resourceTagTypes"
              required
              @input="emitInput('resourceType', $event)"/>
          <XSelect
              label="Tag"
              :value="value.resourceTag"
              :items="resourceTags[value.resourceType]"
              required
              @input="emitInput('resourceTag', $event)"/>
        </InputRow>
      </template>
    </TestStepTarget>
    <InputRow>
      <XTextField
          label="Download Size"
          :value="localStoredValue.downloadSize"
          default="10MB"
          required
          @input="emitInput({...localStoredValue, downloadSize: $event})"/>
      <XTextField
          label="Number of Parallel Streams"
          :value="localStoredValue.streams"
          :default="10"
          type="int"
          required
          @input="emitInput({...localStoredValue, streams: $event})"/>
    </InputRow>
    <TestStepResponse :value="localStoredValue" @input="emitInput"/>
  </div>
</template>
<script>
import StartFinishedMaximum from '@/components/specific/TestStep/StartFinishedMaximum.vue';
import TestStepTarget from '@/components/specific/TestStep/TestStepTarget.vue';
import XTextField from '@/components/basic/XTextField.vue';
import InputRow from '@/components/basic/InputRow.vue';
import TestStepResponse from '@/components/specific/TestStep/TestStepResponse.vue';
import XSelect from '@/components/basic/XSelect.vue';
import resourceManagerService from "@/js/services/ResourceManagerService";
import {deepCopy, setDefaultValues} from "@/js/general";
import teststepDefault from "@/cfg/teststep-default.json";

export default {
  name: 'step_Explorer_http_download',
  components: {
    XSelect,
    TestStepResponse,
    InputRow,
    XTextField,
    TestStepTarget,
    StartFinishedMaximum,
  },
  props: {
    result: Object,
    stepStoredValue: Object,
    stepType: String,
    stepId: [String, Number],
  },
  data() {
    return {
      resourceTags: {},
      setDefaultValues: setDefaultValues,
    };
  },
  created() {
    resourceManagerService.getHttpDownloadResourceTags((resourceTags) => {
      this.resourceTags = resourceTags;
    });
  },
  computed: {
    localStoredValue: {
      get() {
        const localStoredValue = deepCopy(this.stepStoredValue);
        if (this.setDefaultValues(localStoredValue, teststepDefault[this.stepType])) {
          this.$emit('stored-value-changed', localStoredValue);
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit('stored-value-changed', newVal);
      },
    },
    resourceTagTypes() {
      const resourceTagTypes = [];
      for (const [resourceTagKey, resourceTagValues] of Object.entries(this.resourceTags)) {
        if (resourceTagValues.length) resourceTagTypes.push(resourceTagKey);
      }
      return resourceTagTypes;
    },
  },
  methods: {
    emitInput(value) {
      const newValue = {...value};
      delete newValue.category;
      delete newValue.subType;

      this.$emit('stored-value-changed', newValue);
    },
  },
};
</script>

<style scoped>
.explorer-http-download-step {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
