<template>
  <XGridLayout
      v-if="editMode || value.items.length"
      :value="value.items"
      :col-num="12"
      :row-height="30"
      :is-draggable="editMode"
      :is-resizable="editMode"
      :margin="[10, 10]"
      :use-css-transforms="true"
      :item-resizable="editMode"
      class="dashboard-grid"
      @input="$emit('input', {...value, items: $event})"
  >
    <template v-slot="{item, size}">
      <DashboardWidget
          :ref="`widget-${item.i}`"
          v-if="!tagMetricsLoading"
          :value="item"
          :edit-mode="editMode"
          :size="size"
          :range="rangeAndRefresh.range"
          :refresh="rangeAndRefresh.refresh"
          :reload="reload"
          :filters="getApplicableFilters(item)"
          @edit="$emit('edit:widget', $event)"
          @delete="$emit('delete:widget', $event)"
          @loading="$emit('loading:widget', $event, item.i)"/>
    </template>
  </XGridLayout>

  <div v-else class="dashboard-no-items-message">
    <span v-if="!finishedLoading">Dashboard is loading...</span>
    <span v-else>This dashboard has no items.</span>
  </div>
</template>

<script>
import XGridLayout from '@/components/basic/XGridLayout.vue';
import DashboardWidget from '@/components/specific/Dashboard/DashboardWidget.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardWidget,
    XGridLayout,
  },
  props: {
    value: Object,
    editMode: Boolean,
    tagMetricValues: Object,
    tagMetricsLoading: {
      type: Boolean,
      default: undefined,
    },
    rangeAndRefresh: Object,
    reload: Boolean,
    filters: Object,
    finishedLoading: Boolean,
    selectedFilters: Object,
  },
  watch: {
    value() {
      if (!Object.keys(this.$refs).length) return;
      this.$nextTick(() => {
        this.$nextTick(() => {
          for (const widget of this.value.items) {
            const widgetRefName = `widget-${widget.i}`;
            const widgetRef = this.$refs[widgetRefName];
            if (widgetRef) widgetRef.reloadData();
          }
        });
      });
    },
  },
  methods: {
    getApplicableFilters(dashboardWidget) {
      if (this.tagMetricsLoading || this.tagMetricsLoading === undefined) return undefined;
      let applicableFilters = {};
      if (this.tagMetricValues && Object.keys(this.tagMetricValues).length) {
        for (const [key, value] of Object.entries(this.selectedFilters)) {
          if (!!value && this.tagMetricValues[key] &&
              this.tagMetricValues[key].metrics.includes(dashboardWidget.metric)) {
            applicableFilters[key] = value;
          }
        }
      }
      if (!Object.keys(applicableFilters).length) applicableFilters = undefined;
      return applicableFilters;
    },
  },
};
</script>

<style scoped>

</style>
