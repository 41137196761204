<script setup>
import { defineProps, defineEmits, computed, ref, nextTick, onMounted } from "vue"
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
import UiSelect2 from "@/components/basic/form/UiSelect2.vue"
import { useFloating, offset, shift, autoUpdate, flip } from "@floating-ui/vue"
import { useClickOutside } from "@/composition/click-outside"
import { useAsyncValidation } from "@/composition/validation/use-async-validation"
import { debounce } from "lodash-es"
import { useQueryWordManager } from "@/composition/query-input/use-query-word-manager";
import { capitalize } from "@/js/helper"

// type Validator = (query: string) => Promise<string>
// type Tag = string | { value: string, id: string }

const PLACEMENT = "bottom"
const OFFSET = 1
const DEBOUNCE_MS = 300

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  // Validator
  validator: {
    type: Function,
    required: true,
  },
  label: {
    type: String,
    default: "Query",
  },
  hints: {
    type: String,
    default: "",
  },
  // Tag[]
  tags: {
    type: Array,
    default: () => [],
  },
  isDebounced: {
    type: Boolean,
    default: false,
  },
  caseSensitive: {
    type: Boolean,
    default: false,
  },
  errorPlacement: {
    type: String,
    default: "top",
  }
})

const emit = defineEmits(["update:modelValue", "update:is-valid"])
const emitIsValid = (errMsg) => {
  emit("update:is-valid", !errMsg)
}
const updateModel = props.isDebounced
  ? debounce((v) => emit("update:modelValue", v), DEBOUNCE_MS)
  : (v) => emit("update:modelValue", v)

const {
  query: _query,
  word,
  replaceWordAtCursor,
  observeCursor,
  cursorPos,
} = useQueryWordManager(props.modelValue, props.modelValue.length);

const { clickOutsideRoot, isOpen, toggle } = useClickOutside()
const anchor = ref(null)
const floating = ref(null)
const { floatingStyles } = useFloating(anchor, floating, {
  placement: PLACEMENT,
  middleware: [offset(OFFSET), shift(), flip()],
  whileElementsMounted: autoUpdate,
})

const {
  model: validatedQuery,
  isValid,
  error,
  subscribeToValidation,
  doValidation,
} = useAsyncValidation(props.modelValue, props.validator)
subscribeToValidation(emitIsValid)

const inputElement = ref(null)
const isInputFocused = () => document.activeElement === inputElement.value
const keepSelectOpened = () => {
  if (isInputFocused() && !isOpen.value) {
    toggle()
  }
}
const onInputClick = (e) => {
  keepSelectOpened()
  observeCursor(e.target.selectionStart)
}

const checkMatch = (original, word) => {
  if (!props.caseSensitive) {
    return original.toLowerCase().includes(word.toLowerCase())
  }
  return original.includes(word);
}
const currentTags = computed(() => {
  if (!props.tags.length) {
    return [];
  }
  if (!word.value) {
    return props.tags;
  }

  if (typeof props.tags[0] === "string") {
    return props.tags.filter(t => checkMatch(t, word.value));
  }

  return props.tags.filter((t) => checkMatch(t.value, word.value));
})

const query = computed({
  get: () => props.modelValue,
  set: (v) => {
    _query.value = v
    validatedQuery.value = v
  },
})
subscribeToValidation((errMsg) => {
  if (!errMsg) {
    updateModel(_query.value)
  }
})

const placeCarretAtTheEnd = () => {
  if (cursorPos.value < query.value.length) {
    const after = _query.value.substring(cursorPos.value);
    const index = after.indexOf(" ");
    const pos = index < 0 ? after.length : cursorPos.value + index;
    inputElement.value.setSelectionRange(pos, pos);
  }
}

const addTag = async (userTag) => {
  const value = userTag.trim();

  query.value = replaceWordAtCursor(value);
  observeCursor(query.value.length);

  inputElement.value.focus();
  await nextTick();
  placeCarretAtTheEnd();
}

onMounted(() => {
  if (props.modelValue.trim().length > 0) {
    doValidation(props.modelValue)
  }
});
</script>

<template>
  <div
    class="tag-query-field-3"
    :class="{'tag-query-field-3--error': !isValid }"
    ref="clickOutsideRoot"
    @click.prevent.stop
  >
    <UiInputWrapper
      ref="anchor"
      :class="{ 'tag-query-field-3__input-wrapper': true, 'tag-query-field-3__input-wrapper--error': !isValid }"
      :data-error="error"
      :label="label"
      :hints="hints"
      :is-valid="isValid"
      @click.prevent.stop
    >
      <input
        ref="inputElement"
        :value="_query"
        type="text"
        placeholder=" "
        @click="(e) => onInputClick(e)"
        @input="(e) => query = e.target.value"
        @keyup="(e) => observeCursor(e.target.selectionStart)"
      />

      <template #input-slot-right>
        <button
          :class="{
            'tag-query-field-3__arrow-btn': true,
            'tag-query-field-3__arrow-btn--open': isOpen,
          }"
          @click.prevent.stop="() => toggle()"
          type="button"
        >
          <v-icon class="tag-query-field-3__arrow-ico">mdi-menu-down</v-icon>
        </button>
      </template>
    </UiInputWrapper>

    <p
      :class="['tag-query-field-3__error', `tag-query-field-3__error--${errorPlacement}`]"
    >
      <span class="tag-query-field-3__error-txt">
        {{ capitalize(error) }}
      </span>
    </p>

    <v-fade-transition>
      <UiSelect2
        v-if="isOpen"
        ref="floating"
        :options="currentTags"
        :style="floatingStyles"
        class="tag-query-field-3__tags-box"
        @select="(t) => addTag(t)"
        :with-search="false"
      />
    </v-fade-transition>
  </div>
</template>

<style lang="scss">
  .tag-query-field-3 {
    $root: &;

    position: relative;
    width: 100%;

    &__tags-box {
      z-index: 8;
    }

    &__error {
      position: absolute;
      background-color: #ff5722;
      color: white;
      padding: 5px;
      border-radius: 4px;
      white-space: nowrap;
      font-size: 12px;
      opacity: 0;
      will-change: opacity;
      transition: unset;
      z-index: 1;

      &--top {
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        bottom: auto;
        text-align: center;
        transform-origin: center bottom;
      }

      &--left {
        left: 0;
        top: 50%;
        transform: translate(-105%, -50%);
        right: auto;
        text-align: right;
        transform-origin: right center;
      }

      &--right {
        right: 0;
        top: 50%;
        transform: translate(105%, -50%);
        left: auto;
        text-align: left;
        transform-origin: left center;
      }
    }

    &--error:focus-within {
      #{$root}__error {
        opacity: 1;
        transition: opacity 0.3s ease-in;
      }
    }

    &__arrow-btn {
      width: 24px;
      height: 24px;
      background-color: transparent;

      #{$root}__arrow-ico {
        color: var(--input-wrapper-icon-color, rgba(0,0,0,.54));
        transition: transform 0.3s;
      }

      &--open {
        #{$root}__arrow-ico {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
