<template>
  <div>
    <template v-if="isExplorer(type)">
      <template v-if="value.name===0">
        {{ language.componentLabel.explorerFetching }}
      </template>
      <template v-else>
        <ClickableText
            :text="value.name"
            @click="() => openExplorerDialog({ id: value.id })"
        />
        {{ value.alias ? `(${value.alias})` : '' }}
      </template>
    </template>
    <template v-else-if="value.name==0">
      {{ language.componentLabel.na }}
    </template>
    <template v-else>
      {{ value.name }}
    </template>
  </div>
</template>
<script>
import ClickableText from '@/components/basic/ClickableText.vue';
import {inject} from "vue"


export default {
  name: "subscriberExplorerLabel",
  components: {
    ClickableText,
  },
  props: {
    type: Number,
    value: Object
  },
  setup() {
    const {
      openDialog
    } = inject("DialogsRoot")

    return {
      openDialog
    }
  },
  methods: {
    async openExplorerDialog({id}) {
      await this.openDialog("ExplorerDialog", {explorerId: id, value: true})
    },
    isExplorer(type) {
      return type === 8 ? true : false;
    }
  },
}
</script>