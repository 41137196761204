<template>
  <XDialog
    :value="dialog"
    title="Copy to Project"
    :buttons="buttons"
    persistent
    @input="$emit('input')">
    <template #dialog-content>
      <XSelect
        class="project"
        v-model="project"
        :items="projects"
        label="Project"
        required
        validate-immediately
        combobox>
      </XSelect>
    <v-spacer></v-spacer>
    </template>
  </XDialog>
</template>

<script>
import XDialog from '@/components/basic/XDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import userService from "@/js/services/UserService";
// import axios from 'axios';

export default {
  name: 'CopyTestCaseDialog',
  components: { XDialog, XSelect },
  props: {
    testCasesIds: Array,
    value: Boolean,
  },
  data() {
    return {
      activeProjectId: 0,
      allProjects: [],
      projects: [],
      buttons: [
      {
        icon: 'mdi-content-copy',
        color: 'save',
        disabled: () => this.project.value === 0,
        text: 'Copy',
        textColor: 'white',
        click: () => this.copyTestCases(this.testCasesIds, this.project),
      },
      ],
      project: { text: '', value: 0 },
      cornerButtons: [],
      dialog: false,
  };
  },
  methods: {
    copyTestCases(testCasesIds, destinationProject) {
      for (const id of testCasesIds) {
        testCaseInfoService.getTestCase(id, (testCase) => {
          console.log("Copying Testcase: ", testCase, "to: ", destinationProject);
          testCaseInfoService.copyTestCase(destinationProject.value, testCase);
          this.$emit('input', false);
        });
      }
    }
  },
  created() {
    // set active project id
    userService.getProject((activeProjectId) => {
      this.activeProjectId = activeProjectId;
    });

    // get other projects
    const caller = this; // for inner operations
    userService.getProjects((allProjects) => {
      this.allProjects = allProjects.sort((a, b) => a.name.localeCompare(b.name));
      this.projects = allProjects.map(
        function(project) {
          if (project.id !== caller.activeProjectId) return { text: project['name']+':'+project['description'], value: project['hash'] }
        }
      ).filter(p => p !== undefined);

      // console.log("All projects: ", this.projects);
    });
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.dialog = value;
      }
    }
  }
}
</script>
