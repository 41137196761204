<template>
  <div class="test-step-response">
    <HeadlineBox headline="Evaluation">
      <ListEditor :value="eval" :columns="columns" @input="emitInput('eval', $event)" row-name="Eval"/>
    </HeadlineBox>
    <v-divider/>
    <InputRow v-if="onError">
      <span>On Error</span>
      <XSelect
          label="Result"
          :value="onError.result"
          :items="columns[4].items"
          required
          @input="emitInput('onError', {...onError, result: $event})"/>
      <XSelect
          label="Stop Test"
          :value="onError.action"
          :items="columns[5].items"
          required
          @input="emitInput('onError', {...onError, action: $event})"/>
      <XTextField
          label="Info"
          :value="onError.info"
          @input="emitInput('onError', {...onError, info: $event})"/>
    </InputRow>
  </div>
</template>

<script>
import XTextField from '@/components/basic/XTextField.vue';
import HeadlineBox from '@/components/basic/HeadlineBox.vue';
import ListEditor from '@/components/basic/ListEditor.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XSelect from '@/components/basic/XSelect.vue';
import options from "@/cfg/options.json";

export default {
  name: 'TestStepResponse',
  components: {
    XSelect,
    InputRow,
    ListEditor,
    HeadlineBox,
    XTextField,
  },
  props: {
    value: Object,
    evalTypes: {
      type: Array,
      default: () => options.httpDownloadEval,
    }
  },
  created() {
    this.emitDefaultValue({
      ...this.value,
      res: {
        onError: {
          action: 'stopTest',
          result: 'error',
        },
        eval: [],
      },
    }, () => this.value.res === undefined);
  },
  data() {
    return {
      options: options,
      columns: [
        {
          type: 'select',
          label: 'Type',
          value: 'type',
          items: this.evalTypes,
          required: true,
        },
        {
          type: 'select',
          label: 'Operator',
          value: 'operator',
          items: [
            'equal',
            'greater',
            'greater or equals',
            'not equal',
            'save in variable',
            'smaller',
            'smaller or equals',
          ],
          required: true,
        },
        {
          type: 'text',
          label: 'Value',
          value: 'content',
        },
        {
          type: 'span',
          value: 'else',
        },
        {
          type: 'select',
          label: 'Result',
          value: 'result',
          items: [
            {
              text: 'Processing Error',
              value: 'runtimeError',
            },
            {
              text: 'Processing Warning',
              value: 'runtimeWarning',
            },
            {
              text: 'Result Error',
              value: 'error',
            },
            {
              text: 'Result Warning',
              value: 'warning',
            },
          ],
          default: 'error',
          required: true,
        },
        {
          type: 'select',
          label: 'Action',
          value: 'action',
          items: [
            {
              text: 'Continue',
              value: 'continue',
            },
            {
              text: 'Stop Test',
              value: 'stopTest',
            },
          ],
          default: 'stopTest',
          required: true,
        },
      ],
    };
  },
  computed: {
    eval() {
      return this.value.res ? this.value.res.eval : [];
    },
    onError() {
      return this.value.res ? this.value.res.onError : {};
    },
  },
  methods: {
    emitInput(key, value) {
      this.$emit('input', {
        ...this.value,
        res: {
          ...this.value.res,
          [key]: value,
        },
      });
    },
  },
};
</script>

<style scoped>
.test-step-response {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>