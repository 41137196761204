<script setup>
import { computed, defineEmits, defineProps } from "vue"
import { getByKey } from "@/js/helper"

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  sortBy: {
    type: String,
    default: "",
  },
  sortDesc: {
    type: Boolean,
    default: false,
  },
  checkDisabledRow: {
    type: Function,
    default: () => false,
  },
  withHorizontalScroll: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["update:sortBy", "update:sortDesc"])

const makeSlotName = (name) => name.replace(/\./g, "-")

const sortableKeys = computed(() =>
  props.columns.filter((col) => col.sortable).map((col) => col.key),
)

const toggleSort = (colKey) => {
  if (sortableKeys.value.indexOf(colKey) === -1) {
    return
  }

  if (props.sortBy === colKey) {
    emit("update:sortDesc", !props.sortDesc)
  }

  emit("update:sortBy", colKey)
}
</script>

<template>
  <table :class="{
    'ui-table': true,
    'ui-table--full-height': isLoading || !items.length,
    'ui-table--horizontal-scroll': withHorizontalScroll,
  }">
    <thead class="ui-table__thead">
      <slot name="top-headers" />

      <tr class="ui-table__row ui-table__row--head">
        <th
          v-for="col of columns"
          :key="col.key"
          class="ui-table__cell ui-table__cell--head"
        >
          <div
            :class="{
              'ui-table__inner-cell': true,
              'ui-table__inner-cell--head': true,
              'ui-table__inner-cell--sortable': col.sortable,
              'ui-table__inner-cell--active-head': col.key === sortBy,
              'ui-table__inner-cell--descending-head': col.key === sortBy && sortDesc,
            }"
            @click="() => toggleSort(col.key)"
          >
            <slot
              :name="`header(${makeSlotName(col.key)})`"
              :col="col"
            >
              {{ col.name }}
            </slot>

            <div
              v-if="col.sortable"
              class="ui-table__sort-box"
            >
              <v-icon
                small
                class="ui-table__sort-arrow"
              >
                mdi-arrow-up
              </v-icon>
            </div>
          </div>
        </th>
      </tr>
    </thead>

    <tbody
      v-if="!isLoading && items.length"
      class="ui-table__tbody"
    >
      <tr
        v-for="(item, itemIndex) of items"
        :key="itemIndex"
        :class="{
          'ui-table__row': true,
          'ui-table__row--disabled': checkDisabledRow(item, itemIndex),
        }"
      >
        <td
          v-for="(col, colIndex) of columns"
          :key="colIndex"
          class="ui-table__cell"
        >
          <div class="ui-table__inner-cell">
            <slot
              :name="`cell(${makeSlotName(col.key)})`"
              :item="item"
              :col="col"
              :cell-value="getByKey(item, col.key) || ''"
            >
              {{ getByKey(item, col.key) || "" }}
            </slot>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody v-else-if="isLoading">
      <tr class="ui-table__row ui-table__row--loader">
        <td
          :colspan="columns.length"
          class="ui-table__cell ui-table__cell--loader"
        >
          <div class="ui-table__inner-cell ui-table__inner-cell--loader">
            <slot name="loading-indicator">
              <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="10"
              />
            </slot>
          </div>
        </td>
      </tr>
    </tbody>


    <tbody v-if="!isLoading && !items.length">
      <tr>
        <td
          :colspan="columns.length"
          class="ui-table__cell ui-table__cell--empty"
        >
          <div class="ui-table__inner-cell ui-table__inner-cell--empty-txt">
            <slot name="empty-table-text">
              No records found
            </slot>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss">
$table-header-base: #666666;
$table-header-active-base: #383838;
$table-border-base: #e0e0e0;

$table-row-disabled-bg: #9e9e9e;
$table-row-disabled-border-bg: #8b8b8b;

.ui-table {
  $root: &;
  width: 100%;
  border-spacing: 0;

  &--full-height {
    height: 100%;
  }

  &--horizontal-scroll {
    width: max-content;
    min-width: 100%;
  }

  &__row {
    min-height: 32px;
    transition: background-color .2s;

    &:not(&--head):not(&--loader):hover {
      background-color: $table-border-base;
    }

    &--disabled {
      background-color: $table-row-disabled-bg;

      & #{$root}__cell {
        border-bottom-color: $table-row-disabled-border-bg;
      }
    }
  }

  &__thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }

  &__cell {
    text-align: left;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $table-border-base;

    &--loader {
      border-bottom-color: transparent;
    }

    &--empty {
      border-bottom-color: transparent;
    }

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }

  &__inner-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 32px;
    word-break: break-word;
    font-size: 0.875rem;
    line-height: 1.2;
    padding: 0.25rem;

    &--head {
      color: $table-header-base;
      font-weight: 700;
      font-size: 1rem;
    }

    &--head#{&}--sortable {
      cursor: pointer;

      #{$root}__sort-arrow {
        opacity: 0.5;
        visibility: hidden;
        transition: opacity 0.3s, transform 0.3s;
      }

      &:hover:not(#{$root}__inner-cell--active-head) {
        color: $table-header-active-base;

        #{$root}__sort-arrow {
          visibility: visible;
        }
      }
    }

    &--active-head#{&}--sortable {
      color: $table-header-active-base;

      #{$root}__sort-arrow {
        opacity: 1;
        visibility: visible;
      }
    }

    &--descending-head#{&}--sortable {
      color: $table-header-active-base;

      #{$root}__sort-arrow {
        opacity: 1;
        transform: rotate(180deg);
      }
    }

    &--loader {
      justify-content: center;
      width: 100%;
    }

    &--empty-txt {
      justify-content: center;
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
      color: $table-header-base;
    }
  }

  &__sort-box {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  &__skeleton-cell {
    padding: 0.25rem 1.5px;
  }

  &__skeleton-cell-inner {
    line-height: 2;
    // skeletons are not supported on this project
    //@include dark-inline-skeleton();
  }
}
</style>
