<template>
  <v-card outlined v-if="valueLine.metric && checkTagExist" class="mb-4">
    <v-card-title>Filters</v-card-title>
    <v-card-text>
      <lineFieldsArray
        v-if="valueLine.metric"
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        addButtTitle="Add Filter"
      ></lineFieldsArray>
      <input type="hidden" :name="field" :value="JSON.stringify(value)" />
    </v-card-text>
  </v-card>
  <!-- </div> -->

  <!-- <dateFormatInfo></dateFormatInfo> -->
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";

export default {
  props: [
    "field",
    "fieldAttributes",
    "value",
    "valueLine",
    "isValid",
    "result",
    "fieldAttrRow",
  ],
  data() {
    return {
      file: null,
      message: "",
      showDialog: false,
      imageSource: "",
    };
  },
  components: {
    lineFieldsArray,
  },
  methods: {
    checkInput(value) {
      console.log("check input");
      let discFields = {};

      value.forEach((item) => {
        discFields[item.tag] = item.value;
      });

      this.$emit("input", discFields);
    },
  },
  computed: {
    checkTagExist(){
      let content= false;
      if(typeof this.result.fields.metric.info[this.valueLine.metric] != "undefined" &&
      typeof this.result.fields.metric.info[this.valueLine.metric].tags != "undefined" &&
      this.result.fields.metric.info[this.valueLine.metric].tags != null){
        //content = Object.keys(this.result.fields.metric.info[this.valueLine.metric].tags);
        content = true
      }
      console.log(content)
      return content;
    },
    fields() {
      return {
        tag: {
          type: "select",

          name: "Tag",
          options: this.options,
          inputFieldFormatter: "tagOptionsFormatter",
          fieldAttrInput: { class: "required" },
        },
        value: {
          type: "select",

          name: "Value",
          inputFieldFormatter: "tagValueOptionsFormatter",
          fieldAttrInput: { class: "required" },
        },
        remove: lineButtons.remove,
      };
    },
    pairArray: {
      get() {
        let discFields = [];
        if (typeof this.value == "object" && this.value != null) {
          Object.keys(this.value).forEach((index) => {
            discFields.push({ tag: index, value: this.value[index] });
          });
        }
        return discFields;
      },
    },
  },
};
</script>