<template>
    <FormDialog
      :value="dialog"
      title="New Service"
      width="780"
      @input="close"
      @save="save()">
      <template #dialog-content>
        <div class="content">
          <XSelect
            v-model="dataLocalServer.type"
            label="Type"
            :items="['LDAP']"
            dense
            style="margin: 8px -7px 0 0">
          </XSelect>
          <XTextField
            v-model="dataLocalServer.name"
            label="Name"
            :rules="[rules.required]"
            counter
            max-length="100"
            validate-immediately
            style="margin-top: 8px">
          </XTextField>
          <XTextarea
            v-model="dataLocalServer.description"
            label="Description"
            height="56"
            counter
            max-length="800"
            style="margin-top: -7px">
          </XTextarea>
          <XSelect
            v-model="dataLocalServer.activeProbe"
            label="Type"
            :items="activeProbeNames"
            dense
            read-only
            :menu-props="{ disabled: true }"
            style="margin: 8px -7px 0 0">
          </XSelect>
        <InputRow>
          <XSelect
            v-model="dataLocalServer.address"
            label="IP Address"
            :items="ipAddresses"
            :rules="[rules.required]"
            validate-immediately
            dense
            style="margin: 8px -7px 0 0">
          </XSelect>
          <XSelect
            v-model="dataLocalServer.protocol"
            :items="protocols"
            label="Protocol"
            :rules="[rules.required]"
            dense
            style="margin: 8px -7px 0 0">
          </XSelect>
          <XSelect
            v-model="dataLocalServer.port"
            :items="ports"
            label="Port"
            :rules="[rules.required]"
            dense
            style="margin: 8px -7px 0 0">
          </XSelect>
        </InputRow>
        </div>
      <v-spacer></v-spacer>
      </template>
    </FormDialog>
  </template>
  
<script>
import FormDialog from '@/components/extended/FormDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XTextarea from '@/components/basic/XTextarea.vue';
import InputRow from '@/components/basic/InputRow.vue';
// import XBtn from '@/components/basic/XBtn.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";

export default {
  name: 'NewServiceDialog',
  components: {
    FormDialog,
    // XBtn,
    InputRow,
    XSelect,
    XTextarea,
    XTextField
  },
  props: {
    row: Object,
    value: Boolean,
  },
  data() {
    return {
      activeProbes: undefined,
      activeProbesObject: [],
      activeProbeNames: [],
      dataLocalServer: {
        name: "",
        description: "",
        type: "LDAP",
        activeProbe: "",
        address: "",
        port: "",
        protocol: "",
      },
      dialog: false,
      ipAddresses: [],
      ports: [],
      protocols: [],
      rules: {
        required: value => !!value || '',
      },
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
      this.dialog = false;
    },
    save() {
      console.log("Saving..Service: ", this.dataLocalServer);
      // testCaseInfoService.newLocalServer(this.dataLocalServer, () => {
      //   console.log("");
      //   this.$emit('save');
      //   this.close();
      // });
    }
  },
  created() {
    testCaseInfoService.getAllActiveProbes((activeProbes) => {
      this.activeProbeNames = activeProbes.map(ap => ap.name);
      this.activeProbes = activeProbes.sort();
      // console.log("Active Probes: ", activeProbes);
    });
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return;
        this.dialog = value;
      }
    },
    activeProbes: {
      immediate: true,
      deep: true,
      handler() {
        this.dataLocalServer.activeProbe = this.activeProbeNames[0];
      }
    },
    'dataLocalServer.activeProbe': {
      immediate: true,
      deep: true,
      handler(value) {
        console.log("Active probe: ", value);
        if (this.activeProbes !== undefined) {
          this.activeProbesObject = this.activeProbes.find(ap => ap.name === value)['object'];
          this.activeProbesObject = JSON.parse(this.activeProbesObject);
          // console.log("AP Obj: ", this.activeProbesObject);

          // reset
          this.dataLocalServer.address = '';
          this.ipAddresses = [];
          this.protocols = [];
          this.ports = [];

          for (let localAddr in this.activeProbesObject["LocalAddresses"]) {
            this.ipAddresses.push(localAddr)
            this.ipAddresses = this.ipAddresses.sort();
            this.dataLocalServer.address = this.ipAddresses.length === 1 ? this.ipAddresses[0] : '';
          }
        }


      }
    },
    'dataLocalServer.address': {
      immediate: true,
      deep: true,
      handler(value) {
        console.log("Address: ", value);
        // reset
        this.protocols = [];
        this.ports = [];

        if (value !== '') {
          let addr = this.activeProbesObject["LocalAddresses"][value];
          if (Object.keys(addr).length > 0) {
            this.protocols = Object.keys(addr[this.dataLocalServer.type]);
            this.dataLocalServer.protocol = this.protocols.length === 1 ? this.protocols[0] : ''; // wenn es nur ein Element hat
            this.ports = addr[this.dataLocalServer.type][this.dataLocalServer.protocol].sort();
            this.dataLocalServer.port = this.ports[0];
          }
        }
      }
    },
    // protocol: {
    //   immediate: true,
    //   deep: true,
    //   handler(value) {
    //     this.ports = [];

    //     if (value !== '') {
    //       this.ports = this.activeProbesObject["LocalAddresses"][this.ipAddress][this.protocol];
    //     }
    //   }
    // },
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>