<template>
  <v-row no-gutters>
    <v-col>
      <defaultFieldTypes
        :fieldAttributes="{
          value: value,
          fieldAttrInput: { readonly: 'readonly' },
          name:'API Key'
        }"
        field="apiKeyGenerator"
        :templateContent="result"
        :value="value"
      ></defaultFieldTypes>
    </v-col>

    <v-col style="max-width: 60px" class="mt-2 ml-2">
      <v-icon @click="generateApiKey" :disabled="disabled"
        >mdi-key-variant</v-icon
      >
      <trash v-if="value != ''" @click="removeApiKey" :result="result"></trash>
    </v-col>
  </v-row>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

import trash from "@/commonComponents/dynamic/trash.vue";
import requests from '@/js/requests';
export default {
  components: {
    defaultFieldTypes,
    trash,
  },
  data: function () {
    //console.log(this.fieldAttributes);
    return {};
  },
  props: ["field", "fieldAttributes", "result", "value"],
  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    generateApiKey() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();
      }
      var key = "xxxxxyxxxxxxxxyxxxxxxxxxyxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );

      this.$emit("input", key);
      // $(that).closest(".apiKeyContainer").find(".apiKey").val(key);
      // $(that)
      //   .closest(".apiKeyContainer")
      //   .find(".removeKey")
      //   .css("visibility", "visible");
    },

    removeApiKey() {
      // var projectID = $(that)
      //   .closest(".apiKeyContainer")
      //   .find(".projectID")
      //   .val();
      let projectID = this.result.valueLine.n_id;
      // $.ajax({
      //   type: "POST",
      //   url: "serve.php?f=administration&f2=projectAdministration",
      //   data: { function: "deleteApiKey", pid: projectID, requestType: "ajax" },
      //   dataType: "json",
      //   success: function (data) {
      //     if (data) {
      //       $(that).closest(".apiKeyContainer").find(".apiKey").val("");
      //       $(that).css("visibility", "hidden");
      //     }
      //   },
      // });

      let caller = this;
      caller.$emit("input", "");
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=administration&f2=projectAdministration',
        data: {
          function: 'deleteApiKey',
          pid: projectID,
          requestType: 'ajax',
        },
      })
          .then(function () {})
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
  },
};
</script>

