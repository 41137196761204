<template>
  <div v-if="result?.valueLine?.c_type === 'Enumerated'">
    <attributeValueAdderContent
        :addable="true"
        :headerTitle="true"
        indexName="Value"
        v-model="diaEnum"
        :fields="fields"
        :isValid="isValid"
        linePrefix="dia_enum"
    ></attributeValueAdderContent>
    <input type="hidden" :value="JSON.stringify(localValue)" name="c_obj"/>
  </div>
</template>
<script>
import attributeValueAdderContent from "@/components/legacy/attributeValueAdderContent.vue";
import lineButtons from "@/cfg/lineButtons.json";

export default {
  components: {attributeValueAdderContent},
  props: ["result", "fieldAttributes", "field", "value", "isValid"],
  data() {
    return {
      fields: {
        index: {
          type: "text",
          name: "Value",
          fieldAttrInput: {class: "required number"},
        },
        value: {
          type: "text",
          name: "Interpretation",
          fieldAttrInput: {class: "required"},
        },
        remove: lineButtons.remove,
      },
    };
  },
  computed: {
    localValue: {
      get() {
        let localValue;
        try {
          localValue = JSON.parse(this.value);
        } catch (error) {
          localValue = {diaEnum: {}};
        }
        return localValue;
      },
      set(newVal) {
        let val = JSON.stringify(newVal);
        this.$emit("input", val);
      },
    },
    diaEnum: {
      get() {
        return this.localValue.diaEnum;
      },
      set(newVal) {
        this.$set(this.localValue, "diaEnum", newVal);
        let val = JSON.stringify(this.localValue);
        this.$emit("input", val);
      },
    },
  },
};
</script>