<template>
    <div>
        <v-card>
                <v-toolbar
                        flat
                        color="white"
                >
                    <v-toolbar-title>{{language.logView.ld}}</v-toolbar-title>
                        <v-col class="mt-7" cols="1">
                            <associatedOption
                                    :fieldAttributes="{ name: language.logView.ll,
                                                        options: itemCounts,
                                                        sort:false
                                                       }"
                                    type="select"
                                    :fieldAttrInput="{}"
                                    :value=lines
                                    @input="updateCount(...arguments)"
                            ></associatedOption>
                        </v-col>
                        <v-col class="mt-7 ml-n2" cols="5">
                            <primaryTextfield
                                    type="text"
                                    :fieldAttrInput="{ }"
                                    label="Log Search"
                                    v-model="searchLog"
                                    :templateContent="result"
                            ></primaryTextfield>
                        </v-col>
                </v-toolbar>
            <v-divider></v-divider>
                <v-tabs vertical>
                    <v-tab  v-for="(filePath, fileName) in result.logViewValue.fileList"
                            :key="fileName"
                            @click="callLog(filePath)"
                            class="text-start"
                     >
                    {{fileName}}
                    </v-tab>
                    <v-tab-item v-for="(filePath, fileName) in result.logViewValue.fileList"  :key="fileName">
                        <v-card flat>
                            <v-card-text>
                          <span v-if="newData.length!=''">
                            <ul>
                              <li v-for="(dataValue, dataKey) in newData" :key="dataKey">
                                  <div v-html="highlight(dataValue)"></div>
                              </li>
                          </ul>
                          </span>
                          <span class="mt-16 ml-16 subtitle-1" v-else>
                              No Log Found!
                          </span>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
     </div>
</template>
<script>
  import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
  import associatedOption from "@/commonComponents/associatedOption.vue";
  import requests from '@/js/requests';
  export default {
    data:function(){
      return {
        localResult:this.result.logViewValue,
        lines:10,
        localPath:typeof this.result.logViewValue.log!='undefined'?this.result.logViewValue.log:"",
        searchLog: '',
        itemCounts: [["10","10"], ["25","25"], ["50","50"], ["100","100"],["500","500"],["1000","1000"]]
      };
    },
    components: { associatedOption,primaryTextfield },
    computed: {
      newData(){
        let newArray = this.listData(this.localResult.tailList);
        if(this.searchLog){
          let resultItem =  newArray.filter((item)=>{
            return this.searchLog.toLowerCase().split(' ').every(v => item.toLowerCase().includes(v));
          });
          /*if(resultItem.length==0){
            resultItem = ["No Log found!"];
          }*/
           return resultItem;
        }else{
          return this.localResult;
        }
      }
    },
    methods: {
      highlight(text) {
        let final=text;
        if (typeof text === 'string' && text!='') {
           var index = text.indexOf(this.searchLog);
           if (index > 0) {
            final = text.substring(0,index) + "<span class='highlight'>" + text.substring(index,index+this.searchLog.length) + "</span>" + text.substring(index + this.searchLog.length);
          }
        }
        return final;
     },
      updateCount(newVal) {
        this.lines = newVal;
        this.callLog(this.localPath);
      },
      listData(str){
        var n = str.split("\n");
        let  reversed = n.reverse();
        return reversed.filter(item => item);//remove empty!
      },
      callLog(filePath){
        this.searchLog="";
        let caller = this;
        this.localPath=filePath;
        requests.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=administration&f2=logViewer',
          dataType: 'json',
          data: {
            p: filePath,
            lines: this.lines,
            requestType: 'ajax',
          },
        })
            .then(response => {
            caller.localResult=response.data.result.logViewValue ;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    props: ["row","currentTable","result"]
  }
</script>
<style scope>
    .highlight {
        background-color: yellow;
    }
</style>