<template>
  <div class="test-info-explorer-cellular-attach-response">
    <template  v-if="value.state==='registration denied'">
      <TestInfoTable :headers="registerDenied" :items="[value]"/>
    </template>
    <template v-else>
      <TestInfoTable :headers="registerInfoHeaders" :items="[value]"/>
      <div>
        <TestInfoTable step-type="Explorer_cellular_attach"  :headers="ueSystemInfoHeaders" :items="value.ue_system_info" horizontal/>
      </div>
    </template>
  </div>
</template>

<script>
import TestInfoTable from '@/components/basic/TestInfoTable.vue';

export default {
  name: 'TestInfoExplorerCellularAttachResponse',
  components: {TestInfoTable},
  props: {
    value: Object,
  },
  data() {
    return {
      registerDenied: [
        {
          value: 'state',
          text: 'Register Info State',
        },
      ],
      registerInfoHeaders: [
        {
          value: 'state',
          text: 'Register Info State',
        },
        {
          value: 'operator',
          text: 'Operator',
        },
      ],
      ueSystemInfoHeaders: [
        {
          value: 'system_mode',
          text: 'Mode',
        },
        {
          value: 'mccmnc',
          text: 'MCCMNC',
          formatter: (value, row) => `${row.mcc ? row.mcc : ''}${row.mnc ? row.mnc : ''}`,
        },
        {
          value: 'tac',
          text: 'TAC',
        },
        {
          value: 's_cell_id',
          text: 'CI',
        },
        {
          value: 'p_cell_id',
          text: 'PCI',
        },
        {
          value: 'frequency_band',
          text: 'BAND',
        },
        {
          value: 'earfcn',
          text: 'EARFCN',
        },
        {
          value: 'rsrp',
          text: 'RSRP',
        },
        {
          value: 'rsrq',
          text: 'RSRQ',
        },
        {
          value: 'rssnr',
          text: 'RSSNR',
        },
        {
          value: 'rssi',
          text: 'RSSI',
        },
        {
          value: 'dl_bw',
          text: 'DLBW',
        },
        {
          value: 'ul_bw',
          text: 'ULBW',
        },
      ],
    };
  },
};
</script>

<style scoped>
.test-info-explorer-cellular-attach-response {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 500px;
}
</style>
