<template>
    <div>
        <configContainer title="Condition">
            <template v-slot:content>
                <buildFieldsRows
                        :templateContent="radiusCheckCode"
                        v-model="check"
                        languageIndex="expectStep"
                ></buildFieldsRows>
                <expectRadiusEvalContentTable
                        :attrsArray="reqAttributes"
                        v-model="localEval"
                        type="expect"
                        operatorType="incNull"
                        :result="result"
                        :isValid="isValid"
                ></expectRadiusEvalContentTable>
            </template>
        </configContainer>
        <subStepsModule
                :groupedStepDependentInfo="groupedStepDependentInfo"
                :stepStoredValue="localStoredValue"
                :stepID="stepID"
                :result="result"
                @group-value-changed="updateTestValue(...arguments)"
                :isValid="isValid"
        ></subStepsModule>

        <localDataGetter
                :stepStoredValue="parameters"
                :result="result"
                :ipcanStepTemplates="ipcanStepTemplates"
                :stepType="stepType"
                application="RADIUS"
        >
            <template v-slot:default="data">
                <expectRadiusResponse
                        :localData="data.localData"
                        :stepStoredValue="stepStoredValue"
                        :result="result"
                        :stepType="stepType"
                        v-on="$listeners"
                        :isValid="isValid"
                ></expectRadiusResponse>
            </template>
        </localDataGetter>
    </div>
</template>
<script>
    import expectRadiusResponse from "@/components/legacy/expectRadiusResponse.vue";
    import subStepsModule from "@/components/legacy/subStepsModule.vue";
    import expectRadiusEvalContentTable from "@/components/legacy/expectRadiusEvalContentTable.vue";
    import localDataGetter from "@/components/legacy/localDataGetter.vue";
    import configContainer from "@/commonComponents/configContainer.vue";
    import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
    import options from "@/cfg/options.json";
//import { setupAttribute } from "@/js/helper.js";

    export default {
        components: {
            subStepsModule,
            expectRadiusEvalContentTable,
            localDataGetter,
            expectRadiusResponse,
            configContainer,
            buildFieldsRows
        },
        props: [
            "groupedStepDependentInfo",
            "stepStoredValue",
            "result",
            "stepID",
            "stepType",
            "ipcanStepTemplates",
            "isValid"
        ],
        data() {
            return {
                radiusCheckCode: {
                    fields: {
                        code: {
                            type: "combobox",
                            options: options.radiusCode,
                            fieldAttrInput: {class: "required"},
                        }
                    },
                },
                reqAttributes:[]
            };
        },
  /*      mounted() {
            this.getAttributes();
        },*/
        computed: {
            check: {
                get() {
                    let check = {};
                    if (typeof this.stepStoredValue.check != "undefined") {
                        check = this.stepStoredValue.check;
                    }
                    return check;
                },
                set(newValue) {
                    this.$set(this.localStoredValue, "check", newValue);
                    this.$emit("stored-value-changed", this.localStoredValue);
                },
            },
            parameters() {
                let parameters = {};
                if (
                    typeof this.stepStoredValue.response != "undefined" &&
                    typeof this.stepStoredValue.response.parameters != "undefined"
                ) {
                    parameters = this.stepStoredValue.response.parameters;
                }
                return parameters;
            },
            localStoredValue: {
                get() {
                    return this.stepStoredValue;
                },
                set(newValue) {
                    console.log("new value");
                    this.$emit("stored-value-changed", newValue);
                },
            },
            localEval: {
                get() {
                    let evalValue = {};
                    if (
                        typeof this.localStoredValue.check != "undefined" &&
                        typeof this.localStoredValue.check.eval != "undefined"
                    ) {
                        evalValue = this.localStoredValue.check.eval;
                    }
                    return evalValue;
                },
                set(newVal) {
                    this.$set(this.localStoredValue.check, "eval", newVal);
                    this.$emit("stored-value-changed", this.localStoredValue);
                },
            },
        },
        methods: {
       /*     getAttributes() {
                dictionaryService.getRadiusAttributes((attributes) => {
                    this.reqAttributes = setupAttribute(attributes);
                });
            },*/
            updateTestValue(substepsValue) {
                let local = JSON.parse(JSON.stringify(substepsValue));
                console.log(local);
                this.$set(this.localStoredValue, "substeps", local);
                this.$emit("stored-value-changed", this.localStoredValue);
            },
        },
    };
</script>