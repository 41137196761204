<template>
  <thead class="basic-table-header">
    <tr>
      <slot name="before" />

      <th
        class="basic-table-header__header-cell"
        v-for="(h, i) of headers" :key="i"
        :style="h.width ? { width: h.width } : {}"

      >
        <button
          v-if="h.sortable"
          type="button"
          @click="() => sort(h.value)"
        >
          {{ h.text }}
          <v-icon
            small
            :class="`basic-table-header__arrow ${h.value === sortBy ? 'active' : ''} ${isDescending ? 'descending' : ''}`">
              mdi-arrow-up
          </v-icon>
        </button>

        <span v-else>
          {{ h.text }}
        </span>
      </th>

      <slot name="after" />
    </tr>
  </thead>
</template>

<script>
import { defineComponent, toRefs } from 'vue'

export default defineComponent({
  props: {
    /**
    * @type DataTableHeader[]
    */
    headers: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      default: '',
    },
    isDescending: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      sortBy,
      isDescending,
    } = toRefs(props)

    const sort = hValue => {
      const payload = {
        sortBy: hValue,
        descending: isDescending.value,
      }

      if (sortBy.value === hValue) {
        if (!isDescending.value) {
          payload.descending = true;
        } else {
          payload.sortBy = '';
          payload.descending = false;
        }
      }

      emit('sort', payload)
    }

    return {
      sort,
    }
  },
})
</script>

<style lang="scss">
.basic-table-header {
  $root: &;

  &__header-cell {
    font-size: 16px !important;
  }

  &__arrow {
    visibility: hidden;
    transition: all 0.3s;

    &.active {
      visibility: visible;
      color: var(--v-tableHeaderSortArrow-base);
    }

    &.active.descending {
      rotate: -180deg;
    }
  }

  &:hover {
    #{$root}__arrow:not(.active) {
      visibility: visible;
      color: var(--v-tableHeaderSortArrow-base);
    }
  }

}
</style>
