<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <containerTitle title="Request Packet">
        <v-spacer></v-spacer>{{language.componentLabel.labelShowTemplate}}:
        <div v-for="(info, index) in config" :key="index" class="ml-2">
          <v-switch
            hide-details
            v-model="info.requestEnabled"
            :label="info.name"
            :color="info.color"
            :disabled="disabled"
          ></v-switch>
        </div>
      </containerTitle>
      <v-container>
        <radiusRequestAttr
          :attrsArray="reqAttributes"
          :value="mergedTemplate.req"
          :application="stepType"
          source="t_testcases"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateReq(...arguments)"
          :applicationValue="localData.enumeration"
          :result="result"
        ></radiusRequestAttr>
      </v-container>
    </v-card>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2"
          >{{language.componentLabel.labelRPE}}</v-toolbar-title
        >
        <v-spacer></v-spacer>{{language.componentLabel.labelShowTemplate}}:
        <div class="ml-2">
          <v-switch
            hide-details
            v-model="responseDetails"
            :label="config.t_templates.name"
            :color="config.t_templates.color"
            :disabled="disabled"
          ></v-switch>
        </div>
      </v-toolbar>
      <v-container>
        <associatedOption
          v-if="stepType == 'RADIUS_ACCESS'"
          :fieldAttributes="{
            name: 'Expected Result',
            associatedOption: [
              ['ACCESS_ACCEPT', 'Access Accept'],
              ['ACCESS_REJECT', 'Access Reject'],
            ],
          }"
          type="select"
          :fieldAttrInput="{}"
          :value="expectedResponse"
          @input="updateExpected(...arguments)"
          :templateContent="result"
        ></associatedOption>

        <radiusResponseAttr
          :attrsArray="reqAttributes"
          :value="responseObject"
          :application="stepType"
          source="t_testcases"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateRes(...arguments)"
          :result="result"
        ></radiusResponseAttr>

        <v-divider class="mb-6"></v-divider>
        <onTimeOutNoResult
          onEvent="On Timeout"
          v-model="onTimeout"
          :result="result"
        ></onTimeOutNoResult>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import {
  getConfiguredValue,
  //getStepValue,
  addEditedRadiusDiameterValues,
  addAttributes,
  // findIndex,
} from "@/js/testHelpers.js";
import radiusRequestAttr from "@/components/legacy/radiusRequestAttr.vue";
import radiusResponseAttr from "@/components/legacy/radiusResponseAttr.vue";
import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import { getIndexedTemplate } from "@/js/testHelpers.js";
import { setupAttribute } from "@/js/helper.js";
import dictionaryService from "@/js/services/DictionaryService";

export default {
  props: [
    "templatesData",
    "stepStoredValue",
    "stepType",
    "result",

    "radiusDynamicPars",
    "ipcanStepTemplates",
    "localData",
  ],
  components: {
    containerTitle,
    radiusRequestAttr,
    onTimeOutNoResult,
    radiusResponseAttr,
    associatedOption,
  },
  data() {
    return {
      // requestDetails: false,
      reqAttributes: [],
      responseDetails: true,
      config: {
        t_localClients: {
          color: "red",
          name: "Client",
          requestEnabled: false,
          type: "client",
        },
        t_remoteServers: {
          color: "blue",
          name: "Server",
          requestEnabled: false,
          type: "server",
        },
        t_templates: {
          color: "green",
          name: "Packet",
          requestEnabled: false,
          type: "packet",
        },
      },
    };
  },
  mounted() {
        this.getAttributes();
  },
  created() {
    this.combineRadiusDiameterTemplates();
  },
  // watch: {
  //   stepStoredValue(newVal) {
  //    //console.log("step value changed", newVal);
  //     this.mergedTemplate = addEditedRadiusDiameterValues(
  //       this.combinedTemplate,

  //       this.stepType,
  //       newVal
  //     );
  //   }
  // },
  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    onTimeout: {
      get() {
        var onTimeout = {};
        if (
          typeof this.stepStoredValue != "undefined" &&
          typeof this.stepStoredValue["res"] != "undefined"
        ) {
          if (typeof this.stepStoredValue.res.onTimeout != "undefined") {
            onTimeout = this.stepStoredValue.res.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };

        if (localStoredStep == null || typeof localStoredStep == "undefined") {
          localStoredStep = {};
        }
        if (
          localStoredStep == null ||
          typeof localStoredStep == "undefined" ||
          typeof localStoredStep.res == "undefined"
        ) {
          this.$set(localStoredStep, "res", {});
        }
        this.$set(localStoredStep.res, "onTimeout", newVal);
        //console.log("value changed ", localStoredStep);
        this.$emit("stored-value-changed", localStoredStep);
      },
    },
    combinedRequest() {
      let ipcanStep = this.stepType;
      var template = {};
      var configuredValue = getConfiguredValue(this.stepType, ipcanStep);
      //template = $.extend(true, {}, configuredValue);
      //console.log(configuredValue);
      template = JSON.parse(JSON.stringify(configuredValue));
      if (this.config.t_templates.requestEnabled) {
        template = this.buildRadiusRequestTemplate(
          template,
          "t_templates",
          ipcanStep,
          this.templatesData
        );
      }
      if (this.config.t_remoteServers.requestEnabled) {
        template = this.buildRadiusRequestTemplate(
          template,
          "t_remoteServers",
          ipcanStep,
          this.templatesData
        );
      }
      if (this.config.t_localClients.requestEnabled) {
        console.log("client Enabled");
        template = this.buildRadiusRequestTemplate(
          template,
          "t_localClients",
          ipcanStep,
          this.templatesData
        );
      }
      //console.log(template);
      //  this.combinedTemplate = template;
      return template.req;
    },
    combinedResponse() {
      let ipcanStep = this.stepType;
      var template = {};
      var configuredValue = getConfiguredValue(this.stepType, ipcanStep);
      //template = $.extend(true, {}, configuredValue);
      //console.log(configuredValue);
      template = JSON.parse(JSON.stringify(configuredValue));
      template = this.buildRadiusResponseTemplate(
        template,
        "t_templates",
        ipcanStep,
        this.templatesData
      );
      template = this.buildRadiusResponseTemplate(
        template,
        "t_remoteServers",
        ipcanStep,
        this.templatesData
      );
      template = this.buildRadiusResponseTemplate(
        template,
        "t_localClients",
        ipcanStep,
        this.templatesData
      );
      //console.log(template);
      //  this.combinedTemplate = template;
      return template.res;
    },
    mergedTemplate() {
      let ipcanStep = this.stepType;

      let combinedTemplate = {};
      combinedTemplate = getConfiguredValue(this.stepType, ipcanStep);
      //console.log(combinedTemplate);
      //if (this.requestDetails) {
      combinedTemplate.req = this.combinedRequest;
      // }
      if (this.responseDetails) {
        combinedTemplate.res = this.combinedResponse;
      }
      let mergedTemplate = addEditedRadiusDiameterValues(
        combinedTemplate,

        ipcanStep,
        // fieldsObject,
        this.coloredStoredStepValue,
        this.stepType
      );
      return mergedTemplate;
    },
    coloredStoredStepValue() {
      let coloredStoredStepValue = { ...this.stepStoredValue };
      if (
        typeof this.stepStoredValue.res != "undefined" &&
        typeof this.stepStoredValue.res.eval != "undefined"
      ) {
        for (let [protocol, valueObject] of Object.entries(
          this.stepStoredValue.res.eval
        )) {
          for (let [attribute, valueArray] of Object.entries(valueObject)) {
            //console.log(valueArray);
            valueArray.forEach((line, index) => {
              //console.log(line);
              let color = "";
              if (typeof this.config[line.source] != "undefined") {
                color = this.config[line.source].color;
              }
              this.$set(
                coloredStoredStepValue.res.eval[protocol][attribute][index],
                "color",
                color
              );
            });
          }
        }
      }
      return coloredStoredStepValue;
    },
    expectedResponse() {
      let expectedResponse;

      if (this.stepType == "RADIUS_ACCESS") {
        if (
          typeof this.stepStoredValue.res != "undefined" &&
          typeof this.stepStoredValue.res.expectedResponse != "undefined"
        ) {
          expectedResponse = this.stepStoredValue.res.expectedResponse;
        } else {
          expectedResponse = "ACCESS_ACCEPT";
          this.updateExpected(expectedResponse);
        }
      } else if (this.stepType == "RADIUS_ACCOUNTING") {
        expectedResponse = "ACCOUNTING_RESPONSE";
      }
      return expectedResponse;
    },
    responseObject() {
      let responseObject = {};
      if (
        typeof this.mergedTemplate.res != "undefined" &&
        typeof this.mergedTemplate.res.eval != "undefined"
      ) {
        responseObject = this.mergedTemplate.res.eval[this.expectedResponse];
      }
      return responseObject;
    },
  },
  methods: {
    getAttributes() {
          dictionaryService.getRadiusAttributes((attributes) => {
              this.reqAttributes = setupAttribute(attributes);
          });
    },
    updateExpected(expected) {
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res == "undefined") {
        this.$set(localStoredStep, "res", {});
      }
      this.$set(localStoredStep.res, "expectedResponse", expected);
      this.$emit("stored-value-changed", localStoredStep);
    },

    updateReq(reqValue) {
      let localStoredStep = { ...this.stepStoredValue };
      //console.log(reqValue);
      let request = {};
      for (const [key, valueArray] of Object.entries(reqValue)) {
        valueArray.forEach((line, index) => {
          // if (line.source == "t_testcases") {
          //   if (typeof request[key] != "undefined") {
          //     request[key].push(line);
          //   } else {
          //     request[key] = [line];
          //   }
          // } else {
          let value = line.content;
          let originalValue = value;
          if (typeof this.config[line.source] != "undefined") {
            let config = this.config[line.source];

            let objectTemplate = getIndexedTemplate(
              config.type,
              this.stepType,
              this.templatesData
            );
            let mapping = {};
            let i = 0;
            valueArray.forEach((lineinfo, innerIndex) => {
              if (lineinfo.source == line.source) {
                mapping[innerIndex] = i;
                i++;
              }
            });
            if (
              typeof objectTemplate.req != "undefined" &&
              typeof objectTemplate.req[key] != "undefined" &&
              typeof objectTemplate.req[key][mapping[index]] != "undefined"
            ) {
              originalValue = objectTemplate.req[key][mapping[index]].content;
            }
          }
          //console.log(originalValue);
          if (typeof originalValue == "undefined") {
            originalValue = "";
          }

          if (typeof value == "undefined" || value == null) {
            value = "";
          }
          var source = line.source;
          // //////////console.log(value);
          let attrSource = "t_testcases";
          if (
            line.fix == "2" ||
            originalValue !== value ||
            originalValue === "" ||
            source == attrSource
          ) {
            let templateFixed = "0";
            let testFixed = "0";
            // added condition originalValue!=="" for the inputs which are new
            if (line.fix == "2") {
              templateFixed = "2";
              testFixed = "2";
            }
            if (originalValue !== value && originalValue !== "") {
              templateFixed = "2";
              testFixed = "0";
            }
            let mandatory = 0;
            if (value.mandatory == 1) {
              mandatory = 1;
            }

            if (
              originalValue !== value ||
              (originalValue === value && source == "t_testcases")
            ) {
              /// added 29.04.2016 by ammar bug when the origional value is a EVAL which does not exsist in the options
              //  if (!($attrValueObject.prop("tagName")=="SELECT" && originalValue.indexOf("EVAL:")==0 && value.indexOf("EVAL:")==-1)){
              ////console.log(originalValue,value,source);
              let attributeInfo = {
                content: value,
                fix: testFixed,
                source: attrSource,
                mandatory: mandatory,
                atRanId: line.atRanId,
              };

              if (typeof request[key] != "undefined") {
                request[key].push(attributeInfo);
              } else {
                request[key] = [attributeInfo];
              }
            }

            // var attributeName = value.attribute;
            if (originalValue !== "" && source != "t_testcases") {
              // adding the original value from templates
              let attributeInfo = {
                content: originalValue,
                fix: templateFixed,
                source: source,
                mandatory: mandatory,
                atRanId: line.atRanId,
              };

              if (typeof request[key] != "undefined") {
                request[key].push(attributeInfo);
              } else {
                request[key] = [attributeInfo];
              }
            }
          }
          //  }
        });
      }
      console.log(JSON.stringify(request));
      this.$set(localStoredStep, "req", request);
      this.$emit("stored-value-changed", localStoredStep);
    },
    updateRes(resValue) {
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res.eval == "undefined") {
        this.$set(localStoredStep.res, "eval", {});
      }
      if (
        typeof localStoredStep.res.eval[this.expectedResponse] == "undefined"
      ) {
        this.$set(localStoredStep.res.eval, this.expectedResponse, {});
      }
      let response = {};
      console.log(this.responseObject);
      for (const [key, valueArray] of Object.entries(resValue)) {
        valueArray.forEach((value) => {
          // this.$set(value, "source", "t_testcases");

          // if (typeof response[key] != "undefined") {
          //   response[key].push(value);
          // } else {
          //   response[key] = [value];
          // }

          // this.responseObject.forEach((singleValue) => {
          //   let foundIndex = findIndex(resValue, singleValue);

          //   if (foundIndex > -1) {
          //     // if found check valu
          //     console.log("found",foundIndex,resValue[foundIndex]);
          //   }
          // });

          let attrArray;
          if (typeof response[key] != "undefined") {
            attrArray = response[key];
          } else {
            attrArray = [];
          }
          let templateValue;
          let addTemplate = false;
          if (
            value.originalValue != value.content &&
            value.source != "t_testcases"
          ) {
            addTemplate = true;
            console.log(value);
            templateValue = { ...value };
            this.$set(value, "source", "t_testcases");
            this.$set(templateValue, "fix", "2");
            this.$set(templateValue, "content", value.originalValue);
            this.$delete(templateValue, "originalValue");
          }
          this.$delete(value, "originalValue");

          attrArray.push(value);
          if (addTemplate) {
            attrArray.push(templateValue);
          }

          this.$set(response, key, attrArray);

          // }
        });
      }
      this.$set(localStoredStep.res.eval, this.expectedResponse, response);
      console.log(localStoredStep);
      this.$emit("stored-value-changed", localStoredStep);
    },
    combineRadiusDiameterTemplates() {
      // if (this.combinedTemplate) {
      //   // var stepStoredValue = getStepValue(
      //   //   this.templatesData
      //   //   // $testCasesGroup,
      //   //   stepId,
      //   //   stepType,
      //   //   ipcanStep
      //   // );
      //   this.mergedTemplate = addEditedRadiusDiameterValues(
      //     this.combinedTemplate,
      //     ipcanStep,
      //     // fieldsObject,
      //     this.stepStoredValue
      //   );
      // }
      //   if (typeof $testCasesGroup.data("template")[stepId] == "undefined") {
      //     $testCasesGroup.data("template")[stepId] = {};
      //   }
      //   //hard copy the object
      //   $testCasesGroup.data("template")[stepId][ipcanStep] = jQuery.extend(
      //     true,
      //     {},
      //     template
      //   );
      // template = JSON.parse(JSON.stringify(template));
      //   if (ipcanStep == "RADIUS_ACCOUNTING") {
      //     //show Acct-Status-Type
      //     let acctStatusType = "";
      //     if (typeof template.req[40] != "undefined") {
      //       acctStatusType = template.req[40][0]["content"];
      //       if (template.req[40].length > 1) {
      //         template.req[40].forEach(function(value) {
      //           if (value["source"] == "t_testcases" && value["fix"] != "2") {
      //             acctStatusType = value["content"];
      //             return;
      //           }
      //         });
      //       }
      //     }
      //     let acctStatusTypeString = "";
      //     if (
      //       typeof fieldsObject.data("enumerated")["RADIUS_ACCOUNTING"] !=
      //         "undefined" &&
      //       typeof fieldsObject.data("enumerated")["RADIUS_ACCOUNTING"][0] !=
      //         "undefined" &&
      //       typeof fieldsObject.data("enumerated")["RADIUS_ACCOUNTING"][0][40] !=
      //         "undefined"
      //     ) {
      //       let statusTypeArray = fieldsObject.data("enumerated")[
      //         "RADIUS_ACCOUNTING"
      //       ][0][40];
      //       statusTypeArray.forEach(function(value) {
      //         if (value[0] == acctStatusType) {
      //           acctStatusTypeString = value[1];
      //         }
      //       });
      //     }
      //     let packetValue = $stepPlace
      //       .find(".packetValue select  option:selected")
      //       .text();
      //     let infoString = ": " + packetValue;
      //     infoString += ": <span class='statusType'>";
      //     if (
      //       typeof acctStatusTypeString != "undefined" &&
      //       acctStatusTypeString != ""
      //     ) {
      //       infoString += acctStatusTypeString;
      //     }
      //     infoString += "</span>";
      //     $stepPlace
      //       .closest(".stepGroup")
      //       .find(".stepApnText .statusInfo")
      //       .html(infoString);
      //   } else if (checkDiameterProtocol(ipcanStep) || ipcanStep == "RADIUS_ACCESS") {
      //     let packetValue = $stepPlace
      //       .find(".packetValue select  option:selected")
      //       .text();
      //     $stepPlace
      //       .closest(".stepGroup")
      //       .find(".stepApnText .statusInfo")
      //       .text(": " + packetValue + "");
      //   }
      //   buildRadiusDiameterStructure(
      //     $stepPlace,
      //     stepId,
      //     ipcanStep,
      //     fieldsObject,
      //     stepType
      //   );
    },
    getIndexedTemplate(index, ipcanStep, templatesData) {
      let value = {};
      let objectTemplate = {};
      if (
        typeof templatesData != "undefined" &&
        typeof templatesData[index] != "undefined"
      ) {
        //console.log(templatesData[index], ipcanStep);
        value = JSON.parse(templatesData[index]);
      }
      if (value && value[ipcanStep]) {
        objectTemplate = value[ipcanStep];
      }
      //console.log(objectTemplate);
      return objectTemplate;
    },
    buildRadiusRequestTemplate(template, index, ipcanStep, templatesData) {
      let config = this.config[index];
      let objectTemplate = getIndexedTemplate(
        config.type,
        ipcanStep,
        templatesData
      );
      let color = this.config[index]["color"];

      if (typeof objectTemplate["req"] != "undefined") {
        // template['req']=addRadiusRequestAttributes(template['req'], objectTemplate['req'],color,stepType,ipcanStep);
        template["req"] = addAttributes(
          template["req"],
          objectTemplate["req"],
          color
        );
      }

      return template;
    },
    buildRadiusResponseTemplate(template, index, ipcanStep, templatesData) {
      let config = this.config[index];
      let color = this.config[index]["color"];
      let objectTemplate = getIndexedTemplate(
        config.type,
        ipcanStep,
        templatesData
      );
      if (objectTemplate["res"]) {
        for (let [resEvalIndex, resEvalValue] of Object.entries(
          objectTemplate["res"]["eval"]
        )) {
          if (typeof template["res"]["eval"][resEvalIndex] == "undefined") {
            let foundeval = addAttributes(resEvalIndex, resEvalValue, color);
            template["res"]["eval"][resEvalIndex] = foundeval;
          } else {
            template["res"]["eval"][resEvalIndex] = addAttributes(
              template["res"]["eval"][resEvalIndex],
              resEvalValue,
              color
            );
          }
        }
      }
      return template;
    },
  },
};

//  function buildRadiusDiameterStructure($stepPlace,stepId,ipcanStep,fieldsObject,stepType){
//     var $testCasesGroup=$stepPlace.closest(".testCasesGroup");
//     let template= $testCasesGroup.data( "template" )[stepId][ipcanStep];
//     var requestAttributeId="RequestAttributes"+stepId;
//     var responseAttributeId="ResponseAttributes"+stepId;
//     var $ipcanStep=$stepPlace.find('.ipcanStep[name='+ipcanStep+']');
//     $ipcanStep.find( '.templateTable').empty( );
//     var $table=$ipcanStep.find( '.templateTable');

//     var $requestRow=$("<tr><td colspan=3><div class='RequestAttrs tabs attributegroup' id='"+requestAttributeId+"'></div></td></tr>").appendTo($table);
//     let $requestAttrsContainer =$requestRow.find('.RequestAttrs');

//     if (!checkDiameterProtocol(ipcanStep)){
//       // not for diameter
//       let checkingResult=showHideTabs(template.req);
//       buildRadiusRequestResponseAttributesStructure("Request",stepType,"",$requestAttrsContainer,checkingResult.showInTestConfigured,checkingResult.showExpandable);
//       let $attributesTbody=$requestAttrsContainer.find('.attributesTbody');
//       let $expandableAttributesTbody=$requestAttrsContainer.find('.expandableAttributesTbody');

//       $.each(template.req,function(reqIndex,reqValue){
//         var row="";

//         getRadiusRequestAttrRow(window.dictionarisArray['RADIUS_ACCESS'],reqIndex,reqValue,$attributesTbody,$expandableAttributesTbody);

//         if (Object.keys(template.req).length>0 ){
//           $attributesTbody.closest("table").children(".addingRowTbody").find(".deleteAllAttributes").html(getDeleteAllAttributesButton());
//         }

//       });

//       $table.append("<tr><td colspan=3><div class='ResponseAttrs' id='"+responseAttributeId+"'></div></td></tr>");
//       var $responseAttrsContainer=$table.find('.ResponseAttrs');
//       expandAttrField("Response",stepType,stepId,$responseAttrsContainer,template);
//     }
//     var $responseHolder =$table.find('.responseHolder');
//     $responseHolder.append('<div class="contentSeperator"></div>');
//     $("<div class='onActionContainer'></div>").appendTo($responseHolder );
//     let $onActionContainer = $responseHolder .find(".onActionContainer");
//      var onTimeout={};
//      if (typeof template["res"]!="undefined" && typeof template.res.onTimeout!="undefined"){
//       onTimeout=template.res.onTimeout;
//      }
//     addOnTimeOutNoResult($onActionContainer, "On Timeout", "onTimeoutContainer", onTimeout);

//     if (typeof $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue!="undefined" && typeof $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep]!="undefined"&& typeof $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep].parameters!="undefined"){
//       // store value of the release and the packet type
//       var packet= $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep].parameters.packet;
//       var release= $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep].parameters.release;
//       $ipcanStep.find('.releasesselect').val(release);
//       $ipcanStep.find('.treeselector').attr('attrid',packet);

//     }
//     var ipcanStepSelector=$stepPlace.find('.ipcanStep[name='+ipcanStep+']');

//     if (checkSelectPacket(stepType)){
//       var $selectPacket=ipcanStepSelector.find(".selectPacket");
//       var selectPacketVal=$selectPacket.val();
//       var newRequest={};
//       newRequest[selectPacketVal]= template ["req"][selectPacketVal];
//       template ["req"]=newRequest;
//       var newResponse={};
//       newResponse["eval"]={};
//       newResponse["eval"][selectPacketVal]= template ["res"]["eval"][selectPacketVal];
//       template ["res"]=newResponse;
//     }
//     // rendering diameter request

//     if(template.req){
//      /* if(ipcanStep=="DIAMETER"){
//         renderNewDiamerterRequestValues(fieldsObject,$requestAttrsContainer,template);
//       }
//   else if(ipcanStep=="3GPP_GX" ||
//         ipcanStep=="DCCA"||
//         ipcanStep=="3GPP_SY" ||
//         ipcanStep=="SWM_DER"||
//         ipcanStep=="SWM_AAR"||
//         ipcanStep=="SWM_STR"||
//         ipcanStep=="SWX" ||
//         //ipcanStep=="DIAMETER" ||
//         ipcanStep=="3GPP_STA_DER" ||
//         ipcanStep=="3GPP_STA_AAR" ||
//         ipcanStep=="3GPP_STA_STR" ||
//         ipcanStep=="3GPP_SWA_DER" ||
//         ipcanStep=="3GPP_SWA_STR" ||
//         ipcanStep=="3GPP_S6B_AAR" ||
//         ipcanStep=="3GPP_S6B_STR" ||
//         ipcanStep=="3GPP_RX_AAR"  ||
//         ipcanStep=="3GPP_RX_STR"){*/
//         if (checkDiameterProtocol(ipcanStep)){
//         renderDiamerterRequestValues(fieldsObject,$requestAttrsContainer,template);
//       }
//     }
//     // the accordion has to be refreshed after all the content is loaded. otherwise the accordion is not expanded. expanding it before would make no effect.
//     var $testCases=$stepPlace.closest( ".testCases" );
//     var isAccordion = $testCases.hasClass("ui-accordion");
//     if (isAccordion==true){
//       $testCases.accordion( "refresh" );
//     }
//   }
</script>