<template>
  <div>
    <div :class="expectColor" v-if="!isExplorer">
      <template v-if="row.rawType=='Explorer_cellular_attach'">
        MCC MNC:
      </template>
      <span :title="row['sourceIP']">{{ row['direction'] }}</span>
    </div>
    <div v-else-if="row['rawType']=='RATTUX_admin_info'"></div>
    <ExplorerLink v-else :explorer-name="explorerName"/>
  </div>
</template>
<script>
import {getExpectColor} from '@/js/helper.js';
import ExplorerLink from '@/components/dynamic/ExplorerLink';

export default {
  components: {ExplorerLink},
  props: {
    row: Object,
  },
  computed: {
    expectColor() {
      return getExpectColor(this.row);
    },
    isExplorer() {
      return this.row['direction'].includes('Explorer');
    },
    explorerName() {
      //console.log(this.row['direction'],this.row['rawType']);
      if (this.row['direction'].match(/(Explorer:\s)(.+)/) != null) {
        return this.row['direction'].match(/(Explorer:\s)(.+)/)[2];
      }
      return '';
    },
  },
};
</script>