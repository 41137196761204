<template>
  <div class="mt-2" style="position: relative;z-index: 0;" v-if="!isStencil">
    <div v-if="valueLine.subscriberType == 'explorer'">
      <v-expansion-panels hover tile focusable id="groupAction">
        <v-expansion-panel>
          <v-expansion-panel-header
              id="testGroupActions"
              class="stepHeadColor subtitle-2"
              color="white&#45;&#45;black"
          >Configure Test Group Actions
          </v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div class="mt-3 mb-3">
              <v-card
                  v-for="(group, index) in localvalue.testgroup"
                  :key="index"
                  class="mb-1"
              >
                <v-card-text>
                  <v-row>
                    <v-col>
                      <associatedOption
                          :fieldAttributes="{
                          name: 'Action',
                          associatedOption: [
                            ['explorerCsv', 'Generate Explorer CSV Report'],
                            ['checkGroupResult', 'Monitor Status Threshold'],
                          ],
                        }"
                          type="select"
                          :fieldAttrInput="{
                          class: 'required',
                          id: 'action_' + index,
                        }"
                          v-model="group.action"
                          :templateContent="result"
                          :isValid="isValid"
                          field="protocol"
                      ></associatedOption
                      >
                    </v-col>
                    <v-col class="pt-5"
                    >
                      <trash
                          @click="removeLine(index)"
                          :result="result"
                      ></trash
                      >
                    </v-col>
                  </v-row>

                  <explorerGroupEval
                      v-if="group.action == 'checkGroupResult'"
                      v-model="group.eval"
                      :isValid="isValid"
                      :connectorsArray="result.additional.connectorsArray"
                      :index="index"
                      :result="result"
                  >
                  </explorerGroupEval>
                </v-card-text>
              </v-card>
            </div>
            <primaryButton
                type="button"
                @click="addAction()"
                label="Add Action"
                icon="mdi-check"
                color="primary"
                id="addAction"
                :disabled="disabled"
            ></primaryButton>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!--    <div class="connectorAccordion">
      <div class="accordionHeader">{{languagePack("tests","connectors")}}</div>
      <div class="connectorsWrapper"></div>
    </div>-->
    <v-expansion-panels v-else hover tile focusable>
      <v-expansion-panel>
        <v-expansion-panel-header
            class="stepHeadColor subtitle-2"
            color="white--black"
        >{{ languagePack("tests", "connectors") }}
        </v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="mt-3" v-for="condition in conditions" :key="condition">
            <v-col cols="1" class="mt-4">{{ mappingArray[condition] }}</v-col>
            <v-col class="rootConnBuild">
              <lineFieldsArray
                  :fieldAttrRow="{}"
                  :fields="fields"
                  :value="getValue(condition)"
                  @line-field-input="checkInput(condition, ...arguments)"
                  :headerTitle="false"
                  :addable="true"
                  :isValid="isValid"
                  @revalidate="$emit('revalidate')"
                  :templateContent="result"
              ></lineFieldsArray>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pb-3"></div>
    <input type="hidden" :name="field" :value="JSON.stringify(localvalue)"/>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import explorerGroupEval from "@/components/legacy/explorerGroupEval.vue";
import trash from "@/commonComponents/dynamic/trash.vue";
import lineButtons from "@/cfg/lineButtons.json";

export default {
  components: {
    lineFieldsArray,
    associatedOption,
    explorerGroupEval,
    primaryButton,
    trash,
  },
  props: [
    "field",
    "fieldAttributes",
    "result",
    "value",
    "isValid",
    "valueLine",
  ],
  computed: {
    isStencil() {
      return this.checkStencil(this.result);
    },
    disabled() {
      let disabled = this.checkReadOnly;
      if (
          typeof this.result != "undefined" &&
          typeof this.result.disableAllInputs != "undefined" &&
          this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    fields() {
      let fields = {
        test: {
          type: "select",
          options: this.result.additional.connectorsArray,
          name: "Connector",
          fieldAttrInput: {class: "cBuilder required"},
        },

        remove: lineButtons.remove,
      };
      return fields;
    },
  },
  created() {
    let value = {ok: [], error: [], warning: []};
    if (typeof this.value != "undefined" && this.value != "") {
      let localvalue = JSON.parse(this.value);
      if (typeof localvalue == "object") {
        value = localvalue;
      }
    }
    this.localvalue = value;
  },
  methods: {
    removeLine(index) {
      this.localvalue.testgroup.splice(index, 1);
      this.$emit("input", JSON.stringify(this.localvalue));
    },
    addAction() {
      if (typeof this.localvalue.testgroup == "undefined") {
        this.$set(this.localvalue, "testgroup", []);
      }
      this.localvalue.testgroup.push({});
      this.$emit("input", JSON.stringify(this.localvalue));
    },
    getValue(condition) {
      let valueArray = [];
      //console.log(this.localvalue, condition);
      if (
          typeof this.localvalue != "undefined" &&
          this.localvalue != null &&
          typeof this.localvalue[condition] != "undefined"
      ) {
        this.localvalue[condition].forEach((element) => {
          //  console.log(element);
          valueArray.push({test: element});
        });
      }
      //console.log(valueArray);
      return valueArray;
    },
    checkInput(condition, value) {
      //console.log(condition, value);
      let converted = [];
      value.forEach((element) => {
        converted.push(element.test);
      });
      this.$set(this.localvalue, condition, converted);
      this.$emit("input", JSON.stringify(this.localvalue));
    },
  },

  data() {
    return {
      conditions: ["error", "warning", "ok"],
      increment: 0,
      mappingArray: {error: "Error", warning: "Warning", ok: "OK"},
      localvalue: {},
    };
  },
};
</script>
<style>
.rootConnBuild {
  max-width: fit-content;
}

.cBuilder {
  width: 800px;
}

.cBuilder
> .v-input__control
> .v-input__slot
> .v-select__slot
> .v-select__selections
> .v-select__selection--comma {
  min-width: 99%;
}
</style>