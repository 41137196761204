<template>
  <div v-if="typeof mergedTemplate.parameters != 'undefined'">
    <HTTPTabs
        :isValid="isValid"
        :packetType="packetType"
        :requestType="mergedTemplate.parameters.type"
        @revalidate="$emit('revalidate')"
        v-on="$listeners">
      <template v-slot:tabsContent="data">
        <div v-if="data.item === 'Header'">
          <singleAccordion :title="getPredefinedTitle(mergedTemplate['req']['Header'])">
            <attributeValueAdderContent
                :addable="false"
                :headerTitle="true"
                :hideDetails="true"
                :indexName="language.HTTP_step.fieldName"
                :isValid="isValid"
                :result="result"
                :value="mergedTemplate['req']['Header']"/>
          </singleAccordion>
          <singleAccordion :expanded="true" :title="getUserInputTitle(stepStoredValue['req']['header'])">
            <additional-http-header-attributes
                :valid="parseBoolean(isValid)"
                :value="stepStoredValue['req']['header']"
                class="pt-4"
                @input="sendupdateheader(...arguments)"
                @revalidate="$emit('revalidate')"/>
          </singleAccordion>
        </div>
        <div v-if="data.item === 'Parameter'">
          <div v-if="packetType !== 'HTTP_RESPONSE'">
            <singleAccordion
                :id="stepType + '_' + stepID + '_expand'" :title="getPredefinedTitle(parametersAttributes.expand)">
              <attributeValueAdderContentParameterTab
                  :addable="false"
                  :attrSource="attrSource"
                  :deletable="false"
                  :headerTitle="true"
                  :hideDetails="true"
                  :isValid="isValid"
                  :linePrefix="stepType + '_' + stepID + '_expand_parameters'"
                  :result="result"
                  :value="parametersAttributes.expand"
                  @input="sendUpdateTemplateParameters(...arguments)"/>
            </singleAccordion>
            <singleAccordion :expanded="true" :title="getUserInputTitle(parametersAttributes.user)">
              <attributeValueAdderContentParameterTab
                  :attrSource="attrSource"
                  :headerTitle="true"
                  :hideDetails="true"
                  :isValid="isValid"
                  :linePrefix="stepType + '_' + stepID + '_user_parameters'"
                  :result="result"
                  :value="parametersAttributes.user"
                  @input="sendUpdateParameters(...arguments)"/>
            </singleAccordion>
          </div>
        </div>
        <div v-if="data.item === 'Body'">
          <div class="expandableBodyContainer">
            <HTTPBodyContent
                :contentType="contentType"
                :draggable="false"
                :hideDetails="true"
                :isValid="isValid"
                :jsonEditorButtons="false"
                :linePrefix="stepType + '_' + stepID + '_request_body'"
                :packetType="packetType"
                :result="result"
                :storedBody="storedBody"
                :stepId="stepID"
                :template-request-body="templateRequestBody"
                new-editor
                @changeIsValid="$emit('changeIsValid', $event)"
                @stored-body-changed="updateStoredBody"
                @content-type-changed="updateContentType"/>
          </div>
        </div>
      </template>
    </HTTPTabs>
  </div>
</template>
<script>
import HTTPTabs from '@/components/legacy/HTTPTabs.vue';
import attributeValueAdderContent from '@/components/legacy/attributeValueAdderContent.vue';
import attributeValueAdderContentParameterTab from '@/components/legacy/attributeValueAdderContentParameterTab.vue';
import HTTPBodyContent from '@/components/legacy/HTTPBodyContent.vue';
import singleAccordion from '@/commonComponents/singleAccordion.vue';
import * as jsonpatch from 'fast-json-patch';
import AdditionalHttpHeaderAttributes from '@/components/specific/TestStep/AdditionalHttpHeaderAttributes.vue';
import {parseBoolean} from '@/js/general';

export default {
  components: {
    AdditionalHttpHeaderAttributes,
    HTTPTabs,
    attributeValueAdderContent,
    attributeValueAdderContentParameterTab,
    HTTPBodyContent,
    singleAccordion,
  },
  props: {
    mergedTemplate: {},
    stepStoredValue: {
      type: Object,
      default() {
        return {req: {}};
      },
    },
    packetType: {},
    originalTemplate: {},
    result: {},
    isValid: {
      type: Boolean,
    },
    stepType: {},
    stepID: {},
  },
  data() {
    return {
      attrSource: 't_testcases',
      cachedParametersAttributes: [],
      initializedBody: false,
      parseBoolean: parseBoolean,
    };
  },
  created() {
    this.cachedParametersAttributes = this.seperateParameters(
        this.mergedTemplate,
    );
  },
  watch: {
    mergedTemplate(newValue) {
      this.cachedParametersAttributes = this.seperateParameters(newValue);
    },
    storedBody(value) {
      if (this.initializedBody) return;
      this.updateStoredBody(value);
      this.initializedBody = true;
    },
  },
  methods: {
    getPredefinedTitle(checkedObject) {
      let length = 0;
      if (checkedObject) {
        length = Object.keys(checkedObject).length;
      }
      return 'Attributes Defined in Template (' + length + ')';
    },
    getUserInputTitle(checkedObject) {
      let length = 0;
      if (checkedObject) {
        length = Object.keys(checkedObject).length;
      }
      return 'Attributes additionally used in this Test Step (' + length + ')';
    },
    updateStoredBody(testContent) {
      let httpContentType = this.contentType;
      let body;
      if (httpContentType === 'JSON') {
        body = testContent;
      } else if (httpContentType === 'multipart') {
        let originalContent = this.originalTemplate.req.body;
        let originalOrder = Array.from(originalContent, (x) => x.atRanId);
        //console.log(originalContent,originalOrder);
        body = [];
        let tmp = [];
        console.log(testContent);
        let i = 0;
        testContent.forEach(function (part) {
          let index = originalOrder.indexOf(part.atRanId);

          if (index > -1) {
            tmp[index] = part;
          } else {
            tmp[originalContent.length + i] = part;
            i++;
          }
        });
        i = 0;
        for (i = 0; i < tmp.length; i++) {
          let value = tmp[i];
          let atRanId;
          let originalPart = {};
          if (typeof originalContent[i] != 'undefined') {
            originalPart = originalContent[i];
            atRanId = originalPart.atRanId;
          }
          if (typeof value == 'undefined') {
            value = {};
          } else {
            atRanId = value.atRanId;
          }
          let patch = jsonpatch.compare(originalPart, value);
          body[i] = {
            atRanId: atRanId,
            patch: patch,
          };
        }
        body = body.filter(Boolean);
      } else if (httpContentType === 'AVP') {
        body = testContent;
      } else if (httpContentType === 'text') {
        body = testContent;
      }
      this.$emit('body-changed', body);
    },
    sendupdateheader(value) {
      this.$emit('header-changed', value);
    },
    sendUpdateTemplateParameters(value) {
      this.$set(this.cachedParametersAttributes, 'expand', value);
      this.updateTotalParameters();
    },
    sendUpdateParameters(value) {
      // console.log(value);
      this.$set(this.cachedParametersAttributes, 'user', value);
      this.updateTotalParameters();
    },
    updateTotalParameters() {
      let parameters = [];
      //   let parameters = [...this.stepStoredValue["req"].parameters];
      ['expand', 'user'].forEach((chachedIndex) => {
        let value = this.cachedParametersAttributes[chachedIndex];
        value.forEach((valueLine) => {
          let originalInfo = {
            value: '',
            name: '',
          };
          console.log(this.originalTemplate['req']);
          if (typeof this.originalTemplate['req'] != 'undefined'
              && typeof this.originalTemplate['req'].parameters != 'undefined') {
            this.originalTemplate['req'].parameters.forEach((originalLine) => {
              if (valueLine.atRanId === originalLine.atRanId) {
                originalInfo = originalLine;
              }
            });
          }
          const source = valueLine.source;
          let attrSource = 't_testcases';
          if (valueLine.fix === '2' || originalInfo.value === '' || originalInfo.value !== valueLine.value
              || originalInfo.name !== valueLine.name || originalInfo.name === '' || source === attrSource) {
            let templateFixed = '0';
            let testFixed = '0';
            // added condition originalValue!=="" for the inputs which are new
            if (valueLine.fix === '2') {
              templateFixed = '2';
              testFixed = '2';
            }
            if (originalInfo.source === valueLine.source
                && ((originalInfo.value !== valueLine.value && originalInfo.value !== '')
                    || (originalInfo.name !== valueLine.name && originalInfo.name !== ''))) {
              templateFixed = '2';
              testFixed = '0';
            }
            let mandatory = 0;
            if (value.mandatory === 1) {
              mandatory = 1;
            }

            if (originalInfo.value !== valueLine.value || originalInfo.name !== valueLine.name
                || (originalInfo.value === valueLine.value && originalInfo.name === valueLine.name
                    && source === 't_testcases')
            ) {
              /// added 29.04.2016 by ammar bug when the origional value is a EVAL which does not exsist in the options
              //  if (!($attrValueObject.prop("tagName")=="SELECT" && originalValue.indexOf("EVAL:")==0 && value.indexOf("EVAL:")==-1)){
              ////console.log(originalValue,value,source);
              let attributeInfo = {
                value: valueLine.value,
                name: valueLine.name,
                fix: testFixed,
                source: attrSource,
                mandatory: mandatory,
                atRanId: valueLine.atRanId,
              };
              let found = false;
              parameters.forEach((savedLine, index) => {
                if (savedLine.atRanId === attributeInfo.atRanId && savedLine.source === attributeInfo.source) {
                  found = true;
                  this.$set(parameters, index, attributeInfo);
                }
              });
              if (!found) {
                parameters.push(attributeInfo);
              }
            }

            // var attributeName = value.attribute;
            if ((originalInfo.value !== '' || originalInfo.name !== '') && source !== 't_testcases') {
              // adding the original value from templates
              let attributeInfo = {
                value: originalInfo.value,
                name: originalInfo.name,
                fix: templateFixed,
                source: source,
                mandatory: mandatory,
                atRanId: valueLine.atRanId,
              };

              parameters.push(attributeInfo);
            }
          }
        });
      });
      this.$emit('parameter-changed', parameters);
    },
    updateContentType(contentType) {
      this.sendUpdateParameters('content-type', contentType);
    },
    seperateParameters(mergedTemplate) {
      let parametersAttributes = {
        user: [],
        expand: [],
      };
      if (typeof mergedTemplate['req'] != 'undefined' && typeof mergedTemplate['req']['parameters'] != 'undefined') {
        mergedTemplate['req']['parameters'].forEach((value) => {
          if (value.mandatory !== 1 && value.source !== 't_testcases') {
            parametersAttributes.expand.push(value);
          } else {
            parametersAttributes.user.push(value);
          }
        });
      }
      return parametersAttributes;
    },
  },
  computed: {
    storedBody() {
      let storedBody = null;
      if (typeof this.mergedTemplate['req'] != 'undefined'
          && typeof this.mergedTemplate['req']['body'] != 'undefined') {
        storedBody = this.mergedTemplate['req']['body'];
      }
      return storedBody;
    },
    contentType() {
      let contentType = '';
      if (typeof this.mergedTemplate.parameters != 'undefined'
          && typeof this.mergedTemplate.parameters['content-type'] != 'undefined') {
        contentType = this.mergedTemplate.parameters['content-type'];
      }
      return contentType;
    },
    parametersAttributes() {
      return this.seperateParameters(this.mergedTemplate);
    },
    templateRequestBody() {
      return this.originalTemplate ? this.originalTemplate.req ? this.originalTemplate.req.body : {} : {};
    },
  },
};
</script>