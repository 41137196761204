<template>
  <div :data-help="computedHelp" class="x-map">
    <v-row>
      <v-col cols="12" class="d-flex">
        <div class="ml-3 mt-3 subtitle-2">Address:</div>
        <gmap-autocomplete :value="placeValue" @place_changed="setPlace" class="placeInput ml-3 v-text-field__slot"/>
      </v-col>
      <v-col>
        <gmap-map
            class="ml-3"
            ref="gmapMap"
            :center="userCoordinates"
            :zoom="customZoom"
            style="width: 653px; height: 360px;"
            @click="handleClick"
            :options="gmapOptions">
          <gmap-info-window
              v-if="marker && !isNew"
              :opened="infoWindow.opened"
              :position="infoWindow.position"
              :options="infoWindow.options"
              @closeClick="infoWindow.opened = false">
            <div v-if="location" class="address">
              <div>{{ location.street }}</div>
              <div>{{ location.postalCode }} {{ location.city }}</div>
              <div>{{ location.country }}</div>
            </div>
          </gmap-info-window>
          <gmap-marker
              v-if="marker && !isNew"
              :position="marker.position"
              :draggable="true"
              @dragend="handleMarkerDrag"
              :clickable="true"
          />
        </gmap-map>
      </v-col>
    </v-row>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>
<script>
import {gmapApi} from 'vue2-google-maps';
import HelpButton from '@/components/basic/HelpButton.vue';

export default {
  name: 'XMap',
  components: {HelpButton},
  props: {
    id: String,
    valueLine: {
      default: null,
      Object,
    },
    help: String,
  },
  data() {
    return {
      map: null,
      userCoordinates: {
        lat: 0,
        lng: 0,
      },
      marker: null,
      gmapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
      },
      place: null,
      location: null,
      infoWindow: {
        opened: true,
        position: {
          lat: typeof this.valueLine.latitude !== 'undefined' ? this.valueLine.latitude : 0,
          lng: typeof this.valueLine.longitude !== 'undefined' ? this.valueLine.longitude : 0,
        },
        options: {
          pixelOffset: {
            width: 0,
            height: -40,
          },
        },
        country: '',
      },
      apiKey: 'AIzaSyCKmrnIZ_WcrXQ5IXrFwfQ0RmG7NbsGYy0',
    };
  },
  created() {
    /*  this.$getLocation({}).then(coordinates => {
        this.userCoordinates = coordinates;
      }).catch(error => console.log(error));*/

    this.location = {
      street: typeof this.valueLine.street !== 'undefined' ? this.valueLine.street : '',
      postalCode: typeof this.valueLine.postalCode !== 'undefined' ? this.valueLine.postalCode : '',
      city: typeof this.valueLine.city !== 'undefined' ? this.valueLine.city : '',
      region: typeof this.valueLine.region !== 'undefined' ? this.valueLine.region : '',
      country: typeof this.valueLine.country !== 'undefined' ? this.valueLine.country : '',
      latitude: this.currentLat,
      longitude: this.currentLng,
    };
  },
  mounted() {
    this.marker = {
      position: {
        lat: this.currentLat,
        lng: this.currentLng,
      },
    };
    this.$refs.gmapMap.$mapPromise.then((map) => {
      map.panTo({
        lat: this.currentLat,
        lng: this.currentLng,
      });
    });
  },
  computed: {
    /*    mapCoordinates() {
          if (!this.map) {
            return {
              lat: 0,
              lng: 0,
            };
          }
          return {
            lat: this.map.getCenter().lat().toFixed(4),
            lng: this.map.getCenter().lng().toFixed(4),
          };
        },*/
    google: gmapApi,
    isNew: {
      get() {
        let isNew = false;
        if (this.currentLat == 0 && this.currentLng == 0) {
          isNew = true;
        }
        return isNew;
      },
      set(newVal) {
        return newVal;
      },
    },
    customZoom() {
      let zoom = 2;
      if (!this.isNew) {
        zoom = 9;
      }
      return zoom;
    },
    currentLat() {
      return typeof this.valueLine.latitude !== 'undefined' ? this.valueLine.latitude : 0;
    },
    currentLng() {
      return typeof this.valueLine.longitude !== 'undefined' ? this.valueLine.longitude : 0;
    },
    placeValue() {
      let address = '';
      if (typeof this.location.street !== 'undefined' && this.location.street != '') {
        address += this.location.street + ' ';
      }
      if (typeof this.location.postalCode !== 'undefined' && this.location.postalCode != '') {
        address += this.location.postalCode + ' ';
      }
      if (typeof this.location.city !== 'undefined' && this.location.city != '') {
        address += this.location.city + ', ';
      }
      if (typeof this.location.country !== 'undefined' && this.location.country != '') {
        address += this.location.country;
      }
      return address;
    },
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
  methods: {
    handleClick(eventData) {
      this.infoWindow.opened = true;
      this.updateMarker(eventData.latLng.lat(), eventData.latLng.lng());
    },
    handleMarkerDrag(eventData) {
      this.infoWindow.opened = false;
      this.updateMarker(eventData.latLng.lat(), eventData.latLng.lng());
    },
    updateMarker(lat, lng) {
      this.marker = {
        position: {
          lat: lat,
          lng: lng,
        },
      };
      this.axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apiKey}`)
          .then((response) => {
            for (const result of response.data.results) {
              if (result.types.includes('street_address')) {
                this.updateLocation(result, result.geometry.location.lat, result.geometry.location.lng);
                break;
              }
            }
          });
    },
    setPlace(place) {
      this.place = place;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.updateLocation(place, lat, lng);
      this.marker = {
        position: {
          lat: lat,
          lng: lng,
        },
      };
    },
    updateLocation(place, lat, lng) {
      let houseNumber;
      let street;
      let postalCode;
      let city;
      let region;
      let countryShort;
      let countryLong;

      for (const addressComponent of place.address_components) {
        if (addressComponent.types.includes('street_number')) houseNumber = addressComponent.long_name;
        else if (addressComponent.types.includes('route')) street = addressComponent.long_name;
        else if (addressComponent.types.includes('postal_code')) postalCode = addressComponent.long_name;
        else if (addressComponent.types.includes('locality')) city = addressComponent.long_name;
        else if (addressComponent.types.includes('administrative_area_level_1')) region = addressComponent.short_name;
        else if (addressComponent.types.includes('country')) {
          countryShort = addressComponent.short_name;
          countryLong = addressComponent.long_name;
        }
      }
      this.location = {
        street: houseNumber ? `${street} ${houseNumber}` : street,
        postalCode: postalCode,
        city: city,
        region: region,
        country_iso: countryShort,
        country: countryLong,
        latitude: lat,
        longitude: lng,
      };
      this.$emit('location-details', this.location);
      this.infoWindow.position = {
        lat: lat,
        lng: lng,
      };
      this.infoWindow.country = countryLong;
      this.infoWindow.opened = false;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.infoWindow.opened = true;
        });
      });
    },
  },
};
</script>

<style scoped>
.x-map {
  display: flex;
}

.x-map >>> .help-button {
  margin-top: 2px;
}

.placeInput {
  border: solid 1px #80808096;
  width: 98%;
  height: 40px;
  border-radius: 3px;
  text-indent: 7px;
}
</style>