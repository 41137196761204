<template>
    <div ref="attrEditor" class="attr-editor">
      <v-row v-if="dataValue.length > 0" style="gap: 10px">
        <!-- class="mr-3 text-subtitle-1 mb-n2 col"> -->
        <v-col style="flex: 1"><span style="color: var(--v-gray-text-base);">Attribute</span></v-col>
        <v-col style="flex: 1.5"><span style="color: var(--v-gray-text-base);">Value</span></v-col>
      </v-row>
      <v-divider v-if="dataValue.length > 0" />
      <div v-for="(row, i) of dataValue" :key="i" class="attr-editor-row">
        <v-row class="row">
          <v-col style="flex: 1">
            <XTextField
              v-model="dataValue[i]['attribute']"
              class="attribute"
              validate-immediately
              :rules="[rules.required]"
              @input="emitAttributeInput(i, $event)" />
          </v-col>
          <v-col style="flex: 1.5">
            <div class="value-row" v-for="(val, j) in row['values']" :key="j">
              <XTextField
                v-model="val['value']"
                class="value"
                validate-immediately
                :rules="val.required ? [rules.required] : []"
                @input="emitValueInput(i, j, $event)" />
              <div class="d-flex buttons">
                <x-btn
                  :iconAfterText="true"
                  color="red"
                  icon="mdi-delete"
                  x-small
                  @click="row['values'].length === 1 ? deleteRow(i) : deleteValueRow(i, j)" />
                <x-btn
                  v-if="j === row['values'].length - 1"
                  :iconAfterText="true"
                  color="gray"
                  icon="mdi-plus"
                  x-small
                  @click="addValueRow(i)" />
              </div>
            </div>
          </v-col>
          <div class="fixed-width"/>
        </v-row>
      </div>
      <v-divider v-if="dataValue.length > 0" class="divider" />

      <x-btn
        text="Add Attribute"
        color="primary"
        icon="mdi-plus"
        :disabled="disabled"
        @click="addRow" />
    </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn';
import XTextField from '@/components/basic/XTextField.vue';
import {deepCopy} from '@/js/general';

export default {
  name: 'AttributesEditor',
  components: {
    XBtn,
    XTextField,
  },
  props: {
    disabled: Boolean,
    value: Array,
    row: Object,
  },
  data() {
    return {
      dataValue: [],
      focusedElementIndex: -1,
      rules: {
        required: value => !!value || '',
      },
      columns: [
        {
          type: "text",
          attribute: "attribute",
          required: true,
          values: [{ value: "value", required: true, values: "values" }],
        },
      ],
    };
  },
  methods: {
    addRow() {
      const row = {};
      for (const column of this.columns) {
        if (column.default !== undefined) row[column.attribute] = column.default;
        else {
          const type = typeof column.type === 'function' ? column.type() : column.type;
          switch (type) {
            case 'text':
              row[column.attribute] = "";
              break;
            default:
              console.error(`No default value for type '${column.type}'.`);
              break;
          }
        }
        if (column.values !== undefined) {
            row["values"] = [];
            for (const valueRow of column.values) {
              row[valueRow['values']].push({ value: "", required: true });
            }
        }
      }

      // check for duplicate attributes
      const attributes = new Set();

      for (let i = 0; i < this.dataValue.length; i++) {
        const obj = this.dataValue[i];
        const attribute = obj.attribute;

        if (attributes.has(attribute)) {
          return;
        }

        attributes.add(attribute);
      }

      this.dataValue.push(row);
      this.$emit('input', deepCopy(this.dataValue));
    },
    addValueRow(row) {
      this.dataValue[row]["values"].push({ value: "", required: true });
      this.$emit('input', deepCopy(this.dataValue));
    },
    deleteRow(i) {
      this.dataValue.splice(i, 1);
      this.$emit('input', deepCopy(this.dataValue));
      this.$emit('revalidate');
    },
    deleteValueRow(i, j) {
      this.dataValue[i]["values"].splice(j, 1);
      this.$emit('input', deepCopy(this.dataValue));
      this.$emit('revalidate');
    },
    emitAttributeInput(index, value) {
      this.dataValue[index]["attribute"] = value;
      this.$emit('input', deepCopy(this.dataValue));
      this.$emit('revalidate');
    },
    emitValueInput(rowIndex, valueIndex, value) {
      this.dataValue[rowIndex]["values"][valueIndex]["value"] = value;
      this.$emit('input', deepCopy(this.dataValue));
      this.$emit('revalidate');
    },
    showDeleteButton() {
      return (this.dataMin === 0 || this.dataValue.length > this.dataMin) && !this.persistentListLength;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value !== undefined && value.length > 0) {
          this.dataValue = value;
        }
      },
    },
  },
}
</script>

<style scoped>
.row {
  padding-top: 12px;
  gap: 0;
}

.value {
  width: 80%;
}

.buttons {
  display: flex;
  margin-top: 8px;
  justify-content: center;
}

/* .v-col {
  width: 80%;
} */

.value-row {
  display: flex;
}

.value {
  padding-bottom: 14px;
}

.divider {
  padding-bottom: 14px;
  margin-top: 14px;
}
</style>