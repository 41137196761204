<template>
  <div v-if="valueLine.subscriberType != 'explorer' && !isStencil" style="position: relative; z-index: 0;">
    <v-expansion-panels hover tile focusable>
      <v-expansion-panel>
        <v-expansion-panel-header class="stepHeadColor subtitle-2">{{
            languagePack("tests", "testOnRun")
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <fieldsTds
              :templateContent="result"
              :fieldAttributes="result.fields.n_testOnError"
              field="n_testOnError"
              @input="emitInput('n_testOnError', ...arguments)"
              :value="result.valueLine.n_testOnError"
          ></fieldsTds>
          <fieldsTds
              :templateContent="result"
              :fieldAttributes="result.fields.n_testOnWarning"
              field="n_testOnWarning"
              @input="emitInput('n_testOnWarning', ...arguments)"
              :value="result.valueLine.n_testOnWarning"
          ></fieldsTds>
          <fieldsTds
              :templateContent="result"
              :fieldAttributes="result.fields.n_testOnOk"
              field="n_testOnOk"
              @input="emitInput('n_testOnOk', ...arguments)"
              :value="result.valueLine.n_testOnOk"
          ></fieldsTds>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";

export default {
  components: {fieldsTds},
  props: ["field", "fieldAttributes", "result", "valueLine"],
  methods: {
    emitInput(field, value) {
      this.$emit("field-value-changed", field, value);
    },
  },
  computed: {
    isStencil() {
      return this.checkStencil(this.result);
    },
  }
};
</script>
