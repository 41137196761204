<template functional>
  <section
    class="page-headline"
    v-bind="data.attrs"
  >
    <div :class="{
      'page-headline__inner': true
    }">
      <div
        v-if="slots().right"
        class="page-headline__right-block"
      >
        <slot name="right" />
      </div>

      <div class="page-headline__mid-block">
        <template v-if="props.customStyling">
          <slot />
        </template>

        <template v-else>
          <h2 class="page-headline__headline-text">
            <slot />
          </h2>
        </template>

        <slot name="mid-after-headline" />
      </div>

      <div
        v-if="slots().left"
        class="page-headline__left-block"
      >
        <slot name="left" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    customStyling: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">
// can be used in other components
// should be placed in a global file (e.g functions.scss, mixins.scss)
$headline-height: 60px;
@mixin elevation() {
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, .2),
    0 4px 5px 0 rgba(0, 0, 0, .14),
    0 1px 10px 0 rgba(0, 0, 0, .12);
}
.page-headline {
  $root: &;

  position: relative;
  z-index: 2;
  height: $headline-height;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  background-color: #fff;
  @include elevation;

  &__inner {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__mid-block {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__headline-text {
    text-align: left;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing:  .0125rem;
    line-height: 2rem;

    & > * {
      vertical-align: middle;
    }
  }
}
</style>
