<template>
  <div class="dashboard">
    <div class="dashboard-container elevation-2">
      <div class="dashboard-bar">
        <div v-if="!editMode">
          <div class="dashboard-tabs-area">
            <v-tabs v-model="selectedTab" class="dashboard-tabs">
              <v-tab v-for="(dashboard, i) in dashboards" :key="i" class="dashboard-tab" :disabled="!finishedLoading">
                <div>{{ dashboard.name }}</div>
                <div v-if="i === selectedTab" class="edit-buttons">
                  <XBtn icon="mdi-pencil" @click="enableEditMode(false)"/>
                  <XBtn
                      v-if="selectedDashboardId && dashboards.length > 1"
                      icon="mdi-delete"
                      @click="reallyDeleteDialog = true"/>
                </div>
              </v-tab>
            </v-tabs>
            <XBtn icon="mdi-plus" @click="openCreateDashboardDialog" :disabled="!finishedLoading"/>
          </div>
          <div class="date-time-range-picker-container">
            <DateTimeRangeAndRefresh v-model="rangeAndRefresh" :max="new Date()" save/>
          </div>
        </div>
        <div v-else class="dashboard-bar-edit-mode">
          <div class="dashboard-bar-edit-mode-upper">
            <ObjectInputRow
                v-model="dashboardToEdit" :columns="upperEditColumns" class="dashboard-bar-edit-mode-name-area"/>
            <div class="dashboard-bar-edit-mode-filters-area">
              <h4>
                Allow filters to focus this dashboard
              </h4>

              <SimpleListEditor
                v-if="editMode"
                :value="dashboardToEdit.filters"
                :default-item="{ key: '' }"
                type="select"
                label="Filter"
                :items="filters"
                item-text="key"
                item-value="key"
                :item-tooltip="v => { return {content: v.metrics.join(', <br/>'), html: true }}"
                row-name="Filter"
                class="dashboard-filters"
                max="3"
                required
                emit-original
                @input="(filters) => setNewFiltersToDashboard(filters)"
              >
                <template #item-tooltip="{ item }">
                  <div v-for="(metric, i) of item.metrics" :key="i">
                    {{ metric }}
                  </div>
                </template>

                <template #rowLeftSide="{ row: fConfig }">
                  <div class="dashboard-bw-list-btn-box">
                    <XBtn
                      class="dashboard-bw-list-btn"
                      :disabled="!fConfig || !fConfig.key"
                      :text="`Black/White list (${getNumberOfItemsInBWList(fConfig)})`"
                      color="primary"
                      icon="mdi-pencil"
                      width="100%"
                      @click="() => openBlackWhiteListDialog(fConfig)"
                      data-help="Project_dashboard_filter_bw_lists"
                    />

                    <HelpButton help="Project_dashboard_filter_bw_lists" />
                  </div>
                </template>
              </SimpleListEditor>
            </div>
          </div>

          <ObjectInputRow v-model="dashboardToEdit" :columns="lowerEditColumns" class="dashboard-bar-edit-mode-lower"/>
        </div>
      </div>

      <div
          v-if="!editMode && dashboardToEdit.filters && Object.keys(dashboardToEdit.filters).length
           && Object.keys(tagMetricValues).length"
          class="dashboard-filters">
        <XSelect
            v-for="(filter, i) of dashboardToEdit.filters"
            v-model="selectedFilters[filter.key]"
            :key="i"
            :label="filter.key"
            item-text="key"
            item-value="key"
            :items="constrainFilterValues(
              filter.key,
              computedTagMetricValues[filter.key] ? computedTagMetricValues[filter.key].values : [])"
            @input="saveSelectedFilters"/>
      </div>

      <Dashboard
          ref="dashboard"
          v-model="dashboardToEdit"
          :edit-mode="editMode"
          :tag-metric-values="tagMetricValues[selectedTab]"
          :tag-metrics-loading="tagMetricsLoading"
          :range-and-refresh="rangeAndRefresh"
          :finished-loading="finishedLoading"
          :selected-filters="selectedFilters"
          @edit:widget="openWidgetDialog($event)"
          @delete:widget="deleteWidget($event)"
          @loading:widget="handleLoading"/>
    </div>

    <yes-no-dialog
        v-model="createDashboardDialog"
        title="Create dashboard"
        yes-text="Create"
        no-text="Cancel"
        width="500"
        @yes="enableEditMode(true)">

      <x-select
          v-model="selectedTemplate"
          label="Template"
          :items="dashboardTemplates"
          item-value="id"
          item-text="name"
          required/>
    </yes-no-dialog>

    <really-delete-dialog
      v-model="reallyDeleteDialog"
      @yes="deleteDashboard"
      :item-name="selectedDashboardName"
    />

    <DashboardWidgetDialog v-model="widgetDialog" :widget="widget" @save="handleWidgetSave($event)"/>
  </div>
</template>

<script>
import { defineComponent, inject, ref, computed, set } from "vue";
import XBtn from '@/components/basic/XBtn.vue';
import ObjectInputRow from '@/components/basic/ObjectInputRow.vue';
import YesNoDialog from '@/components/extended/YesNoDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog.vue';
import DashboardWidgetDialog from '@/components/specific/Dashboard/DashboardWidgetDialog.vue';
import DateTimeRangeAndRefresh from '@/components/basic/DateTimeRangeAndRefresh.vue';
import SimpleListEditor from '@/components/basic/SimpleListEditor.vue';
import Dashboard from '@/components/specific/Dashboard/Dashboard.vue';
import chartService from '@/js/services/ChartService';
import {deepCopy, getIntURLSearchParam, setURLSearchParam} from '@/js/general';
import requests from '@/js/requests';
import { useBlackWhiteFiltersList } from "@/composition/project-tags/use-black-white-list";
import HelpButton from "@/components/basic/HelpButton.vue"

export default defineComponent({
  name: 'DashboardView',
  components: {
    Dashboard,
    SimpleListEditor,
    DateTimeRangeAndRefresh,
    DashboardWidgetDialog,
    ReallyDeleteDialog,
    XSelect,
    YesNoDialog,
    ObjectInputRow,
    XBtn,
    HelpButton,
  },
  props: ['additional'],
  setup() {
    const { openDialog } = inject('DialogsRoot');
    const _dashboardToEdit = ref(null)
    const saving = ref(false)
    const tagMetricsLoading = ref(false)
    const tagMetricValues = ref({})
    const selectedTab = ref(0)
    const filters = ref([])

    const {
      getFilterName,
      getNumberOfItemsInList,
      getFilterConfigByName,
      adaptFiltersToNewApi,
      constrainFilterValues,
      createFullFilterConfig,
    } = useBlackWhiteFiltersList(_dashboardToEdit)

    const dashboardToEdit = computed({
      get() {
        return _dashboardToEdit.value
      },
      set(newDashboard) {
        if (newDashboard.filters && newDashboard.filters.length) {
          newDashboard.filters = adaptFiltersToNewApi(newDashboard.filters)
        }
        _dashboardToEdit.value = newDashboard
      }
    })
    const createFiltersFieldOnDashboard = () => {
      if (!dashboardToEdit.value.filters) {
        set(dashboardToEdit.value, 'filters', [])
      }
    }

    const getNumberOfItemsInBWList = fiterConfig => getNumberOfItemsInList(fiterConfig);

    const updateFilterConfiguration = newConfig => {
      if (!dashboardToEdit.value) {
        return
      }
      createFiltersFieldOnDashboard()
      // eslint-disable-next-line no-undef
      const oldFilters = structuredClone(dashboardToEdit.value.filters);
      const idx = oldFilters.findIndex((filter) => {
        const filterName = getFilterName(filter);
        return filterName === newConfig.key;
      });
      if (idx >= 0) {
        oldFilters.splice(idx, 1, newConfig);
      } else {
        oldFilters.push(newConfig);
      }
      dashboardToEdit.value.filters = adaptFiltersToNewApi(oldFilters)
    }

    const openBlackWhiteListDialog = async filterConfig => {
      // To avoid mutating the original object
      const config = getFilterConfigByName(filterConfig.key, dashboardToEdit.value.filters) || {}
      const filterName = config.key
      delete config.key
      const result = await openDialog('BlackWhiteListsDialog', {
        filterName,
        filterConfig: config,
        possibleFilterValues: tagMetricValues.value[selectedTab.value]?.[filterName]?.values || [],
      });
      if (result) {
        updateFilterConfiguration(result);
      }
    }

    const setNewFiltersToDashboard = filters => {
      if (!dashboardToEdit.value) {
        return
      }
      createFiltersFieldOnDashboard()

      // eslint-disable-next-line no-undef
      const existingFilters = structuredClone(dashboardToEdit.value.filters);

      let defaultFilter = null;

      for (const filter of filters) {
        if (filter.key === "") {
          defaultFilter = filter;
          continue;
        }

        const fName = getFilterName(filter);


        const existingFilterIndex = existingFilters.findIndex((_f) => getFilterName(_f) === fName);

        if (existingFilterIndex !== -1) {
          // If the filter already exists, no need to change it
          continue;
        }

        const filterToAdd = createFullFilterConfig(fName)

        existingFilters.push(filterToAdd);
      }

      dashboardToEdit.value.filters = existingFilters.filter((existingFilter) => {
        const fName = getFilterName(existingFilter);
        return filters.some((filter) => getFilterName(filter) === fName);
      });

      if (defaultFilter !== null) {
        dashboardToEdit.value.filters.push(defaultFilter);
      }
    }

    return {
      setNewFiltersToDashboard,
      openBlackWhiteListDialog,
      dashboardToEdit,
      saving,
      tagMetricsLoading,
      getFilterName,
      tagMetricValues,
      constrainFilterValues,
      selectedTab,
      getNumberOfItemsInBWList,
      filters,
    }
  },
  data() {
    return {
      isConfiguringBWLists: false,
      dashboards: [],
      editMode: false,
      createDashboardDialog: false,
      reallyDeleteDialog: false,
      widgetDialog: false,
      widget: null,
      selectedTemplate: 0,
      rangeAndRefresh: {
        range: {
          from: new Date(new Date().getTime() - 86400000),
          to: new Date(),
          seconds: 86400,
        },
        refresh: 0,
      },
      upperEditColumns: [
        {
          type: 'text',
          label: 'Name',
          value: 'name',
          required: true,
        },
        {
          type: 'button',
          text: 'Add widget',
          icon: 'mdi-plus',
          color: 'primary',
          disabled: (row) => {
            return !row.name;
          },
          click: () => this.openWidgetDialog(null),
        },
      ],
      lowerEditColumns: [
        {
          type: 'button',
          text: 'Save',
          icon: 'mdi-content-save',
          color: 'primary',
          disabled: (row) => {
            return !row.name || this.saving;
          },
          click: () => this.disableEditMode(true),
        },
        {
          type: 'button',
          text: 'Cancel',
          icon: 'mdi-cancel',
          color: 'primary',
          disabled: () => this.saving,
          click: () => this.disableEditMode(false),
        },
      ],
      loadingMap: [],
      finishedLoading: false,
      selectedFilters: {},
    };
  },
  watch: {
    selectedTab: {
      async handler(value) {
        this.dashboardToEdit = this.selectedDashboard;
        await this.getTagValues();
        setURLSearchParam('selected_tab', value);
      },
    },
    dashboardToEdit(value) {
      const loadingMap = {};
      for (const item of value.items) {
        loadingMap[item.i] = false;
      }
      this.loadingMap = loadingMap;
    },
  },
  async created() {
    this.dashboardToEdit = this.selectedDashboard;
    await this.getDashboards();
    chartService.getChartConfigTagKeys((tagKeys) => {
      this.filters = tagKeys;
    });
  },
  computed: {
    dashboardTemplates() {
      return [
        {
          id: 0,
          name: 'Default',
          items: [],
        },
      ].concat(this.dashboards);
    },
    selectedDashboard() {
      const selectedDashboard = this.dashboards[this.selectedTab];
      if (selectedDashboard) return selectedDashboard;
      return deepCopy(this.dashboardTemplates[0]);
    },
    selectedDashboardName() {
      const selectedDashboard = this.dashboards[this.selectedTab];
      if (selectedDashboard) return selectedDashboard.name;
      return '';
    },
    selectedDashboardId() {
      const selectedDashboard = this.dashboards[this.selectedTab];
      if (selectedDashboard) return selectedDashboard.id;
      return 0;
    },
    currentItems() {
      return this.dashboardToEdit.items;
    },
    computedTagMetricValues() {
      return this.tagMetricValues[this.selectedTab] || {};
    },
  },
  methods: {
    async getDashboards() {
      // TODO: fix 'finishedLoading' flag w/ try/(catch)/finally (it works as not expected, when there is an error)
      let dashboards = await chartService.getDashboardList()

      if (!dashboards.length) {
        const defaultDashboard = deepCopy(this.dashboardTemplates[0]);
        defaultDashboard.name = 'Dashboard';
        dashboards = [defaultDashboard];
        this.finishedLoading = true;
      } else {
        dashboards = dashboards.map((d, idx, arr) => {
          if (!d.name) {
            d.name = arr.length > 1 ? `Dashboard ${idx}` : 'Dashboard';
          }
          if (!d.items) {
            d.items = [];
          }
          return d
        })
      }

      this.dashboards = dashboards;
      this.dashboardToEdit = deepCopy(this.selectedDashboard);

      const selectedTab = getIntURLSearchParam('selected_tab');

      if (selectedTab !== undefined && selectedTab < this.dashboards.length) this.selectedTab = selectedTab;

      if (!dashboards[selectedTab].items.length) this.finishedLoading = true;

      // Do not wait promise resolution, for faster UI response
      this.getTagValues();
    },
    openCreateDashboardDialog() {
      this.selectedTemplate = 0;
      this.createDashboardDialog = true;
    },
    enableEditMode(newDashboard) {
      if (newDashboard) {
        const dashboardToEdit = deepCopy(this.dashboardTemplates.find(x => x.id === this.selectedTemplate));
        if (dashboardToEdit.name === 'Default') dashboardToEdit.name = `Dashboard ${this.dashboards.length + 1}`;
        dashboardToEdit.id = 0;
        this.dashboardToEdit = dashboardToEdit;
      } else {
        this.dashboardToEdit = JSON.parse(JSON.stringify(this.dashboards[this.selectedTab]));
      }
      this.editMode = true;
    },
    async disableEditMode(saveChanges) {
      if (!saveChanges) {
        this.dashboardToEdit = deepCopy(this.dashboards[this.selectedTab]);
        this.editMode = false;
        return
      }

      this.showNotification('Saving dashboard, please wait.', 1000);
      this.saving = true;

      try {
        if (!this.dashboardToEdit.id) {
          const id = await chartService.createDashboard(this.dashboardToEdit)
          this.dashboardToEdit.id = id;
          this.dashboards.push(this.dashboardToEdit);
          this.selectedTab = this.dashboards.length - 1;
        } else {
          await chartService.updateDashboard(this.dashboardToEdit)
          const indexToUpdate = this.dashboards.findIndex(x => x.id === this.dashboardToEdit.id);
          this.dashboards.splice(indexToUpdate, 1, deepCopy(this.dashboardToEdit));
        }
      } finally {
        this.editMode = false;
        this.saving = false;
      }

      // Do not wait promise resolution, for faster UI response
      this.getTagValues();
    },
    deleteDashboard() {
      requests.deleteRequest(`${process.env.VUE_APP_CHART_SERVICE}/chart-service/v3/dashboard/${this.selectedDashboardId}`,
          null, () => {
            this.dashboards.splice(this.selectedTab, 1);
          },
      );
    },
    removeItem: function (value) {
      console.log(`Removing ${value}.`);
    },
    editGraph(i, valueLine) {
      console.log(i, valueLine);
    },
    handleWidgetSave(widget) {
      if (widget.i !== undefined) {
        const index = this.dashboardToEdit.items.findIndex(x => x.i === widget.i);
        if (index >= 0) {
          const dashboardWidget = this.dashboardToEdit.items[index];
          widget.x = dashboardWidget.x;
          widget.y = dashboardWidget.y;
          widget.w = dashboardWidget.w;
          widget.h = dashboardWidget.h;
          widget.i = dashboardWidget.i;
          this.$set(this.dashboardToEdit.items, index, widget);
        }
      } else {
        let w = 2;
        if (widget.type === 'teststatus') w = 8;
        const newWidget = deepCopy(widget);
        newWidget.x = 0;
        newWidget.y = 0;
        newWidget.w = w;
        newWidget.h = 5;
        let newIndex = 0;
        while (this.dashboardToEdit.items.find(x => x.i === newIndex)) newIndex++;
        newWidget.i = newIndex;
        this.dashboardToEdit.items.push(newWidget);
      }
      this.widget = widget;
    },
    deleteWidget(i) {
      this.dashboardToEdit.items.splice(this.dashboardToEdit.items.findIndex(x => x.i === i), 1);
    },
    openWidgetDialog(i) {
      let widget = null;
      if (i !== null) {
        widget = deepCopy(this.dashboardToEdit.items.find(x => x.i === i));
      }
      this.widget = widget;
      this.widgetDialog = true;
    },
    handleLoading(value, i) {
      if (value) this.finishedLoading = false;
      this.loadingMap[i] = value;
      if (!Object.values(this.loadingMap).includes(true)) this.finishedLoading = true;
    },
    clearExtraTagValues() {
      if (!this.tagMetricValues[this.selectedTab]) {
        return
      }
      const tagsToStay = this.dashboardToEdit.filters.map(f => this.getFilterName(f));
      for (const tag of Object.keys(this.tagMetricValues[this.selectedTab])) {
        if (!tagsToStay.includes(tag)) {
          this.$delete(this.tagMetricValues[this.selectedTab], tag);
        }
      }
    },
    async getTagValues() {
      this.tagMetricsLoading = true;

      if (!this.dashboardToEdit.filters || !this.dashboardToEdit.filters.length) {
        this.tagMetricsLoading = false;
        return;
      }

      const dashboardIndex = this.selectedTab;

      this.clearExtraTagValues();

      const doNotRefetchExistingValues = this.dashboardToEdit.filters.every(f => {
        const fName = this.getFilterName(f);
        return Boolean(this.tagMetricValues[dashboardIndex]?.[fName]?.values);
      })

      if (doNotRefetchExistingValues) {
        this.tagMetricsLoading = false;
        return;
      }

      this.selectedFilters = (JSON.parse(
          localStorage.getItem('selectedDashboardFilters')) || {})[this.selectedDashboardId] || {};

      // TODO: imrpove, by fetching only new values
      const promises = this.dashboardToEdit.filters.map(f => {
        const fName = this.getFilterName(f);

        return chartService.getTagValues(fName).then(tagVals => {
          this.tagMetricValues = {
            ...this.tagMetricValues,
            [dashboardIndex]: {
              ...(this.tagMetricValues[dashboardIndex] || {}),
              [fName]: tagVals,
            }
          }
        })
      })

      await Promise.all(promises).finally(() => {
        this.tagMetricsLoading = false;
      })
    },
    saveSelectedFilters() {
      if (!this.selectedDashboardId) return;
      const savedFilters = JSON.parse(localStorage.getItem('selectedDashboardFilters')) || {};
      savedFilters[this.selectedDashboardId] = this.selectedFilters;
      localStorage.setItem('selectedDashboardFilters', JSON.stringify(savedFilters));
    },
  },
});
</script>

<style scoped>
.dashboard {
  padding: 12px;
}

.dashboard-container {
  min-width: 100%;
}

.dashboard-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-bar > div:not(.dashboard-bar-edit-mode) {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.dashboard-tabs-area {
  display: flex;
  align-items: center;
  width: calc(100% - 467px - 36px);
}

.dashboard-tabs {
  flex: 0 1 auto;
}

.dashboard-bar-edit-mode {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.dashboard-bar-edit-mode-upper {
  display: flex;
  border-bottom: 2px solid var(--v-separator-base);
  width: 100%;
}

.dashboard-bar-edit-mode-lower {
  padding: 0 10px 10px 10px;
}

.dashboard-bar-edit-mode-name-area {
  padding: 10px;
  border-right: 2px solid var(--v-separator-base);
}

.dashboard-bar-edit-mode-filters-area {
  padding: 10px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-toolbar {
  width: 100%;
  height: 60px;
  padding: 0 10px;
}

.dashboard-toolbar >>> .v-toolbar__content {
  align-items: center;
  padding: 0;
  gap: 10px;
  align-content: space-between;
}

.dashboard-tab {
  display: flex;
  gap: 10px;
}

.edit-buttons {
  display: flex;
}

.dashboard-filters {
  width: 500px;
}

.dashboard-grid {
  background-color: #f1f0f0;
}

.dashboard-grid >>> .vue-resizable-handle {
  z-index: 1000;
}

.dashboard-no-items-message {
  height: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--v-no-records-base);
}

.date-time-range-picker-container {
  padding: 0 10px;
}

.dashboard-filters {
  display: flex;
  padding: 16px;
  gap: 20px;
  width: 100%;
}

.dashboard-bw-list-btn-box {
  display: flex;
  width: 100%;
}

.dashboard-bw-list-btn {
  flex-grow: 1;
}
</style>
