<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="getFieldAttrRow"
      :fields="fields"
      :templateContent="{}"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      :dialogAdder="true"
      addButtTitle="Add Attribute"
      @line-clicked="checkClicked(...arguments)"
    ></lineFieldsArray>
  </div>
</template>
<script>
import { arrayFindInArray } from "@/js/helper.js";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import { getRandomInt } from "@/js/helper.js";
export default {
  components: { lineFieldsArray },
  computed: {
    fields() {
      let fields = {
        add: {
          input: false,
          addingInput: false,
          inputFieldFormatter: "addGroup",
          colAttrs: {
            style: "max-width: 20px;min-width: 20px;",
            class: "mt-2",
          },
        },
        attribute: {
          type: "combobox",
          associatedOption: this.attrsArray,
          inputFieldFormatter: "attributeInput",
          name: "Attribute",
          source: this.source,
          fieldAttrInput: { class: "required" },
          treeMin: 1,
          color(valueLine) {
            //console.log("color lined called");
            return valueLine.color;
          },
        },

        type: {
          type: "radio",
          inputFieldFormatter: "getValueTypeFields",
          colAttrs: { style: "max-width: 260px;min-width: 260px;" },
          option: { static: "Static", dynamic: "Dynamic" },
          fieldAttrInput: {},

          default: "static",
        },
        content: {
          inputFieldFormatter: "renderInputValue",
          application: this.application,
          radiusDynamicPars: this.radiusDynamicPars,
          type: "text",
          fieldAttrInput: { class: "required validateCases" },
          name: "Value",
        },

        // fix: lineButtons.fix,
        // mandatory: {
        //   type: "hidden",
        //   colAttrs: { class: "d-none" },
        // },
        remove: {...lineButtons.remove},
        source: {
          type: "hidden",
          colAttrs: { class: "d-none" },
          default: this.source,
        },
        atRanId: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
        tree: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
      };
      // if (this.isTemplate) {
      //   fields.fix = {
      //     type: "hidden",
      //     colAttrs: { class: "d-none" },
      //   };
      //   fields.mandatory = lineButtons.mandatory;
      // }

      this.$set(fields.remove, "inputFieldFormatter", "diameterTrash");
      this.$set(fields.remove, "isTemplate", this.isTemplate);
      this.$set(fields.remove, "source", this.source);
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = this.calculatePairArray(this.value, "");
        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
  },
  props: {
    attrsArray: {},
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    application: {},
    source: {},
    radiusDynamicPars: {},
    isTemplate: { type: Boolean, default: false },
  },
  methods: {
    checkClicked(index, name) {
      console.log(index, name);
      if (name == "addGroup") {
        let request = { ...this.value };
        let lineInfo = this.pairArray[index];
        let tree = lineInfo.tree;
        let treeArray = tree.split(".");
        this.findlocationAndAdd(request, treeArray, 0);
        this.$emit("input", request);
      }
    },
    calculatePairArray(sentValue, index) {
      let pairArray = [];
      console.log(sentValue);
      for (const [key, value] of Object.entries(sentValue)) {
        //  valueArray.forEach((value, i) => {
        let type;
        if (typeof value == "string" && value.indexOf("EVAL:") > -1) {
          type = "dynamic";
        } else {
          type = "static";
        }
        let readOnly = false;
        if (value.fix == "2") {
          readOnly = true;
        }
        let content = value;
        if (typeof content == "object") {
          content = {};
        }
        console.log(key);
        let parentIndex = "";
        if (index != "") {
          parentIndex = index + ".";
        }
        let currentTree = parentIndex + key;
        let atRanId = value.atRanId;
        if (atRanId == "") {
          atRanId = getRandomInt(0, 100000);
        }
        console.log(readOnly);
        pairArray.push({
          attribute: key,
          ...value,
          atRanId: atRanId,
          content: content,
          type: type,
          tree: currentTree,
          readOnly: readOnly,
          source: this.source,
        });

        if (typeof value == "object") {
          let childArray = this.calculatePairArray(value, currentTree);
          pairArray = [...pairArray, ...childArray];
        }
        // });
      }
      return pairArray;
    },
    getFieldAttrRow(valueLine) {
      //console.log("color called", valueLine);
      let attrs = {};
      if (typeof valueLine.color != "undefined") {
        attrs = { style: "color: " + valueLine.color };
      }
      return attrs;
    },
    checkInput(newValueArray) {
      let request = {};
      newValueArray.forEach((line) => {
        let content = line.content;
        if (typeof content == "string") {
          if (line.type == "dynamic" && content.indexOf("EVAL:") == -1) {
            content = "EVAL:";
          }
          if (line.type == "static" && content.indexOf("EVAL:") > -1) {
            content = "";
          }
        }

        let valueArray = arrayFindInArray(line.attribute, this.attrsArray);

        let type = valueArray[2];
        console.log(type, content);
        if (type == "Grouped" && typeof line == "string") {
          content = {};
        }

        let insertedLine = {
          content: content,
          fix: line.fix,
          mandatory: line.mandatory,
          source: line.source,
          atRanId: line.atRanId,
        };
        // let treeLocation = request;
        let treeArray = line.tree.split(".");
        this.findlocationAndupdate(insertedLine, request, treeArray, 0, line);
      });
      console.log(JSON.stringify(request));
      this.$emit("input", request);
    },
    findlocationAndupdate(
      insertedLine,
      updatingObject,
      treeArray,
      location,
      line
    ) {
      //let index = treeArray[location];
      if (typeof treeArray[location + 1] != "undefined") {
        // treeArray.forEach((index, i) => {
        //  console.log(index, JSON.stringify(updatingObject));

        this.findlocationAndupdate(
          insertedLine,
          updatingObject[treeArray[location]],
          treeArray,
          location + 1,
          line
        );
      } else {
        let insertedAttr = line.attribute;
        // if (typeof updatingObject[insertedAttr] == "undefined") {
        //   this.$set(updatingObject, insertedAttr, []);
        // }
        updatingObject[insertedAttr] = insertedLine.content;
      }

      // });

      // if (typeof treeLocation != "undefined") {
      //   request[line.attribute].push(insertedLine);
      // } else {
      //   request[line.attribute] = [insertedLine];
      // }
      // treeLocation = insertedLine;
      //console.log(treeLocation, JSON.stringify(request));
    },
    findlocationAndAdd(updatingObject, treeArray, location) {
      console.log(treeArray, location);
      // let index = treeArray[location];
      if (typeof treeArray[location + 1] != "undefined") {
        this.findlocationAndAdd(
          updatingObject[treeArray[location]],
          treeArray,
          location + 1
        );
      } else {
        if (typeof updatingObject[treeArray[location]] != "object") {
          updatingObject[treeArray[location]] = {};
        }
        console.log(updatingObject[treeArray[location]]);
        updatingObject[treeArray[location]][""] = "";
      }
    },
  },
};

// function getRadiusRequestAttrRow(
//   table,
//   attrID,
//   attrValuesArray,
//   $attributesTbody,
//   $expandableAttributesTbody
// ) {
//   // build the radius request attribute row according to the given inputs
//   var content = "";
//   var fixed = "";
//   var tag = "";
//   var style = "";
//   var type = "";
//   var suppress = "";
//   if (attrID == "") {
//     let $application = $attributesTbody.closest(".application");
//     let type = $application.attr("data-application-type");
//     let clases = "requestAtrrClass comboBox";
//     if (type == "RADIUS_ACCOUNTING") {
//       clases += " radiusAccontingAttribute";
//     }
//     tag = makeSelector(table, "", clases);
//   } else {
//     valueArray = arrayFindInArray(attrID, table);
//     tag = valueArray[1];
//     type = valueArray[2];
//     tag = tag + "*";
//   }
//   var value = "";
//   let $fieldsObject = $attributesTbody.closest(".fields");
//   if (typeof attrValuesArray == "undefined" || attrValuesArray == "") {
//     let mandatory = 0;
//     if ($fieldsObject.data("enableSuppress")) {
//       mandatory = 1;
//     }
//     attrValuesArray = [{ content: "", fix: 0, mandatory: mandatory }];
//   }
//   $.each(attrValuesArray, function(index, attrValue) {
//     content = attrValue.content;
//     if (typeof content == "undefined") {
//       content = "";
//     }
//     fixed = "";
//     suppress = "";
//     if (attrValue.fix == 1) {
//       fixed = "checked='checked'";
//     } else if (attrValue.fix == 2) {
//       suppress = "checked='checked'";
//     }
//     style = "";
//     if (attrValue.color) {
//       style = "style=color:" + attrValue.color;
//     }
//     var readonly = "";
//     var disabled = "";
//     var source = "";
//     var suppressDesabled = "";
//     if ($fieldsObject.data("disableFixed")) {
//       if (attrValue.fix == 1) {
//         readonly = 'readonly="readonly" style="color: #787878;"';
//         suppressDesabled = 'disabled="disabled"';
//       } else if (attrValue.fix == 2) {
//         readonly = 'readonly="readonly" style="color: #787878;"';
//       }
//       disabled = 'disabled="disabled"';
//     }
//     if (typeof attrValue.source != "undefined") {
//       source = attrValue.source;
//     } else {
//       source = $fieldsObject.data("attrSource");
//     }
//     var $row = $(
//       "<tr class='attributeRow' " +
//         style +
//         "  source='" +
//         source +
//         "' type='" +
//         type +
//         "'></tr>"
//     );
//     if ($fieldsObject.data("enableSuppress")) {
//       if (attrValue.mandatory != 1 && attrValue.source != "t_testcases") {
//         // $row.addClass("ui-widget-content");
//         $expandableAttributesTbody.append($row);
//       } else {
//         $attributesTbody.append($row);
//       }
//     } else {
//       $attributesTbody.append($row);
//     }

//     let trash = "";
//     let attrSource = $fieldsObject.data("attrSource");
//     if (
//       !attrValue.source ||
//       (typeof $fieldsObject.data("disableFixed") == "undefined" &&
//         !$fieldsObject.data("disableFixed")) ||
//       attrValue.source == attrSource
//     ) {
//       trash =
//         "<a href='javascript:void(0);' class=remove><i style='padding-left:3px;' class='fas fa-trash fs16 iconRed'></i></a>";
//     }
//     let rowContent =
//       "<td class='addGroupTd'></td><td class='attribute treeselector attrNameInput' attrid='" +
//       attrID +
//       "'>" +
//       tag +
//       "</td>" +
//       getValueTypeFields(content, type) +
//       "<td class='value fixSelect attrValueField'></td>";
//     $row[0].innerHTML = rowContent;
//     //$row.append(getValueTypeFields(content,type));
//     //$row.append("<td class='value fixSelect attrValueField'></td>");
//     var $combo = $row.find(".comboBox");
//     setRequestAtrrClass($combo, false);

//     var $valueType = $row.find(".valueType");
//     var inputType = getDynamicAttributes($row, content);

//     renderInputValue(
//       $row,
//       content,
//       inputType,
//       readonly,
//       "",
//       "required",
//       attrValue
//     );

//     let centerMed = "centerMed";
//     if ($fieldsObject.data("enableSuppress")) {
//       //  var $supprestd=$("<td class='suppress gray'><input class='suppressCheck' type='checkbox' "+suppress+" "+suppressDesabled+"></td>").appendTo($row);
//       $row.append(
//         getFixedSupress(
//           attrValue,
//           suppressDesabled,
//           disabled,
//           $fieldsObject.data("enableSuppress")
//         )
//       );
//       toggleSupress($row);
//       centerMed = "";
//     } else {
//       // $row.append("<td class='fixed'><input type='checkbox' "+fixed+" "+disabled+"></td>");
//     }
//     let mandatorytd = getMandatory(
//       attrValue,
//       disabled,
//       $fieldsObject.data("enableSuppress"),
//       centerMed
//     );
//     $row.append(mandatorytd);
//     $row.append("<td>" + trash + "</td>");
//     $row.append("<td style='width:100%'></td>");
//     window.attributeIncrement++;
//     1;
//   });
// }
</script>