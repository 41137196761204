<template>
  <div class="explorer-name">
    <explorer-hw-brand :degust-image-version="degustImageVersion" class="explorer-hw-brand" size="24"/>

    <span class="explorer-name-span" @click="openDialog" v-text="row.c_name"/> <span
      v-if="row.c_alias" v-text="`(${row.c_alias})`"/>

    <LoadingDialog v-model="loading"/>

    <ExplorerDialog
        v-if="dialog"
        v-model="dialog"
        :explorer-data="explorerData"
        :explorer-status="explorerStatus"
        :result="result"
        :tags="currentTable.header.c_tags.associatedOption"
        @input="reloadTable"/>
  </div>
</template>

<script>
import ExplorerHwBrand from '../specific/Explorer/ExplorerHwBrand.vue';
import ExplorerDialog from '@/components/specific/Explorer/Dialog/ExplorerDialog.vue';
import LoadingDialog from '@/components/basic/LoadingDialog';
import explorerStatusService from "@/js/services/ExplorerStatusService";
import {parseBoolean} from '@/js/general';
import requests from "@/js/requests";

export default {
  components: {
    LoadingDialog,
    ExplorerHwBrand,
    ExplorerDialog,
  },
  props: ['row', 'currentTable', 'result'],
  data: function () {
    return {
      dialog: false,
      explorerData: {},
      explorerStatus: {},
      dialogResult: {},
      loading: false,
    };
  },
  computed: {
    additionalAttributes() {
      return {
        n_id: this.row.n_id,
        n_id_key: this.row.n_id_key,
        function: 'getInsertedFieldsEdit',
      };
    },
    degustImageVersion() {
      const systemInfo = JSON.parse(this.row['c_systemInfo']);
      return systemInfo.degustImageVersion;
    },
  },
  mounted() {
    if (parseBoolean(localStorage.getItem('back')) && this.row.c_name === localStorage.getItem('explorerDialog')) {
      this.openDialog();
    }
  },
  methods: {
    openDialog() {
      this.loading = true;
      explorerStatusService.getExplorerByName(this.row.c_name, (explorer) => {
        this.explorerData = explorer;
        this.loading = false;
        this.dialog = true;
        localStorage.setItem('explorerDialog', explorer.name);
      });
    },
    reloadTable() {
      requests.frameworkGetRequest('serve.php', '', '', (response) => {
        this.$store.commit('tableChanged', response.table);
        this.$emit('reload-table');
      });
    },
  },
};
</script>

<style scoped>
.explorer-name {
  display: flex;
  gap: 6px;
  align-items: center;
  min-width: 250px;
}

.explorer-name-span {
  cursor: pointer;
  color: var(--v-anchor-base);
}

>>> .explorer-hw-brand {
  flex-grow: 0;
}
</style>
