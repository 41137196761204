<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="localStoredValue"/>
    <explorerCollectStatistics v-model="statistic" :result="result"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import teststepDefault from '@/cfg/teststep-default.json';
import explorerCollectStatistics from '@/components/legacy/explorerCollectStatistics.vue';

export default {
  components: {fieldsRows, explorerCollectStatistics},
  data() {
    return {};
  },
  props: ['result', 'stepStoredValue', 'stepType', 'stepID'],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        this.$set(this.stepStoredValue, 'statistic', newVal);
        this.$emit('input', this.stepStoredValue);
      },
    },
    localStoredValue: {
      get() {
        let stepStoredValue = this.stepStoredValue;
        if (typeof this.stepStoredValue.startWithinMinutes == "undefined") {
          stepStoredValue = JSON.parse(JSON.stringify({...teststepDefault[this.stepType]}));
          this.$emit("stored-value-changed", stepStoredValue);
        }
        return stepStoredValue;
      },
      set(newVal) {
        this.$emit('stored-value-changed', newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: 'line',
            fields: {
              startWithinMinutes: {
                type: 'number',
                name: 'Start Within Minutes',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:170px', class: ''},
              },
              finishedWithinMinutes: {
                type: 'number',
                name: 'Finished Within Minutes',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:170px', class: ''},
              },
              maxDuration: {
                type: 'number',
                name: 'Maximum Duration (s)',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:200px', class: ''},
              },
            },
          },
          parameters: {
            type: 'text',
            inputFieldFormatter: 'explorerUSSDParameters',
          },
          res: {
            type: 'text',
            inputFieldFormatter: 'explorerResult',
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.stepType,
      };

      return templateContent;
    },
  },
  methods: {
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      this.$set(localStoredStep, field, value);
      this.$emit('stored-value-changed', localStoredStep);
    },
  },
};
</script>