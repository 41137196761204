<template>
  <div class="tag-data-list">
    <UiInputWrapper
      label="Tags List"
      :errors="errors"
      :is-loading="isLoading"
      :hints="hintText"
    >
      <input
        v-model="searchTxt"
        type="text"
        list="datalist"
        autocomplete="off"
        placeholder=" "
        @focus="() => loadTags()"
        @keydown.enter.prevent="(e) => emitPressEnter(e)"
      />

      <template #right>
        <div class="tag-data-list__help-btn" :data-help="help">
          <HelpButton :help="help" />
        </div>
      </template>

      <template
        v-if="isCountDisplayed"
        #bottom-right-info
      >
        Exploers: {{ explorerCount }}
      </template>
    </UiInputWrapper>


    <datalist id="datalist">
      <option
        v-for="(tag, index) in tagsList"
        :key="index"
      >
        {{ tag }}
      </option>
    </datalist>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue"
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
import HelpButton from "@/components/basic/HelpButton.vue";
import { useExplorerTags } from "@/composition/project-tags/use-explorer-tags"
import { debounce } from "lodash-es"

export default defineComponent({
  components: {
    UiInputWrapper,
    HelpButton,
  },

  props: {
    explorerTag: String,
    isCurrentProject: Boolean,
    isCountDisplayed: Boolean,
    help: String,
    additionalHints: String,
  },

  emits: ["update:explorerTag", "press-enter", "start-typing", "end-typing"],

  setup(props, { emit }) {
    const {
      tagsList,
      isLoading,
      loadTags,
      errors,
      checkSyntax,
      explorerCount,
    } = useExplorerTags({ isCurrentProject: props.isCurrentProject })

    const isTyping = ref(false)
    const debouncedCheckSyntax = debounce((newTag) => {
      return checkSyntax(newTag).then(() => {
        const isValid = !errors.value?.length
        const count = isValid ? explorerCount.value : 0
        emit("end-typing", { isValid, count })
      }).finally(() => {
        isTyping.value = false
      })
    }, 600)
    const searchTxt = computed({
      get() {
        return props.explorerTag
      },
      set(newTag) {
        if (newTag === props.explorerTag || typeof newTag !== "string") {
          return
        }
        if (!isTyping.value) {
          isTyping.value = true
          emit("start-typing", { isValid: false, count: 0 })
        }

        emit("update:explorerTag", newTag)
        return debouncedCheckSyntax(newTag)
      }
    })
    const emitPressEnter = (e) => {
      if (errors.value?.length) {
        return
      }
      emit("press-enter", e.target.value)
    }

    const hintText = computed(() => {
      return `Lookup Available Tags. ${props.additionalHints || ""}`
    })

    return {
      searchTxt,
      tagsList,
      isLoading,
      loadTags,
      errors,
      checkSyntax,
      emitPressEnter,
      explorerCount,
      hintText,
    }
  },
})
</script>

<style lang="scss">
.tag-data-list {
  &__help-btn {
    height: 100%;
    padding-top: 3px;
  }
}
</style>
