<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="stepStoredValue"
    ></fieldsRows>
    <configContainer
        title="Evaluation">
      <template v-slot:content>
        <evalContentTable
            v-model="localEval"
            operatorType="calc"
            :correctOperators="true"
            :isValid="isValid"
            :result="result"
        ></evalContentTable>
      </template>
    </configContainer>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import evalContentTable from "@/components/legacy/evalContentTable.vue";
import configContainer from "@/commonComponents/configContainer.vue";

export default {
  components: {
    fieldsRows,
    evalContentTable,
    configContainer,
  },
  methods: {
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      localStoredStep[field] = value;
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          description: {
            type: "textarea",
            name: "Description",
            fieldAttrInput: {class: "required", autofocus: true, id: 'calcStep_description'}
          },
        },
        contentID: this.stepType,
      };
      return templateContent;
    },
    value: {
      get() {
        let value = {};
        if (typeof this.stepStoredValue != "undefined") {
          value = this.stepStoredValue;
        }
        return value;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    localEval: {
      get() {
        let localEval = {};
        if (typeof this.value.eval != "undefined") {
          localEval = this.value.eval;
        }
        return localEval;
      },
      set(newVal) {
        let localStoredStep = {...this.stepStoredValue};
        this.$set(localStoredStep, "eval", newVal);

        this.$emit("stored-value-changed", localStoredStep);
      },
    },
  },

  props: [
    "result",
    "stepStoredValue",
    "stepType",
    "stepID",
    "isValid"
  ],
};
</script>