<template>
  <div>
    <XMap @location-details="updateLocation(...arguments)"
          :valueLine="localValueLine"/>
  </div>
</template>

<script>
import XMap from '@/components/basic/XMap';

export default {
  name: 'OrganizationLocationPicker',
  components: {XMap},
  data() {
    return {
      place: null,
      apiKey: 'AIzaSyCKmrnIZ_WcrXQ5IXrFwfQ0RmG7NbsGYy0'
    }
  },
  props: ['valueLine'],
  methods: {
    updateLocation(val) {
      this.$emit("location", "c_obj", val);
    },
  },
  computed: {
    localValueLine() {
      let valueLine = this.valueLine;
      if (valueLine == null) {
        valueLine = {
          latitude: 0,
          longitude: 0
        }
      }
      return valueLine;
    }
  }
};
</script>

<style scoped>

</style>