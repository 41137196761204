<script setup>
import { computed, nextTick, defineProps, defineEmits, isRef } from "vue"
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
import XSelect from "@/components/basic/XSelect.vue"
import XCheckbox from "@/components/basic/XCheckbox.vue"
import { useAsyncValidation } from "@/composition/validation/use-async-validation"

const MIN_NAME_LENGTH = 3

// export type Field = {
//   name: string
//   value: string
//   required: boolean
//   nameHints?: string
//   valueHints?: string
//   type: string
// }

// type FieldType = {
//   type: string
//   validator: Validator
// }

// type Props = {
//   types?: FieldType[] | string[]
//   field: Field,
//   withValue?: boolean,
//   valueLabel?: string
// }

const props = defineProps({
  types: {
    type: Array,
    default: () => ([]),
  },
  field: {
    type: Object,
    required: true,
  },
  withValue: {
    type: Boolean,
    default: false,
  },
  valueLabel: {
    type: String,
    default: "Default value"
  },
  canBeRequired: {
    type: Boolean,
    default: true,
  }
})

const emit = defineEmits(["update:field", "update:is-valid"])

const typesNames = computed(() => {
  return props.types?.map((t) => typeof t === "string" ? t : t.type) || []
})

const fieldName = computed({
  get: () => props.field.name,
  set: (name) => {
    emit("update:field", { ...props.field, name })
    emitIsValid()
  }
})
const isRequired = computed({
  get: () => props.field.required,
  set: (required) => {
    emit("update:field", { ...props.field, required })
  }
})
const fieldType = computed({
  get: () => props.field.type,
  set: (type) => {
    emit("update:field", { ...props.field, type })
    emitIsValid()
  }
})

const fieldNameError = computed(() => {
  const _n = props.field.name.trim()

  if (!_n) {
    return "Field name is required"
  }

  if (_n.length < MIN_NAME_LENGTH) {
    return `Field name should be at least ${MIN_NAME_LENGTH} characters`
  }

  return ""
})

const emitIsValid = async () => {
  await nextTick()
  emit("update:is-valid", isValid.value)
}

const dataForField = createFieldForValue(props.types)
const fieldValue = dataForField?.fieldValue || null
const error = dataForField?.error || ""
const isLoading = dataForField?.isLoading || false

const isValid = computed(() => {
  const isValueValid = isRef(error) ? !error.value.length : !error
  return !fieldNameError.value && isValueValid
})

function validatorToTypeMap(_types) {
  return _types.reduce((m, t) => {
    return m.set(t.type, t.validator)
  }, new Map())
}

function createFieldForValue(_types) {
  if (!props.withValue) {
    return
  }

  if (!_types || typeof _types[0] === "string") {
    return {
      fieldValue: computed({
        get: () => props.field.value,
        set: (value) => {
          emit("update:field", { ...props.field, value })
          emitIsValid()
        }
      })
    }
  }

  const validators = validatorToTypeMap(props.types)
  const currentValidator = computed(() => {
    return validators.get(props.field.type) || (() => Promise.resolve(""))
  })

  const {
    error,
    isLoading,
    model,
    subscribeToValidation
  } = useAsyncValidation(props.field.value, currentValidator)
  subscribeToValidation(emitIsValid)

  const fieldValue = computed({
    get: () => props.field.value,
    set: (value) => {
      model.value = value
      emit("update:field", { ...props.field, value })
    }
  })

  return {
    fieldValue,
    error,
    isLoading,
  }
}
</script>

<template>
  <fieldset class="ui-create-field">
    <div
      :class="{
        'ui-create-field__grid': true,
        'ui-create-field__grid--no-types': typesNames.length
      }"
    >
      <UiInputWrapper
        label="Field name"
        class="ui-create-field__name"
        :hints="field.nameHints || ''"
        :errors="fieldNameError"
      >
        <input
          v-model="fieldName"
          type="text"
          placeholder=" "
        >
      </UiInputWrapper>

      <XSelect
        v-if="typesNames.length"
        v-model="fieldType"
        label="Field type"
        :items="typesNames"
        class="ui-create-field__type"
      />

      <XCheckbox
        v-if="canBeRequired"
        v-model="isRequired"
        class="ui-create-field__required"
        label="Required"
      >
        required
      </XCheckbox>

      <UiInputWrapper
        v-if="withValue"
        :label="valueLabel"
        class="ui-create-field__value"
        :errors="error"
        :is-loading="isLoading"
        :hints="field.valueHints || ''"
      >
        <textarea
          v-model="fieldValue"
          rows="1"
          placeholder=" "
        />
      </UiInputWrapper>
    </div>
  </fieldset>
</template>

<style lang="scss">
.ui-create-field {
  $root: &;

  width: 100%;
  border: 0;
  container-type: inline-size;
  container-name: panel;

  &__grid {
    display: grid;
    gap: .5rem .875rem;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  &__name {
    grid-column: span 2;
  }

  &__value {
    grid-column: span 2;
  }
}
</style>
