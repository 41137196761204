<template>
  <div>
    <fieldsRows
      v-if="templateContent != null"
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      v-on="$listeners"
      @updated-fields="emitUpdateFields"
      :valueLine="valueLine"
    ></fieldsRows>
    <v-system-bar window dark color="primary" class="mb-2">
      <span>Evaluation</span>
    </v-system-bar>
    <!-- <div v-for="(evalRowsContainer, evalType) in evalValue" :key="evalType">
      <v-row v-for="(evalRow, evalIndex) in evalRowsContainer" :key="evalIndex">
        <v-col>
          <associatedOption
            :fieldAttributes="{
              name: 'Type',
              associatedOption: result.additional.pingEvalType,
            }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            :value="evalType"
            @input="updateEvalType(evalIndex, evalType, ...arguments)"
            :templateContent="result"
          ></associatedOption>
        </v-col>
        <v-col>
          <associatedOption
            :fieldAttributes="{
              name: 'Operator',
              associatedOption: result.additional.pingEvalOperator,
            }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            v-model="evalRow.operator"
            :templateContent="result"
          ></associatedOption>
        </v-col>

        <v-col>
          <primaryTextfield
            type="number"
            v-model="evalRow.content"
            label="Value"
            :fieldAttrInput="{ placeholder: 'Value', class: 'required' }"
            :templateContent="result"
          ></primaryTextfield>
        </v-col>
        <v-col cols="1">
          <div class="mt-2">else</div>
        </v-col>
        <v-col>
          <associatedOption
            :fieldAttributes="{
              name: 'Result',
              associatedOption: options.ldapResult,
            }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            v-model="evalRow.result"
            :templateContent="result"
          ></associatedOption>
        </v-col>
        <v-col>
          <associatedOption
            :fieldAttributes="{
              name: 'Action',
              associatedOption: options.evalAction,
            }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            v-model="evalRow.action"
            :templateContent="result"
          ></associatedOption>
        </v-col>
        <v-col class="mt-2">
          <trash @click="removeEval(evalType, evalIndex)"></trash>
        </v-col>
      </v-row>
    </div>
    <primaryButton
      label="Add Eval"
      icon="mdi-plus"
      @click="addEvalControl()"
      type="button"
      color="primary"
      :disabled="localDisabled"
    ></primaryButton> -->

    <div>
        <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        :addable="true"
        :dialogAdder="true"
        addButtTitle="Add Eval"
        :linePrefix="stepType + '_' + stepID + '_ping_eval'"
        :isValid="isValid"
      ></lineFieldsArray>
    </div>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";

import fieldsRows from "@/commonComponents/fieldsRows.vue";
// import primaryButton from "@/commonComponents/primaryButton.vue";
// import associatedOption from "@/commonComponents/associatedOption.vue";
// import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
// import trash from "@/commonComponents/dynamic/trash.vue";
import options from "@/cfg/options.json";
import requests from '@/js/requests';

export default {
  components: {
    lineFieldsArray,
    fieldsRows,
    // primaryButton,
    // associatedOption,
    // primaryTextfield,
    // trash,
  },
  data() {
    return { options: options, connectionsArray: {} };
  },
  props: ["result", "stepStoredValue", "stepID", "stepType","isValid"],
  created() {
    this.getConnectionsArray();
  },
  computed: {
    fields() {
      let fields = {
        type: {
          type: "select",
          associatedOption: this.result.additional.pingEvalType,

          name: "Type",

          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: this.result.additional.pingEvalOperator,

          name: "Operator",

          fieldAttrInput: { class: "required" },
        },
        content: {
          type: "number",

          name: "Value",

          fieldAttrInput: { class: "required" },
        },
        else: {
          input: false,

          type: "string",

          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },

        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      };

      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        for (const [type, valueArray] of Object.entries(this.evalValue)) {
          console.log(valueArray);
          valueArray.forEach((value) => {
            pairArray.push({
              type: type,
              ...value,
            });
          });
        }
        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },

    localDisabled() {
      let localDisabled = this.false;

      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        localDisabled = true;
      }

      return localDisabled;
    },
    valueLine() {
      let parameters = {};
      if (typeof this.stepStoredValue != "undefined") {
        parameters = this.stepStoredValue;
      }
      if (typeof parameters.parameters == "undefined") {
        parameters.parameters = { count: 4, ttl: 64, packet: 56 };
      }
      let valueLine = {
        connectionId: parameters.connectionId,
        ...parameters.parameters,
      };
      return valueLine;
    },
    templateContent() {
      let templateContent = {
        fields: {
          connectionId: {
            type: "combobox",
            name: "Connection",
            fieldAttrInput: { class: "required" },
            // value: parameters["connectionId"],
            associatedOption: this.connectionsArray,
            addingURL:
              "serve.php?f=configuration&f2=outboundConnections&f3=nocsConnection",
            addingFunction: "addConnection",
          },
          pingLine: {
            type: "line",
            fields: {
              count: {
                type: "number",
                // value: parameters["parameters"]["count"],
              },
              ttl: {
                type: "number",
                //  value: parameters["parameters"]["ttl"],
              },
              packet: {
                type: "number",
                // value: parameters["parameters"]["packet"],
              },
            },
          },
        },
        stepStoredValue: this.stepStoredValue,
        subType: "SSH",
        category: "System",
        stepType: "SSH",
        dialogRef: this.result.dialogRef,
        enableAdd: true,
        disableAllInputs: this.result.disableAllInputs,
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      this.$set(
        templateContent,
        "fields",
        this.languageHelpProvider(templateContent.fields, "pingStep")
      );
      return templateContent;
    },
    evalValue() {
      let evalValue = {};
      if (
        typeof this.stepStoredValue != "undefined" &&
        typeof this.stepStoredValue["res"] != "undefined"
      ) {
        evalValue = this.stepStoredValue["res"]["eval"];
      }
      return evalValue;
    },
  },
  methods: {
    checkInput(newValueArray) {
      let request = {};
      newValueArray.forEach((line) => {
        let insertedLine = {
          operator: line.operator,
          content: line.content,
          result: line.result,
          action: line.action,
          info: line.info,
        };

        if (typeof request[line.type] != "undefined") {
          request[line.type].push(insertedLine);
        } else {
          request[line.type] = [insertedLine];
        }
      });

      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res == "undefined") {
        this.$set(localStoredStep, "res", {});
      }
      if (typeof localStoredStep.res.eval == "undefined") {
        this.$set(localStoredStep.res, "eval", {});
      }
      this.$set(localStoredStep.res, "eval", request);
      this.$emit("stored-value-changed", localStoredStep);
    },

    getConnectionsArray() {
      //  return new Promise((resolve) => {
      let systemIdValue = "";
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: {
          function: 'getSSHClientServer',
          n_systemId: systemIdValue,
        },
      })
          .then(function (response) {
          caller.connectionsArray =
            response.data.result.json["connectionsArray"];
          //update the additionalStepsInfo
          caller.$set(
            caller.result.additional.additionalStepsInfo,
            "SSH",
            response.data.result.json
          );
          caller.$store.commit("dialogResultChanged", caller.result);
        })
        .catch(function (error) {
          //handle error
          console.log(error);
        });

      //    return templateContent;
      //  });
    },
    emitUpdateFields(info) {
      console.log("updated-fields emited", info);

      this.connectionsArray = info.connectionId.associatedOption;

      this.updateFieldValue("connectionId", info.connectionId.value);
    },
    // removeEval(evalType, evalIndex) {
    //   let localStoredStep = { ...this.stepStoredValue };
    //   localStoredStep.res.eval[evalType].splice(evalIndex, 1);
    //   this.$emit("stored-value-changed", localStoredStep);
    // },

    // addEvalControl() {
    //   let newType = this.result.additional.pingEvalType[
    //     this.result.additional.pingEvalType.length - 1
    //   ][0];
    //   let evalLine = { operator: "", content: "", result: "", action: "" };
    //   let localStoredStep = { ...this.stepStoredValue };
    //   let valueArray = [];
    //   if (typeof localStoredStep.res == "undefined") {
    //     this.$set(localStoredStep, "res", {});
    //   }
    //   if (typeof localStoredStep.res.eval == "undefined") {
    //     this.$set(localStoredStep.res, "eval", {});
    //   }
    //   if (typeof localStoredStep.res.eval[newType] != "undefined") {
    //     valueArray = localStoredStep.res.eval[newType];
    //   }
    //   valueArray.push(evalLine);

    //   this.$set(localStoredStep.res.eval, newType, valueArray);
    //   this.$emit("stored-value-changed", localStoredStep);
    // },
    // updateEvalType(evalIndex, oldType, newType) {
    //   let localStoredStep = { ...this.stepStoredValue };

    //   let evalLine = localStoredStep.res.eval[oldType][evalIndex];
    //   localStoredStep.res.eval[oldType].splice(evalIndex, 1);
    //   if (typeof localStoredStep.res.eval[newType] == "undefined") {
    //     localStoredStep.res.eval[newType] = [];
    //   }
    //   localStoredStep.res.eval[newType].push(evalLine);
    //   this.$emit("stored-value-changed", localStoredStep);
    // },
    updateFieldValue(field, value) {
      let localStoredStep = { ...this.stepStoredValue };
      if (field == "connectionId") {
        localStoredStep[field] = value;
      } else {
        localStoredStep.parameters[field] = value;
      }

      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>
