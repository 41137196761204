<template>
  <div>
    <div class="imsiFields">
      <v-row
          no-gutters
          v-for="(multiTextItem, increment) in multipleFields"
          class="imsicontainer"
          :key="increment"
      >
        <div>
          <primaryTextfield
              type="text"
              :label="fieldAttributes.name"
              @input="changetoVirtual(increment, ...arguments)"
              :value="multiTextItem.value"
              :fieldAttrInput="getfieldAttrInput(multiTextItem)"
              :templateContent="result"
              style="width: 200px"
              class="mr-6"
          ></primaryTextfield>
          <input
              type="hidden"
              :value="multiTextItem.type"
          />
        </div>
        <v-col class="mt-2" style="max-width: 30px" title="Clear/Delete IMSI">
          <v-icon @click="removeVirtual(increment)" :result="result" color="red">mdi-delete-forever</v-icon>
        </v-col>
        <v-col class="mt-2 d-flex" style="min-width: 50px; max-width: 100px">
          <a :title="multiTextItem.type">
            <v-icon
                @click="generateRandom(increment)"
                :disabled="disabled"
                color="blue"
            >mdi-auto-fix</v-icon
            >
          </a>
          <span
              v-if="fieldAttributes.multi != true"
              class="type mt-1"
              style="text-transform: capitalize; min-width: 50px"
          >
            {{ multiTextItem.type }}</span
          >
        </v-col>
      </v-row>
    </div>
    <div v-if="fieldAttributes.multi != true" class="">
      <primaryButton
          :label="fieldAttributes.buttonName"
          icon="mdi-plus"
          @click="addVirtual()"
          type="button"
          color="primary"
          :disabled="disabled"
      ></primaryButton>
    </div>
    <input
        type="hidden"
        :value="JSON.stringify(multipleFields)"
        :name="field"
    />
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["fieldAttributes", "value", "field", "result"],
  components: {
    primaryTextfield,
    primaryButton
  },
  computed: {
    disabled() {
      let disabled = false;
      if (
          typeof this.result != "undefined" &&
          typeof this.result.disableAllInputs != "undefined" &&
          this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },

    multipleFields() {
      let multipleFields = [{}];
      if (
          typeof this.value != "undefined" &&
          this.value != ""
      ) {
        // console.log(this.value);
        multipleFields = JSON.parse(this.value);
      }
      return multipleFields;
    },
  },
  methods: {
    getfieldAttrInput(multiTextItem) {
      let fieldAttrInput = this.fieldAttributes.fieldAttrInput;
      if (
          typeof multiTextItem.type != "undefined" &&
          multiTextItem.type != "" &&
          multiTextItem.type != "virtual"
      ) {
        fieldAttrInput["readonly"] = "readonly";
      }
      return fieldAttrInput;
    },
    generateRandom(index) {
      this.$set(
          this.multipleFields[index],
          "value",
          "47101"+Math.random().toString().substr(2, 14)
      );
      this.$set(this.multipleFields[index], "type", "virtual");
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
    changetoVirtual(index, value) {
      //console.log(index, value);
      this.$set(this.multipleFields, index, {
        value: value,
        type: "virtual",
      });
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
    addVirtual() {
      this.multipleFields.push({ type: "virtual" });
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
    removeVirtual(index) {
      this.multipleFields.splice(index, 1);
      if (this.multipleFields.length == 0) {
        this.multipleFields.push({});
      }
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
  },
};
</script>