<template>
  <div class="ma-n1" style="padding-top:2px">
    <a v-if="row['n_status']<2" href="javascript:void(0);">
      <v-icon color="red"
              @click="clearSelectedTest()"
              title="Stop Selected"
              style="font-size: 30px;"
      > mdi-stop</v-icon>
    </a>
  </div>
</template>
<script>
//import { clearTest } from "@/js/testHelpers.js";
import testCaseInfoService from "@/js/services/TestCaseInfoService";
export default {
  props: ["row", "rowindex", "currentTable"],
  methods: {
    clearSelectedTest() {
      //clearTest(this.row.n_id, this.row, this.currentTable, this);
      testCaseInfoService.stopTests([this.row.n_id], () => {
        this.showNotification(`Stopping test ${this.row.testNo}  ${this.row.c_testcase_name}.`, 3000);
      });
    },
  },
};
</script>