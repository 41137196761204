<template>
  <section class="variables-table">
    <div class="variables-table__headline">
      <slot name="headline" />
    </div>

    <v-data-table
      class="variables-table__data-table"
      :headers="headers"
      :items="variables"
      disable-pagination
      hide-default-footer
      hide-default-header
      fixed-header
    >
      <template #header={props}>
        <BasicHeader
          class="variables-table__table-header"
          :headers="props.headers"
          :sort-by="settings.sortBy"
          :is-descending="settings.descending"
          @sort="sortData => updateTableSettings(sortData)"
        />
      </template>

      <template #body="{
        items: variablesList
      }">
        <!-- <draggable -->
        <!--   tag="tbody" -->
        <!--   :list="variablesList" -->
        <!--   handle=".handle" -->
        <!-- > -->
          <tbody>
            <slot name="tbody" :items="variablesList" />

            <tr>
              <slot name="lastRow" />
            </tr>
          </tbody>
        <!-- </draggable> -->
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { defineComponent } from "vue"
import BasicHeader from "@/components/basic/vuetify-tuning/data-table/BasicHeader.vue"

export default defineComponent({
  name: "VariablesTableFrame",
  components: {
    BasicHeader,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    variables: {
      type: Array,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    }
  },
})
</script>

<style lang="scss">
$headline-height: 60px;

.variables-table {
  $root: &;

  position: relative;

  &__headline {
    position: sticky;
    z-index: 3;
    top: 0;
    max-height: $headline-height;
  }

  &__table-header {
    th {
      top: $headline-height !important;
    }
  }

  &__header-cell {
    font-size: 16px;
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>

