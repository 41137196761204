<template>
  <TestInfoTable :headers="headers" :items="[value]"/>
</template>

<script>
import TestInfoTable from '@/components/basic/TestInfoTable.vue';

export default {
  name: 'TestInfoErrorTable',
  components: {TestInfoTable},
  props: {
    value: Object,
  },
  data() {
    return {
      headers: [
        {
          value: 'errorCode',
          text: 'Error Code',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>