<template>
  <XForm
    ref="tagsFilterForm"
    class="tag-query-text-field"
    @input="$emit('update:is-valid', $event)"
  >
    <div
      class="tag-query-text-field__inner"
    >
      <XTextField
        class="tag-query-text-field__field"
        :required="required"
        ref="textField"
        :label="label ? label : 'Tags Filter'"
        :value="localValue"
        :rules="computedRules"
        :tooltip="tagsFilterTooltip"
        :help="help"
        @input="handleImmediateInput"
        @keydown.up="moveSelection(-1)"
        @keydown.down="moveSelection(1)"
        @keydown.enter="selectSuggestion"
        @click="() => keepSelectOpen()"
      />

      <div
        v-show="showSuggestions"
        ref="floating"
        class="suggestions"
        :style="floatingStyles"
      >
        <v-list dense>
          <v-list-item
            v-for="(suggestion, i) of suggestions"
            :key="i"
            :class="`suggestion-list-item ${i === selection ? 'cursor' : ''}`"
            @click.prevent.stop="addSuggestion(suggestion.suggestion, suggestion.mask)">
            <v-list-item-content>
              <v-list-item-title>
                 {{suggestion.suggestion}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <VIntersectionObserver
            class="intersection-observer"
            @visible="() => $emit('scroll-request')"
          />
        </v-list>
      </div>
    </div>

    <div
      class="tag-query-text-field__help-btn-box"
      :data-help="id">
      <HelpButton :data-help="id" />
    </div>
  </XForm>
</template>

<script>
import { defineComponent, ref } from "vue";
import XTextField from '@/components/basic/XTextField.vue';
import XForm from '@/components/basic/XForm.vue';
import VIntersectionObserver from "@/components/basic/web-api/VIntersectionObserver.vue"
import HelpButton from "@/components/basic/HelpButton.vue";
import explorerStatusService from '@/js/services/ExplorerStatusService';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';
import cockpitSimService from '@/js/services/CockpitSimService';
import { useFloating, offset, shift, autoUpdate, flip } from "@floating-ui/vue";
import { useClickOutside } from "@/composition/click-outside";

const PLACEMENT = "bottom";
const OFFSET = 1;

export default defineComponent({
  name: 'TagQueryTextField',

  components: {
    XForm,
    XTextField,
    VIntersectionObserver,
    HelpButton,
  },

  props: {
    required: {
      type: Boolean,
      default: undefined,
    },
    value: String,
    id: String,
    currentProject: Boolean,
    scrollContainer: HTMLDivElement,
    help: String,
    label: String,
    type: {
      type: String,
      default: 'explorer',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    allowTestVariables: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const floating = ref(null)
    const textField = ref(null);
    const {
      clickOutsideRoot: tagsFilterForm,
      isOpen,
      toggle
    } = useClickOutside()

    const { floatingStyles } = useFloating(textField, floating, {
      placement: PLACEMENT,
      middleware: [offset(OFFSET), shift(), flip()],
      whileElementsMounted: autoUpdate,
    })

    const isInputFocused = () => document.activeElement === textField.value.$refs.input.$refs.input;
    const keepSelectOpen = () => {
      if (isInputFocused() && !isOpen.value) {
        toggle();
      }
    };

    return {
      floating,
      floatingStyles,
      tagsFilterForm,
      isOpen,
      toggle,
      textField,
      keepSelectOpen,
    };
  },

  data() {
    return {
      localValue: '',
      tagsFilterRules: [],
      tagsFilterTooltip: undefined,
      tags: [],
      suggestions: [],
      focus: false,
      immediateValue: '',
      delay: 4000,
      selection: -1,
      maxSuggestions: 20,
      latestCheckValue: '',
      debouncing: false,
      latestDebounceValue: '',
    };
  },
  created() {
    let value = this.value;
    let containsTestVariables = false;
    if (this.allowTestVariables) {
      value = value.replace(/\${[\w:.]+}/, 'test-variable-tag');
      containsTestVariables = value !== this.value;
      this.$emit('update:contains-test-variable', containsTestVariables);
    }
    if (this.type === 'explorer') {
      if (!containsTestVariables) {
        explorerStatusService.checkTagSyntaxCb(value, (response) => {
          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            if (this.tagsFilterForm) this.tagsFilterForm.validate();
          });
          this.$emit('count', response.count);
        }, (error) => {
          const data = error.response.data;
          if (data.error === 'ESS_CREATE_TAG_QUERY') {
            error = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
            this.tagsFilterTooltip = error;
            this.tagsFilterRules = [v => v !== value];
            this.$nextTick(() => {
              this.tagsFilterForm.validate();
            });
          }
        });
      }

      if (!this.currentProject) {
        cockpitExplorerService.getExplorerTags((tags) => {
          this.tags = tags;
        });
      } else {
        cockpitExplorerService.getExplorerTagsForCurrentProject((tags) => {
          this.tags = tags;
        });
      }
    } else if (this.type === 'sim') {
      if (!containsTestVariables) {
        cockpitSimService.getSimCountByTagQuery(value, (response) => {
          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            if (this.tagsFilterForm) this.tagsFilterForm.validate();
          });
          this.$emit('count', response.count);
        }, (error) => {
          const data = error.response.data;
          if (data.error === 'CSS_CREATE_TAG_QUERY') {
            error = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
            this.tagsFilterTooltip = error;
            this.tagsFilterRules = [v => v !== value];
            this.$nextTick(() => {
              this.$refs.tagsFilterForm.validate();
            });
          }
        });
      }

      cockpitSimService.getTags((tags) => {
        this.tags = tags;
      });
    } else {
      console.error(`Unknown tag query text field type: ${this.type}`);
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value === this.localValue) return;
        this.localValue = value;
      },
      immediate: true,
    },
    suggestions(value) {
      if (!value) this.selection = -1;
    },
  },
  computed: {
    showSuggestions() {
      return this.isOpen && this.suggestions.length > 0;
    },

    computedRules() {
      return this.rules.concat(this.tagsFilterRules);
    },
  },
  methods: {
    handleImmediateInput(value) {
      this.immediateValue = value;
      this.tagsFilterTooltip = undefined;
      let results = [];
      if (this.tags.length) {
        if (value && value.length) {
          const regex = /[a-zA-Z0-9!_\-=.]+/;
          let matches = value.match(regex);
          const caretPosition = this.textField.$refs.input.$refs.input.selectionStart;
          let offset = 0;
          while (matches != null) {
            offset--;
            matches = value.charAt(caretPosition + offset).match(regex);
          }
          offset++;
          const match = value.substring(caretPosition + offset, caretPosition).toLowerCase(); // Convert match to lowercase
          results = this.tags.filter(function (x) {
            if (this.count < this.maxSuggestions && x.toLowerCase().includes(match)) { // Convert tag to lowercase
              this.count++;
              return true;
            }
            return false;
          }, {
            count: 0,
            maxSuggestions: this.maxSuggestions,
          });
          for (let i = 0; i < results.length; i++) {
            const index = results[i].toLowerCase().indexOf(match);
            results[i] = {
              suggestion: results[i],
              beforeMask: results[i].substring(0, index),
              mask: results[i].substring(index, index + match.length),
              afterMask: results[i].substring(index + match.length),
            };
          }
        }
      }
      this.suggestions = results;

      this.latestDebounceValue = value;
      this.debouncing = true;
      setTimeout(() => {
        if (this.debouncing && value === this.latestDebounceValue) {
          this.emitInput(value);
          this.debouncing = false;
        }
      }, this.delay);
    },
    emitInput(value) {
      this.localValue = value;

      const originalValue = value;
      this.latestCheckValue = value;

      if (this.allowTestVariables) {
        value = value.replace(/\${[\w:.]+}/, 'test-variable-tag');
        this.$emit('update:contains-test-variable', value !== originalValue);
      }

      if (this.type === 'explorer') {
        explorerStatusService.checkTagSyntaxCb(value, (response) => {
          if (this.latestCheckValue !== originalValue) return;

          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            this.tagsFilterForm.validate();
          });
          this.$emit('input', originalValue);
          this.$emit('count', response.count);
        }, (error) => {
          if (this.latestCheckValue !== originalValue) return;

          const data = error.response.data;
          let errorMessage = 'Tag query syntax error.';
          if (data.error === 'ESS_TAG_QUERY') {
            errorMessage = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
          }
          this.tagsFilterTooltip = errorMessage;
          this.tagsFilterRules = [v => v !== originalValue];
          this.$nextTick(() => {
            this.tagsFilterForm.validate();
          });
        });
      } else if (this.type === 'sim') {
        cockpitSimService.getSimCountByTagQuery(value, (response) => {
          if (this.latestCheckValue !== originalValue) return;

          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            this.tagsFilterForm.validate();
          });
          this.$emit('input', originalValue);
          this.$emit('count', response.count);
        }, (error) => {
          if (this.latestCheckValue !== originalValue) return;

          const data = error.response.data;
          let errorMessage = 'Tag query syntax error.';
          if (data.error === 'CSS_TAG_QUERY') {
            errorMessage = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
          }
          this.tagsFilterTooltip = errorMessage;
          this.tagsFilterRules = [v => v !== originalValue];
          this.$nextTick(() => {
            this.tagsFilterForm.validate();
          });
        });
      } else {
        console.error(`Unknown tag query text field type: ${this.type}`);
      }
    },

    async addSuggestion(suggestion, mask) {
      this.debouncing = false;
      let value = this.immediateValue;
      const caretPosition = this.textField.$refs.input.$refs.input.selectionStart;
      value = value.substring(0, caretPosition - mask.length) + suggestion + value.substring(caretPosition);
      this.emitInput(value);
      this.suggestions = [];

      this.textField.$refs.input.$refs.input.focus();

      await this.$nextTick();

      this.keepSelectOpen();

      const newPosition = caretPosition - mask.length + suggestion.length;
      this.textField.$refs.input.$refs.input.setSelectionRange(newPosition, newPosition);
    },

    moveSelection(value) {
      let selection = this.selection;
      selection += value;
      if (selection < 0) selection = this.suggestions.length - 1;
      else if (selection > this.suggestions.length - 1) selection = 0;
      this.selection = selection;
    },
    selectSuggestion() {
      if (this.selection >= 0) {
        const suggestion = this.suggestions[this.selection];
        this.addSuggestion(suggestion.suggestion, suggestion.mask);
      }
    },
  },
});
</script>

<style lang="scss">
.tag-query-text-field {
  display: flex;

  &__inner {
    position: relative;
    flex: 1;
  }

  &__help-btn-box {
    flex-shrink: 0;
  }
}

.suggestions {
  width: 100%;
  max-height: 300px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: -2px 0 4px -1px rgba(0, 0, 0, 0.2), 2px 0 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  padding-bottom: .25rem;
  z-index: 8;
  overflow: auto;
  overscroll-behavior-y: contain;
}

.suggestion-list-item {
  cursor: pointer;
  transition: .3s cubic-bezier(.25, .8, .5, 1);
}

.suggestion-list-item.cursor {
  background-color: var(--v-list-item-cursor-base);
}

.suggestion-list-item:not(.cursor):hover {
  background-color: var(--v-list-item-hover-base);
}
</style>
