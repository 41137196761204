<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="localStoredValue"
        @type-changed="changeTargetType"
    ></fieldsRows>
    <div v-if="checkUplink" class="mb-3">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <containerTitle title="Evaluation Uplink"></containerTitle>
        <v-card-text>
          <lineFieldsArray
              :fieldAttrRow="{}"
              :fields="fields"
              :templateContent="result"
              :value="upLinkPairArray"
              @line-field-input="checkInput(...arguments, 'uplink')"
              :headerTitle="true"
              :addable="true"
              addButtTitle="Add Eval"
              :linePrefix="stepType + '_' + stepID + '_uplink_eval'"
              :isValid="isValid"
              v-on="$listeners"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="checkDownlink">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <containerTitle title="Evaluation Downlink"></containerTitle>
        <v-card-text>
          <lineFieldsArray
              :fieldAttrRow="{}"
              :fields="fields"
              :templateContent="result"
              :value="downLinkPairArray"
              @line-field-input="checkInput(...arguments, 'downlink')"
              :headerTitle="true"
              :addable="true"
              addButtTitle="Add Eval"
              :linePrefix="stepType + '_' + stepID + '_downlink_eval'"
              :isValid="isValid"
              v-on="$listeners"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </div>
    <explorerOnError
        v-model="onErrorVal"
        :result="{
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        contentID: this.stepType,
      }"
    ></explorerOnError>
    <explorerCollectStatistics v-model="statistic" :result="result">
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import teststepDefault from '@/cfg/teststep-default.json';
import lineFieldsArray from '@/commonComponents/lineFieldsArray.vue';
import options from '@/cfg/options.json';
import lineButtons from '@/cfg/lineButtons.json';
import containerTitle from '@/commonComponents/containerTitle.vue';
import explorerOnError from '@/components/dynamic/explorerOnError.vue';
import explorerCollectStatistics from '@/components/legacy/explorerCollectStatistics.vue';
import {deepCopy, setDefaultValues} from '@/js/general';

export default {
  components: {
    fieldsRows,
    lineFieldsArray,
    containerTitle,
    explorerOnError,
    explorerCollectStatistics,
  },
  data() {
    return {
      options: options,
      setDefaultValues: setDefaultValues,
    };
  },
  props: ['result', 'stepStoredValue', 'stepType', 'stepID', 'isValid'],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        this.$set(this.stepStoredValue, 'statistic', newVal);
        this.$emit('input', this.stepStoredValue);
      },
    },
    onErrorVal: {
      get() {
        return this.stepStoredValue.res;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    checkUplink() {
      let value = false;
      if (
          typeof this.localStoredValue != 'undefined' &&
          typeof this.localStoredValue.uplink != 'undefined' &&
          this.localStoredValue.uplink == 1
      ) {
        value = true;
      }
      return value;
    },
    checkDownlink() {
      let value = false;
      if (
          typeof this.localStoredValue != 'undefined' &&
          typeof this.localStoredValue.downlink != 'undefined' &&
          this.localStoredValue.downlink == 1
      ) {
        value = true;
      }
      return value;
    },
    fields() {
      let fields = {
        type: {
          type: 'combobox',
          associatedOption: options.iperfEvalType,
          // associatedOption: this.result.additional.explorerEvalType,
          name: 'Type',
          fieldAttrInput: {class: 'required'},
        },
        operator: {
          type: 'select',
          associatedOption: this.result.additional.explorerEvalOperator,
          name: 'Operator',
          fieldAttrInput: {class: 'required'},
        },
        content: {
          name: 'Value',
          inputFieldFormatter: 'udpEvalValue',
          AvgMaxNumber: 100,
        },
        else: {
          input: false,
          type: 'string',
          string: 'else',
          colAttrs: {
            style: 'max-width: 50px;',
          },
        },
        result: {
          type: 'select',
          associatedOption: options.ldapResult,
          name: 'Result',
          fieldAttrInput: {class: 'required'},
          default: 'error',
        },
        action: {
          type: 'select',
          associatedOption: options.evalAction,
          name: 'Action',
          default: 'stopTest',
          fieldAttrInput: {class: 'required'},
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },
    upLinkPairArray: {
      get() {
        let pairArray = [];
        let uplink = [];
        if (
            typeof this.stepStoredValue['res']['eval']['uplink'] != 'undefined'
        ) {
          uplink = this.stepStoredValue['res']['eval']['uplink'];
        }
        uplink.forEach((value) => {
          pairArray.push({
            type: 'uplink',
            ...value,
          });
        });
        return pairArray;
      },
    },
    downLinkPairArray: {
      get() {
        let pairArray = [];
        let downlink = [];
        //console.log(this.stepStoredValue);
        if (typeof this.stepStoredValue['res']['eval']['downlink'] != 'undefined') {
          downlink = this.stepStoredValue['res']['eval']['downlink'];
        }
        downlink.forEach((value) => {
          pairArray.push({
            type: 'downlink',
            ...value,
          });
        });
        return pairArray;
      },
    },
    localStoredValue: {
      get() {
        const localStoredValue = deepCopy(this.stepStoredValue);
        if (setDefaultValues(localStoredValue, teststepDefault[this.stepType])) {
          this.$emit('stored-value-changed', localStoredValue);
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit('stored-value-changed', newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: 'line',
            fields: {
              startWithinMinutes: {
                type: 'number',
                name: 'Start Within Minutes',
                fieldAttrInput: {
                  class: ' ',
                },
                colAttrs: {style: 'max-width:170px', class: ''},
              },
              finishedWithinMinutes: {
                type: 'number',
                name: 'Finished Within Minutes',
                fieldAttrInput: {
                  class: ' ',
                },
                colAttrs: {style: 'max-width:170px', class: ''},
              },
              maxDuration: {
                type: 'number',
                name: 'Maximum Duration (s)',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:200px', class: ''},
              },
            },
          },
          iperfBlock: {
            inputFieldFormatter: 'explorerIperfBlock',
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        //contentID: this.stepType + '_' + this.stepID,
        contentID: this.stepType,
      };
      return templateContent;
    },
  },
  methods: {
    changeTargetType(value) {
      this.$set(this.stepStoredValue.target, 'type', value);
      this.$emit('stored-value-changed', this.stepStoredValue);
    },
    checkInput(newValueArray, type) {
      let request = [];
      newValueArray.forEach((line) => {
        let insertedLine = {
          type: line.type,
          operator: line.operator,
          content: line.content,
          result: line.result,
          action: line.action,
          info: line.info,
        };
        if (typeof request != 'undefined') {
          request.push(insertedLine);
        } else {
          request = [insertedLine];
        }
      });

      let localStoredStep = {...this.stepStoredValue};
      if (typeof localStoredStep.res == 'undefined') {
        this.$set(localStoredStep, 'res', {});
      }
      if (typeof localStoredStep.res.eval == 'undefined') {
        this.$set(localStoredStep.res, 'eval', {});
      }
      this.$set(localStoredStep.res.eval, type, request);
      this.$emit('stored-value-changed', localStoredStep);
    },

    updateFieldValue(field, value) {
        console.log("CORE", field, value);
      if (field != 'iperfBlock' && field != 'testDirection') {
        let localStoredStep = {...this.stepStoredValue};
        this.$set(localStoredStep, field, value);
        this.$emit('stored-value-changed', localStoredStep);
      }
    },
  },
};
</script>


