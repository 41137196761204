var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('associatedOption',{attrs:{"fieldAttributes":_vm.fieldAttributes,"type":"tagCombo","value":_vm.selectedVal,"fieldAttrInput":{
      ..._vm.fieldAttributes.fieldAttrInput,
      multiple: true,
      chips: true,
      class: 'categoryTag',
      id: _vm.id,
    },"templateContent":_vm.result,"name":_vm.field},on:{"input":function($event){return _vm.updateValue(...arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_c('v-chip',_vm._b({attrs:{"label":"","small":""},on:{"click:close":function($event){return _vm.removeValue(slotProps.item)}}},'v-chip',_vm.chipBind,false),[_c('span',[_vm._v(_vm._s(slotProps.item.text))])]),_c('input',{attrs:{"type":"hidden","name":_vm.field + '[]'},domProps:{"value":slotProps.item.value}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }