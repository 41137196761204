<template>
  <div v-if="valueLine.n_type != '4' && valueLine.n_type != '5'">
    <v-row
      no-gutters
      v-for="(multiTextItem, increment) in project"
      class="imsicontainer"
      :key="increment"
    >
      <v-col>
        <associatedOption
          :fieldAttributes="{
            name: 'Project',
            associatedOption: result.additional.projectsArray,
          }"
          type="combobox"
          :fieldAttrInput="{
            ...fieldAttrInput,
            id: 'projectSelector_' + increment,
          }"
          v-model="project[increment]"
          :templateContent="result"
        />
      </v-col>

      <v-col class="mt-2" style="max-width: 30px">
        <v-icon 
          :id="'projectSector_' + increment"
          @click="addRow(increment)"
        >
          mdi-plus
        </v-icon>
      </v-col>

      <v-col class="mt-2" style="max-width: 30px">
        <trash @click="removeRow(increment)" :result="result" />
      </v-col>
    </v-row>

    <input type="hidden" :value="JSON.stringify(project)" :name="field" />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import trash from "@/commonComponents/dynamic/trash.vue";

import associatedOption from "@/commonComponents/associatedOption.vue";

export default defineComponent({
  props: ["fieldAttributes", "value", "field", "result", "valueLine"],

  components: { associatedOption, trash },

  data() {
    return { project: [""] };
  },

  watch: {
    "valueLine.n_type": function () {
      let projects = JSON.parse(JSON.stringify(this.project));
      this.project = ["1"];
      this.$nextTick(() => {
        this.project = projects;
        // this.$emit("revalidate");
      });
    },
  },

  computed: {
    fieldAttrInput() {
      let fieldAttrInput = { class: "required" };
      if (this.valueLine.n_type != "3") {
        fieldAttrInput = {};
      }
      return fieldAttrInput;
    },
  },

  methods: {
    removeRow(index) {
      this.project.splice(index, 1);
      if (this.project.length == 0) {
        this.project.push("");
      }
    },

    addRow() {
      this.project.push("");
    },
  },
});
</script>
