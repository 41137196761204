<template>
  <div>
    <HistoryFormDialog
        :value="value && !loading"
        :item="msisdn"
        :item-id="msisdn.id"
        item-name="MSISDN"
        name-header="msisdn"
        :viewingHistory="!!version"
        @input="$emit('input', $event)"
        @save="save">
      <template #dialog-content>
        <div class="msisdn-form">
          <XTextField
              label="MSISDN"
              v-model="msisdn.msisdn"
              max-length="15"
              required
              validate-immediately
              type="int-string"/>
          <XTextarea label="Description" v-model="msisdn.description"/>
          <XTextField
              label="MCCMNC"
              v-model="msisdn.mccmnc"
              max-length="10"
              required
              validate-immediately
              type="int-string"/>
        </div>
      </template>
    </HistoryFormDialog>
    <LoadingDialog v-model="loading"/>
  </div>
</template>

<script>
import XTextarea from '@/components/basic/XTextarea.vue';
import XTextField from '@/components/basic/XTextField.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import HistoryFormDialog from '@/components/extended/HistoryFormDialog.vue';
import cockpitSimService from "@/js/services/CockpitSimService";
import { setHistoryComment } from "@/js/general";

export default {
  name: 'MsisdnDialog',
  components: {HistoryFormDialog, LoadingDialog, XTextField, XTextarea},
  props: {
    value: Boolean,
    id: Number,
    version: Number,
  },
  data() {
    return {
      dataValue: false,
      msisdn: this.createDefaultItem(),
      loading: false,
      setHistoryComment: setHistoryComment,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) return;
        if (this.id) {
          this.loading = true;
          cockpitSimService.readMsisdn(this.id, this.version, (msisdn) => {
            this.msisdn = msisdn;
            this.loading = false;
          });
        } else {
          this.msisdn = this.createDefaultItem();
        }
      },
    },
  },
  methods: {
    save(comment) {
      const msisdn = this.setHistoryComment(this.msisdn, comment);
      if (!msisdn.id) {
        cockpitSimService.createMsisdn(msisdn, (id) => {
          msisdn.id = id;
          this.$emit('save', msisdn);
          this.$emit('input', false);
        });
      } else {
        cockpitSimService.updateMsisdn(msisdn, () => {
          this.$emit('save', msisdn);
          this.$emit('input', false);
        });
      }
    },
    createDefaultItem() {
      return {
        msisdn: null,
        description: '',
        mccmnc: null,
      };
    },
  },
};
</script>

<style scoped>
.msisdn-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>