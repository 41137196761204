import { render, staticRenderFns } from "./UiColumnSorter.vue?vue&type=template&id=327eb062"
import script from "./UiColumnSorter.vue?vue&type=script&lang=ts&setup=true"
export * from "./UiColumnSorter.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./UiColumnSorter.vue?vue&type=style&index=0&id=327eb062&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports