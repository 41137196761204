<template>
  <AddField
      path="serve.php?f=testing&f2=configureAndRunTests"
      :openDialogFlag="dialog"
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="{}"
      v-on="$listeners">
  </AddField>
</template>

<script>
import AddField from '@/commonComponents/addField.vue';
import {runTest, shaKey} from '@/js/helper';
import requests from '@/js/requests';

export default {
  name: 'TestCaseExecutor',
  components: { AddField },
  props: {
    row: Object,
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      startParameters: [],
    };
  },
  created() {
    if (this.row) {
      console.log("Running test...");
    }
  },
  computed: {
    additionalAttributes () {
      let additionalAttributes = {};
      if (this.row) {
        additionalAttributes = {
          n_id: this.row.id.toString(),
          n_id_key: this.shaKey(this.row.id),
          function: "getInsertedFieldsEdit",
          ...additionalAttributes,
        };
      }
      return additionalAttributes;
    },
  },
  methods: {
    checkBeforeRun(n_id, n_id_key) {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: {
          function: 'checkBeforeRun',
          n_id: n_id,
          n_id_key: n_id_key,
        },
      })
          .then(function (response) {
            console.log(response);
            let data = response.data.result.json;
            if (data.result) {
              caller.dialog = true;
              caller.runTest(n_id, n_id_key);
            }
          })
          .catch(function (response) {
            // handle error
            console.log(response);
          });
    },
    runTest(n_id, n_id_key) {
      runTest(n_id, n_id_key, this);
    },
    shaKey(msg) {
      return shaKey(msg);
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.row || !value) return;
        this.checkBeforeRun(this.row.id.toString(), this.shaKey(this.row.id));
      }
    }
  }
}
</script>
