var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldsRows',_vm._g({attrs:{"templateContent":_vm.templateContent,"valueLine":_vm.localStoredValue,"isValid":_vm.isValid},on:{"field-value-changed":function($event){return _vm.updateFieldValue(...arguments)},"type-changed":_vm.changeTargetType}},_vm.$listeners)),_c('explorerOnError',{attrs:{"result":{
      stepType: this.stepType,
      stepID: this.stepID,
      disableAllInputs: this.result.disableAllInputs,
      enableAdd: false,
      contentID: this.stepType,
    }},model:{value:(_vm.onErrorVal),callback:function ($$v) {_vm.onErrorVal=$$v},expression:"onErrorVal"}}),_c('explorerCollectStatistics',{attrs:{"result":_vm.result},model:{value:(_vm.statistic),callback:function ($$v) {_vm.statistic=$$v},expression:"statistic"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }