<template>
  <v-list-item>
    <v-list-item-title>
      <addField
          :path="currentTable.tableUrl"
          :additionalAttributes="additionalAttributes"
          :showDialog="openDialog"
          @dialog-closed="openDialog=false"
      >
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-tag-multiple-outline</v-icon>
          {{ language.componentLabel.labelCategory }}
        </v-card>
      </addField>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import addField from "@/commonComponents/addField.vue";

export default {
  data() {
    return {openDialog: false};
  },
  components: {addField},
  props: ["currentTable", "selectedRows"],
  computed: {
    additionalAttributes() {
      return {
        function: "listAllTestCategory",
        requestType: "ajax",
        selected: Object.keys(this.selectedRows) /*, 'hideButtons':'true'*/,
        requiredPage: this.currentTable.tableUrl,
      };
    },
  },
};
</script>