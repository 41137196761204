<script lang="ts" setup>
import { ref, computed, defineProps, defineEmits } from "vue"
import { useFloating, offset, shift, autoUpdate, flip } from "@floating-ui/vue"
import { useClickOutside } from "@/composition/click-outside"
import UiFieldsController from "@/components/basic/form/UiFieldsController.vue"

const PLACEMENT = "bottom"
const OFFSET = 10

/*
* @typedef {import('../form/UiFieldsController.vue').Field} Field
*/
const props = defineProps({
  /** @type {import('vue').PropOptions<Field[]>} */
  columns: {
    type: Array,
  }
})
const emit = defineEmits(["update:columns"])
const anchor = ref(null)
const floating = ref(null)

const { clickOutsideRoot, isOpen, toggle } = useClickOutside()

const { floatingStyles } = useFloating(anchor, floating, {
  placement: PLACEMENT,
  middleware: [offset(OFFSET), shift(), flip()],
  whileElementsMounted: autoUpdate,
})

const columns = computed({
  get: () => props.columns,
  set: (value) => {
    emit("update:columns", value)
  },
})
</script>

<template>
  <div
    class="ui-column-sorter"
    placement="bottom"
    ref="clickOutsideRoot"
  >
    <v-btn
      icon
      ref="anchor"
      color="secondary"
      @click="() => toggle()"
    >
      <v-icon
        :size="24"
      >
        mdi-table-cog
      </v-icon>
    </v-btn>

    <div
      v-if="isOpen"
      class="ui-column-sorter__float-box"
      ref="floating"
      :style="floatingStyles"
    >
      <UiFieldsController
        :fields-data.sync="columns"
        with-search
      />
    </div>
  </div>
</template>

<style lang="scss">
.ui-column-sorter {
  display: inline-block;

  &__float-box {
    z-index: 10;
    background-color: #ffffff;
    max-width: 250px;
    width: 100%;
    padding: .5rem 0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
}
</style>
