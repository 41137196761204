<template>
    <div ref="hssEditor" class="hss-editor">
      <HssObjectAdder ref="hssObjectAdder" v-if="showHssTable" :row="row" />
      <x-btn
        text="Add HSS Objects"
        color="primary"
        icon="mdi-plus"
        @click="addRow" />
      <HssObjectDialog v-model="dialog" :hss-object="hssObject" new-hss @save="closeDialogs" />
    </div>
  </template>
  
<script>
import XBtn from '@/components/basic/XBtn';
import HssObjectDialog from '@/components/specific/HssObjectDialog';
import HssObjectAdder from "@/components/specific/HssObjectAdder.vue";
import testCaseInfoService from "@/js/services/TestCaseInfoService";

export default {
  name: 'HssEditor',
  components: {
    HssObjectAdder,
    XBtn,
    HssObjectDialog,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      refresh: 0,
      rowValue: null,
      dialog: false,
      focusedElementIndex: -1,
      imsi: "",
      showHssTable: false,
    };
  },
  created() {
    this.hssExists();
  },
  computed: {
    hssObject() {
      const hssObject = {};
      if (this.row) {
        hssObject['imsi'] = this.imsi;
        hssObject['imsiItems'] = this.imsiNumbers;
        hssObject['msisdn'] = this.row.msisdn;
        hssObject['subscriberName'] = `${this.row.subscriber}` ? `${this.row.msisdn}: ${this.row.subscriber}` : `${this.row.msisdn}`;
        hssObject['subscriberId'] = this.row.subscriberId;
      }
      return hssObject;
    },
    imsiNumbers() {
      const imsiNumbers = [];

      testCaseInfoService.getSubscriber(this.row.subscriberId, (subscriber) => {
        const subscriberImsi = JSON.parse(subscriber.imsi);
        for (const imsi of subscriberImsi) {
          imsiNumbers.push(imsi['value'].toString());
        }
        this.imsi = imsiNumbers.length > 0 ? imsiNumbers[0] : "";
      });
      return imsiNumbers;
    },
  },
  methods: {
    hssExists() {
      testCaseInfoService.getRawHSSObjects((this.row.subscriberId), (hss) => {
        this.showHssTable = hss.length > 0; // HSS Objekt(e) existieren
      });
      this.showHssTable = false;
    },
    addRow() {
      this.dialog = true;
    },
    refreshTable() {
      this.$refs.hssObjectAdder.$refs.xDataTableRef.loadData();
    },
    closeDialogs() {
      this.dialog = false;
      this.showHssTable = true;
      if (this.$refs.hssObjectAdder) {
        this.refreshTable();
      }
      this.$emit('close-dialogs');
    },
  },
};
</script>

<style scoped>
.hss-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>