<script>
import { defineComponent, ref, toRefs, computed  } from "vue";
import XTextField from "@/components/basic/XTextField.vue";

export default defineComponent({
  name: "ConnectionsMenu",

  components: {
    XTextField,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const activeTab = ref(0);
    const search = ref("");
    const {
      categories,
    } = toRefs(props);

    const availableSystems = computed(() => {
      const _t = activeTab.value;
      const _systems = categories.value?.[_t]?.availableSystems;
      return _systems?.filter((s) => s.text.toLowerCase().includes(search.value.toLowerCase())) || []
    })

    return {
      activeTab,
      search,
      availableSystems,
    };
  },
});
</script>

<template>
  <div class="connections-menu-promo">
    <v-divider/>

    <div class="connections-menu-promo__inner">
      <div class="connections-menu-promo__categories">
        <div class="connections-menu-promo__category-title">
          Category
        </div>

        <v-tabs v-model="activeTab" vertical>
          <v-tab
            v-for="(category, i) in categories"
            :key="i"
            @mouseover="activeTab = i"
          >
            {{ category.name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-divider vertical/>

      <div class="connections-menu-promo__recently-used-block">
        <h6 class="connections-menu-promo__recently-used-title">
          Recently Used
        </h6>

        <p class="connections-menu-promo__promo-text">
          This is not active in your current project, ask your project manager or admin for activation
        </p>
      </div>

      <v-divider vertical/>

      <div class="connections-menu-promo__available-systems">
        <div class="connections-menu-promo__category-title">
          Available Systems
        </div>

        <XTextField
          v-model="search"
          class="connections-menu-promo__search"
          placeholder="Search"
          append-icon="mdi-magnify"
          @click.native.stop
        />

        <ul class="connections-menu-promo__items-list">
          <li
            v-for="(system, i) of availableSystems"
            :key="i"
            class="connections-menu-promo__item"
          >
            {{ system.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.connections-menu-promo {
  &__inner {
    display: flex;
    width: 100%;
  }

  &__categories {
    margin-right: 1px;
  }

  &__recently-used-title {
    font-weight: 400;
    font-size: 1rem;
  }

  &__recently-used-title,
  &__category-title {
    color: var(--v-primary-base);
    letter-spacing: 0.08rem;
  }

  &__recently-used-title,
  &__category-title,
  &__available-systems {
    padding: 10px 15px;
  }

  &__available-systems {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__search {
    width: 100%;
  }

  &__promo-text {
    color: rgba(0,0,0,.6);
    font-size: .875rem;
    line-height: 1.375rem;
    letter-spacing: .0071428571em;
    max-width: 160px;
    padding: 0 8px;
  }

  &__recently-used-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--v-primary-base);
  }

  & &__items-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 237px;
    gap: 20px;
    padding: 10px 15px;
    list-style: none;
    list-style-type: none;
  }

  &__item {
    color: rgba(0,0,0,.6);
    font-size: 13px;
    font-weight: 500;
  }
}
</style>
