<template>
  <div>
    <buildFieldsRows
      :templateContent="templateContent"
      v-model="localParameters"
      languageIndex="expectStep"
    ></buildFieldsRows>
    <expectDiameterContainer
      v-if="localParameters.packetType == 'DIAMETER'"
      :groupedStepDependentInfo="groupedStepDependentInfo"
      :stepStoredValue="localStoredValue"
      :stepID="stepID"
      :result="result"
      :stepType="stepType"
      :ipcanStepTemplates="ipcanStepTemplates"
      v-on="$listeners"
      :isValid="isValid"
    ></expectDiameterContainer>
    <expectHTTPContainer
      v-else-if="localParameters.packetType == 'HTTP'"
      :groupedStepDependentInfo="groupedStepDependentInfo"
      :stepStoredValue="localStoredValue"
      :stepID="stepID"
      :result="result"
      :stepType="stepType"
      :ipcanStepTemplates="ipcanStepTemplates"
      v-on="$listeners"
      :isValid="isValid"
    ></expectHTTPContainer>
      <expectLDAPContainer
              v-if="localParameters.packetType == 'LDAP'"
              :groupedStepDependentInfo="groupedStepDependentInfo"
              :stepStoredValue="localStoredValue"
              :stepID="stepID"
              :result="result"
              :stepType="stepType"
              :ipcanStepTemplates="ipcanStepTemplates"
              v-on="$listeners"
              :isValid="isValid"
      ></expectLDAPContainer>
    <v-divider class="mb-6"></v-divider>
    <expectRadiusContainer
            v-if="localParameters.packetType == 'RADIUS'"
            :groupedStepDependentInfo="groupedStepDependentInfo"
            :stepStoredValue="localStoredValue"
            :stepID="stepID"
            :result="result"
            :stepType="stepType"
            :ipcanStepTemplates="ipcanStepTemplates"
            v-on="$listeners"
            :isValid="isValid"
    ></expectRadiusContainer>
    <onTimeOutNoResult
      v-if="localParameters['expectType'] == 'fg'"
      onEvent="On Timeout"
      v-model="onTimeout"
    ></onTimeOutNoResult>
  </div>
</template>
<script>
import expectRadiusContainer from "@/components/legacy/expectRadiusContainer.vue";
import expectDiameterContainer from "@/components/legacy/expectDiameterContainer.vue";
import expectHTTPContainer from "@/components/legacy/expectHTTPContainer.vue";
import expectLDAPContainer from "@/components/legacy/expectLDAPContainer.vue";

import testCases from "@/cfg/testCases.json";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";

export default {
  props: [
    "stepStoredValue",
    "groupedStepDependentInfo",
    "stepID",
    "result",
    "stepType",
    "ipcanStepTemplates",
    "isValid"
  ],

  components: {
    buildFieldsRows,
    expectDiameterContainer,
    expectHTTPContainer,
    expectLDAPContainer,
    expectRadiusContainer,
    onTimeOutNoResult,
  },

  computed: {
    packetType() {
      let packetType = "";
      if (
        typeof this.localStoredValue != "undefined" &&
        typeof this.localStoredValue.parameters != "undefined" &&
        typeof this.localStoredValue.parameters.packetType != "undefined"
      ) {
        packetType = this.localStoredValue.parameters.packetType;
      }
      return packetType;
    },
    onTimeout: {
      get() {
        return this.localStoredValue.onTimeout;
      },
      set(newVal) {
        this.$set(this.localStoredValue, "onTimeout", newVal);
        this.$emit("stored-value-changed", this.localStoredValue);
      },
    },
    localStoredValue: {
      get() {
        let localStoredValue = {
          check: { eval: {} },
          substeps: {},
          response: {
            parameters: {},
            req: {},
          },
          parameters: { packetType: "", expectType: "" },
        };
        if (
          typeof this.stepStoredValue != "undefined" &&
          typeof this.stepStoredValue.parameters != "undefined"
        ) {
          localStoredValue = this.stepStoredValue;
        } else {
          localStoredValue = { ...this.stepStoredValue, ...localStoredValue };
        }
        return localStoredValue;
      },
      set(newValue) {
        this.$emit("stored-value-changed", newValue);
      },
    },
    localParameters: {
      get() {
        let parameters = {};
        console.log(this.localStoredValue["parameters"]);
        if (typeof this.localStoredValue["parameters"] != "undefined") {
          parameters = { ...this.localStoredValue["parameters"] };
        }
        this.$set(
          parameters,
          "connectionId",
          this.localStoredValue.connectionId
        );

        return parameters;
      },
      set(newVal) {
        this.$set(this.localStoredValue, "connectionId", newVal.connectionId);
        this.$delete(newVal, "connectionId");
        if (this.localParameters.packetType != newVal.packetType) {
          //packet type changed. remove stored value
          this.$set(this.localStoredValue, "check", { eval: {}, DecodeAs: "JSON" });
          this.$set(this.localStoredValue, "response", {
            parameters: {},
            req: {},
          });
        }
        if (newVal["expectType"] == "fg") {
          this.$delete(newVal, "reuse");
          if (typeof newVal.wait == "undefined") {
            this.$set(newVal, "wait", 60);
          }
        } else {
          if (typeof newVal.reuse == "undefined") {
            this.$set(newVal, "reuse", 0);
          }
          this.$delete(newVal, "wait");
        }
        this.$set(this.localStoredValue, "parameters", newVal);
        this.$emit("stored-value-changed", this.localStoredValue);
      },
    },
    templateContent() {
      let attrArray = { ...testCases[this.stepType] };

      if (this.localParameters["expectType"] == "fg") {
        this.$delete(attrArray, "reuse");
      } else {
        this.$delete(attrArray, "wait");
      }
      console.log(attrArray, this.stepType);
      //  this.$set(attrArray.connectionId, "options", this.connectionsList);
      let templateContent = {};
      this.$set(templateContent, "fields", attrArray);
      this.$set(templateContent, "enableAdd", false);
      this.$set(
        templateContent,
        "disableAllInputs",
        this.result.disableAllInputs
      );
      return templateContent;
    },
    // packetType() {
    //   console.log("packetType called", this.localParameters);
    //   return this.localParameters.packetType;
    // },
  },
};

// function renderExpect(
//   $stepTypeSelector,
//   fieldsObject,
//   stepType,
//   stepStoredValue,
//   stepID,
//   additionalStepsInfo
// ) {
//   expectRenderingAttributes(
//     $stepTypeSelector,
//     fieldsObject,
//     stepType,
//     stepStoredValue,
//     stepID,
//     additionalStepsInfo
//   );
//   if (typeof stepStoredValue["parameters"] != "undefined") {
//     let $step = $stepTypeSelector.closest(".step");
//     let $packetTypeSelect = $step.find(".expectPacketType");
//     //packetTypeSelect.trigger('change');// to get the items beased on packet type!
//     renderExpectByType($packetTypeSelect);
//   }

//   let parameters = {};
//   if (typeof stepStoredValue["parameters"] != "undefined") {
//     parameters = stepStoredValue["parameters"];
//   }
//   if (parameters["expectType"] == "fg") {
//     let $requestTable = $stepTypeSelector.closest(".requestTable");
//     // render timeout
//     let $onActionContainer = $requestTable.find(".onActionContainer");
//     $onActionContainer.html("");
//     let onTimeout = {};
//     if (typeof stepStoredValue["OnTimeout"] != "undefined") {
//       onTimeout = stepStoredValue["OnTimeout"];
//     }
//     addOnTimeOutNoResult(
//       $onActionContainer,
//       "On Timeout",
//       "onTimeoutContainer",
//       onTimeout
//     );
//   }
// }

// function expectRenderingAttributes(
//   $stepTypeSelector,
//   $fieldsObject,
//   stepType,
//   stepStoredValue,
//   stepID,
//   additionalStepsInfo
// ) {
//   let $requestTable = $stepTypeSelector.closest(".requestTable");
//   let $step = $stepTypeSelector.closest(".step");
//   var $additionalFields = $requestTable.find(".additionalFields");
//   let $parentTestCasesGroup = $requestTable.closest(".testCasesGroup");

//   if (window.templates[stepType]) {
//     $additionalFields.html(
//       "<tr><td colspan=3 class=''></td></tr><tr><td colspan=3 class='commandsContainerTd'></td></tr><tr><td colspan=3 class=''><div class='line'></div></td></tr>"
//     );
//     let $commandsContainer = $(
//       "<div class='commandsContainer'></div>"
//     ).appendTo($additionalFields.find(".commandsContainerTd"));

//     let attrArray = jQuery.extend(true, {}, window.templates[stepType]);

//     prepeareCommadAttributes(
//       attrArray,
//       parameters,
//       $fieldsObject,
//       $commandsContainer
//     );
//   }
// }

// function renderExpectByType(expectPacketType) {
//   var $step = expectPacketType.closest(".step");
//   let packetType = expectPacketType.find("option:selected").val();
//   let methodVal = $step.find(".expectMethodSelector option:selected").val();
//   let packetName = "";
//   var methodName = "";
//   if (packetType != 0 && methodVal != 0) {
//     packetName = expectPacketType.find("option:selected").text();
//     methodName = $step.find(".expectMethodSelector option:selected").text();
//   }
//   //labeling the header of step
//   // expectHeader(expectPacketType, packetName, methodName);

//   let $stepTypeSelector = $step.find(".stepTypeSelector");
//   let fieldsObject = $step.closest(".fields");
//   let stepType = $step.find(".stepTypeSelector option:selected").val();
//   let $testCasesGroup = $step.closest(".testCasesGroup");
//   let stepId = $step.attr("id");
//   let stepStoredValue = getStepValue($testCasesGroup, stepId, stepType, "");
//   let additionalStepsInfo = fieldsObject.data("additionalStepsInfo");
//   let $commandsContainer = expectPacketType.closest(".commandsContainer");
//   $commandsContainer.find(".connectionContainer").remove();

//   //Expect Connection Row
//   getInboundConnectionList(
//     fieldsObject,
//     stepStoredValue,
//     additionalStepsInfo,
//     $commandsContainer,
//     packetType
//   );
//   //Clean-up the place! @sujith. please becarefull with such a statment. this will clear the objectArea in all steps
//   // fieldsObject.find('.objectArea').empty();
//   // instead in this case you want to clear only the objectArea in this step
//   $step.find(".objectArea").empty();

//   if (packetType == "DIAMETER") {
//     renderExpectDiameterAttribute(
//       $stepTypeSelector,
//       fieldsObject,
//       stepType,
//       stepStoredValue,
//       stepId,
//       additionalStepsInfo,
//       packetType
//     );
//   } else if (packetType == "HTTP") {
//     renderExpectHttpAttribute(
//       $stepTypeSelector,
//       fieldsObject,
//       stepType,
//       stepStoredValue,
//       stepId,
//       additionalStepsInfo,
//       packetType
//     );
//   } else {
//   }
// }

// function getInboundConnectionResult(
//   data,
//   stepStoredValue,
//   additionalStepsInfo,
//   $commandsContainer
// ) {
//   let connectionId = stepStoredValue["connectionId"];
//   let dataSource = data["connectionsArray"];
//   if (typeof additionalStepsInfo["connectionsArray"] != "undefined") {
//     // do we need?
//     dataSource = additionalStepsInfo["connectionsArray"];
//   }
//   let $connectionRow = $(
//     "<div class='normaltable connectionContainer'><div class='lineDiv'><div class='inlineDiv rattuxCommandName'>Connection*</div><div class='connectionValue inlineBlock'></div></div></div>"
//   ).appendTo($commandsContainer);
//   let $step = $connectionRow.find(".connectionValue").append(
//     makeSelector(dataSource, connectionId, {
//       class:
//         "required connectionSelector expectConnectionSelector extralargeinput comboBox versionSaver",
//       name: "",
//       "data-name": "inboundConnnection",
//     }) +
//       "<a href='javascript:void(0);' onclick='addInboundConnection(this);'> <i class='fas fa-plus-square fs16' ></i></a>"
//   );
//   initializeCombobox($step);
// }
</script>