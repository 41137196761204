
<template>
    <XDialog
      :value="dialog"
      :title="newHss ? 'New HSS Object' : 'Edit HSS Object'"
      :persistent="modified"
      width="800"
      @input="close"
      @save="$emit('save')"
      @click:outside="close">
      <template #dialog-content>
        <div>
          <v-form  class="content" v-model="formValid">
            <XTextField
              v-model="dataHss.subscriberName"
              label="Subscriber"
              disabled
              style="margin-top: 8px">
            </XTextField>
            <XSelect
              v-model="dataHss.imsi"
              label="IMSI"
              :items="dataHss.imsiItems"
              :rules="[required]"
              style="margin: -1px 0 10px 0; width: 100%;">
            </XSelect>
            <XTextField
              v-model="dataHss.key"
              label="Key"
              counter
              max-length="47"
              :rules="[required]"
              style="margin-top: -7px">
              <template v-slot:append-outer>
                <div class="d-flex" style="margin-top: -7px;">
                  <a title="Add">
                    <XBtn icon="mdi-auto-fix" @click="randomHashGenerator('key')" />
                  </a>                
                </div>
              </template>
            </XTextField>
            <XTextField
              v-model="dataHss.op"
              label="OP"
              counter
              max-length="47"
              :rules="[required]"
              style="margin-top: -7px">
              <template v-slot:append-outer>
                <div class="d-flex" style="margin-top: -7px;">
                  <a title="Add">
                    <XBtn icon="mdi-auto-fix" @click="randomHashGenerator('op')" />
                  </a>                
                </div>
              </template>
            </XTextField>
          </v-form>
          <UnsavedChangedDialog v-model="unsavedChangesDialog" @yes="dialog = false"/>
        </div>
        <v-spacer></v-spacer>
      </template>
      <template #dialog-bottom>
        <InputRow>
          <XTextField
              v-if="!newHss"
              label="Commit Message"
              class="commit-msg"
              placeholder="Note for versioning" />
          <XBtn
            text="Save"
            color="save"
            :disabled="!formValid || loading"
            :loading="loading"
            icon="mdi-content-save"
            @click="newHss ? createHss() : save()" />
          <XBtn
            v-if="!newHss"
            text="Save as new"
            color="save"
            icon="mdi-content-save-all"
            @click="createHss" />
          <XBtn
            text="Cancel"
            color="secondary"
            icon="mdi-cancel"
            @click="close" />
        </InputRow>
      </template>
    </XDialog>
  </template>
  
<script>
import InputRow from '@/components/basic/InputRow.vue';
import UnsavedChangedDialog from '@/components/extended/UnsavedChangesDialog';
import XDialog from '@/components/basic/XDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XBtn from '@/components/basic/XBtn.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import {deepCopy} from '@/js/general';

export default {
  name: 'HssObjectDialog',
  components: {
    InputRow,
    UnsavedChangedDialog,
    XDialog,
    XBtn,
    XSelect,
    XTextField
  },
  props: {
    value: Boolean,
    hssObject: Object,
    newHss: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataHss: {
        description: "",
        history: {
          comment: '',
          createDate: 0,
          modifyDate: 0,
          version: 1,
        },
        id: 0,
        msisdn: "",
        imsi: "",
        imsiItems: [],
        key: this.random128Hex(),
        op: this.random128Hex(),
        subscriber: "",
      },
      dialog: false,
      formValid: true,
      loading: false,
      modified: false,
      originalHss: {},
      required: value => !!value || '',
      unsavedChangesDialog: false,
    };
  },
  mounted() {
    this.originalHss = deepCopy(this.dataHss);
  },
  methods: {
    close() {
      if (!this.modified) {
        this.$emit('input', false);
        this.dialog = false;
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    createHss() {
      this.dataHss.attributes = JSON.stringify({ K: `${this.dataHss.key.replace(/\s/g, "")}`, OP: `${this.dataHss.op.replace(/\s/g, "")}` });
      this.dataHss.imsi = Number(this.dataHss.imsi);
      this.loading = true;
      testCaseInfoService.newHSSObject(this.dataHss, () => {
        this.$emit('save');
        this.modified = false;
        this.loading = false;
        this.close();
      });
    },
    randomHashGenerator(keyValue) {
      if (keyValue === "key") {
        this.dataHss.key = this.random128Hex();
      } else if (keyValue === "op") {
        this.dataHss.op = this.random128Hex();
      }
    },
    /** random 128-bit number in canonical uuid format. all bits are random. */
    random128Hex() {
      function random8Hex() {
        return (0x100 | (Math.random() * 0x100)).toString(16).substring(1);
      }
      let randomArray = [];
      for (let i = 0; i < 16; i++) {
        randomArray.push(random8Hex());
      }
      return randomArray.join(" ");
    },
    save() {
      this.dataHss.attributes = JSON.stringify({ K: `${this.dataHss.key.replace(/\s/g, "")}`, OP: `${this.dataHss.op.replace(/\s/g, "")}` });
      this.dataHss.imsi = Number(this.dataHss.imsi);
      this.loading = true;
      testCaseInfoService.saveHSSObject(
        this.dataHss.subscriberId,
        this.dataHss,
        () => {
          this.$emit('save');
          this.modified = false;
          this.loading = false;
          this.close();
      });
    },
    setDataHss(hss) {
      if (!this.newHss) {
        this.dataHss['id'] = hss.id;
        this.dataHss['key'] = hss.key;
        this.dataHss['op'] = hss.op;
      }
      this.dataHss['imsi'] = hss.imsi;
      this.dataHss['imsiItems'] = hss.imsiItems;
      this.dataHss['msisdn'] = hss.msisdn;
      this.dataHss['subscriberName'] = hss.subscriberName;
      this.dataHss['subscriberId'] = hss.subscriberId;
      this.modified = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.hssObject || !value) return;
        this.dialog = value;
        this.setDataHss(this.hssObject);
      }
    },
    dataHss: {
      deep: true,
      handler(value) {
        // Using JSON.stringify() because they are still different objects in memory
        this.modified = JSON.stringify(this.originalHss) !== JSON.stringify(value) && Object.keys(this.originalHss).length > 0;
      }
    },
    dialog: {
      immediate: true,
      handler(value) {
        if (!value) this.$emit('input', false);
      }
    },
  },
}
</script>

<style>
.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>