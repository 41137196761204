<template>
  <v-card outlined :class="typeName">
    <v-card-subtitle class="black--text bgColorBlue">{{tableTitle}}</v-card-subtitle>
    <v-card-text>
      <table class="ocsTable" :id="getCurrentTable.id">
        <tableViewBody
          :currentTable="getCurrentTable"
          :result="result"
          :selectedRows="[]"
          :search="search"
          :items="filterItem"
        ></tableViewBody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import tableViewBody from "@/commonComponents/tableViewBody.vue";
import {sortObjectSpecial} from '@/js/helper.js';

export default {
  props: ["table", "outKeyLC", "result", "search"],
  components: { tableViewBody },
  data: function () {
    return {
      typeName: this.outKeyLC,
    };
  },
  methods:{
    sortME(property) {
        return function(a, b) {
            console.log(a[property], b[property]);
            return (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        }
    }
  },
  computed: {
    tableTitle() {
      let title = this.getMappedName(this.typeName);
      const path = this.$route.path;

      if (path.includes("/5g/")) {
        const subType = path.split("/").pop().toUpperCase();
        title = `${subType} ${this.languagePack("5gTitle", "nf")}`;
      } else if (path.includes("/protocols/")) {
        if (this.typeName === "RADIUS") {
          title = title + " " + "(recommended)";
        } else if (this.typeName === "RADIUS_ACCOUNTING" || this.typeName === "RADIUS_ACCESS") {
          title = title + " " + "(legacy)";
        }
      }
      return title;
    },
    tablesData() {
      return this.$store.state.tablesData;
    },
    getCurrentTable() {
      // table data is takeken from the main tablesData storage to maintain reactivity
      let returnedTable;
      // console.log(table, outKeyLC);
      if (typeof this.tablesData[this.table.id] == "undefined") {
        this.$store.commit("tableChanged", this.table);
      }
      returnedTable = this.tablesData[this.table.id];
      return returnedTable;
    },

    filterItem() {
      // eslint-disable-next-line no-undef
      let data = structuredClone(this.getCurrentTable.data);
      if (this.search) {
        data = this.getCurrentTable.data.filter((item) => {
          let findItem =
            (typeof item.c_name != "undefined" &&
              item.c_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (typeof item.c_description != "undefined" &&
              item.c_description
                .toLowerCase()
                .includes(this.search.toLowerCase()));
          return findItem;
        });
      }
      let result = data;
      if(Object.keys(data).length>0){
        /*  const arrOfObj = Object.values(data);
          console.log(arrOfObj);
         // console.log("-data ->",data);
          let sortedArrOfObj = arrOfObj.sort(objSortBy('c_name'));
          console.log("-data ->",sortedArrOfObj);
          const arrayToObject5 = (arr) => {
              const res = {};
              arr.forEach((obj,index) => {
                  console.log(obj,index);
                  res[index] = obj;
              });
              return res
          };
          console.log(arrayToObject5(sortedArrOfObj));*/

         result = sortObjectSpecial(data,'c_name');
      }
      return result;
    },
  },
};
</script>
