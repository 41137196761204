<template>
  <XDialog
    class="bw-filter-list-dialog"
    :buttons="buttons"
    title="Configure Black/White list"
    :value="true"
    @close="() => closeModal({ persistState: false })"
    @input="() => closeModal({ persistState: false })"
  >
    <template #dialog-content>
      <h4 class="bw-filter-list-dialog__headline">
        Values of Attribute "{{ filterName }}"
      </h4>

      <XRadioGroup
        v-model="listType"
        class="bw-filter-list-dialog__radio-group"
        label="List type"
        :items="[LIST_TYPES.BLACK, LIST_TYPES.WHITE]"
        required
        :help="getHelpButtonId('list_type')"
      />

      <XSelect
        v-model="filterValues"
        class="bw-filter-list-dialog__list-editor"
        type="select"
        label="Filter value"
        :items="allFilterValues"
        multiple
        :help="getHelpButtonId('filter_values_select')"
      />
    </template>
  </XDialog>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount } from "vue"
import XDialog from '@/components/basic/XDialog.vue';
import XRadioGroup from '@/components/basic/XRadioGroup.vue';
import XSelect from "@/components/basic/XSelect.vue"
import { LIST_TYPES, useBlackWhiteFiltersList } from "@/composition/project-tags/use-black-white-list";
import { isEmptyObject } from "@/js/helper"
import chartService from '@/js/services/ChartService';
import { useHelpButton } from "@/composition/help/use-help-button"

const HELP_BTN_PREFIX = "Black_White_lists_dialog"

export default defineComponent({
  name: "BlackWhiteListsDialog",
  components: {
    XDialog,
    XRadioGroup,
    XSelect,
  },
  props: {
    filterName: String,
    filterConfig: Object,
    possibleFilterValues: Array,
    closeMethod: Function,
  },
  setup(props) {
    const [[_listType, _filterValues] = []] = Object.entries(props.filterConfig)
    const listType = ref(_listType || "")
    const filterValues = ref(_filterValues || [])
    const allFilterValues = ref(props.possibleFilterValues)
    const {
      createFullFilterConfig,
    } = useBlackWhiteFiltersList()
    const {
      getHelpButtonId,
    } = useHelpButton({ prefix: HELP_BTN_PREFIX })

    const noValuesWasAdded = computed(() => filterValues.value.length === 0)
    const isEmptyValues = computed(() => filterValues.value.every(v => !v || isEmptyObject(v)))
    const isTheSameValues = computed(() =>
      filterValues.value.length === _filterValues?.length && filterValues.value.every(v => _filterValues?.includes(v)) && _listType === listType.value)

    const result = computed(() => {
      const nothingChanged = noValuesWasAdded.value || isEmptyValues.value || isTheSameValues.value

      if (nothingChanged) {
        return null
      }

      return createFullFilterConfig(props.filterName, listType.value, filterValues.value)
    })

    const closeModal = ({ persistState = true } = {}) => {
      if (persistState) {
        props.closeMethod(result.value)
      } else {
        props.closeMethod(null)
      }
    }

    const buttons = [
      {
        text: "Save",
        icon: "mdi-content-save",
        color: "save",
        textColor: "white",
        click: () => closeModal()
      },

      {
        text: "Cancel",
        icon: "mdi-cancel",
        color: "secondary",
        textColor: "white",
        click: () => closeModal({ persistState: false }),
      },
    ]

    onBeforeMount(async () => {
      if (allFilterValues.value.length === 0)  {
        const res = await chartService.getTagValues(props.filterName)
        allFilterValues.value = res.values
      }
    })

    return {
      allFilterValues,
      listType,
      LIST_TYPES,
      filterValues,
      closeModal,
      buttons,
      getHelpButtonId,
    }
  },
})
</script>

<style lang="scss">
.bw-filter-list-dialog {
  &__headline {
    margin-bottom: 16px;
  }

  &__radio-group {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
