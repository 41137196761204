<template>
  <div class="explorer-map-tag-editor">
    <XSelect
      :value="currentTag"
      :items="possibleTags"
      label="Tag"
      @input="(tagName) => newTagSelect(tagName)"
    />

    <v-fade-transition>
      <HeadlineBox
        v-if="tagValueColumn.items.length > 0"
        headline="Filters"
      >
        <ListEditor
          :value="currentTagValues"
          :columns="[tagValueColumn, ...cols]"
          @input="(v) => updateCurrentTagValues(v)"
        />
      </HeadlineBox>
    </v-fade-transition>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onBeforeMount,
  shallowReactive,
  ref
} from "vue"
import HeadlineBox from "@/components/basic/HeadlineBox.vue";
import XSelect from '@/components/basic/XSelect.vue';
import ListEditor from '@/components/basic/ListEditor.vue';
import { validateLatitude, validateLongitude } from "@/js/validators"

const cols = [
  {
    label: "Latitude",
    value: "lat",
    type: "float",
    rules: [(v) => validateLatitude(v) || "Invalid latitude"]
  },
  {
    label: "Longitude",
    value: "lon",
    type: "float",
    rules: [(v) => validateLongitude(v) || "Invalid longitude"],
  }
]

export default defineComponent({
  name: "ExplorerByMapFilterEditor",
  components: {
    HeadlineBox,
    ListEditor,
    XSelect,
  },
  props: {
    mapConfiguration: Object,
    tags: Object,
  },
  emits: ["update:map-configuration"],
  setup(props, { emit }) {
    const tagValueColumn = shallowReactive({
      label: "Tag value",
      value: "value",
      type: "select",
      items: []
    })

    const sortTagValues = (nameA, nameB) => {
      const a = nameA.toLowerCase()
      const b = nameB.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0;
    }

    const currentTag = ref(props.mapConfiguration?.key || "")

    const possibleTags = computed(() => {
      return Object.keys(props.tags || {})
    })

    const currentTagValues = computed(() => {
      if (currentTag.value && currentTag.value === props.mapConfiguration?.key) {
        return props.mapConfiguration?.values || []
      }
      return []
    })

    const updatePossibleTagValues = () => {
      if (currentTag.value) {
        const items = props.tags[currentTag.value] || [];
        items.sort(sortTagValues)
        tagValueColumn.items = items
      }
    }

    const parseLocationToFloat = loc => {
      let _l = parseFloat(loc)
      if (Number.isNaN(_l)) _l = 0.0
      return _l
    }

    const updateCurrentTagValues = values => {
      for (const v of values) {
        if (typeof v.lat === "number" && typeof v.lon === "number") {
          continue
        }
        v.lon = parseLocationToFloat(v.lon)
        v.lat = parseLocationToFloat(v.lat)
      }
      emit("update:map-configuration", {
        key: currentTag.value,
        values,
      })
    }

    const newTagSelect = async tagName => {
      currentTag.value = tagName
      updatePossibleTagValues()
      emit("update:map-configuration", {
        key: tagName,
        values: currentTagValues.value,
      })
    }

    onBeforeMount(() => {
      updatePossibleTagValues()
    })

    return {
      updateCurrentTagValues,
      currentTag,
      cols,
      possibleTags,
      currentTagValues,
      tagValueColumn,
      newTagSelect,
    }
  },
})
</script>

<style lang="scss">
.explorer-map-tag-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
