<template>
  <div>
    <addField
      :path="fieldAttributes['addingURL']"
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="fieldAttributes['addingDialogOptions']"
      :showDialog="openDialog"
      @dialog-closed="openDialog = false"
    >
    </addField>
    <v-icon @click="checkAdd">mdi-plus-box-outline</v-icon>
    <noticeDialog
      v-model="categoryMissing"
      title="Notice"
      message="
        Select Category First
      "
      :action="true"
      maxWidth="250"
    ></noticeDialog>
  </div>
</template>
<script>
import noticeDialog from "@/commonComponents/noticeDialog.vue";
import addField from "@/commonComponents/addField.vue";
export default {
  data() {
    return { categoryMissing: false, openDialog: false };
  },
  components: { addField, noticeDialog },
  props: [
    "result",
    "fieldAttributes",
    "field",
    "updatedDialogRef",
    "additionalAttributes",
    "category",
  ],
  computed: {
    additionlAddingOptions() {
      return this.fieldAttributes.additionlAddingOptions;
    },
  },
  methods: {
    checkAdd() {
      this.openDialog = true;
      // if (this.additionalAttributes.c_category[0] != "") {
      //   this.openDialog = true;
      // } else {
      //   this.categoryMissing = true;
      // }
    },
  },
};

// function addingSubscriberFromTestCase(
//   caller,
//   uri,
//   additionalOptions,
//   dialogOptions
// ) {
//   let $caller = $(caller);
//   let $subscriberContainer = $caller.closest(".subscriberContainer");
//   if ($subscriberContainer.length == 0) {
//     throw "subscriberContainer is not found";
//   }
//   let subscriberType = $subscriberContainer.find(".subscriberTypeSelect").val();
//   let category = $subscriberContainer.find(".categorySelector").val();
//   additionalOptions["n_type"] = subscriberType;
//   additionalOptions["c_category"] = '["' + category + '"]';
//   return AddFieldUpdateSelect(caller, uri, additionalOptions, dialogOptions);
// }
</script>