<template>
  <div> Explorer:
    <span @click="dialog = true" class="explorer-link">{{ computedExplorerName }}</span>
    <LoadingDialog v-model="loading"/>
    <div v-if="dialog">
      <ExplorerDialog v-model="dialog" v-if="dialog" :explorer-name="computedExplorerName"/>
    </div>
  </div>
</template>

<script>
import ExplorerDialog from '@/components/specific/Explorer/Dialog/ExplorerDialog.vue';
import LoadingDialog from '@/components/basic/LoadingDialog';

export default {
  name: 'ExplorerLink',
  components: {
    LoadingDialog,
    ExplorerDialog,
  },
  props: {
    row: Object,
    explorerName: String,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      explorerData: null,
      explorerStatus: null,
    };
  },
  computed: {
    computedExplorerName() {
      return this.explorerName ? this.explorerName : this.row.c_subscriber;
    },
  },
};
</script>

<style scoped>
.explorer-link {
  cursor: pointer;
  color: var(--v-anchor-base);
}
</style>