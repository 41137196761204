<template>
  <div>
    <div v-if="attributes.length" class="list">
      <div v-for="(attribute, i) of attributes" :key="i">
        <div class="index-row">
          <x-text-field v-model="attribute.index" :rules="rules.unique" class="index-text-field" label="Index"
                        validate-immediately/>
          <div class="value">
            <div v-for="(attributeValue, j) of attribute.values" :key="j" class="value-row">
              <x-text-field v-model="attribute.values[j]" :rules="rules.required" class="value-text-field" label="Value"
                            validate-immediately/>
              <x-btn v-if="attribute.values.length > 1" :tooltip="`This will delete
                     ${attributeValue ? `the value '${attributeValue}'` : 'this empty value'} of
                     ${attribute.index ? `index '${attribute.index}'` : 'this empty index'}.`" class="delete-button"
                     color="red" icon="mdi-delete" @click="deleteValueRow(i, j)"/>
              <div v-else class="delete-button-placeholder"/>
              <v-btn v-if="parseInt(j) === 0" :disabled="!valid" class="add-value-button" color="primary"
                     @click="addValue(i)">
                <v-icon left>mdi-plus</v-icon>
                Add value
              </v-btn>
              <div v-else class="add-value-button-placeholder"/>
            </div>
          </div>
          <x-btn
              :tooltip="`This will delete the index ${attribute.index ? `'${attribute.index}` : ''} and all of its values.`"
              class="delete-button" color="red" icon="mdi-delete" @click="deleteIndexRow(i)"/>
        </div>
        <div class="list-separator"/>
      </div>
    </div>
    <v-btn :disabled="!valid" color="primary" @click="addIndex">
      <v-icon left>mdi-plus</v-icon>
      Add index
    </v-btn>
  </div>
</template>

<script>
import XTextField from '@/components/basic/XTextField.vue';
import XBtn from '@/components/basic/XBtn.vue';
import { getRequiredRule } from "@/js/general";

export default {
  name: 'AdditionalHttpHeaderAttributes',
  components: {XBtn, XTextField},
  props: {
    value: Object,
    valid: Boolean,
  },
  data() {
    return {
      attributes: [],
      rules: {
        required: [getRequiredRule()],
        unique: [
          getRequiredRule(), v => this.hasNoDuplicates(v) || 'Duplicate index.',
        ],
      },
      dataValid: this.valid,
      getRequiredRule: getRequiredRule,
      oldValue: null,
    };
  },
  watch: {
    value(value) {
      if (JSON.stringify(value) !== JSON.stringify(this.oldValue)) {
        this.convertToComponentStructure();
        this.oldValue = value;
      }
    },
    attributes: {
      deep: true,
      handler(value) {
        this.$emit('revalidate');
        const structuredValue = {};
        for (const valueRow of value) {
          structuredValue[valueRow.index] = valueRow.values;
        }
        this.$nextTick(() => {
          if (this.valid) this.$emit('input', structuredValue);
        });
      },
    },
  },
  created() {
    this.convertToComponentStructure();
  },
  methods: {
    addIndex() {
      this.attributes.push({index: '', values: ['']});
    },
    addValue(i) {
      this.attributes[i].values.push('');
    },
    deleteIndexRow(i) {
      this.attributes.splice(i, 1);
    },
    deleteValueRow(i, j) {
      this.attributes[i].values.splice(j, 1);
    },
    convertToComponentStructure() {
      const attributes = [];
      if(typeof this.value!='undefined'){
          for (const [index, values] of Object.entries(this.value)) {
              let localValue = values;
              if (!Array.isArray(localValue)) {
                  localValue = [localValue];
              }
              attributes.push({index: index, values: localValue});
          }
      }
      this.attributes = attributes;
    },
    hasNoDuplicates(v) {
      const filtered = this.attributes.filter(x => x.index === v);
      console.log(filtered);
      return filtered.length <= 1;
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.index-row {
  display: flex;
  gap: 10px;
}

.index-row > .index-text-field {
  flex-basis: calc((100% - 232px) / 2 - 7px);
}

.index-row > .value {
  flex-basis: calc(100% - (100% - 232px) / 2 - 7px);
}

.value {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.value-row {
  display: flex;
  gap: 10px;
}

.value-text-field {
  flex: 1 1 auto;
}

.delete-button {
  margin-top: 2px;
}

.delete-button-placeholder {
  margin-top: 2px;
  width: 36px;
  height: 36px;
}

.add-value-button {
  margin-top: 2px;
  width: 140px;
}

.add-value-button-placeholder {
  margin-top: 2px;
  width: 140px;
  height: 36px;
}

.list-separator {
  margin-top: 10px;
  border-top: 1px solid #9e9e9e;
}
</style>