<template>
  <v-row no-gutters>
    <div class="mr-2">
      <defaultFieldTypes
          :fieldAttributes="result.fields['c_mccmnc']"
          field="c_mccmnc"
          :templateContent="result"
          :value="result.valueLine.c_mccmnc"></defaultFieldTypes>
    </div>
    <defaultFieldTypes
        :fieldAttributes="result.fields['c_mnp_aging']"
        field="c_mnp_aging"
        :templateContent="result"
        :value="result.valueLine.c_mnp_aging"></defaultFieldTypes>
  </v-row>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  props: ["result"],
  components: { defaultFieldTypes },
};
</script>