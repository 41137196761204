import { render, staticRenderFns } from "./DashboardWidgetDialog.vue?vue&type=template&id=35574364&scoped=true"
import script from "./DashboardWidgetDialog.vue?vue&type=script&lang=js"
export * from "./DashboardWidgetDialog.vue?vue&type=script&lang=js"
import style0 from "./DashboardWidgetDialog.vue?vue&type=style&index=0&id=35574364&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35574364",
  null
  
)

export default component.exports