<template>
  <div>
    <XBtn icon="mdi-logout" @click="openDialog"/>
    <XDialog
        v-if="dialog" v-model="dialog" title="Packet" width="1000" content-height="686" :corner-buttons="cornerButtons">
      <template #dialog-content>
        <XTree v-if="packet && !showLoading" :value="packet" :headers="headers"/>
        <XThrobber centered v-else/>
      </template>
    </XDialog>
  </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn.vue';
import XDialog from '@/components/basic/XDialog.vue';
import XTree from '@/components/basic/XTree.vue';
import XThrobber from '@/components/basic/XThrobber.vue';
import {
  dateToIsoDateTimeString,
  deepCopy,
  downloadStringAsFile,
  getValueByFunctionOrDirectly,
  unixToDateTimeString,
} from '@/js/general';

export default {
  name: 'PacketIcon',
  components: {
    XThrobber,
    XTree,
    XDialog,
    XBtn,
  },
  props: {
    resultIndex: Number,
    itemsRequest: Function,
    itemsParams: [Object, Function],
    count: Number,
  },
  data() {
    return {
      packet: null,
      dataResultIndex: 0,
      dialog: false,
      downloadStringAsFile: downloadStringAsFile,
      loading: false,
      showLoading: false,
      headers: [
        {
          type: 'value',
          value: 'protocol',
        },
        {
          type: 'pair',
          text: 'Received Time',
          value: 'receivedTime',
          formatter: v => unixToDateTimeString(v / 1000000),
        },
        {
          type: 'pair',
          text: 'Source',
          value: 'clientIp',
        },
        {
          type: 'pair',
          text: 'Active Probe',
          value: 'activeProbe.name',
        },
        {
          type: 'pair',
          text: 'Server',
          value: 'server',
          formatter: v => `${v.ip}:${v.port}`,
        },
        {
          type: 'json',
          text: 'Data',
          value: 'data',
          formatter: v => {
            const formattedValue = JSON.parse(v);
            if (formattedValue.data) {
              const cleanedData = formattedValue.data.replace(/\\n/g, '\n').replace(/\\t/g, '\t').replace(/\\"/g, '"');
              formattedValue.data = JSON.parse(cleanedData);
            }
            return JSON.stringify(formattedValue);
          },
        },
      ],
      cornerButtons: [
        {
          icon: 'mdi-arrow-left-circle',
          click: () => {
            if (this.dataResultIndex <= 0) {
              this.dataResultIndex = this.count - 1;
            } else {
              this.dataResultIndex--;
            }
            this.loadData();
          },
        },
        {
          icon: 'mdi-arrow-right-circle',
          click: () => {
            if (this.dataResultIndex >= this.count - 1) {
              this.dataResultIndex = 0;
            } else {
              this.dataResultIndex++;
            }
            this.loadData();
          },
        },
        {
          icon: 'mdi-file-download',
          click: () => {
            const data = JSON.parse(this.packet.data);
            if (data.data) {
              const cleanedData = data.data.replace(/\\n/g, '\n').replace(/\\t/g, '\t').replace(/\\"/g, '"');
              data.data = JSON.parse(cleanedData);
            }

            const fileContent = 'Acctopus Degust Packet Information (https://degust.eu.acctopus.com)\n' +
                `Packet Type: ${this.packet.protocol}\n` +
                `Source: ${this.packet.clientIp}\n` +
                `Destination: ${this.packet.server.ip}:${this.packet.server.port}\n` +
                `Time: ${unixToDateTimeString(this.packet.receivedTime / 1000000)}\n\n` +
                JSON.stringify(data, null, 2);

            this.downloadStringAsFile(`Packet-${dateToIsoDateTimeString(new Date())}.txt`, fileContent);
          },
        },
      ],
    };
  },
  watch: {
    loading(value) {
      if (value) {
        setTimeout(() => {
          if (this.loading) {
            this.showLoading = true;
          }
        }, 2000);
      } else {
        this.showLoading = false;
      }
    },
  },
  methods: {
    openDialog() {
      this.dataResultIndex = this.resultIndex;
      this.loadData();
      this.dialog = true;
    },
    loadData() {
      this.loading = true;
      const params = deepCopy(getValueByFunctionOrDirectly(this.itemsParams));
      params.page = this.dataResultIndex + 1;
      params['items-per-page'] = 1;
      this.itemsRequest(params, (packets) => {
        this.packet = packets[0];
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>