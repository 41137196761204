<template>
  <UiInputWrapper
    class="cron-editor"
    label="Cron Syntax"
    :is-loading="isLoading"
    :errors="errors"
  >
    <textarea
      v-model="cronValue"
      class="cron-editor__textarea"
      autocomplete="off"
      rows="5"
      placeholder=" "
    />

    <template #right>
      <div class="cron-editor__help-btn" :data-help="help">
        <HelpButton :help="help" />
      </div>
    </template>
  </UiInputWrapper>
</template>

<script>
import { defineComponent, ref, computed, onActivated, onMounted } from "vue"
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
import { debounce } from "lodash-es"
import cockpitTimeSchedulingService from '@/js/services/CockpitTimeSchedulingService';
import HelpButton from '@/components/basic/HelpButton.vue';

const DEFAULT_HELP = "Cron_syntax_text_area_editor"
const ERRORS = {
  INVALID_CRON: "Invalid cron syntax",
}

export default defineComponent({
  name: "CronEditor",

  components: {
    UiInputWrapper,
    HelpButton,
  },

  props: {
    cronString: {
      type: String,
      required: true,
    },

    help: {
      type: String,
      default: DEFAULT_HELP,
    }
  },

  emits: ["update:cronString", "start-typing", "end-typing"],

  setup(props, { emit }) {
    const errors = ref("")
    const isLoading = ref(false)
    const isTyping = ref(false)

    const checkCronSyntax = (v) => cockpitTimeSchedulingService.checkCronSyntax(v)

    const formatCron = (s) => {
      return s.replace('\n', '\\n')
    }

    const debouncedCronCheckSyntax = debounce(async (cronStr) => {
      isLoading.value = true
      await checkCronSyntax(cronStr).then(() => {
        errors.value = ""
      }).catch(() => {
        errors.value = ERRORS.INVALID_CRON
      }).finally(() => {
        isLoading.value = false
        isTyping.value = false
        emit("end-typing", { isValid: !errors.value })
      });
    }, 600)

    const cronValue = computed({
      get() {
        return props.cronString
      },
      set(newCron) {
        if (newCron === props.cronString || typeof newCron !== "string") {
          return
        }

        if (!isTyping.value) {
          isTyping.value = true
        }

        emit("update:cronString", formatCron(newCron))
        emit("start-typing", { isValid: false })
        return debouncedCronCheckSyntax(newCron)
      }
    })

    onMounted(() => {
      if (props.cronString) {
        emit("start-typing", { isValid: false })
        debouncedCronCheckSyntax(props.cronString)
      }
    })

    onActivated(() => {
      if (props.cronString) {
        emit("start-typing", { isValid: false })
        debouncedCronCheckSyntax(props.cronString)
      } else {
        errors.value = ""
      }
    })

    return {
      cronValue,
      isLoading,
      errors,
    }
  }
})
</script>

<style lang="scss">
.cron-editor {
  &__help-btn {
    padding-top: 4px;
  }
}
</style>
