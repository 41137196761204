<script setup>
  import { defineProps, defineEmits, defineExpose } from "vue"

  // type Props = {
  //   modelValue: string[]
  // }

  const props = defineProps({
    modelValue: {
      type: Array,
      required: true,
    },
    noWrap: {
      type: Boolean,
      default: false,
    }
  })

  const emit = defineEmits(["update:modelValue"])

  const deleteChip = (index) => {
    const copy = [...props.modelValue]
    copy.splice(index, 1)
    emit("update:modelValue", copy)
  }

  defineExpose({
    deleteChip,
  })
</script>

<template>
  <div
    :class="{
      'ui-chips': true,
      'ui-chips--no-wrap': noWrap,
    }"
  >
    <span
      v-for="(chip, i) of modelValue"
      :key="`${i}modelValue`"
      class="ui-chips__a-chip"
      @click.prevent.stop
    >
      {{ chip }}

      <button
        class="ui-chips__del-btn"
        :data-index="i"
        @click.prevent.stop="() => deleteChip(i)"
        type="button"
      >
        <v-icon :size="18">mdi-close-circle</v-icon>
      </button>
    </span>

    <div
      v-if="$slots['input-slot']"
      class="ui-chips__input-slot"
    >
      <slot name="input-slot" />
    </div>

    <!-- <input -->
    <!--   ref="inputRef" -->
    <!--   :value="userInput" -->
    <!--   class="ui-chips__input" -->
    <!--   :placeholder="placeholder" -->
    <!--   @input="(e) => handleInput(e)" -->
    <!--   @keypress.enter="() => addChip()" -->
    <!--   @keydown.delete="(e) => deleteTxt(e)" -->
    <!-- > -->
  </div>
</template>

<style lang="scss">
  $chip-border-radius: 0.75rem;
  $chip-font-size: 0.75rem;
  $chip-height: 1.5rem;
  $chip-border-color: rgba(0, 0, 0, 0.12);

  .ui-chips {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    gap: 3px;
    padding-bottom: 4px;

    &--no-wrap {
      flex-wrap: nowrap;
    }

    &__a-chip {
      position: relative;
      display: inline-flex;
      //min-width: .25rem;
      align-items: center;
      gap: 4px;
      height: $chip-height;
      padding: 0 0.75rem;
      vertical-align: middle;
      border-radius: $chip-border-radius;
      font-size: $chip-font-size;
      color: rgba(0, 0, 0, 0.87);
      border-color: $chip-border-color;
      background-color: #e0e0e0;
      white-space: nowrap;
//      overflow: hidden;
      overflow-wrap: break-word;
      cursor: default;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: currentColor;
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 0.04;
        }
      }
    }

    &__del-btn {
      position: relative;
      z-index: 1;
      display: inline-flex;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
      pointer-events: auto;
    }

    &__input-slot {
      flex: 1 1 auto;
      width: 30px;

      input {
        width: 100%;
        border: none;
        outline: none;
        padding: 4px;
      }
    }
  }
</style>
