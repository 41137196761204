<template>
  <div class="mb-2">
     <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      addButtTitle="Add Organization"
      linePrefix="userOrgTypeAdder"
      :isValid="isValid"
    ></lineFieldsArray>
    <input type="hidden" name="userOrgTypeAdder" :value="JSON.stringify(pairArray)" />
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import options from "@/cfg/options.json";
export default {
  components: {
    lineFieldsArray,
  },
  // data: function () {

  //   return {
  //     pairArray: this.value,
  //   };
  // },
  props: ["field", "fieldAttributes", "result", "value","isValid"],
  computed: {
    pairArray: {
      get() {
        let pairArray = [];
        if (typeof this.value != "undefined" && this.value != "") {
          pairArray = this.value;
        }
        //console.log(pairArray);
        //console.log(this.result.organisationList);
        return pairArray;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    fields() {
      let fields = {
        c_organisation: {
          type: "combobox",
          name: "Organization",
          associatedOption: this.result.organisationList,
          fieldAttrInput: { class: "required" },
        },
        n_type: {
          type: "select",
          name: "Role",
          associatedOption: options.userType,
          fieldAttrInput: { class: "required" },
        },
        n_active: {
          type: "select",
          name: "Status",
          associatedOption: options.userStatus,
          fieldAttrInput: { class: "required" },
        },
        remove: {
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      return fields;
    },
  },
  methods: {
    checkInput(value) {
      this.pairArray = [...value];
    },
  },
};
</script>