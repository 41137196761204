<template>
  <div class="x-checkbox-group" :data-help="computedHelp">
    <h4>{{ label }}</h4>
    <XCheckbox
        v-for="(item, i) of items"
        :value="value ? value.includes(item.value) : false"
        :key="i"
        :label="item.text"
        @input="handleInput(item.value, $event)"/>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>

<script>
import XCheckbox from '@/components/basic/XCheckbox';
import HelpButton from '@/components/basic/HelpButton.vue';
import {deepCopy} from '@/js/general';

export default {
  name: 'XCheckboxGroup',
  components: {
    XCheckbox,
    HelpButton,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: String,
    items: Array,
    id: String,
    help: String,
  },
  methods: {
    handleInput(key, value) {
      let newValue = deepCopy(this.value);
      if (!newValue) newValue = [];
      if (value && !newValue.includes(key)) newValue.push(key);
      else {
        const index = newValue.findIndex(x => x === key);
        if (index >= 0) {
          newValue.splice(index, 1);
        }
      }
      this.$emit('input', newValue);
    },
  },
  computed: {
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
};
</script>

<style scoped>
.x-checkbox-group {
  display: flex;
  align-items: center;
  gap: 7px;
}

h4 {
  font-weight: normal;
}

.x-checkbox-group >>> .help-button {
  margin-top: 2px;
}
</style>