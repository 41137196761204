<template>
  <div class="mt-2">
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="localFields"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="headerTitle"
      :addable="addable"
      :templateContent="result"
      :updateOnChange="true"
      :isValid="isValid"
      :linePrefix="linePrefix"
      @revalidate="$emit('revalidate')"
      :hideDetails="hideDetails"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: { lineFieldsArray },
  data() {
    return {};
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    hideDetails: { type: Boolean, default: false },
    addable: { type: Boolean, default: true },
    headerTitle: { type: Boolean },
    indexName: { default: "Index" },
    fields: {},
    result: {},
    isValid: {
      type: Boolean,
    },
    linePrefix: { type: String, default: "" },
  },
  computed: {
    localFields() {
      let fields;
      if (typeof this.fields == "undefined") {
        fields = {
          index: {
            type: "text",
            name: this.indexName,
            fieldAttrInput: { class: "required" },
          },
          value: {
            type: "text",
            name: "Value",
            fieldAttrInput: { class: "required" },
          },
          remove: lineButtons.remove,
          source: {
            type: "hidden",
            colAttrs: { class: "d-none" },
            default: this.attrSource,
          },
        };
      } else {
        fields = this.fields;
      }

      this.$set(fields.remove, "source", this.attrSource);

      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        for (const [key, value] of Object.entries(this.value)) {
          let readOnly = false;
          if (!this.addable) {
            readOnly = true;
          }
          pairArray.push({ index: key, value: value, readOnly: readOnly }); //intentional space removed by trim!
        }
        return pairArray;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    checkInput(value) {
      let sentValue = {};
      let update = true;
      let caller = this;
      //in this case obj cant sort we introduce the space,which latterly become string,so obj keep the position
      value.forEach(function (info) {
        if (typeof sentValue[info.index] != "undefined") {
          update = false;
          return;
        }
        caller.$set(sentValue, info.index, info.value);
      });
      if (update) {
        this.$emit("input", sentValue);
      }
    },
  },
};
</script>