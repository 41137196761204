<template>
  <div>
     <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        associatedOption: associatedOption,
        ignoreEmptyAdd:true,
        fieldAttrInput: { ...fieldAttributes.fieldAttrInput, disabled: disableProject  } }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="value"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import requests from '@/js/requests';
import useBrowserStorageModel from "@/composition/browser-storage"

export default defineComponent({
  components: { defaultFieldTypes },

  props: ["fieldAttributes", "field", "result", "value"],

  setup() {
    const {
      model: lastProjectId,
    } = useBrowserStorageModel("localStorage", "last-project-id", {})

    const setNewLastProjectId = (orgId, projectId) => {
      lastProjectId.value = {
        ...lastProjectId.value,
        [orgId]: projectId
      }
    }

    return {
      lastProjectId,
      setNewLastProjectId
    }
  },

  data() {
    return {
      associatedOption: this.fieldAttributes.associatedOption,
      disableProject: false,
    };
  },

  async mounted() {
    let orgId = this.result.valueLine.organisations;
    await this.init(orgId);
  },

  watch: {
    value(newValue, oldValue) {
      const currentOrgId = this.result.valueLine.organisations
      if (newValue !== oldValue && this.lastProjectId[currentOrgId] !== newValue) {
        this.setNewLastProjectId(currentOrgId, newValue)
      }
    },
    async "result.valueLine.organisations"(orgId) {
      await this.init(orgId)
    },
  },

  methods: {
    async init(orgId) {
      const { projects, currentProjectId } = await this.fetchProjects(orgId);

      this.associatedOption = projects;

      if (this.lastProjectId[orgId]) {
        this.emitProjectId(this.lastProjectId[orgId])
      } else {
        this.emitProjectId(currentProjectId);
      }
    },

    emitProjectId(projectId) {
      this.$emit("input", projectId);
    },

    fetchProjects(orgId) {
      this.disableProject = true;
      return new Promise((resolve) => {
        const options = {
          function: "getOrganisationProjects",
          requestType: "ajax",
          orgaID: orgId,
        };

        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=administration&f2=projectAdministration',
          data: options,
        })
          .then((response) => {
            resolve({
              projects: response.data.result.projects,
              currentProjectId: response.data.result.currentDomainId,
            })
          })
          .catch((response) => {
            console.error(response);
          })
          .finally(() => {
            this.disableProject = false;
          });
      })
    },
  },
});
</script>
