<template>
  <div>
    <a @click="reboot">
      <v-list-item data-type="rebootExplorer">
        <v-list-item-title>
          <v-card class="pa-2 mt-3 elevation-0">
            <v-icon class="mr-2 mt-n1">mdi-restart</v-icon>
            {{ language.componentLabel.rebootExplorer }}
          </v-card>
        </v-list-item-title>
      </v-list-item>
    </a>
    <x-dialog v-model="okDialog"/>
  </div>
</template>

<script>

import XDialog from '@/components/basic/XDialog';
import explorerStatusService from "@/js/services/ExplorerStatusService";

export default {
  components: {XDialog},
  props: ['info', 'actionName', 'currentTable', 'row', 'rowindex', 'tableIcon', 'result'],
  data() {
    return {
      okDialog: false,
      okDialogText: '',
    };
  },
  methods: {
    reboot() {
      const name = this.row['c_name'];
      this.$store.commit('updateTableDialog', {
        active: true,
        title: 'Confirm reboot',
        text: `Really reboot '${name}'?`,
        width: 350,
        yes: () => {
          this.$store.commit('notification', {
            text: `Sent reboot request to Explorer '${name}'. Please wait.`,
            length: 5000,
          });
          explorerStatusService.reboot(name, (response) => {
            if (response.status) {
              this.$store.commit('notification', {
                text: `Explorer '${name}' is rebooting.`,
                length: 5000,
              });
            } else {
              this.$store.commit('notification', {
                text: `Explorer could not reboot.`,
                length: 5000,
              });
            }
          }, () => {
            this.$store.commit('notification', {
              text: `Explorer could not reboot.`,
              length: 5000,
            });
          });
        },
      });
    },
  },
};
</script>

<style scoped>

</style>