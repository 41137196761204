<template>
  <div>
    <fieldsRows
        v-if="templateContent != null"
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="valueLine"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import requests from '@/js/requests';
function getTestCaseOptions(Category) {
  return new Promise((resolve) => {
    let options = {
      function: "getTestcaseListByCat",
      cat: [...Category],
    };
    requests.frameworkAxiosRequest({
      method: 'post',
      url: 'serve.php?f=testing&f2=configureAndRunTests',
      data: options,
    })
        .then(function (response) {
          resolve(response.data.result.json.testcaseList);
        })
        .catch(function () {
          //handle error
        });
  });
}
export default {
  components: { fieldsRows },
  data() {
    return {};
  },
  props: ["result", "stepStoredValue"],
  asyncComputed: {
    async testCaseOptions() {
      return await getTestCaseOptions(this.category);
    },
  },
  computed: {
    valueLine() {
      return {
        testCategory: this.category,
        testcaseId: this.stepStoredValue.testcaseId,
      };
    },
    customCategoryOptions(){
      let options = this.result.additional.domainTestGroups;
      //if(this.checkStencil(this.result)){ // in any case no stencil tagged test case
      options = options.filter(function (el){
          return el.toLowerCase()!=="stencil";
        });
      //}
      return options;
    },
    templateContent() {
      let templateContent = {
        fields: {
          testCategory: {
            type: "combobox",
            name: "Category",
            associatedOption: this.customCategoryOptions,
            fieldAttrInput: {
              multiple: true,
              class: "required",
              chips: true,
            },
          },
          testcaseId: {
            type: "combobox",
            name: "Test",
            fieldAttrInput: {
              class: "required",
            },
          },
        },
      };
      templateContent.fields.testcaseId.associatedOption = this.testCaseOptions;
      return templateContent;
    },
    category() {
      let category = [];
      if (
          typeof this.stepStoredValue.Filter != "undefined" &&
          typeof this.stepStoredValue.Filter.Category != "undefined"
      ) {
        category = this.stepStoredValue.Filter.Category;
      }
      return category;
    },
  },
  methods: {
    updateFieldValue(field, value) {
      //console.log(field, value, text);
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.Filter == "undefined") {
        this.$set(localStoredStep, "Filter", {});
      }
      if (field == "testCategory") {
        this.$set(localStoredStep.Filter, "Category", value);
      }
      if (field == "testcaseId") {
        // localStoredStep.testcaseName = text;
        let testcaseName = this.testCaseOptions.find(a => a[0] == value)[1];
        this.$set(localStoredStep, "testcaseName", testcaseName);
        this.$set(localStoredStep, field, value);
      } else {
        this.$set(localStoredStep, field, value);
      }
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>