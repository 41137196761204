<template>
  <div>
    <v-data-table :headers="computedHeaders" :items="dataItems" :search="search" sort-by="name"
                  class="x-data-table elevation-1" :loading="loading">
      <template v-slot:top>
        <div class="table-top">
          <v-btn color="primary" :disabled="rowEditingDisabled" @click="edit(0)">
            <v-icon left dark>mdi-plus</v-icon>
            New {{ itemName }}
          </v-btn>
          <span class="table-title" v-text="title"/>
          <v-spacer/>
          <v-text-field v-model="search" label="Search" outlined dense hide-details class="search"
                        append-icon="mdi-magnify"/>
        </div>
      </template>
      <template v-slot:[`item.enabled`]="{ item }">
        <x-switch v-model="item.enabled" @input="toggleItem(item.id)" :disabled="item.toggling"/>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-for="action in combinedActions" :key="action.icon" :disabled="action.disabled" icon
               @click="action.click(item.id)">
          <v-icon>{{ action.icon }}</v-icon>
        </v-btn>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="combinedAdditionalActions.length" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-hover v-slot="{ hover }" v-for="additionalAction in combinedAdditionalActions"
                     :key="additionalAction.icon">
              <v-list-item @click="additionalAction.click(item.id)"
                           :style="!hover ? '' : additionalAction.hoverStyle.item">
                <v-list-item-title>
                  <v-icon left :style="!hover ? '' : additionalAction.hoverStyle.icon" class="additional-action-icon">
                    {{ additionalAction.icon }}
                  </v-icon>
                  {{ additionalAction.text }}
                </v-list-item-title>
              </v-list-item>
            </v-hover>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <form-dialog v-model="editDialog" :item="item" :item-name="itemName" :item-id="itemId" :width="dialogWidth"
                 :custom-valid="customValid" @reload="$emit('dialog-reload', $event)" @created="handleCreated"
                 @updated="handleUpdated" @open="$emit('dialog-open')" @close="$emit('dialog-close')"
                 @before-save="$emit('dialog-before-save')">
      <template v-slot:dialog-content>
        <slot name="form"/>
      </template>
    </form-dialog>
    <really-delete-dialog v-model="reallyDeleteDialog" :item-name="selectedItemName" @yes="deleteItem"/>
  </div>
</template>

<script>
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog';
import XSwitch from '@/components/basic/XSwitch';
import FormDialog from '@/components/extended/FrameworkFormDialog';
import requests from "@/js/requests";

export default {
  name: 'FrameworkDataTable',
  components: {FormDialog, ReallyDeleteDialog, XSwitch},
  props: {
    items: Array,
    title: String,
    item: Object,
    itemName: String,
    headers: Array,
    rowSwitch: Boolean,
    actions: Array,
    additionalActions: Array,
    apiUrl: String,
    rowEditingDisabled: Boolean,
    dialogWidth: {Number, String},
    customValid: Boolean,
  },
  data() {
    return {
      defaultActions: [
        {icon: 'mdi-pencil', disabled: true, click: this.edit},
      ],
      defaultAdditionalActions: [
        {
          icon: 'mdi-delete',
          text: 'Delete',
          hoverTextColor: 'white',
          hoverBackgroundColor: '#b82519',
          click: this.openDeleteDialog,
        },
      ],
      combinedActions: [],
      combinedAdditionalActions: [],
      dataItems: this.items != null ? [...this.items] : [],
      editDialog: false,
      reallyDeleteDialog: false,
      search: '',
      itemId: 0,
      loading: true,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    items(value) {
      this.dataItems = value;
    },
    rowEditingDisabled(value) {
      for (let i = 0; i < this.defaultActions.length; i++) {
        if (this.defaultActions[i].icon === 'mdi-pencil') {
          this.defaultActions[i].disabled = value;
        }
      }
    },
    actions: {
      immediate: true,
      deep: true,
      handler() {
        this.combineActions();
      },
    },
    additionalActions: {
      immediate: true,
      deep: true,
      handler() {
        this.combineAdditionalActions();
      },
    },
  },
  computed: {
    computedHeaders() {
      const headers = [];
      if (this.rowSwitch) headers.push({text: '', value: 'enabled', sortable: false, width: '16'});
      if (this.combinedActions.length || this.combinedAdditionalActions.length) {
        let length = this.combinedActions.length;
        if (this.combinedAdditionalActions.length) length++;
        let width = length * 36;
        if (width < 36) width = 32;
        headers.push({text: '', value: 'actions', sortable: false, width: width});
      }
      headers.push(...this.headers);
      return headers;
    },
    selectedItemName() {
      const item = this.getItemById(this.itemId);
      if (item) return item.name;
      return '';
    },
  },
  methods: {
    getItems() {
      requests.frameworkGetRequest(this.apiUrl, 'getItems', null, (items) => {
        if (this.rowSwitch) {
          for (let i = 0; i < items.length; i++) {
            this.prepareItemForTable(items[i]);
          }
        }
        this.dataItems = items;
        this.$emit('input', items);
        this.loading = false;
      });
    },
    edit(itemId) {
      this.itemId = itemId;
      this.editDialog = true;
    },
    handleCreated(newItem) {
      this.editDialog = false;
      this.prepareItemForTable(newItem);
      this.dataItems.push(newItem);
      this.$emit('input', this.dataItems);
    },
    handleUpdated(updatedItem) {
      this.editDialog = false;
      const itemInTable = this.getItemById(updatedItem.id);
      if (itemInTable) {
        const index = this.dataItems.indexOf(itemInTable);
        this.prepareItemForTable(updatedItem);
        this.dataItems.splice(index, 1, updatedItem);
        this.$emit('input', this.dataItems);
      }
    },
    openDeleteDialog(id) {
      this.itemId = id;
      this.reallyDeleteDialog = true;
    },
    deleteItem() {
      const id = this.itemId;
      requests.frameworkDeleteRequest(undefined, 'deleteItem', {id: id}, () => {
        this.dataItems.splice(this.dataItems.indexOf(this.getItemById(id)), 1);
        this.$emit('input', this.dataItems);
      });
    },
    toggleItem(id) {
      const itemById = this.getItemById(id);
      this.dataItems[this.dataItems.indexOf(itemById)].toggling = true;
      const func = itemById.enabled ? 'enableItem' : 'disableItem';
      requests.frameworkPatchRequest(undefined, func, {id: id}, () => {
        this.dataItems[this.dataItems.indexOf(itemById)].toggling = false;
        this.$emit('input', this.dataItems);
      });
    },
    getItemById(id) {
      return this.dataItems.find(item => item.id === id);
    },
    prepareItemForTable(item) {
      if (!this.rowSwitch) return;
      if (!('enabled' in item)) item.enabled = true;
      item.toggling = false;
    },
    combineActions() {
      if (this.actions) this.combinedActions = this.defaultActions.concat(this.actions);
      else this.combinedActions = this.defaultActions;
    },
    combineAdditionalActions() {
      let combinedAdditionalActions = this.defaultAdditionalActions;
      if (this.additionalActions) combinedAdditionalActions = this.defaultAdditionalActions.concat(this.additionalActions);
      for (let i = 0; i < combinedAdditionalActions.length; i++) {
        const additionalAction = combinedAdditionalActions[i];
        if (!('hoverTextColor' in additionalAction)) {
          additionalAction.hoverTextColor = 'inherit';
        }
        if (!('hoverBackgroundColor' in additionalAction)) {
          additionalAction.hoverBackgroundColor = '#eeeeee';
        }
        if (!('hoverStyle' in additionalAction)) {
          additionalAction.hoverStyle = {
            item: `color: ${additionalAction.hoverTextColor}; background-color: ${additionalAction.hoverBackgroundColor};`,
            icon: `color: ${additionalAction.hoverTextColor};`,
          };
        }
        combinedAdditionalActions[i] = additionalAction;
      }
      this.combinedAdditionalActions = combinedAdditionalActions;
    },
  },
};
</script>

<style scoped>
.table-top {
  display: flex;
  padding: 16px 16px 0 16px;
  gap: 20px;
  align-items: center;
}

.table-title {
  font-size: 20px;
  font-weight: 500;
}

.search {
  max-width: 400px;
}

.v-list-item__title {
  display: flex;
  align-items: center;
}

.additional-action-icon {
  transition: none !important;
}

/deep/ .x-data-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-right: 0;
}

/deep/ .x-data-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  padding-left: 0;
  padding-right: 0;
}
</style>