<template>
  <div>
    <v-divider></v-divider>
    <v-row class="mt-3 d-flex">
      <v-col style="max-width:485px" v-if="showExpected">
        <associatedOption
            :fieldAttributes="{ name: 'Expected Result', options: this.expectedOptions }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            :value="expectedResulValue"
            @input="updateField('expectedResult', ...arguments)"
            :templateContent="result"
            field="expectedResult"
        ></associatedOption>
      </v-col>
      <v-col cols="1" class="mt-2" style="max-width: 80px;"> On Error</v-col>
      <v-col style="max-width:350px" class="ml-n2 mr-n3">
        <associatedOption
            :fieldAttributes="{ name: 'Result', options: options.ldapResult }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            :value="resultFieldValue"
            @input="updateField('result', ...arguments)"
            :templateContent="result"
            field="result"
        ></associatedOption>
      </v-col>
      <v-col style="max-width:200px" class="mr-n3">
        <associatedOption
            :fieldAttributes="{ name: 'Action', options: options.evalAction }"
            type="select"
            :fieldAttrInput="{ class: 'required' }"
            :value="actionFieldValue"
            @input="updateField('action', ...arguments)"
            :templateContent="result"
            field="action"
        ></associatedOption>
      </v-col>
      <v-col style="max-width:250px">
        <primaryTextfield
          label="Info"
          type="text"
          name="info"
          :fieldAttrInput="{ maxlength:100  }"
          :fieldAttributes="{  }"
          :templateContent="result"
          :hideDetails="false"
          @input="updateField('info', ...arguments)"
          :value="infoFieldValue"
      ></primaryTextfield>
      </v-col>

      <HelpButton id="onError_block" class="mt-3 ml-n3"></HelpButton>
    </v-row>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import associatedOption from "@/commonComponents/associatedOption.vue";
import HelpButton from "@/components/basic/HelpButton.vue";
//import XTextField from '@/components/basic/XTextField';
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";

export default {
  data() {
    return {
      options: options,
      permittedSteps:["Explorer_cellular_data_connect","Explorer_cellular_attach","Explorer_insert_sim"]
    };
  },
  components: {
    associatedOption,
    HelpButton,
    //XTextField,
    primaryTextfield

  },
  props: ["field", "fieldAttributes", "value", "result"],
  computed: {
    expectedOptions(){
      let optionList =  this.options.expectedResultConnect;
      if(this.result.stepType=="Explorer_cellular_voice_call" || this.result.stepType=="Explorer_cellular_receive_call" ){
        optionList = this.options.expectedResultCall;
      }else if (this.result.stepType=="Explorer_cellular_attach"){
        optionList = this.options.expectedResultAttach;
      }else if (this.result.stepType=="Explorer_insert_sim"){
        optionList = this.options.expectedResultSimInsert;
      }
      return optionList;
    },
    showExpected() {
      return this.permittedSteps.includes(this.result.stepType);
    },
    expectedResulValue() {
      let expectedResulValue = "connected";
      if (typeof this.value != "undefined" && typeof this.value.expectedResult != "undefined") {
        expectedResulValue = this.value.expectedResult;
      }
      return expectedResulValue;
    },
    actionFieldValue() {
      let action = "stopTest";
      if (typeof this.value != "undefined" &&
          typeof this.value.onError != "undefined" &&
          typeof this.value.onError.action != "undefined"
      ) {
        action = this.value.onError.action;
      }
      return action;
    },
    infoFieldValue() {
      let info = "";
      if (typeof this.value != "undefined" &&
          typeof this.value.onError != "undefined" &&
          typeof this.value.onError.info != "undefined"
      ) {
        info = this.value.onError.info;
      }
      return info;
    },
    resultFieldValue() {
      let result = "runtime_error";
      if (typeof this.value != "undefined" &&
          typeof this.value.onError != "undefined" &&
          typeof this.value.onError.result != "undefined"
      ) {
        result = this.value.onError.result;
      }
      return result;
    }
  },
  methods: {
    updateField(index, newVal) {
      let localValue = {...this.value};
      if (index=='expectedResult' ) { //step:  Explorer Cellular Data Connect
        this.$set(localValue, index, newVal);
      } else {
        if(typeof localValue.onError=="undefined"){ // rare case it can be undefined
           localValue = {...this.value,onError:{}};
        }
        //console.log(localValue);
        this.$set(localValue.onError, index, newVal);
      }
      this.$emit("input", localValue);
    },
  },
};
</script>