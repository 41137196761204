<template>
  <div>
    <FormDialog
        :value="value"
        @input="$emit('input', $event)"
        title="Select Device Pool"
        save-text="Assign"
        save-icon="mdi-view-grid-plus"
        save-color="primary"
        width="350"
        @save="confirmDialog = true">
      <template #dialog-content>
        <XSelect
            v-model="devicePool"
            label="Device Pool"
            :items="devicePools"
            item-text="name"
            item-value="id"
            required/>
      </template>
    </FormDialog>
    <YesNoDialog
        v-model="confirmDialog"
        text="This action will transfer the Explorer's status to the starting point of the new Business Flow. All existing Business Flow information will be erased. Do you want to proceed?"
        title="Confirm"
        width="350"
        @yes="assign"/>
    <LoadingDialog v-model="loading"/>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect.vue';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';
import FormDialog from '@/components/extended/FormDialog.vue';
import YesNoDialog from '@/components/extended/YesNoDialog.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';

export default {
  name: 'AssignToDevicePoolDialog',
  components: {
    LoadingDialog,
    YesNoDialog,
    FormDialog,
    XSelect,
  },
  props: {
    value: Boolean,
    explorerIds: Array,
  },
  data() {
    return {
      devicePool: 0,
      devicePools: [],
      confirmDialog: false,
      loading: false,
    };
  },
  created() {
    cockpitExplorerService.getDevicePools((devicePools) => {
      this.devicePools = devicePools;
    });
  },
  methods: {
    assign() {
      this.loading = true;
      cockpitExplorerService.assignToDevicePool(this.explorerIds, this.devicePool, () => {
        this.$emit('input', false);
        this.$emit('assigned');
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>