<template>
  <div>
    <v-row no-gutters v-for="(val, index) in localValue" :key="index">
      <v-col>
        <primaryTextfield
          :value="val"
          :fieldAttrInput="{
            class: 'required',
            id: fieldAttributes.fieldAttrInput.id + '_' + index,
          }"
          @change="updateSingleValue(index, ...arguments)"
          :templateContent="result"
          :label="label"
        ></primaryTextfield>
      </v-col>
      <v-col cols="1" style="min-width: 80px">
        <trash
          class="ml-2 mt-2"
          :size="20"
          @trash-clicked="removeValue(index)"
          :result="result"
           :id="fieldAttributes.fieldAttrInput.id+ '_' + index + '_remove'"
        ></trash>
        <v-icon
          v-if="index == localValue.length - 1"
          class="mt-2"
          @click="addValue()"
          :disabled="localDisabled"
          :id="fieldAttributes.fieldAttrInput.id + '_adder'"
          >mdi-plus</v-icon
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import trash from "@/commonComponents/dynamic/trash.vue";

export default {
  components: { primaryTextfield, trash },
  data() {
    return {
      label: "",
    };
  },
  props: ["result", "fieldAttributes", "value"],
  computed: {
    localDisabled() {
      let localDisabled = this.false;

      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        localDisabled = true;
      }

      return localDisabled;
    },
    localValue: {
      get() {
        let localValue = this.value;
        if (!Array.isArray(this.value)) {
          localValue = [this.value];
        }
        //console.log(localValue);
        return localValue;
      },
      set(newValue) {
        this.updateVal(newValue);
      },
    },
  },
  methods: {
    removeValue(index) {
      this.localValue.splice(index, 1);
      this.updateVal(this.localValue);
    },
    updateSingleValue(index, value) {
      this.$set(this.localValue, index, value);
      this.updateVal(this.localValue);
    },
    updateVal(newValue) {
      let sentValue;
      if (newValue.length == 0) {
        sentValue = "";
        this.$emit("click", "trash");
      } else {
        if (newValue.length == 1) {
          sentValue = newValue[0];
        } else {
          sentValue = newValue;
        }
        this.$emit("input", sentValue);
      }
    },
    addValue() {
      this.localValue.push("");
      this.updateVal(this.localValue);
    },
  },
};
</script>
