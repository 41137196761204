<template>
  <div v-if="localDisabled">
    <addField
        path="serve.php?f=testing&f2=reporting"
        :additionalAttributes="additionalAttributes"
        :dialogAttributes="{}"
        :showDialog="openDialogFlag"
        @dialog-closed="openDialogFlag == false"
    >
      <div class="mr-4 d-flex">
        <v-icon class="mr-1">mdi-file-pdf-box</v-icon>
        <div class="">{{ language.componentLabel.labelReport }}</div>
      </div>
    </addField>
  </div>

</template>
<script>
import addField from "@/commonComponents/addField.vue";

export default {
  data() {
    return {openDialogFlag: false};
  },
  props: ["result", "additional"],
  components: {addField},
  computed: {
    additionalAttributes() {
      let selected = Object.keys(this.additional.selectedRows);
      return {
        n_testgroup_id: this.result["groupID"],
        selected: selected,
        updateTable: "false",
        function: "getInsertedInstantReporting",
      };
    },
    localDisabled() {
      return Object.keys(this.additional.selectedRows).length > 0 ? true : false;
    }
  },
};
</script>