<script setup>
import { defineProps, defineEmits, computed, ref } from "vue"
import UiSearchInput from "@/components/basic/form/UiSearchInput.vue"

// type Option = string | { value: string, id: string }

const stringToItem = (option, idx) => ({
  value: option,
  id: `${idx}-${option}`,
})

const props = defineProps({
  // Option[]
  options: {
    type: Array,
    default: () => [],
  },
  hints: {
    type: String,
    default: "",
  },
  errors: {
    type: String,
    default: ""
  },
  allowCustom: {
    type: Boolean,
    default: false
  },
  withSearch: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(["select"])

const searchPlaceholder = props.allowCustom ? "Search or add new" : "Search"

const isOptionObject = computed(() => {
  return typeof props.options[0] === "object"
})

const searchTxt = ref("")
const _searchedOptions = ref([])
const _selectOptions = computed(() => {
  if (!props.options) {
    return []
  }
  if (isOptionObject.value) {
    return props.options
  }
  return props.options.map(stringToItem)
})
const selectOptions = computed(() => {
  if (_searchedOptions.value.length > 0 || searchTxt.value.length > 0) {
    return _searchedOptions.value
  }
  return _selectOptions.value
})

const resetSearch = () => {
  searchTxt.value = ""
  _searchedOptions.value = []
}

const updateSearchTxt = (txt) => {
  if (txt.length === 0) {
    resetSearch()
    return
  }
  searchTxt.value = txt
  _searchedOptions.value = _selectOptions.value.filter((opt) =>
    opt.value.toLowerCase().includes(txt.toLowerCase().trim()),
  )
}

const emitSelect = (opt) => {
  if (isOptionObject.value) {
    emit("select", opt)
    return
  }

  emit("select", opt.value)
}

const onEnterPress = () => {
  const _val = searchTxt.value.trim()

  if (!_val) {
    return
  }

  let option = _selectOptions.value.find((opt) => opt.value === _val)

  if (!option && props.allowCustom) {
    option = stringToItem(_val, _selectOptions.value.length)
  }

  if (!option && !props.allowCustom) {
    return
  }

  emitSelect(option)
  searchTxt.value = ""
}
</script>

<template>
  <div
    class="ui-select-2"
    @click.stop
  >
    <div
      v-if="withSearch"
      class="ui-select-2__top-block"
    >
      <div
        class="ui-select-2__search-box"
        @keyup.enter.stop="() => onEnterPress()"
      >
        <UiSearchInput
          class="ui-select-2__search"
          :label="searchPlaceholder"
          :model-value="searchTxt"
          with-clear
          hints="Press Enter to add"
          @update:modelValue="(txt) => updateSearchTxt(txt)"
        />
      </div>
    </div>

    <ul class="ui-select-2__options">
      <li
        v-for="opt of selectOptions"
        :key="opt.id"
        class="ui-select-2__option"
      >
        <button
          class="ui-select-2__option-btn"
          type="button"
          @click="() => emitSelect(opt)"
        >
          {{ opt.value }}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.ui-select-2 {
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  box-shadow: -2px 0 4px -1px rgba(0, 0, 0, 0.2), 2px 0 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  padding-bottom: .25rem;

  &__top-block {
    min-height: 74px;
  }

  &__search-box {
    position: absolute;
    z-index: 10;
    padding: 0 1rem 0.25rem;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    --input-slot-width: 100%;
  }

  &__options {
    overflow: auto;
    max-height: 300px;
    list-style: none;
  }

  &__option {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__option-checkbox {
    width: 100%;
  }

  &__option-btn {
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;
    user-select: none;
    color: rgba(0,0,0,.6);
  }
}
</style>


