<template>
  <v-row
    style="max-width: 970px"
    class="explorerTagFilterContainerClass mt-7"
    no-gutters
  >
    <v-col class="mr-2">
      <explorerStatusFilter
        :currentTable="currentTable"
        :result="result"
      ></explorerStatusFilter>
    </v-col>
    <v-col class="mr-2" md="4">
      <associatedOption
        :fieldAttributes="{
          name: 'Tags Filter',
          associatedOption: result.domainTestGroups,
        }"
        type="tagCombo"
        :value="groupFilterExplorer"
        @input="updateFilter(...arguments)"
        :fieldAttrInput="{
          multiple: true,
          chips: true,
          class: 'tagContainerAdjust',

          id: 'explorerTagFilter',
        }"
        :disabled="localDisabled"
      >
        <template v-slot:selection="slotProps">
          <v-chip
            label
            x-small
            close
            @click.stop="updateOperation(slotProps.item, slotProps.index)"
            @click:close="removeValue(slotProps.item)"
          >
            <span :class="getColor(slotProps.item)">{{
              slotProps.item.text
            }}</span>
          </v-chip>
        </template>
      </associatedOption>
    </v-col>

    <v-col class="mr-2" md="3">
      <searchInput
        v-model="localSearch"
        :key="currentTable.id"
        ref="explorerSearch"
        id="explorerSearchInput"
      ></searchInput>
    </v-col>
    <v-col class="explorerClearButt" cols="1" style="max-width: 170px">
      <primaryButton
        label="Clear Filter"
        icon="mdi-filter-remove"
        @click.prevent="applyFilter('showAll')"
        type="button"
        color="primary"
        id="explorerFilterClear"
        shrink="lgAndDown"
      ></primaryButton>
    </v-col>
  </v-row>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import searchInput from "@/commonComponents/searchInput.vue";
import explorerStatusFilter from "@/components/legacy/explorerStatusFilter.vue";

export default {
  data() {
    return {
      operations: {
        0: { title: "OR", color: "blue" },
        1: { title: "AND", color: "green" },
        2: { title: "NOT", color: "red" },
      },
      localDisabled: false,
    };
  },
  components: {
    associatedOption,
    primaryButton,
    searchInput,
    explorerStatusFilter,
  },
  props: ["result", "currentTable"],
  created() {
    this.$root.$refs.explorerTagFilter = this;
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "tableChanged") {
        this.localDisabled = false;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  computed: {
    groupFilterExplorer: {
      get() {
        let groupFilterExplorer = [];
        if (
          typeof this.currentTable.groupFilterExplorer != "undefined" &&
          Array.isArray(this.currentTable.groupFilterExplorer)
        ) {
          this.currentTable.groupFilterExplorer.forEach((element) => {
            if (
              typeof element["data-operation"] == "undefined" &&
              element == "object"
            ) {
              element["data-operation"] = 1;
            }
            groupFilterExplorer.push({ text: element.value, ...element });
          });
        }
        return groupFilterExplorer;
      },
      set(newFilter) {
        let filters = [];
        if (Array.isArray(newFilter)) {
          newFilter.forEach((value) => {
            if (typeof value == "object") {
              //console.log(value);
              filters.push(value);
            }
          });
        }
        this.$set(this.currentTable, "groupFilterExplorer", filters);
      },
    },
    localSearch: {
      get() {
        return this.currentTable.searchValue;
      },
      set(newVal) {
        this.$set(this.currentTable, "searchValue", newVal);
      },
    },
  },
  methods: {
    removeValue(item) {
      let clickedIndex = this.groupFilterExplorer.findIndex(
        (x) => x.value === item.value
      );
      this.groupFilterExplorer.splice(clickedIndex, 1);
      this.$set(
        this.currentTable,
        "groupFilterExplorer",
        this.groupFilterExplorer
      );
      this.applyFilter(this.groupFilterExplorer);
    },
    updateFilter(value, text, info) {
      console.log(value);
      console.log(text);
      console.log(info);
      console.log(this.groupFilterExplorer);
      this.$set(this, "groupFilterExplorer", info);
      //let result = this.result;
      this.$set(
        this.currentTable,
        "groupFilterExplorer",
        this.groupFilterExplorer
      );
      this.applyFilter(this.groupFilterExplorer);
    },

    updateOperation(item, index) {
      //console.log(item);
      let currentOperationIndex = 1;
      if (typeof item["data-operation"] != "undefined") {
        currentOperationIndex = item["data-operation"];
      }
      currentOperationIndex++;
      if (currentOperationIndex >= Object.keys(this.operations).length) {
        currentOperationIndex = 0;
      }
      this.$set(item, "data-operation", currentOperationIndex);
      this.$set(this.groupFilterExplorer, index, item);
      this.applyFilter(this.groupFilterExplorer);
    },
    getColor(item) {
      //console.log(item);
      let currentOperationIndex = 0;
      if (typeof item["data-operation"] != "undefined") {
        currentOperationIndex = item["data-operation"];
      }
      return this.operations[currentOperationIndex].color + "--text";
    },
    applyFilter(filters) {
      console.log(filters);
      if (filters == "showAll") {
        let shortURL = "?f=administration&f2=explorers";
        this.$set(
          this.currentTable,
          "tableUrl",
          "serve.php?f=administration&f2=explorers"
        );
        this.$set(this.currentTable, "tableShortUrl", shortURL);
           history.pushState({}, null, shortURL);
        this.$set(this.currentTable, "online", "");
         this.$nextTick(function () {
         this.$set(this.currentTable, "online", false);
        });
        this.$set(this.currentTable, "groupFilterExplorer", "");
        this.$set(this.currentTable, "selectedStatus", "all");
        this.$set(this.currentTable, "searchValue", "");
      }
      this.$store.commit("tableChanged", this.currentTable);
    },
  },
};
</script>
<style>

</style>