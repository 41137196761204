<template>
    <div>
        <RadiusPacket
                v-model="responsePacket"
                :attributes="reqAttributes"
                :template-rows="reqTemplateRows"
                title="Response Packet"
                @input="updateResponse"
        >
            <template #top>
                <step_connectionTemplateBlockInbound
                        v-if="localData !== null"
                        :connectionOption="false"
                        :templateOption="localData.options.templateId"
                        :templateValue="response.parameters.templateId"
                        :localData="localData"
                        @value-changed="updateTemplate"
                        :addUrlAttr="{   subType: 'RADIUS', category: 'Protocols',  stepType: 'RADIUS', }"
                        :dialogRef="result.dialogRef"
                        :result="result"
                ></step_connectionTemplateBlockInbound>
            </template>
        </RadiusPacket>
    </div>
</template>
<script>
import dictionaryService from "@/js/services/DictionaryService";
    import step_connectionTemplateBlockInbound from "@/components/legacy/step_connectionTemplateBlockInbound.vue";
    import RadiusPacket from '@/components/specific/TestStep/RadiusPacket.vue';

    export default {
        props: ["localData", "stepStoredValue", "result", "stepType", "isValid"],
        components: {
            step_connectionTemplateBlockInbound,
            RadiusPacket
        },
        data() {
            return {
                reqAttributes: [],
            };
        },
        mounted() {
            this.getAttributes();
        },
        computed: {
            responsePacket: {
                get() {
                    let responsePacket = {deactivated: [], add: {}};
                    if (typeof this.stepStoredValue.response.req.add !== 'undefined') {
                        responsePacket = this.stepStoredValue.response.req;
                    }
                    return responsePacket
                },
                set(newValue) {
                    return newValue
                }
            },
            response() {
                let response = {parameters: {}};
                if (typeof this.stepStoredValue.response != "undefined") {
                    response = this.stepStoredValue.response;
                }
                if (typeof this.stepStoredValue.response.parameters == "undefined") {
                    this.$set(response, "parameters", {});
                    this.$set(response, "req", {});
                }
                return response;
            },
            reqTemplateRows() {
                let reqTemplateRows = {};
// second condition:templateId!=="undefined"  prevent to show default template rows without selection of template!
                if (this.localData != null && typeof this.response.parameters.templateId !== "undefined") {
                    let packetData = JSON.parse(this.localData.templates.packet);
                    reqTemplateRows = packetData['RADIUS'].req.attributes;
                }
                return reqTemplateRows;
            },
        },
        methods: {
            updateResponse(response) {
                let localStoredStep = {...this.stepStoredValue};
                this.$set(localStoredStep.response, "req", response);
                this.$emit("stored-value-changed", localStoredStep);
            },

            getAttributes() {
                dictionaryService.getRadiusAttributes((attributes) => {
                    this.reqAttributes = attributes;
                });
            },
            updateTemplate(field, value) {
                let localStoredStep = {...this.stepStoredValue};
                if (localStoredStep == null) {
                    localStoredStep = {};
                }
                if (
                    typeof localStoredStep.response != "undefined" &&
                    typeof localStoredStep.response.parameters != "undefined" &&
                    typeof localStoredStep.response.parameters.templateId != "undefined" &&
                    localStoredStep.response.parameters.templateId != value
                ) {
//template id is changed. reset request body
                    this.$set(localStoredStep.response, "req", {});
                }
                this.$set(localStoredStep.response.parameters, field, value);
                this.$emit("stored-value-changed", localStoredStep);
            },
        },
    };
</script>