<template>
  <XDataTable :headers="headers" :items-request="itemsRequest">
    <template #[`item.message.message`]="{value}">
      <pre class="kafka-text-area" v-html="prettyPrintJSON(JSON.stringify(value))"></pre>
    </template>
  </XDataTable>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import { prettyPrintJSON, dateTimeToDateTimeStringMilliseconds } from "@/js/general";

export default {
  name: 'LogView',
  components: {XDataTable},
  data() {
    return {
      dateTimeToDateTimeStringMilliseconds: dateTimeToDateTimeStringMilliseconds,
      headers: [
        {
          text: 'Time',
          value: 'time',
          formatter: v => this.dateTimeToDateTimeStringMilliseconds(v),
          width: 200,
        },
        {
          text: 'ID',
          value: 'message.id',
          width: 300,
        },
        {
          text: 'Message',
          value: 'message.message',
        },
      ],
      prettyPrintJSON: prettyPrintJSON,
    };
  },
  methods: {
    itemsRequest(params, then) {
      then(this.$store.state.log);
    },
  },
};
</script>

<style scoped>

</style>