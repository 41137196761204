<template>
  <div>
    <addField
      path="serve.php?f=testing&f2=configureAndRunTests"
      :additionalAttributes="{
        updateTable: false,
        function: 'getInsertedFieldsEdit',
        n_id: row.n_tc_id,
        n_id_key: row.n_tc_id_key,
      }"
      :dialogAttributes="{}"
      v-on="$listeners"
      @dialog-closed="updatedTableAfterSave"
    >
     <template v-if="currentTable.header.n_tc_id.associatedOption[row.n_tc_id]!=='undefined'">
       {{ currentTable.header.n_tc_id.associatedOption[row.n_tc_id].name }}
     </template>
    </addField>
  </div>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
import { setAndUpdateTable } from "@/js/helper.js";
import requests from '@/js/requests';
export default {
  components: { addField },
  props: ["row", "currentTable"],
  methods: {
    updatedTableAfterSave() {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url:
            typeof this.currentTable != 'undefined' &&
            typeof this.currentTable.tableUrl != 'undefined'
                ? this.currentTable.tableUrl
                : '',
        data: {
          function: 'getTableContent',
          requestType: 'ajax',
        },
      }).then(function (response) {
        //handle success
        setAndUpdateTable(
          caller.currentTable,
          response.data.result.table,
          caller
        );
        caller.$store.commit("tableChanged", response.data.result.table);
      });
    },
  },
};
</script>