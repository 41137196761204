<script setup>
  import { ref, computed, defineProps, defineEmits } from "vue"
  import draggable from "vuedraggable"
  import XCheckbox from "@/components/basic/XCheckbox.vue"
  import UiSearchInput from "@/components/basic/form/UiSearchInput.vue"

  /**
   * @typedef {Object} Field
   * @property {string} name - The name of the field.
   * @property {boolean} enabled - Indicates whether the field is enabled.
   * @property {string} id - The unique identifier for the field.
   * @property {string} frozen - The field cannot be moved or enabled/disabled.
  */

  const props = defineProps({
    /** @type {import('vue').PropOptions<Field[]>} */
    fieldsData: {
      type: Array,
      required: true,
    },
    inRow: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(["update:fieldsData"])
  const _fields = ref(props.fieldsData)
  /*
  * @type {Ref<Field[]>}
  */
  const searchResult = ref([])
  const _searchStr = ref("")
  const searchStr = computed({
    get: () => _searchStr.value,
    set: (value) => {
      _searchStr.value = value
      if (value.length === 0) {
        searchResult.value = []
        return
      }
      searchResult.value = _fields.value.filter((f) =>
        f.name.toLowerCase().includes(value.toLowerCase()),
      )
    },
  })

  const fields = computed({
    get: () => {
      if (!searchStr.value) {
        return _fields.value
      }
      return searchResult.value
    },
    set: (value) => {
      _fields.value = sortEnabledFirst(value)
      emit("update:fieldsData", value)
    },
  })

  const sortEnabledFirst = (list) => {
    return list.sort((a, b) => {
      if (a.enabled && !b.enabled) {
        return -1
      }
      if (!a.enabled && b.enabled) {
        return 1
      }
      return 0
    })
  }

  const updateField = (field, list) => {
    const index = list.findIndex((f) => f.id === field.id)
    if (index === -1) {
      return list
    }
    list[index] = field
    return list
  }

  const toggleField = (field) => {
    if (searchStr.value.length) {
      searchResult.value = [...updateField(field, searchResult.value)]
    }
    fields.value = [...updateField(field, _fields.value)]
  }

  const onMove = (e) => {
    return !e.draggedContext.element?.frozen
  }
</script>

<template>
  <div class="ui-fields-controller">
    <draggable
      v-model="fields"
      :disabled="searchStr.length"
      item-key="id"
      ghost-class="ui-fields-controller__drag-ghost"
      handle=".handle"
      :move="(e) => onMove(e)"
      :class="{
        'ui-fields-controller__draggable': true,
        'ui-fields-controller__draggable--row': inRow,
        'ui-fields-controller__draggable--search': withSearch,
      }"
    >
      <template
        v-if="withSearch"
        #header
      >
        <div class="ui-fields-controller__search-box">
          <div class="ui-fields-controller__search">
            <UiSearchInput
              :model-value.sync="searchStr"
              label="Search"
              with-clear
            />
          </div>
        </div>
      </template>

      <!-- <div> -->
        <div
          v-for="field in fields"
          :key="field.id"
          :class="{
            'draggable': true,
            'ui-fields-controller__item-box': true,
            'ui-fields-controller__item-box--no-top-border': withSearch,
          }"
        >
         <v-icon class="ui-fields-controller__handle handle">
            mdi-drag-vertical
          </v-icon>

          <XCheckbox
            :value="field.enabled"
            @input="() => toggleField({ ...field, enabled: !field.enabled })"
            :label="field.name"
            :disabled="field.frozen"
          />
        </div>
      <!-- </div> -->
    </draggable>
  </div>
</template>

<style lang="scss">
  .ui-fields-controller {
    display: flex;
    width: 100%;

    &__draggable {
      display: flex;
      flex-direction: column;
      width: 100%;

      max-height: 65vh;
      overflow: auto;
    }

    &__search-box {
      min-height: 60px;
    }

    &__search {
      position: absolute;
      z-index: 10;
      padding: 0 0.75rem 0.5rem;
      background-color: #fff;
    }

    &__handle {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }

    &__item-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;
      padding: 0.5rem 0.75rem;
      background-color: #ffffff;
    }

    &__drag-ghost {
      background-color: #e0e0e0;
      opacity: 0.5;
    }
  }
</style>
