<template>
  <v-row v-bind="fieldAttrRow" no-gutters>
    <input
        type="hidden"
        name="c_obj"
        :value="JSON.stringify(localValue)"/>
    <input
        type="hidden"
        :value=testType
        name="c_type"
    />
    <v-col cols="12">
      <!-- <v-divider></v-divider> -->
      <div></div>
      <!--
      <v-alert type="info">At least one Step should be kept!</v-alert>
      -->
      <!-- <div style="height: 25px; display: inline-block"></div> -->
      <div class="testGroupsContainer">
        <v-card>
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab
                v-for="(groupValue, groupKey) in customStepsGroups"
                :key="groupValue"
                :id="'testCaseGroupTabHeader_' + groupValue"
            >
              <draggable
                  v-model="droped[groupValue]"
                  @change="updateGroup(groupValue, ...arguments)"
                  :group="{
                  name: groupValue,
                  put: true,
                  pull: false,
                }"
                  ghostClass="d-none"
                  draggable=".draggable"
                  class="my-8 mx-12"
              >{{ groupKey }}({{ testsCount(groupValue) }})
              </draggable>
            </v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item
                v-for="(groupValue, i, index) in customStepsGroups"
                :key="groupValue"
            >
              <v-card flat :id="'testCaseGroupTabBody_' + groupValue">
                <v-card-text>
                  <v-form
                      :ref="'tabFormValidation_' + index"
                      v-model="tabFormValidation"
                  >
                    <testCasesGroup
                        :isValid="isValid"
                        :stepsGroups="customStepsGroups"
                        :groupValue="groupValue"
                        :templateValue="templateValuesArray[groupValue]"
                        :groupedStepDependentInfo="
                        groupedStepDependentInfo(groupValue)
                      "
                        :result="result"
                        @group-value-changed="
                        updateTestValue(groupValue, ...arguments)
                      "
                        v-on="$listeners"
                    ></testCasesGroup>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import testCasesGroup from "@/components/legacy/testCasesGroup.vue";
import {getfieldAttrRow, getFieldsAttributes} from "@/js/helper.js";
import draggable from "vuedraggable";

export default {
  components: {testCasesGroup, draggable},
  props: ["result", "field", "isValid"],
  data: function () {
    return {
      tabFormValidation: false,
      // parsedValue: JSON.parse(this.result.valueLine.c_obj),
      droped: {pre: [], test: [], post: []},
      parsedValue: {},
      stringfiedValue: this.result.valueLine.c_obj,
      tab: 1,
      currentTab: 1,
      stepsGroups: {Setup: "pre", Test: "test", Clearing: "post"},
      attributes: {},
    };
  },
  watch: {
    tabFormValidation(isValid) {
      this.$emit("changeIsValid", isValid);
    },
    isValid(isValid) {
      if (isValid) {
        let oldVal = this.currentTab;
        if (
            typeof this.$refs["tabFormValidation_" + oldVal] != "undefined" &&
            this.$refs["tabFormValidation_" + oldVal][0] != "undefined"
        ) {
          let form = this.$refs["tabFormValidation_" + oldVal][0];
          let valid = form.validate();
          if (!valid) {
            this.$emit("changeIsValid", valid);
          }
          return !valid;
        }
      }
    },
    tab(newVal, oldVal) {
      if (typeof this.$refs["tabFormValidation_" + oldVal] != "undefined") {
        let form = this.$refs["tabFormValidation_" + oldVal][0];
        if (!form.validate()) {
          this.$nextTick(function () {
            this.tab = oldVal;
          });
        } else {
          this.currentTab = newVal;
        }
      }
    },
  },
  computed: {
    customStepsGroups: function () {
      //console.log(this.result)
      let customStepGroups = this.stepsGroups;
      if (typeof this.result.valueLine.c_type != "undefined" && this.result.valueLine.c_type == "stencil") {
        customStepGroups = {Setup: "pre", Stencil: "test", Clearing: "post"};
      }
      return customStepGroups;
    },
    fieldAttrRow: function () {
      return getfieldAttrRow(this.fieldAttributes, this.field);
    },
    fieldAttributes: function () {
      let fieldAttributes = getFieldsAttributes(this.result, this.field);
      //console.log(fieldAttributes, this.field);
      return fieldAttributes;
    },
    templateValuesArray() {
      let templateValuesArray = {pre: {}, post: {}, test: {}};
      this.$set(templateValuesArray, "pre", this.localValue["pre"]);
      this.$set(templateValuesArray, "post", this.localValue["post"]);
      for (let [index, testValue] of Object.entries(this.localValue)) {
        if (index != "pre" && index != "post") {
          this.$set(templateValuesArray["test"], index, testValue);
        }
      }
      return templateValuesArray;
    },
    testType() {
      let testType = "test"; //default;
      if (typeof this.result.valueLine.c_type != 'undefined' && this.result.valueLine.c_type != '') {
        testType = this.result.valueLine.c_type;
      }
      return testType;
    },
    localValue: {
      get() {
        let value = {};
        if (this.result.valueLine.c_obj != this.stringfiedValue) {
          try {
            console.log("parsed again");
            value = JSON.parse(this.result.valueLine.c_obj);
          } catch (error) {
            value = {};
          }
        } else {
          value = this.parsedValue;
        }
        return value;
      },
      set(newVal) {
        this.updateLocalValue(newVal);
      },
    },
  },
  created() {
    let parsedValue;
    try {
      parsedValue = JSON.parse(this.result.valueLine.c_obj);
    } catch (error) {
      parsedValue = {1: {}};
    }
    this.parsedValue = parsedValue;
  },
  methods: {
    testsCount(groupValue) {
      let testsCount = 0;
      if (typeof this.templateValuesArray[groupValue] != "undefined") {
        testsCount = Object.keys(this.templateValuesArray[groupValue]).length;
      }
      return testsCount;
    },
    convertToStepObject(local) {
      let stepObject = {};
      let caller = this;
      local.forEach((element, index) => {
        caller.$set(stepObject, index + 1, element);
      });
      return stepObject;
    },
    updateGroup(groupValue, value) {
      // console.log(groupValue, value);
      let templateValue = this.templateValuesArray[groupValue];
      if (typeof templateValue == "undefined") {
        templateValue = {};
      }
      let local = Object.values(templateValue);
      local.unshift(value.added.element);
      // let length = Object.keys(templateValue).length;
      // this.$set(templateValue, length + 1, value.added.element);
      this.updateTestValue(groupValue, this.convertToStepObject(local));
    },
    updateLocalValue(newVal) {
      this.stringfiedValue = JSON.stringify(newVal);
      this.templateStoreValue = newVal;
      this.parsedValue = newVal;
      this.$emit("input", this.stringfiedValue);
      //  this.$emit("input", JSON.stringify(newValue));
    },
    groupedStepDependentInfo(groupValue) {
      let groupedStepDependentInfo = {};
      if (
          typeof this.result.additional.stepDependentInfo != "undefined" &&
          typeof this.result.additional.stepDependentInfo[groupValue] !=
          "undefined"
      ) {
        groupedStepDependentInfo = this.result.additional.stepDependentInfo[
            groupValue
            ];
      }
      // //  console.log(this.result.additional.stepDependentInfo,groupValue,groupedStepDependentInfo);
      return groupedStepDependentInfo;
    },
    updateTestValue(group, value) {
      if (group == "test") {
        // delete none exsisting steps
        for (let index of Object.keys(this.localValue)) {
          if (index != "pre" && index != "post") {
            if (typeof value[index] == "undefined") {
              this.$delete(this.localValue, index);
            }
          }
        }
        for (let [index, testValue] of Object.entries(value)) {
          this.$set(this.localValue, index, testValue);
        }
      } else {
        this.$set(this.localValue, group, value);
      }
      this.updateLocalValue(this.localValue);
    },
  },
};
</script>