<template>
  <div>
    <primaryTextfield
      type="number"
      :fieldAttrInput="{ class:'number required' ,id:'step_wait_input'}"
      label="Wait"
      v-model="wait"
      :templateContent="result"
    ></primaryTextfield>
    <defaultFieldTypes
        :fieldAttributes="{ type:'textarea',
                            name:'Description',
                            fieldAttrInput:{ id:'step_wait_description_input',
                                             class:'',
                                             rows:2,
                                             autofocus:true,
                                             'auto-grow':true
                                            }
                           }"
        @input="updateInput('description',...arguments)"
        :templateContent="result"
        :value="description">
    </defaultFieldTypes>
    <defaultFieldTypes
      :fieldAttributes="{ type:'textarea',
                          name:'Wait Condition',
                          fieldAttrInput:{ id:'step_wait_condition_input',
                                           class:'testConditionInput required',
                                           hint:' Example: ${step:subscriber} == \'49123456789\' AND (${LOCATION} != \'26202\' OR \'3GPP-SGSN-MCC-MNC\' == ${MCCMNC})',
                                           'persistent-hint':true
                                          }
                          }"
      @input="updateInput('wait_condition',...arguments)"
      :templateContent="result"
      :value="condition">
    </defaultFieldTypes>
    <onTimeOutNoResult onEvent="On Timeout" v-model="onTimeout"></onTimeOutNoResult>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";

export default {
  components: {
    onTimeOutNoResult,
    defaultFieldTypes,
    primaryTextfield,
  },
  computed: {
    condition() {
      return this.stepStoredValue.wait_condition;
    },
    description() {
      return this.stepStoredValue.description;
    },
    onTimeout: {
      get() {
        var onTimeout = {};
        if (typeof this.stepStoredValue != "undefined") {
          if (typeof this.stepStoredValue.onTimeout != "undefined") {
            onTimeout = this.stepStoredValue.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };

        if (localStoredStep == null || typeof localStoredStep == "undefined") {
          localStoredStep = {};
        }

        this.$set(localStoredStep, "onTimeout", newVal);

        this.$emit("stored-value-changed", localStoredStep);
      },
    },
    wait: {
      get() {
        var wait = {};
        if (typeof this.stepStoredValue != "undefined") {
          if (typeof this.stepStoredValue.wait != "undefined") {
            wait = this.stepStoredValue.wait;
          }
        }
        return wait;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };

        if (localStoredStep == null || typeof localStoredStep == "undefined") {
          localStoredStep = {};
        }

        this.$set(localStoredStep, "wait", newVal);

        this.$emit("stored-value-changed", localStoredStep);
      },
    },
  },
  methods: {
    updateInput(field,value) {
      let localStoredStep = { ...this.stepStoredValue };
      this.$set(localStoredStep,field, value);
      this.$emit("stored-value-changed", localStoredStep);
    },
  },

  props: [
    "result",
    "stepStoredValue",
    "stepType",
    "groupedStepDependentInfo",
    "stepID",
    "additionalStepsInfo",
  ],
};
</script>