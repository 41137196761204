<template>
  <div>
    <template v-if="row.rawType=='Explorer_cellular_attach' && !isExplorer">
      MCC MNC:
    </template>
    <span :class="expectColor" :title="row['destinationIP']">{{row["destination"]}}</span>
  </div>
</template>
<script>
    import {getExpectColor} from "@/js/helper.js";

    export default {
        props: ["row"],
        computed: {
            expectColor() {
                return getExpectColor(this.row);
            },
          isExplorer() {
            return this.row['destination'].includes('Explorer');
          },
        },
    };
</script>