var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('primaryTextfield',{attrs:{"type":"number","fieldAttrInput":{ class:'number required' ,id:'step_wait_input'},"label":"Wait","templateContent":_vm.result},model:{value:(_vm.wait),callback:function ($$v) {_vm.wait=$$v},expression:"wait"}}),_c('defaultFieldTypes',{attrs:{"fieldAttributes":{ type:'textarea',
                          name:'Description',
                          fieldAttrInput:{ id:'step_wait_description_input',
                                           class:'',
                                           rows:2,
                                           autofocus:true,
                                           'auto-grow':true
                                          }
                         },"templateContent":_vm.result,"value":_vm.description},on:{"input":function($event){return _vm.updateInput('description',...arguments)}}}),_c('defaultFieldTypes',{attrs:{"fieldAttributes":{ type:'textarea',
                        name:'Wait Condition',
                        fieldAttrInput:{ id:'step_wait_condition_input',
                                         class:'testConditionInput required',
                                         hint:' Example: ${step:subscriber} == \'49123456789\' AND (${LOCATION} != \'26202\' OR \'3GPP-SGSN-MCC-MNC\' == ${MCCMNC})',
                                         'persistent-hint':true
                                        }
                        },"templateContent":_vm.result,"value":_vm.condition},on:{"input":function($event){return _vm.updateInput('wait_condition',...arguments)}}}),_c('onTimeOutNoResult',{attrs:{"onEvent":"On Timeout"},model:{value:(_vm.onTimeout),callback:function ($$v) {_vm.onTimeout=$$v},expression:"onTimeout"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }