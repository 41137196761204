<template>
  <div>
    <associatedOption
      :fieldAttributes="{ name: 'Status Code', associatedOption: localCodes }"
      type="combobox"
      :fieldAttrInput="{ class: 'HTTPCodes required comboBox ' }"
      v-model="ExpectedStatusType"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import requests from '@/js/requests';
export default {
  components: { associatedOption },
  data() {
    return { localCodes: [] };
  },
  computed: {
    ExpectedStatusType: {
      get() {
        let ExpectedStatusType = "200";
        if (this.value != "") {
          ExpectedStatusType = this.value;
        } else {
          this.$emit("input", ExpectedStatusType);
        }
        return ExpectedStatusType;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  created() {
    // additionalStepsInfo["HTTP"]["HTTPCodes"];
    // $.ajax({
    //   type: "POST",
    //   url: "serve.php?f=testing&f2=configureAndRunTests",
    //   data: {
    //     function: "getHTTPCodesJson",
    //     requestType: "ajax",
    //   },
    //   dataType: "json",
    //   success: this
    //     .updateHttpCode
    //     // $evalParametersContainer,
    //     //  ExpectedStatusType,
    //     // additionalStepsInfo
    //     (),
    // });
    if (typeof this.HTTPCodes == "undefined" || this.HTTPCodes.length == 0) {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: {
          function: 'getHTTPCodesJson',
          requestType: 'ajax',
        },
      })
          .then(function (post) {
          caller.localCodes = post.data.result.json;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.localCodes = this.HTTPCodes;
    }
  },
  props: ["HTTPCodes", "value","result"],
};
</script>