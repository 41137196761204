<template>
  <div class="radius-attribute">
    <XTextField
        :value="selectedAttribute ? `${selectedAttribute.name} (${selectedAttribute.id})` : ''"
        label="Attribute"
        :items="this.attributes"
        class="radius-attribute-template-row"
        disabled/>
    <template v-if="selectedAttribute">
      <XSelect
          v-if="selectedAttribute.type === 'Tunnel-Integer' ||
                selectedAttribute.type === 'Tunnel-String'||
                selectedAttribute.type === 'Tunnel-Password'"
          :value="value.tag"
          label="Tag"
          @input="changeTag"
          :items="tunnelTagObject()"
          item-text="name"
          item-value="id"
          :disabled="deactivated"/>
      <XSelect
          v-if="selectedAttribute.type === 'enum'"
          :value="value.content"
          label="Value"
          @input="changeValue"
          :items="selectedAttribute.enum"
          :item-text="(item) => `${item.name} (${item.id})`"
          item-value="id"
          keep-value
          :disabled="deactivated"/>
      <XTextField
          v-if="selectedAttribute.type !== 'enum'"
          :value="value.content"
          label="Value"
          @input="changeValue"
          :type="selectedAttribute.type === 'integer' ? 'number' : undefined"
          keep-value
          :disabled="deactivated"/>
    </template>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import {tunnelTagObject} from "@/js/helper.js";

export default {
  name: 'RadiusRequestTemplateAttribute',
  components: {
    XTextField,
    XSelect,
  },
  props: {
    value: Object,
    attributes: {
      type: Array,
      default: () => [],
    },
    deactivated: Boolean,
  },
  computed: {
    selectedAttribute() {
      return this.attributes.find(x => x.id === this.value.attribute);
    },
  },
  methods: {
    tunnelTagObject: tunnelTagObject,
    changeAttribute(value) {
      if (!this.attributes.length) return;
      this.$emit('input', {
        attribute: value.id,
        content: value.type === 'enum' ? value.enum[0] : undefined,
        tag: this.value.tag,
      });
    },
    changeValue(value) {
      if (!this.attributes.length) return;
      this.$emit('input', {
        attribute: this.value.attribute,
        content: value,
        tag: this.value.tag,
      });
    },
    changeTag(value) {
      if (!this.attributes.length) return;
      this.$emit('input', {
        attribute: this.value.attribute,
        content: this.value.content,
        tag: value,
      });
    },
  },
};
</script>

<style scoped>
.radius-attribute {
  display: flex;
  gap: 10px;
}

.radius-attribute > * {
  width: calc(50% - 5px);
}

.radius-attribute-template-row >>> .v-text-field__slot > input {
  color: var(--v-success-base);
}
</style>