<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>
    <primaryButton
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :value="functionElement['functionName']"
      @click="restart"
      :label="functionElement['functionName']"
      color="save"
      :disabled="disabled"
      :loading="loading"
      icon="mdi-restart"
    ></primaryButton>
    <startParametersDialog
      v-if="parameterDialog"
      v-model="startParameters"
      :parameterDialog="parameterDialog"
      :loading="loading"
      :testNames="testNames"
      @confirmation-ok="runTest"
    >
    </startParametersDialog>
    <confirmationDialog
      :hideCancelButton="true"
      :value="showErrorDialog"
      title="Error"
      message="Error Running test. Please retry later"
      @confirmation-ok="showErrorDialog = !showErrorDialog"
      maxWidth="290"
    ></confirmationDialog>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
import standbyDialog from "@/commonComponents/standbyDialog.vue";
import startParametersDialog from "@/components/legacy/startParametersDialog.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
import requests from '@/js/requests';

export default {
  props: ["isValid", "loading", "functionElement", "result","disabled"],
  components: {
    primaryButton,
    standbyDialog,
    startParametersDialog,
    confirmationDialog,
  },
  data() {
    return {
      localLoadingDialog: false,
      parameterDialog: false,
      startParameters: [],
      showErrorDialog: false,
      //  loading: false,
    };
  },
  created() {
    // this.$parent.$root.getFilteredRow(
    //   {
    //     pre: false,
    //     post: false,
    //     condition: true,
    //   },
    //   this
    // );
  },
  methods: {
    runTest(execute) {
      if (execute) {
        this.restart();
      } else {
        this.parameterDialog = false;
      }
    },
    restart() {
      this.localLoadingDialog = true;
      let caller = this;
      let n_id_key = this.result.valueLine.n_id_key;
      let n_id = this.result.valueLine.n_id;
      const n_testcase_id = this.result.valueLine.n_testcase_id;
      const n_testgroup_id = this.result.valueLine.n_testgroup_id;
      requests.frameworkAxiosRequest({
        method: 'post',
        url:
            'serve.php?f=testing&f2=testsExecuteApi&function=restartTestandShow&n_id=' +
            n_id +
            '&n_id_key=' +
            n_id_key +
            '&n_testcase_id=' +
            n_testcase_id +
            '&n_testgroup_id=' +
            n_testgroup_id,
        data: {startParameters: this.startParameters},
        //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
      })
          .then( (response)=> {
          this.$store.commit("dialogButtonsLoading", false);
          if (response.data.result.responseCode == "201") {
            //console.log(response);
            caller.$router.push(response.data.result.redirect).catch(() => {
              caller.$router.go();
            });
            caller.parameterDialog = false;
          } else if (response.data.result.responseCode == "300") {
            caller.parameterDialog = true;
            // console.log(response.data.result.body);
            caller.startParameters = response.data.result.body.startParameters;
            caller.testNames = response.data.result.testNames;
          } else {
            caller.showErrorDialog = true;
            caller.parameterDialog = false;
            caller.startParameters = [];
          }
          caller.localLoadingDialog = false;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
  },
};
</script>