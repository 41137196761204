<template>
  <div class="alm-folders">
    <XSelect
        v-model="folder"
        :items="folders"
        label="Folder"
        required
        item-value="id"
        item-text="name"
        class="folder-select"
        validate-immediately
        :tooltip="!almValuesEntered ? 'To be able to load the ALM folders, you need to provide all required values above.' : ''"
        @input="emitInput"/>
    <XBtn
        :disabled="!almValuesEntered"
        @click="getFolders"
        text="Get folders"
        class="get-folders-button"
        color="primary"/>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect';
import XBtn from '@/components/basic/XBtn';
import almService from "@/js/services/AlmService";

export default {
  name: 'AlmFolders',
  components: {
    XBtn,
    XSelect,
  },
  props: {
    valueLine: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      folder: this.valueLine.folderId,
      folders: [],
    };
  },
  created() {
    if (this.almValuesEntered) this.getFolders();
  },
  computed: {
    almValuesEntered() {
      const valueLine = this.valueLine;
      return !!valueLine.url && !!valueLine.client && !!valueLine.secret && !!valueLine.project && !!valueLine.domain;
    },
  },
  methods: {
    getFolders() {
      almService.getFolders(this.valueLine, (folders) => {
        this.folders = folders;
      });
    },
    emitInput(value) {
      const folder = this.folders.find(x => x.id === value);
      this.$emit('input', folder);
    },
  },
};
</script>

<style scoped>
.alm-folders {
  display: flex;
  gap: 10px;
}

.folder-select {
  flex-grow: 1;
}

.get-folders-button {
  margin-top: 2px;
}
</style>