<template>
  <v-row no-gutters>
    <v-col :cols="customCols">
      <associatedOption
          type="combobox"
          :fieldAttributes="{  name: fieldAttributes.name,
                               associatedOption: reqAttributes,
                               sort:false,
                               ignoreEmptyAdd: true,
                               showValueInText:false
                             }"
          :value="localValue"
          v-on="$listeners"
          :templateContent="result"
          @input="checkAttribute(...arguments)"
      ></associatedOption>
    </v-col>
    <v-col class="ml-3">
      <associatedOption
          v-if="isTunnelType"
          :fieldAttributes="{
          name: 'tag',
          options: tunnelTag(fieldAttributes.fieldAttrInput.additionalTunnelTag),
          setEmpty: false,
          sort: false,
          ignoreEmptyAdd: true,
        }"
          type="combobox"
          :fieldAttrInput="{ class: ' ' }"
          :value="valueTag"
          :templateContent="result"
          @input="updateField('tag', ...arguments)"
      ></associatedOption>
    </v-col>
  </v-row>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import {setupAttribute, tunnelTag, arrayFindInArray} from "@/js/helper.js";
import dictionaryService from "@/js/services/DictionaryService";

export default {
  components: {associatedOption},
  props: ["result", "fieldAttributes", "value"],
  data() {
    return {
      reqAttributes: [],
      isTunnelType: false
    };
  },
  beforeMount() {
    this.getAttributes();
    this.checkAttribute();
  },
  methods: {
    checkAttribute() {
      this.$nextTick(() => {
        let type = this.type();
        this.isTunnelType = false;
        if (type === "Tunnel-Integer" ||
            type === "Tunnel-String" ||
            type === "Tunnel-Password" ||
            typeof this.result.valueLine.tag !== 'undefined'
        ) {
          this.isTunnelType = true;
        }
      });
    },
    type() {
      let valueArray = arrayFindInArray(
          this.result.valueLine.attribute,
          this.result.fields.attribute.associatedOption
      );
      let type = valueArray[2];
      return type;
    },

    tunnelTag: tunnelTag,
    updateField(index, newVal) {
      let localValue = {...this.result.valueLine};
      this.$set(localValue, index, newVal);
      this.$emit("input", localValue);
    },
    getAttributes() {
      dictionaryService.getRadiusAttributes((attributes) => {
        this.reqAttributes = setupAttribute(attributes);
      });
    },
  },
  computed: {
    customCols() {
      let customCols = 12;
      if (this.isTunnelType) {
        customCols = 8;
      }
      return customCols;
    },
    valueTag() {
      return this.result.valueLine.tag;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>