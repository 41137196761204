<template>
<div>
    <!--<v-dialog :width="width">
        <v-card>
            <v-card-text>
                <image
                        v-if="typeof imgDetails.imgURL!='undefined'"
                        :height="height"
                        :width="width"
                        :src="imgDetails.imgURL"
                />
                <span v-else>We are working on it!</span>
            </v-card-text>
        </v-card>
        <template v-slot:activator="{ on, attrs }">
            <v-icon  v-bind="attrs" v-on="on">mdi-file-image</v-icon>
        </template>
    </v-dialog>-->

    <v-icon
            v-tooltip="tooltipSource(imgDetails)"

    >mdi-file-image</v-icon>
</div>
</template>

<script>
import { tooltipSource } from "@/js/helper.js";
export default {
  props: ["row"],
  methods:{
    tooltipSource:tooltipSource
  },
  computed: {
    imgDetails() {
      return this.row.headerImage;
    },
    height() {
      let height = this.imgDetails.height;
      if (this.imgDetails.height == "" || this.imgDetails.height == 0) {
        height = 45;
      }
      return height;
    },
    width() {
      let width = this.imgDetails.width;
      if (this.imgDetails.width == "" || this.imgDetails.width == 0) {
        width = 545;
      }
      return width;
    },
  },
};
</script>