<template>
  <div>
    <v-tabs
        :value="value"
        background-color="primary"
        dark
        :class="`tab-box-tabs ${value === 0 ? 'first-selected' : ''}`"
        @change="$emit('input', $event)">
      <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab.text }}</v-tab>
    </v-tabs>
    <div class="content">
      <template v-for="(tab, i) of tabs">
        <template v-if="value === i">
          <slot :name="tab.value"/>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabBox',
  props: {
    value: Number,
    tabs: Array,
  },
};
</script>

<style scoped>
.tab-box-tabs {
  border-radius: 4px 4px 0 0;
}

.first-selected >>> .v-tabs-slider {
  border-left: 1px solid var(--v-box-border-base);
}

.content {
  border: 1px solid var(--v-box-border-base);
  border-top: none;
  padding: 20px;
}
</style>