<template>
  <div>
    <defaultFieldTypes
        v-if="result.contentID==='Explorer_VF_resource_timings'"
        :fieldAttributes="{
            name: 'URLS',
            type: 'textarea',
            fieldAttrInput:  { rows: 3, class: ' ' },
          }"
        @input="updateField('urls', ...arguments)"
        :templateContent="result"
        :value="urls"
        :hideDetails="true"
        field="urls"
    ></defaultFieldTypes>
    <primaryTextfield
        :label="fieldAttributes.name"
        :value="additionalParameters"
        :fieldAttrInput="fieldAttrInput"
        @input="updateField('additionalParameters', ...arguments)"
    ></primaryTextfield>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  components: {
    defaultFieldTypes,
    primaryTextfield,
  },
  props: ["result", "fieldAttributes", "field", "value", "fieldAttrInput"],
  computed: {
    additionalParameters() {
      let ap = "";
      if (this.value?.additionalParameters) {
        ap = this.value.additionalParameters;
      }
      return ap;
    },
    urls() {
      let urls = "";
      if (this.value?.urls) {
        urls = this.value.urls;
      }
      return urls;
    },
  },
  methods: {
    updateField(index, newVal) {
      let localValue = {...this.value};
      this.$set(localValue, index, newVal);
      this.$emit("input", localValue);
    },
  },
};
</script>