<template>
  <div class="mr-3">
    <v-btn
        color="primary"
        fab
        small
        dark
        @click.stop="dialogSessionAll = true"
        title="Forced logout for all users!"
    >
      <v-icon>mdi-alpha-f-box</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialogSessionAll"
        max-width="450"
    >
      <v-card>
        <v-card-title class="headline-box-headline mb-3">
          Users will be forced to log out!
        </v-card-title>
        <v-card-subtitle class="red--text">Note: We will force all users to logout immediately when we detect any click
          on Any page!
        </v-card-subtitle>
        <v-card-subtitle class="green--text">You are safe, your login session remains active!
        </v-card-subtitle>
        <v-card-text>
          Do you really want to end all users' sessions?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialogSessionAll = false"
          >{{ language.componentLabel.labelNo }}
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="flushoutAllSession()"
          >{{ language.componentLabel.labelYes }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from '@/js/requests';

export default {
  props: ["currentTable", "result"],
  data() {
    return {
      disabled: false,
      loading: false,
      dialogSessionAll: false
    };
  },

  computed: {},
  methods: {
    flushoutAllSession() {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=administration&f2=superUserAdministration',
        dataType: 'json',
        data: {
          function: 'flushoutAllSession',
          requestType: 'ajax',
          statusType: this.currentTable.selectedStatus
        },
      })
          .then(response => {
            //console.log(response);
            let currentTable = caller.currentTable;
            caller.$set(currentTable, "data", response.data.result.table.data);
            //caller.$set(caller.result, "headerExtraInfo",  response.data.result.headerExtraInfo);
            caller.$store.commit("tableChanged", currentTable);
            this.dialogSessionAll = false;
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>