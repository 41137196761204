<template>
  <div>
    <grid-layout
        :layout="dataValue"
        :row-height="rowHeight"
        :col-num="colNum"
        :vertical-compact="false"
        :is-draggable="isDraggable"
        :is-resizable="isResizable"
        :margin="margin">
      <grid-item
          v-for="item in dataValue"
          :key="`${item.chartId || item.i}-${item.type}-${item.name || ''}`"
          :ref="item.i"
          @move="handleMove"
          @moved="handleMoved"
          @resize="handleResize"
          @resized="handleResized"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :min-w="typeof minW === 'function' ? minW(item) : minW"
          :min-h="typeof minH === 'function' ? minH(item) : minH"
          :is-resizable="itemResizable"
          class="sandbox-grid-item">
        <slot :item="item"/>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import {deepCopy, deepEquals} from '@/js/general';

export default {
  name: 'XGridLayout',
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    value: Array,
    colNum: {
      type: [Number, String],
      default: 30,
    },
    rowHeight: {
      type: [Number, String],
      default: 12,
    },
    isDraggable: Boolean,
    isResizable: Boolean,
    margin: Array,
    useCssTransforms: Boolean,
    minW: [Number, String, Function],
    minH: [Number, String, Function],
    itemResizable: Boolean,
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      moving: false,
      running: true,
      dataValue: [],
      oldDataValue: [],
      itemSizes: {},
    };
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition, false);
    this.running = true;
    this.tick();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateMousePosition, false);
    this.running = false;
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!deepEquals(value, this.dataValue)) {
          this.dataValue = value;
        }
      },
    },
  },
  methods: {
    tick() {
      if (this.running) {
        if (this.moving) {
          if (this.mouseY > window.innerHeight - 30) {
            scrollBy(0, 5);
          } else if (this.mouseY < 30) {
            scrollBy(0, -5);
          }
        }
        setTimeout(this.tick, 1.0 / 60.0);
      }
    },
    updateMousePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    handleMove() {
      if (!this.moving) {
        this.oldDataValue = deepCopy(this.dataValue);
      }
      this.moving = true;
    },
    handleMoved(movedItemIndex) {
      const newLayout = [this.dataValue.find(x => x.i === movedItemIndex)];
      for (let i = 0; i < this.dataValue.length; i++) {
        const oldItem = this.oldDataValue[i];
        if (oldItem.i === movedItemIndex) {
          continue;
        }

        let collision = false;
        for (const newItem of newLayout.filter(x => x.i !== movedItemIndex && x.i !== oldItem.i)) {
          const xOverlap = oldItem.x >= newItem.x && oldItem.x < newItem.x + newItem.w
              || oldItem.x + oldItem.w > newItem.x && oldItem.x + oldItem.w < newItem.x + newItem.w;
          const yOverlap = oldItem.y >= newItem.y && oldItem.y < newItem.y + newItem.h
              || oldItem.y + oldItem.h > newItem.y && oldItem.y + oldItem.h < newItem.y + newItem.h;
          if (xOverlap && yOverlap) {
            collision = true;
            break;
          }
        }
        if (collision) {
          newLayout.push(this.dataValue[i]);
        } else {
          newLayout.push(this.oldDataValue[i]);
        }
      }
      this.dataValue = newLayout;
      this.moving = false;
      this.$emit('input', this.dataValue);
    },
    handleResize() {
      this.moving = true;
    },
    handleResized() {
      this.moving = false;
    },
  },
};
</script>

<style scoped>
/deep/ .sandbox-grid-item {
  touch-action: none;
  background-color: lightgray;
}
</style>
