<template>
  <v-card class="mx-auto" outlined>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Testcase Search/Details</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list three-line>
      <v-list-item>
        <v-list-item-content>
          <v-row no-gutters>
            <v-col cols="2">
              <primaryTextfield
                  :fieldAttrInput="{ class: 'ml-3' }"
                  style="max-width: 300px"
                  label="Testcase Number"
                  v-model="testcaseNumber"
                  @input="clearOtherField('testcaseNumber')"
              />
            </v-col>
            <div class="d-flex align-center justify-center ">
              <span class="mb-5">OR</span>
            </div>
            <v-col cols="2">
              <primaryTextfield
                  :fieldAttrInput="{ class: 'ml-3' }"
                  style="max-width: 300px"
                  label="Test Queue Number"
                  v-model="testQNumber"
                  @input="clearOtherField('testQNumber')"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                  outlined
                  text
                  :class="checkClass"
                  @click="getTestcaseDetails"
                  :disabled="disableButt"
                  style="height:40px;"
              >
                <v-icon left>mdi-magnify</v-icon>
                Find
              </v-btn>
            </v-col>
          </v-row>
          <template v-if="localData">
            <div class="dataList ml-3">
              <v-row v-if="testcaseURL">
                <v-col cols="2" class="subtitle-2">Testcase Info</v-col>
                <v-col><a :href="testcaseURL" target="_blank">TestInfo URL</a></v-col>
              </v-row>
              <v-row v-if="testQURL">
                <v-col cols="2" class="subtitle-2">Test Queue</v-col>
                <v-col><a :href="testQURL" target="_blank">Test Queue URL</a></v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Testcase Name</v-col>
                <v-col>{{ localData.testcaseName }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Testcase Created By</v-col>
                <v-col>{{ localData.c_firstname }} {{ localData.c_lastname }} <template v-if="localData?.c_email">( {{ localData.c_email }} )({{ localData.userHash }} )</template>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Executed by</v-col>
                <v-col>{{ localData.excutorName }} <template v-if="localData?.excutorEmail">( {{ localData.excutorEmail }} ) ( {{ localData.executor }} )</template>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Date</v-col>
                <v-col><strong>Issued:</strong> {{ localData.testcaseDate.iTime }} | <strong>Started:</strong>
                  {{ localData.testcaseDate.sTime }} | <strong>Finished:</strong> {{ localData.testcaseDate.eTime }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Status</v-col>
                <v-col><label :style="'color:rgb('+localData.testcaseStatusColor+')'">{{ localData.testcaseStatusVal }}</label></v-col>
              </v-row>
              <v-divider class="mb-3 mt-3"></v-divider>
              <v-row>
                <v-col cols="2" class="subtitle-2">Project Name</v-col>
                <v-col>{{ localData.projectName }} ( {{ localData.projectHash }} )</v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Functionality and Area of Testing</v-col>
                <v-col>{{ localData.projectAllowedTypes }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="subtitle-2">Organisation Name</v-col>
                <v-col>{{ localData.orgName }} ( {{ localData.orgHash }} )</v-col>
              </v-row>
            </div>
          </template>
          <template v-else>
            <p class="ml-4 red--text">{{ noData }}</p>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import requests from '@/js/requests';

export default {
  props: ["row", "currentTable", "result", "isValid"],
  data() {
    return {
      testcaseNumber: "",
      testQNumber: "",
      localData: "",
      noData: "",
      disableButt: true,
      testcaseURL: "",
      testQURL: "",
    };
  },
  components: {
    primaryTextfield
  },
  watch: {
    testcaseNumber(value) {
      this.updateDisableButt();
      if (value) {
        this.testQNumber = "";
      }
    },
    testQNumber(value) {
      this.updateDisableButt();
      if (value) {
        this.testcaseNumber = "";
      }
    },
  },
  computed: {
    checkClass() {
      return this.disableButt ? "ml-3 v-btn--disabled v-btn--has-bg white--text" : "ml-3 primary white--text";
    }
  },
  methods: {
    updateDisableButt() {
      this.disableButt = !this.testcaseNumber && !this.testQNumber;
    },
    clearOtherField(changedField) {
      if (changedField === 'testcaseNumber') {
        this.testQNumber = '';
      } else if (changedField === 'testQNumber') {
        this.testcaseNumber = '';
      }
    },
    getTestcaseDetails() {
      this.localData = "";
      this.testcaseURL = "";
      this.testQURL = "";
      requests.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=administration&f2=testcaseDetails',
        dataType: 'json',
        data: {
          tNumber: this.testcaseNumber,
          tQNumber: this.testQNumber,
          requestType: 'ajax',
        },
      })
          .then(response => {
            if (Object.keys(response.data.result.testcaseDetailsValue).length !== 0) {
              this.localData = response.data.result.testcaseDetailsValue;
              this.testcaseURL = response.data.result.testcaseURL;
              this.testQURL = response.data.result.testQURL;
            } else {
              this.noData = "No Testcase Found!";
            }
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
}
</script>

<style scoped>
.dataList > .row:hover {
  background-color: #eeeeee;
}
</style>