<template>
    <div v-if="row.n_id!=result.login_n_id">
        <v-icon
                style="cursor: pointer;"
                @click="userSwitch(row.n_id,row.n_id_key)"
                title="Switch the account!"
        >mdi-account-switch
        </v-icon>
    </div>
</template>
<script>
  import requests from '@/js/requests';

  export default {
    data:function(){
      return {

      };
    },
    methods: {
      userSwitch(nid,key){
        requests.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=administration&f2=superUserAdministration',
          dataType: 'json',
          data: {
            function: 'switchToUser',
            n_id: nid,
            n_id_key: key,
            requestType: 'ajax',
          },
        })
            .then(response => {
              let fwdURL="?f=administration&f2=info";
             if(response.data.result.projectFlag==1){
               fwdURL="?f=testing&f2=configureAndRunTests";
             }
            window.location.href = fwdURL;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    props: ["row","currentTable","result"]
  }
</script>