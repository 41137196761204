<template>
  <XSelect
      class="resolution"
      label="Resolution"
      :value="$store.state.scheduledTestsHistoryResolution"
      :items="[60, 30, 15]"
      @input="storeResolution"/>
</template>

<script>
import XSelect from '@/components/basic/XSelect.vue';

export default {
  name: 'ScheduledTestsResolutionSelect',
  components: {XSelect},
  data() {
    return {
      resolution: 60,
    };
  },
  methods: {
    storeResolution(value) {
      this.$store.commit('setScheduledTestsHistoryResolution', value);
    },
  },
};
</script>

<style scoped>
.resolution {
  position: absolute;
  right: 30.8%;
}
</style>