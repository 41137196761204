<template>
  <div>
    <fieldsRows :templateContent="templateContent" @field-value-changed="updateFieldValue(...arguments)"
                :valueLine="localStoredValue"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import teststepDefault from '@/cfg/teststep-default.json';
import {deepCopy, setDefaultValues} from '@/js/general';

export default {
  components: {fieldsRows},
  data() {
    return {
      localIpv: [
        ['ipv4', 'IPv4'],
        ['ipv6', 'IPv6'],
      ],
      localProtocol: [
        ['udp', 'UDP'],
        ['tcp', 'TCP'],
        ['icmp', 'ICMP'],
      ],
      setDefaultValues: setDefaultValues,
    };
  },
  props: ['result', 'stepStoredValue', 'stepType', 'stepID'],
  watch: {
    'localStoredValue.protocol'(value) {
      if (value === 'udp') this.localStoredValue.port = 33434;
      else if (value === 'tcp') this.localStoredValue.port = 443;
    },
  },
  computed: {
    localStoredValue: {
      get() {
        const localStoredValue = deepCopy(this.stepStoredValue);
        if (setDefaultValues(localStoredValue, teststepDefault[this.stepType])) {
          this.$emit('stored-value-changed', localStoredValue);
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit('stored-value-changed', newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: 'line',
            fields: {
              startWithinMinutes: {
                type: 'number',
                name: 'Start Within Minutes',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:170px', class: ''},
              },
              finishedWithinMinutes: {
                type: 'number',
                name: 'Finished Within Minutes',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:170px', class: ''},
              },
              maxDuration: {
                type: 'number',
                name: 'Maximum Duration (s)',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {style: 'max-width:200px', class: ''},
              },
            },
          },

          targetLine: {
            type: 'line',
            fields: {
              ip: {
                type: 'select',
                associatedOption: this.localIpv,
                name: 'IP Version',
                fieldAttrInput: {class: 'required'},
                colAttrs: {style: 'max-width:150px', class: ''},
              },
              protocol: {
                type: 'select',
                associatedOption: this.localProtocol,
                name: 'Protocol',
                fieldAttrInput: {class: 'required'},
                colAttrs: {style: 'max-width:150px', class: ''},
              },
              target: {
                type: 'text',
                name: 'Server',
                // fieldAttrInput: {
                //   class: "required IPDomain",
                // },
                inputFieldFormatter: 'ip4ip6ValidatedInput',
                colAttrs: {style: 'max-width:372px', class: ''},
              },
            },
          },
          res: {
            type: 'text',
            inputFieldFormatter: 'explorerOnError',
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        //contentID: this.stepType + '_' + this.stepID,
        contentID: this.stepType,
      };

      if (this.localStoredValue.protocol === 'tcp' || this.localStoredValue.protocol === 'udp') {
        templateContent.fields.targetLine.fields.port = {
          type: 'number',
          name: 'Port',
          fieldAttrInput: {class: 'validatePort'},
          colAttrs: {style: 'max-width: 150px'},
        };
      }

      return templateContent;
    },
  },
  methods: {
    // emitUpdateFields(info) {
    //   this.$emit("updated-fields", info);
    // },
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      this.$set(localStoredStep, field, value);
      this.$emit('stored-value-changed', localStoredStep);
    },
  },
};
</script>


