<template>
    <div class="mb-2">
        <table style="width: 100%; max-width: 360px" class="innerClassTable">
            <colgroup>
                <col style="width: 33%" />
                <col style="width: 33%" />
                <col style="width: 33%" />
            </colgroup>
            <tbody>
            <tr class="primary white--text">
                <template v-for="(value,index) in newItems">
                    <th :key="index"  class="primary">
                        <span > {{ languagePack("speedTestTable", index) }}</span>
                    </th>
                </template>
            </tr>
            <tr>
                <template v-for="(value,index) in newItems">
                    <td style="text-align: center" :key="index">
                        <span v-if="index=='latency'"> {{ convertMs(item.latency)}}</span>
                        <span v-else>{{ showMbs(value) }}</span>
                    </td>
                </template>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
  import { convertMs,objectCustomSort,showMbs } from "@/js/helper.js";

  export default {
  props: {
    item: Object,
  },
  computed: {
      newItems(){
        let itemList = {};
        itemList['uplink'] = this.item['uplink'];
        itemList['downlink'] = this.item['downlink'];
        itemList['latency'] = this.item['latency'];
        var sortOrder = ['uplink','downlink','latency'];
        return objectCustomSort(sortOrder,itemList);
      }
    },
  methods: {
    showMbs:showMbs,
    convertMs:convertMs
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
table th:nth-child(1), th:nth-child(2) {
    border-right-color: white !important;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>