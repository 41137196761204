
<template>
  <div>
    <associatedOption
      :fieldAttributes="{
        name: 'Type',
        associatedOption: [
          ['array', 'Array'],
          ['object', 'Object'],
        ],
      }"
      type="select"
      :fieldAttrInput="{ class: 'required', id: 'httpBodyJSONType' }"
      v-model="rootType"
      :templateContent="result"
    ></associatedOption>
    <v-row
      v-if="
        typeof localValue.content != 'undefined' &&
        Object.keys(localValue.content).length > 0
      "
      no-gutters
      class="mb-2"
    >
      <v-col class="text-subtitle-1"> Attribute </v-col>

      <v-col style="max-width: 40px; min-width: 40px" class="mt-2"> </v-col
      ><v-col class="text-subtitle-1"> Type </v-col>
      <v-col class="ml-2 text-subtitle-1"> Content </v-col>
      <v-col class="ml-2 mt-2" style="max-width: 40px"> </v-col>
    </v-row>
    <v-form
      :lazy-validation="true"
      ref="addingLineForm"
      v-model="addingFormValid"
    >
      <jsonContentRecursive
        v-model="localValue"
        :result="result"
        :treeLength="1"
      ></jsonContentRecursive>
      <primaryButton
        class="mr-2 mt-3"
        label="Add Attribute"
        icon="mdi-plus"
        @click="addLine()"
        type="button"
        color="primary"
        :attributesArray="{ id: 'httpBodyJSON_mainAdder' }"
      ></primaryButton>
    </v-form>
  </div>
</template>
<script>
import jsonContentRecursive from "@/components/legacy/jsonContentRecursive.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
// import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
// import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: {
    primaryButton,
    // lineFieldsArray,
    associatedOption,
    jsonContentRecursive,
  },

  props: ["storedBody", "result"],
  data() {
    return {
      addingFormValid: true,
      types: [
        ["array", "Array"],
        ["string", "String"],
        ["boolean", "Boolean"],
        ["null", "Null"],
        ["number", "Number"],
        ["object", "Object"],
      ],
      // cachedPairArray: null,
    };
  },
  watch: {
    rootType(newVal, oldVal) {
      if (newVal != oldVal) {
        let storedBody = { ...this.storedBody };
        if (newVal == "array") {
          this.$set(storedBody, "content", []);
        } else {
          this.$set(storedBody, "content", {});
        }
        this.$emit("stored-body-changed", storedBody);
      }
    },
  },
  computed: {
    localValue: {
      get() {
        if (typeof this.storedBody.type == "undefined") {
          let storedBody = { ...this.storedBody };
          this.$set(storedBody, "type", "object");
          this.$set(storedBody, "content", {});

          this.$emit("stored-body-changed", storedBody);
        }
        return this.storedBody;
      },
      set(newVal) {
        this.$emit("stored-body-changed", newVal);
      },
    },
    rootType: {
      get() {
        let rootType = "object";
        let storedBody = this.storedBody;
        if (
          typeof storedBody == "object" &&
          typeof storedBody.type != "undefined"
        ) {
          rootType = storedBody.type;
        }
        return rootType;
      },
      set(newVal) {
        let storedBody = { ...this.storedBody };

        this.$set(storedBody, "type", newVal);

        this.$emit("stored-body-changed", storedBody);
      },
    },
  },

  methods: {
    addLine() {
      if (this.$refs.addingLineForm.validate()) {
        let line = { type: "string", content: "" };
        let storedBody = { ...this.storedBody };
        if (storedBody.type == "array") {
          if (
            typeof storedBody.content != "object" ||
            !Array.isArray(storedBody.content)
          ) {
            storedBody.content = [];
          }
          storedBody.content.push(line);
        } else {
          storedBody.content[""] = line;
        }
        this.$emit("stored-body-changed", storedBody);
      }
    },
  },
};
</script>