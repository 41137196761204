<template>
  <div>
    <groupCombobox
        :options="groupedType"
        :value="localValue"
        @input="updateTypeValue(...arguments)"
        :result="result"
        itemText="group"
     ></groupCombobox>
  </div>
</template>
<script>
import groupCombobox from "@/commonComponents/groupCombobox.vue";

//to do construct the new att as group
export default {
  props: ["result", "fieldAttributes", "field", "value","fieldAttrInput"],
  data: function () {
    return {
      groupedType: this.getCompleteGroupOptions()
    };
  },
  components: {
    groupCombobox,
  },
  computed: {
    localValue: {
      get() {
        let returned = {};
        let  sourceIndex = this.groupedType.findIndex(p => p.value == this.value);
        let sourceObj = this.groupedType[sourceIndex];
        if(typeof sourceObj!=='undefined'){
           returned = { "value": sourceObj.value, "text": sourceObj.text,"selector": sourceObj.selector };
        }
        return returned;
      },
    },
   },
  methods: {
    getCompleteGroupOptions(){
      let opts = this.fieldAttrInput.options;
      let groupName = '';
      opts.forEach(function (element) {
        if(typeof element.header!=='undefined' ){
           groupName = element.header;
        }
        if(typeof element.header==='undefined' && typeof element.divider==='undefined' ){
          element.group = element.text+' ['+groupName+']';
          element.selector = element.value;
        }
      });
      return opts;
    },
    updateTypeValue(selectedValue) {
      this.$emit("input", selectedValue.value);
    },
  }
};
</script>