<template>
  <div class="mb-2 pa-3">
    <div style="max-width: 450px">
      <template v-for="(value,index) in newItems">
        <v-row :key="index" style="" class="table-value-row">
          <v-col
              class="primary white--text pa-1"
              cols="4"
          > {{ languagePack("waitTable", index) }}
          </v-col>
          <v-col class="pa-1">
            {{ value }}
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>
<script>
import {objectCustomSort} from "@/js/helper";

export default {
  props: {
    item: Object,
  },
  computed: {
    newItems() {
      let sortOrder = ['msg', 'description', 'condition', 'evaluated'];
      let itemList = this.item;
      return objectCustomSort(sortOrder, itemList);
    }
  },
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-row {
  border-top: solid 1px #dbdbdb;
  border-right: solid 1px #dbdbdb
}
.table-value-row:last-child {
  border-bottom: solid 1px #dbdbdb;
}
</style>