<template>
  <InputRow>
    <XSelect
        :value="stepStoredValue.testcaseId"
        label="Stencil"
        :items="stencils"
        item-value="id"
        item-text="name"
        class="stencil-select"
        :tooltip="tooltip"
        required
        @input="emitInput"/>
    <XBtn text="Copy Stencil Steps to this Position" @click.stop="copyStencilSteps" color="primary"/>
    <HelpButton id="copy_stencil_steps"/>
  </InputRow>
</template>
<script>
import XSelect from '@/components/basic/XSelect.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XBtn from '@/components/basic/XBtn.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'step_use_stencil',
  components: {
    HelpButton,
    XBtn,
    InputRow,
    XSelect,
  },
  props: {
    result: Object,
    stepStoredValue: Object,
    stepType: String,
    stepId: [String, Number],
  },
  data() {
    return {
      stencils: [],
      loading: false,
    };
  },
  created() {
    this.loading = true;
    testCaseInfoService.getStencils((stencils) => {
      this.stencils = stencils;
      this.loading = false;
    });
  },
  computed: {
    tooltip() {
      if (this.loading) return 'Stencils are loading. Please wait.';
      if (!this.stencils.length) return 'Currently, there are no stencils available. Please create a stencil test case.';
      return undefined;
    },
  },
  methods: {
    copyStencilSteps() {
      this.$emit('copy-stencil-steps', this.stepStoredValue.testcaseId);
    },
    emitInput(value) {
      let stencil = this.stencils.find(x => x.id === value);
      let testcaseName = "";
      if (stencil) {
        testcaseName = stencil.name;
      }
      this.$emit('stored-value-changed', {
        testcaseId: value,
        name: testcaseName
      });
    },
  },
};
</script>
<style scoped>
.stencil-select {
  width: 300px;
}
</style>
