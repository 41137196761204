<template>
  <div></div>
</template>
<script>
import requests from '@/js/requests';
const DELAY_TIME = 3000
export default {
  props: ["result"],
  data() {
    return {
      reloadCounter: 0,
      updateReportIntervalListener: null,
      updateInProgress: false,
      testInfoTimer: 0,
      reloading: false,
      reloadFlag: true,
    };
  },
  created() {
    this.reloadFlag = true;
    if (
      this.result.content.includes("testInfoReloader") &&
      !this.reloading &&
      this.reloadFlag
    ) {
      this.updateTimer();
    }
  },
  beforeDestroy() {
    this.reloadFlag = false;
  },
  watch: {
    result(newresult) {
      if (
        newresult.content.includes("testInfoReloader") &&
        !this.reloading &&
        this.reloadFlag
      ) {
        this.updateTimer();
      }
    },
  },
  methods: {
    updateTimer() {
      this.updateRunning = true;
      this.reloading = true;
      // console.log("updating");
      this.testInfoTimer--;
      // var $reloadtimer = $(".reloadtimer");
      if (this.testInfoTimer < 0) {
        this.reloadCounter++;
        this.updateReport();
        //console.log( this.reloadCounter);
        if (this.reloadCounter > 10) {
          this.testInfoTimer = 3;
        } else {
          this.testInfoTimer = 1;
        }
      } else {
        let caller = this;
        this.updateReportIntervalListener = setTimeout(function () {
          caller.updateTimer();
        }, DELAY_TIME);
      }
    },

    updateReport() {
      // if (!this.loginRequired && this.updateInProgress === false)
      {
        this.updateInProgress = true;
        // let $tableSelector = $(".dataTable");
        //  let $overlayWrapper = $tableSelector.closest(".overlayWrapper");
        var excludeRows = [];

        let id = this.result.valueLine.n_id;
        let hashKey = this.result.valueLine.n_id_key;

        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=testInfo&function=getInfo',
          //url: "serve.php?f=testing&f2=testInfo&function=getInfoUpdate",
          data: {
            n_id: id,
            n_id_key: hashKey,
            requestType: 'ajax',
            excludeRows: excludeRows,
            showInfo: this.$store.state.testInfoAdminInfo,

            ...caller.result.valueLine.c_space,
          },
        })
            .then(function (response) {
            if (caller.reloadFlag) {
              caller.$set(
                response.data.result.table,
                "sortBy",
                caller.result.table.sortBy
              );
              caller.$set(
                response.data.result.table,
                "sortDesc",
                caller.result.table.sortDesc
              );
              caller.$set(
                response.data.result.table,
                "searchValue",
                caller.result.table.searchValue
              );
              caller.$store.commit("resultChanged", response.data.result);
              caller.$store.commit("tableChanged", response.data.result.table);

              if (response.data.result.json.updateInfo == "false") {
                clearTimeout(caller.updateReportIntervalListener);
                caller.reloading = false;
                // $(".reloadtimer").remove();
                // $(".stopButton").remove();
              } else {
                caller.updateReportIntervalListener = setTimeout(function () {
                  caller.updateTimer();
                }, DELAY_TIME);
              }
              caller.updateInProgress = false;
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);

            if (caller.reloadFlag) {
              caller.updateReportIntervalListener = setTimeout(function () {
                caller.updateTimer();
              }, DELAY_TIME);

              caller.updateInProgress = false;
            }
          });
      }
    },
  },
};
</script>