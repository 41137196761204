<template>
  <div class="json-editor-scope">
    <div>
      <div v-for="(_, i) of new Array(depth)" :key="i" class="json-editor-scope-spacer"/>
    </div>
    <div class="json-editor-endless-vertical-line"/>
    <div class="json-editor-scope-content">
      <div v-for="(key, i) in keyOrder" :key="i" class="json-editor-row">
        <div v-if="i === Object.keys(keyOrder).length - 1" class="json-editor-line-hider"/>
        <div class="json-editor-row-main">
          <div class="json-editor-scope-depth-symbol">
            <div class="json-editor-vertical-line"/>
            <div class="json-editor-horizontal-line"/>
            <XBtn
                v-if="!!value[key] && typeof value[key] === 'object' && Object.keys(value[key]).length"
                :icon="`mdi-${!expandedScopes[key] ? 'plus' : 'minus'}-box-outline`"
                class="json-editor-scope-expander"
                @click="$set(expandedScopes, key, !expandedScopes[key])"/>
          </div>
          <InputRow>
            <XTextField
                v-if="!Array.isArray(value)" label="Attribute" :value="key" @input="emitKey(key, $event)"/>
            <XSelect
                label="Type"
                :value="value[key] === null ? 'null' : Array.isArray(value[key]) ? 'array' : typeof value[key]"
                :items="types"
                class="json-editor-scope-type-select"
                @input="emitType(key, $event)"/>
            <XTextField
                label="Value"
                v-if="typeof value[key] === 'string'"
                :value="value[key]"
                @input="emitInput(key, $event)"/>
            <XTextField
                label="Value"
                v-else-if="typeof value[key] === 'number'"
                :value="value[key]"
                type="float"
                @input="emitInput(key, $event)"/>
            <XCheckbox
                v-else-if="typeof value[key] === 'boolean'"
                :value="value[key]"
                @input="emitInput(key, $event)"
                class="json-editor-scope-value-checkbox"
            />
            <div class="json-editor-row-buttons">
              <XBtn
                  v-if="!!value[key] && typeof value[key] === 'object' && !('' in value[key])"
                  icon="mdi-plus-box"
                  color="primary"
                  @click="addValue(key, value[key])"/>
              <XBtn icon="mdi-delete" color="red" @click="deleteValue(key, i)"/>
            </div>
          </InputRow>
        </div>
        <JsonEditorScope
            v-if="expandedScopes[key] && !!value[key]
             && typeof value[key] === 'object' && Object.keys(value[key]).length"
            :value="deepCopy(value[key])"
            :depth="depth + 1"
            :last="i === Object.keys(value).length - 1"
            @input="emitInput(key, $event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XCheckbox from '@/components/basic/XCheckbox.vue';
import XBtn from '@/components/basic/XBtn.vue';
import InputRow from '@/components/basic/InputRow.vue';
import { deepCopy } from "@/js/general";

export default {
  name: 'JsonEditorScope',
  components: {
    InputRow,
    XBtn,
    XCheckbox,
    XTextField,
    XSelect,
  },
  props: {
    value: [Object, Array],
    depth: {
      type: Number,
      default: 0,
    },
    last: Boolean,
  },
  data() {
    return {
      deepCopy: deepCopy,
      types: [
        {
          text: 'String',
          value: 'string',
        },
        {
          text: 'Number',
          value: 'number',
        },
        {
          text: 'Boolean',
          value: 'boolean',
        },
        {
          text: 'Object',
          value: 'object',
        },
        {
          text: 'Array',
          value: 'array',
        },
        {
          text: 'Null',
          value: 'null',
        },
      ],
      defaultValues: {
        'string': '',
        'number': 0,
        'boolean': false,
        'object': {},
        'array': [],
        'null': null,
      },
      keyOrder: [],
      expandedScopes: [],
    };
  },
  created() {
    const keyOrder = [];
    for (const key of Object.keys(this.value)) {
      keyOrder.push(key);
    }
    this.keyOrder = keyOrder;
  },
  watch: {
    value: {
      handler(value) {
        if (!Object.keys(value).length) {
          this.keyOrder = [];
          return;
        }
        if (!this.depth) {
          this.keyOrder = [];
        }
        for (const key of Object.keys(value)) {
          if (!this.keyOrder.includes(key)) {
            this.keyOrder.push(key);
          }
        }
      },
    },
  },
  methods: {
    emitKey(oldKey, newKey) {
      if (newKey && this.keyOrder.find(x => x === newKey) !== undefined) {
        let max = 1;
        for (const key of this.keyOrder) {
          const match = key.match(/(?<=\()\d+(?!=\))/);
          if (match) {
            const intMatch = parseInt(match);
            if (intMatch > max) {
              max = intMatch;
            }
          }
        }
        newKey = `${newKey} (${max + 1})`;
      }
      const newValue = {
        ...this.value,
        [newKey]: this.value[oldKey],
      };
      delete newValue[oldKey];
      const index = this.keyOrder.findIndex(x => x === oldKey);
      this.keyOrder.splice(index, 1, newKey);
      this.$emit('input', newValue);
    },
    emitInput(key, value) {
      if (!Array.isArray(this.value)) {
        this.$emit('input', {
          ...this.value,
          [key]: value,
        });
      } else {
        key = parseInt(key);
        this.$emit('input', [
              ...this.value.slice(0, key),
              value,
              ...this.value.slice(key + 1),
            ],
        );
      }
    },
    emitType(key, value) {
      if (!Array.isArray(this.value)) {
        this.$emit('input', {
          ...this.value,
          [key]: this.defaultValues[value],
        });
      } else {
        key = parseInt(key);
        this.$emit('input', [
              ...this.value.slice(0, key),
              this.defaultValues[value],
              ...this.value.slice(key + 1),
            ],
        );
      }
    },
    addValue(key) {
      let newValue = this.value[key];
      if ('' in newValue) return;
      if (Array.isArray(newValue)) {
        newValue.push('');
      } else {
        newValue[''] = '';
      }

      this.expandedScopes[key] = true;

      if (!Array.isArray(this.value)) {
        this.$emit('input', {
          ...this.value,
          [key]: newValue,
        });
      } else {
        key = parseInt(key);
        this.$emit('input', [
              ...this.value.slice(0, key),
              newValue,
              ...this.value.slice(key + 1),
            ],
        );
      }
    },
    deleteValue(key, i) {
      if (!Array.isArray(this.value)) {
        this.keyOrder.splice(i, 1);
        const newValue = {
          ...this.value,
        };
        delete newValue[key];
        this.$emit('input', newValue);
      } else {
        key = parseInt(key);
        this.keyOrder = [];
        this.$emit('input', [
              ...this.value.slice(0, key),
              ...this.value.slice(key + 1),
            ],
        );
      }
    },
  },
};
</script>

<style scoped>
.json-editor-scope {
  position: relative;
  display: flex;
}

.json-editor-scope-spacer {
  width: 36px;
}

.json-editor-scope-depth-symbol {
  position: relative;
  top: -10px;
  left: 10px;
  width: 15px;
  height: 40px;
  user-select: none;
}

.json-editor-scope-corner {
  position: relative;
  top: -5px;
  left: -1.5px;
}

.json-editor-vertical-line {
  width: 1px;
  height: 30px;
  border-left: 1px solid black;
}

.json-editor-endless-vertical-line {
  position: relative;
  left: 11px;
  width: 1px;
  border-left: 1px solid black;
}

.json-editor-horizontal-line {
  width: 15px;
  height: 1px;
  border-top: 1px solid black;
}

.json-editor-scope-expander {
  cursor: pointer;
  position: relative;
  top: -13px;
  left: -8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.json-editor-scope-expander >>> .v-btn {
  width: 18px;
  height: 18px;
  background-color: white;
}

.json-editor-line-hider {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 1px;
  height: calc(100% - 20px);
  background-color: white;
}

.json-editor-scope-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.json-editor-row {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.json-editor-row-main {
  display: flex;
  gap: 10px;
}

.json-editor-scope-type-select {
  width: 120px;
}

.json-editor-scope-value-checkbox {
  margin-left: 6px;
  width: 20px;
}

.json-editor-row-buttons {
  display: flex;
  margin-top: 2px;
}
</style>