<template>
  <div>
    <XSelect
        :value="voiceCallIds"
        label="Channel ID(s)"
        :items="computedItems"
        multiple
        @input="changeCallIds"
        item-text="text"
        item-value="value"
    />
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import XSelect from '@/components/basic/XSelect';

export default {
  data() {
    return {
      options: options,
      disabledItems: [],
      anyValue: 'none',//before had requirement that if user select 'All' other items should disable, now no need so simply put 'none'
    };
  },
  components: {
    XSelect
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine', 'isValid'],
  mounted() { // to show on new or existing value
    this.changeCallIds(this.voiceCallIds);
  },
  computed: {
    bandOptions() {
      return this.options.voiceCallId;
    },
    computedItems() {
      return this.bandOptions.map(item => {
        return {
          text: item[1],
          value: item[0],
          disabled: this.disabledItems.includes(item[0])
        }
      })
    },
    voiceCallIds: {
      get() {
        let callIdsVal = [];//default
        if (typeof this.valueLine.callIds != "undefined" &&
            typeof this.valueLine.callIds != "undefined" &&
            this.valueLine.callIds.length != 0
        ) {
          callIdsVal = this.valueLine.callIds;
        }
        return callIdsVal;
      },
      set(newVal) {
        this.$set(this.valueLine, "callIds", newVal);
      },
    },
  },
  methods: {
    changeCallIds(value) {
      let initial = [];
      if (value.indexOf(this.anyValue) !== -1 && value.length > 0) {
        this.bandOptions.forEach((item) => {
          if (item[0] != this.anyValue) {
            initial.push(item[0]);
          }
        });
        this.disabledItems = initial;
      } else if (value.length == 0) {
        this.disabledItems = [];
      } else {
        this.disabledItems = [this.anyValue];
      }
      this.$emit('input', value);
    },
  },
};
</script>