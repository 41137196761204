<template>
    <XDialog
      :value="dialog"
      :title="newLdap ? 'New LDAP Content' : 'Edit LDAP Content'"
      :persistent="modified"
      width="870"
      @input="close"
      @save="$emit('save')"
      @click:outside="close">
      <template #dialog-content>
        <div>
          <v-form 
            ref="ldapForm"
            class="content"
            v-model="formValid">
            <!-- <XSelect
              v-if="!newSubscriber"
              v-model="subscriber"
              label="Subscriber"
              :rules="[rules.required]"
              readonly
              :items="allSubscribers"
              validate-immediately
              :style="{ 'margin-top': '-1px 0 8px 0', width: !newSubscriber ? '104%':'100%' }">
              <template v-slot:append-outer>
                <div class="d-flex" style="margin-top: -7px; margin-left: -12px;">
                  <a title="Add">
                    <XBtn icon="mdi-plus-box-outline" @click="subscriberDialog" />
                  </a>                
                </div>
              </template>     
            </XSelect> -->
            <XTextField
              v-model="dataLdap.subscriberName"
              label="Subscriber"
              :rules="[rules.required]"
              readonly
              validate-immediately
              :style="{ 'margin-top': '-1px 0 8px 0' }">
              <!-- , width: !newSubscriber ? '104%':'100%' }"> -->
              <!-- <template v-slot:append-outer>
                <div v-if="!newSubscriber" class="d-flex" style="margin-top: -7px; margin-left: -12px;">
                  <a title="Add">
                    <XBtn icon="mdi-plus-box-outline" @click="subscriberDialog" />
                  </a>
                </div>
              </template> -->
            </XTextField>
            <div class="d-flex" style="margin-bottom: 10px">
              <XSelect
                  ref="service"
                  v-model="dataLdap.serverId"
                  :items="localServers"
                  item-text="name"
                  item-value="id"
                  label="Service"
                  validate-immediately
                  style="width: 100%;"
                  :rules="[rules.required]">
              </XSelect>
              <XBtn icon="mdi-plus-box-outline" @click="serviceDialog = true"/>
            </div>

            <XTextField
              ref="filter"
              v-model="dataLdap.filter"
              label="Filter"
              counter
              max-length="100"
              validate-immediately
              :rules="[rules.required]"
              style="margin-top: -7px">
            </XTextField>
            <XTextField
              ref="base"
              v-model="dataLdap.base"
              label="Base"
              counter
              max-length="1000"
              validate-immediately
              :rules="[rules.required]"
              style="margin-top: -7px">
            </XTextField>
            <AttributesEditor :disabled="!formValid" v-model="attributes" />
          </v-form>
          <UnsavedChangedDialog v-model="unsavedChangesDialog" @yes="dialog = false"/>
          <NewServiceDialog v-model="serviceDialog" :row="ldapContent" @close="close" />
        </div>
        <v-spacer></v-spacer>
      </template>
      <template #dialog-bottom>
        <InputRow>
          <XTextField
              v-if="!newLdap"
              v-model="dataLdap.history.comment"
              label="Commit Message"
              placeholder="Note for versioning"
              class="comment" />
          <XBtn
            text="Save"
            color="save"
            :disabled="!formValid || loading"
            :loading="loading"
            icon="mdi-content-save"
            @click="newLdap ? createLdap() : save()" />
          <XBtn
            v-if="!newLdap"
            text="Save as new"
            color="save"
            icon="mdi-content-save-all"
            @click="createLdap"/>
          <XBtn
            text="Cancel"
            color="secondary"
            icon="mdi-cancel"
            @click="close" />
        </InputRow>
      </template>
      <SubscriberEditDialog v-model="newSubscriberDialog" :row="subscriber" @save="$emit('save')" />
    </XDialog>
  </template>
  
<script>
import XDialog from '@/components/basic/XDialog.vue';
import NewServiceDialog from '@/components/specific/NewServiceDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XBtn from '@/components/basic/XBtn.vue';
import AttributesEditor from '@/components/specific/AttributesEditor.vue';
import SubscriberEditDialog from '@/components/specific/SubscriberEditDialog.vue';
import UnsavedChangedDialog from "@/components/extended/UnsavedChangesDialog.vue";
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import {deepCopy} from '@/js/general';

export default {
  name: 'LdapDialog',
  components: {
    UnsavedChangedDialog,
    InputRow,
    XDialog,
    NewServiceDialog,
    XSelect,
    XBtn,
    XTextField,
    AttributesEditor,
    SubscriberEditDialog,
},
  props: {
    value: Boolean,
    newLdap: {
      type: Boolean,
      default: true
    },
    ldapContent: Object,
  },
  data() {
    return {
      allSubscribers: [],
      dataLdap: {
        history: {
          comment: '',
          createDate: 0,
          modifyDate: 0,
          version: 1,
        },
        serverId: 0,
        filter: "",
        base: "",
        attributes: "",
      },
      dialog: false,
      formValid: false,
      ldapId: 0,
      loading: false,
      localServerNames: [],
      localServers: [],
      modified: false,
      newSubscriberDialog: false,
      originalLdap: {},
      rules: {
        required: value => !!value || '',
      },
      serviceDialog: false,
      subscriber: {},
      type: "LDAP",
      unsavedChangesDialog: false,
    };
  },
  mounted() {
    this.originalLdap = deepCopy(this.dataLdap);
  },
  computed: {
    attributes: {
      get() {
        const attributes = [];
        if (this.ldapContent['attributes'] !== undefined) {
          attributes.length = 0; // reset
          Object.entries(this.ldapContent["attributes"]).map(([key, value]) => {

            // for one value for one attribute
            if (typeof value === 'string') {
              const row = { attribute: "", values: [] };
              row["attribute"] = key;
              row["values"].push({ required: true, value: value });
              attributes.push(row);
            }

            // for multiple values for one attribute
            else if (Array.isArray(value)) {
              const row = { attribute: "", values: [] };
              row["attribute"] = key;
              for (const val of value) {
                row["values"].push({ required: true, value: val });
              }
              attributes.push(row);
            }
          });
        }
        return attributes;
      },
      set(newValue) {
        const ldapAttributes = {};
        for (const attr of newValue) {
          if (attr["values"].length === 1) {
            for (const val of attr["values"]) {
              ldapAttributes[attr["attribute"]] = val["value"];
            }
          } else {
            ldapAttributes[attr["attribute"]] = attr["values"].map(value => value.value);
          }
        }
        this.dataLdap.attributes = ldapAttributes;
      },
    },
  },
  methods: {
    close() {
      if (!this.modified) {
        this.$emit('input', false);
        this.dialog = false;
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    createLdap() {
      this.dataLdap.attributes = JSON.stringify(this.dataLdap.attributes);
      this.loading = true;
      testCaseInfoService.newLDAPContent(this.dataLdap, () => {
        this.$emit('save');
        this.modified = false;
        this.loading = false;
        this.close();
      });
    },
    save() {
      this.dataLdap.attributes = JSON.stringify(this.dataLdap.attributes);
      this.loading = true;
      testCaseInfoService.saveLDAPContent(
        this.dataLdap.subscriberId,
        this.dataLdap,
        () => {
          this.$emit('save');
          this.modified = false;
          this.loading = false;
          this.close();
      });
    },
    setDataLdap(ldap) {
      if (!this.newLdap) {
        this.dataLdap['id'] = ldap.id;
        this.dataLdap['serverId'] = ldap.serverId;
        this.dataLdap['service'] = ldap.service;
        this.dataLdap['filter'] = ldap.filter;
        this.dataLdap['base'] = ldap.base;
        this.dataLdap['attributes'] = ldap.attributes;
      }
      this.dataLdap['subscriberName'] = ldap.subscriberName;
      this.dataLdap['subscriberId'] = ldap.subscriberId;
      this.originalLdap = deepCopy(this.dataLdap);
      this.modified = false;
    },
    subscriberDialog() {
      this.newSubscriberDialog = true;
    },
  },
  created() {
    testCaseInfoService.getLocalServers(this.type, (localServers) => {
      this.localServerNames = localServers.map(server => server.name);
      this.localServers = localServers;
    });
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.ldapContent || !value) return;
        this.dialog = value;
        this.setDataLdap(this.ldapContent);
      }
    },
    dataLdap: {
      deep: true,
      handler(value) {
        // Using JSON.stringify() because they are still different objects in memory
        this.modified = JSON.stringify(this.originalLdap) !== JSON.stringify(value) && Object.keys(this.originalLdap).length > 0;
      }
    },
    dialog: {
      immediate: true,
      handler(value) {
        if (!value) this.$emit('input', false);
      }
    },
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 16px;
}
</style>