<script>
import { defineComponent, computed, ref, watch, shallowReactive } from "vue";
import { useRouter } from "vue-router/composables";
import { shaKey } from "@/js/helper";
import { vuetifyConfig } from "@/plugins/vuetify";
import { use, graphic } from "echarts/core";
import { CustomChart } from "echarts/charts";
import { TooltipComponent, GridComponent, DataZoomComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import acctopusLightTheme from "@/js/acctopus-light.json";
import testStatus from "@/cfg/testStatus.json";

use([TooltipComponent, GridComponent, CustomChart, CanvasRenderer, DataZoomComponent]);

const DAY = 84600000;
const TO_TIME = +new Date();
const FROM_TIME = TO_TIME - DAY;
const GRID_HEIGHT = 40;
const TEST_STATUS_MAP_BY_ID = new Map(testStatus.map(t => [t.id, t]));

const tooltip = {
  formatter(params) {
    const startTime = new Date(params.value[1]);
    const endTime = new Date(params.value[2]);
    const testCaseName = params.value[3];
    const resultInfo = params.value[4]

    const formatTime = function (date) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    const fromTime = formatTime(startTime);
    const toTime = formatTime(endTime);

    const tooltip = `${testCaseName} | ${fromTime} - ${toTime} <br/> ${params.marker}${params.name}`;

    if (resultInfo) {
      return tooltip + ` | ${resultInfo}`;
    }

    return tooltip;
  }
};

const grid = {
  height: GRID_HEIGHT,
  left: 16,
  right: 16,
  top: 2,
  bottom: 2,
};

export default defineComponent({
  components: {
    VChart
  },

  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
    chartWidth: {
      type: Number,
      default: 400,
    },
    chartHeight: {
      type: Number,
      default: 180,
    },
    toMs: {
      type: Number,
      default: TO_TIME,
    },
    fromMs: {
      type: Number,
      default: FROM_TIME
    }
  },

  setup(props) {
    const chart = ref(null);
    const zoomPoints = shallowReactive({ start: 0, end: 100 });
    const router = useRouter();

    function createSeriesDataEntry(statisticEntry) {
      const {
        status,
        endTime: endTimeSec,
        startTime: startTimeSec,
        name,
        id: testId,
        resultInfo
      } = statisticEntry;
      let [endTimeMs, startTimeMs] = [endTimeSec * 1000, startTimeSec * 1000];

      if (startTimeMs - props.fromMs < 0) {
        return null;
      }

      if (endTimeMs <= 0) {
        endTimeMs = Date.now();
      }

      const testStatus = TEST_STATUS_MAP_BY_ID.get(status);

      return {
        value: [0, startTimeMs, endTimeMs, name, resultInfo, testId],
        name: testStatus.text,
        itemStyle: {
          normal: {
            color: vuetifyConfig.theme.themes.light[testStatus.color],
          }
        }
      }
    }


    const xAxis = {
      min: props.fromMs,
      max: props.toMs,
      scale: true,
      interval: 4 * 3600000,
      axisLabel: {
        formatter: function (val) {
          const date = new Date(val);
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
        }
      }
    };


    const data = computed(() => props.statisticsData?.map(createSeriesDataEntry).filter(Boolean));
    const option = computed(() => ({
      tooltip,
      grid,
      xAxis,
      yAxis: { data: [] },
      series: [
        {
          type: "custom",
          renderItem(params, api) {
            const categoryIndex = api.value(0);
            const start = api.coord([api.value(1), categoryIndex]);
            const end = api.coord([api.value(2), categoryIndex]);
            const height = api.size([0, 1])[1] * 0.6;
            const rectShape = graphic.clipRectByRect(
              {
                x: start[0],
                y: start[1] - height / 2,
                width: end[0] - start[0],
                height,
              },
              {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height
              }
            );

            return (
              rectShape && {
                type: "rect",
                transition: ["shape"],
                shape: rectShape,
                style: api.style()
              }
            );
          },
          itemStyle: {
            opacity: 0.8,
            height: GRID_HEIGHT,
          },
          encode: {
            x: [1, 2],
            y: 0
          },
          // DATA TO DISPLAY
          data: data.value,
        }
      ],
      dataZoom: [
        {
          type: "inside",
          orient: "horizontal",
        }
      ]
    })
    );

    const openTestInfo = (e) => {
      const id = e.data.value[e.data.value.length - 1];
      const idKey = shaKey(id.toString());
      const route = router.resolve({ name: "test-info", params: { id, idKey } })
      window.open(route.href, "_blank");
    };

    let unwatch = null;
    const toggleWatcher = () => {
      if (zoomPoints.start === 0 && zoomPoints.end === 100 && unwatch) {
        unwatch();
        return;
      } else if (unwatch) {
        return;
      }

      unwatch = watch(data, () => {
        chart.value.setOption({
          dataZoom: [{
            start: zoomPoints.start,
            end: zoomPoints.end,
          }],
        });
      });
    };

    const rememberZoom = (zoomEvnt) => {
      const { batch } = zoomEvnt;
      const [zoom,] = batch;
      const { start, end } = zoom;
      zoomPoints.start = start;
      zoomPoints.end = end;
      toggleWatcher();
    };

    return {
      acctopusLightTheme,
      openTestInfo,
      chart,
      rememberZoom,
      option,
    };
  }
});
</script>

<template>
  <div
    class="explorer-status-chart"
    :style="`--chart-width: ${chartWidth}px; --chart-height: ${chartHeight}px;`"
  >
    <VChart
      class="explorer-status-chart__chart"
      ref="chart"
      :option="option"
      :theme="acctopusLightTheme"
      @click="(e) => openTestInfo(e)"
      @datazoom="(e) => rememberZoom(e)"
    />
  </div>
</template>

<style lang="scss">
.explorer-status-chart {
  height: var(--chart-height, 180px);
  width: 100%;
  padding: 6px 0;
}
</style>
