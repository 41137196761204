<template>
  <div class="api-documentation-view">
    <div class="api-documentation-description-container">
      <div class="api-documentation-description">
        <h1>API Documentation</h1>
        <div>This is the Acctopus Degust® API Documentation.</div>
      </div>
    </div>
    <iframe :src="iframeSrc" width="100%" class="api-documentation"/>
  </div>
</template>

<script>
import customerApiService from '@/js/services/CustomerApiService';

export default {
  name: 'ApiDocumentationView',
  data() {
    return {
      iframeSrc: '',
    };
  },
  created() {
    customerApiService.getDocumentation((documentation) => {
      const blob = new Blob([documentation], {type: 'text/html'});
      if (this.iframeSrc) {
        URL.revokeObjectURL(this.iframeSrc);
      }
      this.iframeSrc = URL.createObjectURL(blob);
    });
  },
};
</script>

<style scoped>
.api-documentation-view {
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.api-documentation-description-container {
  margin: 0 23px 0 8px;
}

.api-documentation-description {
  width: 1460px;
  margin: 0 auto;
  padding: 0 20px;
}

.api-documentation {
  border: none;
  flex: 1 0 auto;
}
</style>