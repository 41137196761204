<script setup>
import { ref, onMounted, defineProps, inject } from "vue";
import UiDialog from "@/components/basic/dialog/UiDialog.vue"
import { getMdPreviewComponent } from "@/js/get-md-preview"
import { useHelpStore } from "@/composition/help/use-help-store";

const { MdPreviewComponent } = getMdPreviewComponent();

const props = defineProps({
  helpId: {
    type: String,
    required: true,
  }
})

const { getCloseMethod } = inject("DialogsRoot");
const _close = getCloseMethod("HelpDialog");
const { getHelpText, isLoading } = useHelpStore();

const content = ref("");

const close = () => {
  _close();
}

onMounted(async () => {
  content.value = await getHelpText({ id: props.helpId });
})
</script>

<template>
  <UiDialog
    title="Help"
    size="small"
    @close-modal="() => close()"
  >
    <template #body>
      <div
        v-if="isLoading"
      >
        <span class="skeleton skeleton--first"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
      </div>

      <MdPreviewComponent
        v-else
        :text="content"
      />
    </template>
  </UiDialog>
</template>

<style lang="scss">

// TODO: move to separate file, if accepted
@keyframes animate-skeleton {
  from {
    transform: translateX(-100%) translateY(-50%);
  }

  to {
    transform: translateX(100%) translateY(-50%);
  }
}

@function generateLorem($length: 5, $base: "-") {
  $correct-length: round($length * 1.3);

  $result: "";

  @for $_ from 1 through $correct-length {
    $result: $result + $base;
  }

  @return $result;
}

@mixin base-text-skeleton(
  $length: 5,
  $line-height: 1.4,
  $background-color: transparent,
  $animation-color: transparent,
  $animation-time: 1
) {
  $percent: 1 / $line-height * 100;

  &:empty {
    position: relative;
    overflow: hidden;

    background-image: linear-gradient(
      0deg,
      transparent calc((100% - #{$percent}) / 2),
      $background-color calc((100% - #{$percent}) / 2),
      $background-color calc(#{$percent} + (100% - #{$percent}) / 2),
      transparent calc((100% - #{$percent}) / 2 + #{$percent})
    );

    color: transparent;

    &:before {
      content: generateLorem($length);
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;

      z-index: 1;
      display: block;

      width: 100%;
      height: #{$percent}+ "%";
      border: none;
      border-radius: 2px;

      background-image: linear-gradient(
        90deg,
        $background-color,
        $animation-color 50%,
        $background-color
      );

      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-animation: animate-skeleton #{$animation-time}s infinite;
      animation: animate-skeleton #{$animation-time}s infinite;
    }
  }
}

@mixin base-inline-skeleton(
  $length: 5,
  $percent: 90,
  $background-color: transparent,
  $animation-color: transparent,
  $animation-time: 1
) {
  &:empty {
    position: relative;
    overflow: hidden;

    background-image: linear-gradient(
      0deg,
      transparent calc((100% - #{$percent}) / 2),
      $background-color calc((100% - #{$percent}) / 2),
      $background-color calc(#{$percent} + (100% - #{$percent}) / 2),
      transparent calc(#{$percent} + (100% - #{$percent}) / 2)
    );

    color: transparent;

    &:before {
      content: generateLorem($length);
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;

      z-index: 1;
      display: block;

      width: 100%;
      height: #{$percent}+ "%";
      border: none;
      border-radius: 8px;

      background-image: linear-gradient(
        90deg,
        $background-color,
        $animation-color 50%,
        $background-color
      );

      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-animation: animate-skeleton #{$animation-time}s infinite;
      animation: animate-skeleton #{$animation-time}s infinite;
    }
  }
}

$whitesmoke: #f6f6f6;
$gainsboro: #dcdcdc;
$dark-theme-skeleton-bg: $whitesmoke;
$dark-theme-skeleton-animation-bg: $gainsboro;

@mixin dark-text-skeleton(
  $length: 5,
  $line-height: 1.4,
  $background-color: $dark-theme-skeleton-bg,
  $animation-color: $dark-theme-skeleton-animation-bg,
  $animation-timing: 1
) {
  @include base-text-skeleton(
    $length,
    $line-height,
    $background-color,
    $animation-color,
    $animation-timing
  );
}

@mixin dark-inline-skeleton(
  $length: 5,
  $percent: 90,
  $background-color: $dark-theme-skeleton-bg,
  $animation-color: $dark-theme-skeleton-animation-bg,
  $animation-timing: 1
) {
  @include base-inline-skeleton(
    $length,
    $percent,
    $background-color,
    $animation-color,
    $animation-timing
  );
}

.skeleton {
  display: inline-block;
  width: 100%;
  height: 24px;
  @include dark-text-skeleton();

  &--first {
    height: 32px;
    width: 42%;
  }
}

</style>
