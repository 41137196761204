<template>
  <div @click="openTestStatusView(row['c_name'])">
    <XChart v-if="!!value" :value="value" class="scheduling-results-chart"/>
  </div>
</template>

<script>
import XChart from '@/components/basic/XChart.vue';
import chartService from '@/js/services/ChartService';

export default {
  props: {
    row: Object,
  },
  components: {XChart},
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.getSchedulingResults();
  },
  watch: {
    '$store.state.scheduledTestsHistoryResolution'() {
      this.getSchedulingResults();
    },
  },
  methods: {
    getSchedulingResults() {
      chartService.getSchedulingResults(
          this.row['n_id'],
          '-24h',
          this.$store.state.scheduledTestsHistoryResolution,
          (schedulingResults) => {
            this.value = {
              options: schedulingResults,
            };
          },
      );
    },
    openTestStatusView(scheduledTestName) {
      this.$store.commit('setTestStatusSearch', scheduledTestName);
      this.$router.push({ name: "test-status" })
    },
  },
};
</script>

<style scoped>
.scheduling-results-chart {
  width: 360px;
  height: 60px;
}
</style>
