<template>
  <div>
    <v-alert
        style="background-color: #5a83d9 !important;"
        type="info"
        transition="scroll-y-transition"
        v-if="stencilInfo"
    >
      {{ language.stencil.stepInfo }}
    </v-alert>
    <v-expansion-panels multiple v-model="panel">
      <draggable
          @start="dragging = true"
          @end="endDrag"
          class="step-list"
          v-model="templateValueArray"
          handle=".handle"
          :group="{
          name: groupValue,
          put: true,
          pull: getDraggablePull(groupValue),
        }"
      >
        <step
            v-for="(step, stepID) in templateValue"
            :key="step.stepIdentifier"
            :stepID="stepID"
            :step="step"
            :result="result"
            :templateValue="templateValue"
            :groupedStepDependentInfo="groupedStepDependentInfo"
            @step-value-changed="updateStep(stepID, ...arguments)"
            @remove-step="removeStep(stepID)"
            @insert-next-step="insertNext(stepID)"
            @copy-step="copyStep(stepID)"
            @copy-stencil-steps="copyStencilStep(...arguments,stepID)"
            :isValid="isValid"
            @revalidate="$emit('revalidate')"
            @changeIsValid="$emit('changeIsValid', $event)"
            :forWhat="forWhat"
            :groupValue="groupValue"
        ></step>
      </draggable>
    </v-expansion-panels>
    <div class="pt-3"></div>
    <primaryButton
        label="Add Step"
        icon="mdi-plus"
        @click="addStep()"
        type="button"
        color="primary"
        :disabled="disabled || !isValid"
        id="addStepButton"
    ></primaryButton>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import step from "@/components/legacy/step.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import {arrayMergeOnPosition} from "@/js/helper";
import requests from "@/js/requests";

export default {
  components: {
    step,
    primaryButton,
    draggable,
  },
  props: [
    "result",
    "groupedStepDependentInfo",
    "templateValue",
    "stepsGroups",
    "groupValue",
    "isValid",
    "forWhat"
  ],
  data: function () {
    return {
      panel: [],
      stepIncrement: 1,
      objectsArray: {},
      template: {},
      dragging: false,
      old: null,
      //stencilInfo: false,
      // stepsIdentifiers: [],
    };
  },
  created() {
    if (
        typeof this.templateValue == "object" &&
        Object.keys(this.templateValue).length == 1
    ) {
      this.panel = [0];
    }
  },
  watch: {
    /*    stencilInfo(value){
          if(value){
             setTimeout(()=>{this.stencilInfo=false},10000)
          }
        },
    'result.valueLine.c_type': function (value) {
      this.stencilInfo = false;
      if (value == "stencil" && (this.groupValue == "pre" || this.groupValue == "post")) {
        this.stencilInfo = true;
      }
    },*/
    panel(newVal, old) {
      if (!this.arraysEqual(newVal, old)) {
        this.$emit("revalidate");
        if (!this.isValid) {
          //this.old = old;
          let merged = newVal.concat(old);
          this.panel = merged.filter(
              (item, pos) => merged.indexOf(item) === pos
          );
        }
      }
    },
  },
  computed: {
    stencilInfo() {
      let stencilInfo = false;
      if (this.result.valueLine.c_type === "stencil" && (this.groupValue == "pre" || this.groupValue == "post")) {
        stencilInfo = true;
      }
      return stencilInfo;
    },
    disabled() {
      let disabled = false;
      //console.log(this.result);
      if (
          typeof this.result != "undefined" &&
          typeof this.result.disableAllInputs != "undefined" &&
          this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    templateValueArray: {
      //get and set for re-order the drag and drop items
      get() {
        let templateValueArray = [];
        if (typeof this.templateValue != "undefined") {
          templateValueArray = Object.values(this.templateValue);
        }
        return templateValueArray;
      },
      set(newValue) {
        this.$emit("group-value-changed", this.convertToStepObject(newValue));
      },
    },
  },
  methods: {
    arrayMergeOnPosition: arrayMergeOnPosition,
    endDrag() {
      this.dragging = false;
      this.$store.commit("stepResorted");
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.
      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    getDraggablePull(groupValue) {
      if (typeof this.stepsGroups != "undefined") {
        let group = Object.values(this.stepsGroups);
        group = group.filter((item) => item !== groupValue);
        return group;
      }
    },
    updateStep(stepID, value) {
      let local = {...this.templateValue};
      this.$set(local, stepID, value);
      this.$emit("group-value-changed", local);
    },
    convertToStepObject(local) {
      let stepObject = {};
      let caller = this;
      local.forEach((element, index) => {
        caller.$set(stepObject, index + 1, element);
      });
      return stepObject;
      // return local;
    },
    removeStep(stepID) {
      let local = {...this.templateValue};
      // console.log(this.templateValue);
      this.$delete(local, stepID);
      let panel = [];
      this.panel.forEach((element) => {
        if (stepID < element) {
          panel.push(element);
        } else if (stepID < element) {
          panel.push(element - 1);
        }
      });
      this.panel = panel;

      this.$emit(
          "group-value-changed",
          this.convertToStepObject(Object.values(local))
      );
    },
    copyStencilStep(testcaseId, stepID) {
      delete this.templateValue[stepID];
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: {
          function: 'getStencilSteps',
          testcaseId: testcaseId
        },
      })
          .then(response => {
            let copiedStencilObj = Object.values(JSON.parse(response.data.result.json)); //json into obj
            let existingSteps = Object.values(this.templateValue);
            let stencilStep = Object.values(copiedStencilObj);//obj to array
            let mergedSteps = this.arrayMergeOnPosition(existingSteps, stencilStep, stepID - 1);
           /* console.log("stencilStep",stencilStep);
            console.log("existingSteps",existingSteps);
            console.log("mergedSteps",mergedSteps);*/
            this.$emit("group-value-changed", this.convertToStepObject(mergedSteps));
          })
          .catch(error => {
            console.log(error);
          });
    },
    copyStep(stepID) {
      let local = Object.values(this.templateValue);
      let copiedStep = JSON.parse(JSON.stringify(this.templateValue[stepID]));
      local.splice(parseInt(stepID), 0, copiedStep);
      this.panel.push(parseInt(stepID));
      this.$emit("group-value-changed", this.convertToStepObject(local));
    },
    insertNext(stepID) {
      let local = Object.values(this.templateValue);
      local.splice(parseInt(stepID), 0, {});
      this.panel.push(parseInt(stepID));
      this.$emit("group-value-changed", this.convertToStepObject(local));
    },
    addStep() {
      this.$emit("revalidate");
      if (this.isValid) {
        let local = {...this.templateValue};
        let checkArray = [];
        if (typeof this.templateValue != "undefined") {
          checkArray = Object.keys(this.templateValue).map(function (item) {
            return parseInt(item, 10);
          });
        }
        let maxKey = 0;
        if (checkArray.length > 0) {
          maxKey = Math.max(...checkArray);
        }
        //this.$set(local, local.length + 1, {});
        this.$set(local, maxKey + 1, {});
        this.$emit("group-value-changed", local);
        this.panel.push(Object.keys(local).length - 1);
      }
    },
  },
};
</script>
<style>
.step-list {
  width: 100%;
}
</style>