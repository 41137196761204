var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldsValues',{attrs:{"fieldAttributes":{ 'type': 'text',
                                'fieldAttrInput': { 'class': 'required ',
                                                 'maxlength': 100 },
                                                 'name': 'Name'
                                               },"field":"c_name","templateContent":_vm.result,"value":_vm.result.valueLine.c_name,"valueLine":_vm.result.valueLine,"type":"text"}}),_c('v-row',[_c('v-col',[_c('simVirtualObject',{staticClass:"mb-5",attrs:{"fieldAttributes":{ 'type': 'c_iccid',
                                'fieldAttrInput': { 'class': 'required number object imsivalue',
                                                    'maxlength': 20 },
                                                    'name': 'ICCID',
                                                    'buttonName':'Add Virtual ICCID'
                          },"field":"c_iccid","result":_vm.result,"value":_vm.result.valueLine.c_iccid},on:{"input":function($event){return _vm.updateValues(...arguments,'c_iccid')}}})],1),_c('v-col',[_c('simVirtualObject',{staticClass:"mb-5",attrs:{"fieldAttributes":{ 'type': 'text',
                                'fieldAttrInput': { 'class': 'required number object imsivalue',
                                                    'maxlength': 20 },
                                                    'name': 'IMSI',
                                                    'buttonName':'Add Virtual IMSI'
                          },"field":"c_imsi","result":_vm.result,"value":_vm.result.valueLine.c_imsi},on:{"input":function($event){return _vm.updateValues(...arguments,'c_imsi')}}})],1)],1),_c('fieldsValues',{attrs:{"fieldAttributes":{ 'type': 'text',
                                'fieldAttrInput': { 'class': 'required ',
                                                 'maxlength': 100 },
                                                 'name': 'Issuer'
                                               },"field":"c_issuer","templateContent":_vm.result,"value":_vm.result.valueLine.c_issuer,"valueLine":_vm.result.valueLine,"type":"text"}}),_c('v-row',[_c('v-col',[_c('associatedOption',{attrs:{"fieldAttributes":{ name: 'Type', associatedOption:  [
            ['physical', 'Physical'],
            ['virtual', 'Virtual'],
            ['eSIM', 'eSIM']
          ],
           sort:false
  },"type":"select","fieldAttrInput":{ class: 'required ' },"field":"c_type","value":_vm.result.valueLine.c_type,"templateContent":_vm.result},model:{value:(_vm.typeSelect),callback:function ($$v) {_vm.typeSelect=$$v},expression:"typeSelect"}})],1),_c('v-col',[_c('fieldsValues',{attrs:{"fieldAttributes":{ name: 'MSISDN',
                          type:  'combobox',
                          addingURL: 'serve.php?f=configuration&f2=msisdn',
                          associatedOption:_vm.result.additional.msisdn,
                          fieldAttrInput: { 'class': 'required'  },
                          sort: false,
                          value:_vm.result.valueLine.n_msisdn
                         },"field":"n_msisdn","templateContent":_vm.result,"value":_vm.result.valueLine.n_msisdn,"valueLine":_vm.result.valueLine},model:{value:(_vm.msisdnSelect),callback:function ($$v) {_vm.msisdnSelect=$$v},expression:"msisdnSelect"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }