<template>
  <v-col class="ml-3 mt-1" style="">
    <template v-if="Object.keys(testlocal).length>0">
      <v-row dense>
        <v-col style="max-width: 120px; color: gray;">Testlocal</v-col>
        <v-col style="word-break: break-all">
          <div v-for="(value, name) in testlocal" :key="name" class="test-variables-local">
            {{ name }}={{ value }}
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-if="Object.keys(testgroup).length>0">
      <v-row dense>
        <v-col style="max-width: 120px; color: gray; text-transform: capitalize">testgroup</v-col>
        <v-col style="word-break: break-all">
          <div v-for="(value, name) in testgroup" :key="name">
            {{ name }}={{ value }}
          </div>
        </v-col>
      </v-row>
    </template>
  </v-col>
</template>
<script>
import {sortObjectByKeys} from '@/js/helper.js';

export default {
  props: ['fieldAttributes', 'value', 'result'],
  methods: {},
  computed: {
    testlocal() {
      let result = '';
      if (typeof this.value === 'object' && Object.keys(this.value).length !== 0) {
        result = sortObjectByKeys(this.value);
      }
      return result;
    },
    testgroup() {
      let result = '';
      if (typeof this.result.globalTestVariables !== 'undefined' && this.result.globalTestVariables != null && typeof this.result.globalTestVariables === 'object' && Object.keys(
          this.result.globalTestVariables).length !== 0) {
        result = sortObjectByKeys(this.result.globalTestVariables);
      }
      return result;
    },
  },
};
</script>

<style scoped>
.test-variables-local {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
</style>