<template>
  <div>
    <buildFieldsRows
        :templateContent="smppHeader"
        v-model="smppHeaderValue"
        @updated-fields="emitUpdateFields"
        languageIndex="smppStep"
    ></buildFieldsRows>
    <headline-box headline="Request" padded>
      <primaryTextfield
          type="msisdn"
          :fieldAttrInput="{class:'' ,id:'step_SMPP_msisdn'}"
          label="MSISDN"
          v-model="msisdn"
          :templateContent="result"
      ></primaryTextfield>
      <defaultFieldTypes
          :fieldAttributes="{ type:'textarea',name:'Text', fieldAttrInput:{ id:'step_SMPP_text',  class:'',  }  }"
          @input="updateRequestKey('text',...arguments)"
          :templateContent="result"
          :value="text">
      </defaultFieldTypes>
    </headline-box>
    <onTimeoutNoRetry
        onEvent="On Timeout"
        v-model="onTimeout"
        :result="result"
    ></onTimeoutNoRetry>
  </div>
</template>
<script>
import onTimeoutNoRetry from "@/components/legacy/onTimeoutNoRetry.vue";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import HeadlineBox from '@/components/basic/HeadlineBox';
import requests from '@/js/requests';

export default {
  components: {
    onTimeoutNoRetry,
    defaultFieldTypes,
    primaryTextfield,
    HeadlineBox,
    buildFieldsRows,
  },

  asyncComputed: {
    async stepInfo() {
      let stepInfo = {};
      let additionalStepsInfo = this.result.additional.additionalStepsInfo;
      if (typeof additionalStepsInfo != "undefined" &&
          typeof additionalStepsInfo["SMPP"] != "undefined") {
        stepInfo = additionalStepsInfo["SMPP"];
        return stepInfo;
      } else {
        return await new Promise((resolve) => {
          requests.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: {
              function: 'getSMPPClientServer',
              n_systemId: '',
            },
          })
              .then(function (post) {
                let stepInfo = post.data.result.json;

                resolve(stepInfo);
              })
              .catch((error) => {
                console.log(error);
              });
        });
      }
    },
  },
  computed: {
    msisdn: {
      get() {
        return this.req.msisdn;
      },
      set(newVal) {
        this.updateRequestKey("msisdn", newVal);
      },
    },
    text: {
      get() {
        return this.req.text;
      },
      set(newVal) {
        this.updateRequestKey("text", newVal);
      },
    },
    req() {
      let req = {};
      if (typeof this.stepStoredValue.req != "undefined") {
        req = this.stepStoredValue.req;
      }
      return req;
    },
    connectionId: {
      get() {
        let connectionId = "";
        if (typeof this.stepStoredValue.parameters != "undefined") {
          connectionId = this.stepStoredValue.parameters.connectionId;
        }
        return connectionId;
      },
      set(newVal) {
        //console.log("connection chaged");
        if (typeof this.stepStoredValue.parameters == "undefined") {
          this.$set(this.stepStoredValue, "parameters", {});
        }
        this.$set(this.stepStoredValue.parameters, "connectionId", newVal);
      },
    },
    smppHeaderValue: {
      get() {
        let smppHeaderValue = {
          connectionId: this.connectionId,
          msisdn: this.req.msisdn,
          text: this.req.text,
        };
        return smppHeaderValue;
      },
      set(newVal) {
        //console.log(newVal);
        this.connectionId = newVal.connectionId;
        if (typeof newVal.req != 'undefined' && typeof newVal.req.msisdn != 'undefined') {
          this.$set(this.req, "msisdn", newVal.req.msisdn);
        }
        if (typeof newVal.req != 'undefined' && typeof newVal.text != 'undefined') {
          this.$set(this.req, "text", newVal.req.text);
        }
       // console.log(newVal)
        this.$emit("stored-value-changed", this.localStoredStep);
      },
    },
    smppHeader() {
      let smppHeader = {
        fields: {
          connectionId: {
            type: "select",
            fieldAttrInput: {class: "required" },
          },
        },
        enableAdd: false,
        stepStoredValue: this.stepStoredValue,
        subType: this.stepStoredValue.subType,
        category: this.stepStoredValue.category,
        stepType: "SMPP",
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.stepType,
      };
      //console.log(smppHeader);
      if (this.stepInfo != null) {
        this.$set(smppHeader.fields.connectionId, "associatedOption", this.stepInfo.connectionsArray);
      }
      return smppHeader;
    },
    localStoredStep: {
      get() {
        let localStoredStep = {req: {}, res: {}};
        if (typeof this.stepStoredValue != "undefined") {
          localStoredStep = this.stepStoredValue;
          if (typeof localStoredStep["req"] == "undefined") {
            this.$set(localStoredStep, "req", {});
          }
          if (typeof localStoredStep["res"] == "undefined") {
            this.$set(localStoredStep, "res", {});
          }
        }
        return localStoredStep;
      },
      set(newValue) {
        //console.log("new local value set");
        this.$emit("stored-value-changed", newValue);
      },
    },
    onTimeout: {
      get() {
        var onTimeout = {};
        if (typeof this.localStoredStep != "undefined" &&
            typeof this.localStoredStep["res"] != "undefined") {
          if (typeof this.localStoredStep.res.onTimeout != "undefined") {
            onTimeout = this.localStoredStep.res.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        if (typeof this.localStoredStep["res"] == "undefined") {
          this.$set(this.localStoredStep, "res", {});
        }
        this.$set(this.localStoredStep["res"], "onTimeout", newVal);
      },
    },
    onNoResult: {
      get() {
        var onNoResult = {};
        if (typeof this.localStoredStep != "undefined" &&
            typeof this.localStoredStep["res"] != "undefined") {
          if (typeof this.localStoredStep.res.onNoResult != "undefined") {
            onNoResult = this.localStoredStep.res.onNoResult;
          }
        }
        return onNoResult;
      },
      set(newVal) {
        if (typeof this.localStoredStep["res"] == "undefined") {
          this.$set(this.localStoredStep, "res", {});
        }
        this.$set(this.localStoredStep["res"], "onNoResult", newVal);
      },
    },
  },
  methods: {
    emitUpdateFields(info) {
     // console.log("updated-fields emmited");
      this.$set(this.stepInfo, "connectionsArray", info.connectionId.associatedOption);
      this.connectionId = info.connectionId.value;
    },
    updateRequestKey(index, newVal) {
      //console.log(index, newVal);
      if (typeof this.localStoredStep["req"] == "undefined") {
        this.$set(this.localStoredStep.req, 0, {});
      }
      this.$set(this.localStoredStep["req"], index, newVal);
      this.$emit("stored-value-changed", this.localStoredStep);
    },
  },
  props: ["result", "stepStoredValue", "additionalStepsInfo", "isValid"],
};
</script>