<template>
  <div>
    <fieldsValues
        :fieldAttributes="{ 'type': 'text',
                                  'fieldAttrInput': { 'class': 'required ',
                                                   'maxlength': 100 },
                                                   'name': 'Name'
                                                 }"
        field="c_name"
        :templateContent="result"
        :value="result.valueLine.c_name"
        :valueLine="result.valueLine"
        type="text"
    ></fieldsValues>
    <v-row>
      <v-col>
        <simVirtualObject
            :fieldAttributes="{ 'type': 'c_iccid',
                                  'fieldAttrInput': { 'class': 'required number object imsivalue',
                                                      'maxlength': 20 },
                                                      'name': 'ICCID',
                                                      'buttonName':'Add Virtual ICCID'
                            }"
            field="c_iccid"
            :result="result"
            :value="result.valueLine.c_iccid"
            @input="updateValues(...arguments,'c_iccid')"
            class="mb-5"
        >
        </simVirtualObject></v-col>
        <v-col>
          <simVirtualObject
              :fieldAttributes="{ 'type': 'text',
                                  'fieldAttrInput': { 'class': 'required number object imsivalue',
                                                      'maxlength': 20 },
                                                      'name': 'IMSI',
                                                      'buttonName':'Add Virtual IMSI'
                            }"
              field="c_imsi"
              :result="result"
              :value="result.valueLine.c_imsi"
              @input="updateValues(...arguments,'c_imsi')"
              class="mb-5"
          >
          </SimVirtualObject>
        </v-col>
    </v-row>
    <fieldsValues
        :fieldAttributes="{ 'type': 'text',
                                  'fieldAttrInput': { 'class': 'required ',
                                                   'maxlength': 100 },
                                                   'name': 'Issuer'
                                                 }"
        field="c_issuer"
        :templateContent="result"
        :value="result.valueLine.c_issuer"
        :valueLine="result.valueLine"
        type="text"
    ></fieldsValues>
    <v-row>
      <v-col>
        <associatedOption
            :fieldAttributes="{ name: 'Type', associatedOption:  [
              ['physical', 'Physical'],
              ['virtual', 'Virtual'],
              ['eSIM', 'eSIM']
            ],
             sort:false
    }"
            type="select"
            :fieldAttrInput="{ class: 'required ' }"
            field="c_type"
            :value="result.valueLine.c_type"
            :templateContent="result"
            v-model="typeSelect"
        ></associatedOption>
      </v-col>
      <v-col>
        <fieldsValues
            :fieldAttributes="{ name: 'MSISDN',
                            type:  'combobox',
                            addingURL: 'serve.php?f=configuration&f2=msisdn',
                            associatedOption:result.additional.msisdn,
                            fieldAttrInput: { 'class': 'required'  },
                            sort: false,
                            value:result.valueLine.n_msisdn
                           }"
            field="n_msisdn"
            :templateContent="result"
            :value="result.valueLine.n_msisdn"
            :valueLine="result.valueLine"
            v-model="msisdnSelect"
        ></fieldsValues>
      </v-col>
    </v-row>

   </div>
</template>
<script>
import fieldsValues from "@/commonComponents/fieldsValues.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import simVirtualObject from "@/components/legacy/simVirtualObject.vue";
import requests from '@/js/requests';

export default {
  components: {
    fieldsValues,
    associatedOption,
    simVirtualObject
  },
  props: ["fieldAttributes", "field", "result","value"],
  data() {
    return { associatedOption: this.fieldAttributes.associatedOption };
  },
  computed: {
    msisdnSelect: {
      get() {
        console.log(this.result.valueLine.n_msisdn);
        let msisdnSelect = '';
        if (typeof this.result.valueLine.n_msisdn != 'undefined') {
          msisdnSelect = this.result.valueLine.n_msisdn;
        }
        return msisdnSelect;
      },
      set(selected) {
        this.$set(this.result.valueLine, 'n_msisdn', selected);
      },
    },
    typeSelect: {
      get() {
        let typeSelect = '';
        if (typeof this.result.valueLine.c_type != 'undefined') {
          typeSelect = this.result.valueLine.c_type;
        }
        return typeSelect;
      },
      set(selected) {
        this.$set(this.result.valueLine, 'c_type', selected);
      },
    },
  },
  watch: {
    "result.valueLine.n_msisdn": function (selected) {
      this.$set(this.result.valueLine, 'n_msisdn', selected);
      this.getMSISDN();
    },
  },
  methods: {
    updateValues(val,field){
      console.log(val,field);
      this.$set(this.result.valueLine, field, val);
    },
    getMSISDN() {
      let caller = this;
      var options = {
        function: "getListMSISDN",
        requestType: "ajax",
      };
      requests.frameworkAxiosRequest({
        method: 'post',
        url:
            typeof this.result != 'undefined' &&
            typeof this.result.tableUrl != 'undefined'
                ? this.result.tableUrl
                : '',
        data: options,
      })
          .then(function (response) {
            console.log(response.data.result.listMSISDN);
            caller.result.additional.msisdn = response.data.result.json.listMSISDN;
          })
          .catch(function (response) {
            console.log(response);
          });
    },
   },
};
</script>