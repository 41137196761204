<template>
  <div>
    <v-card v-show="!result.showQR">
      <v-card-title>
        <p class="subtitle-1" v-if="isset(result['info']['msg1'])">
          {{ result["info"]["msg1"] }}
        </p>
      </v-card-title>
      <div
        v-if="isset(result['info']['msg2'])"
        class="noteWarn noteBox marginTB10"
      >
        {{ result["info"]["msg2"] }}
      </div>
      <p
        v-if="isset(result['info']['msg3'])"
        class="noteWarn noteBox marginTB10"
        style="background-color: #ff00001c"
      >
        {{ result["info"]["msg3"] }}
      </p>

      <div
        class="notice"
        style="
          height: 22px;
          background-color: #3d6ca2;
          text-align: center;
          padding: 0em;
          color: white;
        "
      >
        {{ fieldAttributes["name"] }}
      </div>
      <v-card-text>
        <div class="notice">
          <ul class="rCodeSet contentExport">
            <li
              v-for="value in fieldAttributes['value']"
              :key="value"
              class="rCode"
            >
              {{ value }}
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
    <div class="2faContainer" v-show="result.showQR">
      <v-row>
        <v-col>
          <div class="subtitle-1">{{ language.tfa_details.title }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="qrBox text-center"><img :src="aURL" /></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="subtitle-1">{{ language.tfa_details.enter_code }}</div>
          <label>{{ language.tfa_details.instruction }}</label>
        </v-col>
      </v-row>
      <!--   <v-row>
                <v-col>
                    <div class="tfa-fail" style="display: none;">{{language.tfa_details.fail_msg}}</div>
                </v-col>
            </v-row>-->
      <v-row id="passCode2FARow">
        <v-col class="d-flex">
          <primaryTextfield
            type="text"
            :fieldAttrInput="{ name: 'passCode2FA' }"
            label="Passcode"
            counter="6"
            v-model="keyBoardValue"
            ref="numInput"
            style="min-width: 500px"
            @input="changeValue(...arguments)"
            :templateContent="result"
            :name="field"
          ></primaryTextfield>
          <div class="text-center">
            <v-dialog v-model="numPadDialog" height="250" width="237">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  style="margin-left: 5px"
                  id="recoverPad"
                >
                  <v-icon> mdi-dialpad </v-icon>
                </v-btn>
              </template>
              <keyboard
                @pressed="keyBoardValue = $event"
                :selfValue="keyBoardValue"
                :result="result"
              ></keyboard>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import keyboard from "@/components/legacy/keyboard.vue";
import { validateOTP } from "@/js/testHelpers.js";

export default {
  components: {
    keyboard,
    primaryTextfield,
  },

  data: function () {
    return {
      aURL: this.fieldAttributes.URL,
      keyBoardValue: "",
      showKeyBoard: false,
      numPadDialog: false,
    };
  },
  methods: {
    changeValue(value) {
      validateOTP(value, this);
    },
  },
  props: ["result", "fieldAttributes", "field"],
};
</script>
<style scoped>
.rCode {
  display: inline-block;
  width: 45%;
  line-height: 1.5;
  text-align: center;
}

.rCodeSet {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
}
.noteBox {
  position: relative;
  padding: 10px;
  color: #032f62;
  background-color: #dbedff;
  border: 1px solid rgba(27, 31, 35, 0.15);
}
.qrBox {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.1), 0 0 0 rgb(255, 255, 255),
    0 0 5px rgba(0, 0, 0, 0.3);
}
</style>