<template>
    <div ref="ldapEditor" class="ldap-editor">
      <LdapContentAdder v-if="showLdapTable" :row="row" />
      <x-btn
        text="Add LDAP Content"
        color="primary"
        icon="mdi-plus"
        @click="addRow" />
      <LdapDialog v-model="dialog" :ldap-content="ldapContent" new-ldap @save="closeDialogs" />
    </div>
  </template>
  
<script>
import XBtn from '@/components/basic/XBtn';
import LdapDialog from '@/components/specific/LdapDialog';
import LdapContentAdder from "@/components/specific/LdapContentAdder.vue";
import testCaseInfoService from "@/js/services/TestCaseInfoService";

export default {
  name: 'LdapEditor',
  components: {
    XBtn,
    LdapContentAdder,
    LdapDialog,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      dialog: false,
      focusedElementIndex: -1,
      showLdapTable: false,
    };
  },
  created() {
    this.ldapExists();
  },
  computed: {
    ldapContent() {
      const ldapContent = {};
      if (this.row) {
        ldapContent['subscriberName'] = `${this.row.subscriber}` ? `${this.row.msisdn}: ${this.row.subscriber}` : `${this.row.msisdn}`;
        ldapContent['subscriberId'] = this.row.subscriberId;
      }
      return ldapContent;
    },
  },
  methods: {
    addRow() {
      this.dialog = true;
    },
    ldapExists() {
      testCaseInfoService.getRawLDAPContents((this.row.subscriberId), (ldap) => {
        this.showLdapTable = ldap.length > 0;
      });
      this.showLdapTable = false;
    },
    closeDialogs() {
      this.dialog = false;
      this.showLdapTable = true;
      this.$emit('close-dialogs');
    },
  },
};
</script>

<style scoped>
.ldap-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>