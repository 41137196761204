<script>
import { computed, defineComponent, ref, onBeforeMount } from "vue";
import XDialog from "@/components/basic/XDialog.vue";
import XSelect from "@/components/basic/XSelect.vue";
import LoadingDialog from "@/components/basic/LoadingDialog.vue";
import { useSwitchContext } from "@/composition/user/use-switch-context";
import { useNotifications } from "@/composition/notifications";
import { useStore } from "@/store";
import useBrowserStorageModel from "@/composition/browser-storage";

export default defineComponent({
  name: "ContextSwitcher",

  components: {
    LoadingDialog,
    XSelect,
    XDialog,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(_, {emit}) {
    const store = useStore();
    const notifications = useNotifications();
    const { doContextSwitch } = useSwitchContext();
    const loading = ref(false);

    const organizationsWithProjects = computed(() => store.getters["userModule/orgsWithProjects"]);
    const currentProjectId = computed(() => store.getters["projectId"]);
    const currentOrgId = computed(() => store.getters["organizationId"]);
    const { model: storedLastProjectIds } = useBrowserStorageModel("localStorage", "last-project-id", {});

    const orgId = ref(currentOrgId.value);
    const projId = ref(currentProjectId.value);

    const switchContext = async () => {
      loading.value = true;

      try {
        storedLastProjectIds.value = { ...storedLastProjectIds.value, [orgId.value]: projId.value };
        await doContextSwitch({ projID: projId.value, orgID: orgId.value });
        location.reload();
      } finally {
        emit("input", false);
        loading.value = false;
      }
    };

    const buttons = [
      {
        text: "Switch",
        icon: "mdi-move-resize-variant",
        color: "save",
        textColor: "white",
        click: switchContext,
        disabled: () => organizationsWithProjects.value.length === 0,
      },
    ];

    const computedProjects = computed(() => {
      if (!orgId.value || !organizationsWithProjects.value.length) {
        return [];
      }

      const org = organizationsWithProjects.value.find(org => org.id === orgId.value);
      if (!org) {
        return organizationsWithProjects.value[0].projects;
      }

      return org.projects;
    });

    const setOrgId = (id) => {
      if (!id) {
        return;
      }

      if (organizationsWithProjects.value.length === 0) {
        return;
      }

      orgId.value = id;

      if (storedLastProjectIds.value[orgId.value]) {
        projId.value = storedLastProjectIds.value[orgId.value];
      } else {
        const orgProjects = organizationsWithProjects.value.find(org => org.id === id)?.projects || [];
        projId.value = orgProjects[0].id || 0;
      }
    };

    onBeforeMount(() => {
      if (organizationsWithProjects.value.length === 0) {
        notifications.showNotification({message: "No organizations found. Please contact your administrator."});
        return;
      }

      const org = organizationsWithProjects.value.find(x => x.id === orgId.value);

      if (!org) {
        orgId.value = organizationsWithProjects.value[0].id;
      }

      if (!storedLastProjectIds.value[orgId.value]) {
        storedLastProjectIds.value = { ...storedLastProjectIds.value, [orgId.value]: projId.value };
      }
    });

    return {
      setOrgId,
      organizationsWithProjects,
      orgId,
      projId,
      loading,
      buttons,
      computedProjects
    };
  },
});
</script>

<template>
  <XDialog
    :value="value"
    title="Switch Project"
    :buttons="buttons"
    @input="$emit('input', $event)"
    content-class="context-switcher-dialog"
  >
    <template #dialog-content>
      <div>
        <div class="context-switcher-dialog-content">
          <div class="context-switcher-text">
            <div>
              Degust follows an organizational and project structure.
            </div>

            <div>
              An organization can be a company you work for. Your first organization was set up when you registered. You
              can
              change the settings of this organization in
              <router-link
                  :to="{ name: 'general-organization-settings' }"
                  @click.native="() => $emit('input', false)"
              >
                this
              </router-link>
              menu.
            </div>
            <div>
              Projects are test suites set up for specific purposes, such as the next release of a service on your
              network.
            </div>
            <div>
              You can work for different organizations/companies and there on different projects each. You have to
              switch to
              these projects via this dialog.
            </div>
          </div>

          <XSelect
            label="Organization"
            :value="orgId"
            :items="organizationsWithProjects"
            item-value="id"
            item-text="name"
            :disabled="organizationsWithProjects.length === 0"
            @input="(id) => setOrgId(id)"
            required
          />

          <XSelect
            label="Project"
            v-model="projId"
            :items="computedProjects"
            item-value="id"
            item-text="name"
            :disabled="organizationsWithProjects.length === 0"
            required
          />
        </div>

        <LoadingDialog v-model="loading"/>
      </div>
    </template>
  </XDialog>
</template>

<style>
.context-switcher-dialog {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<style scoped>
.context-switcher-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.context-switcher-text {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
  color: #666666;
}

.orga-settings-link {
  cursor: pointer;
  color: var(--v-primary-base);
}
</style>
