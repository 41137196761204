<template>
  <div>
    <div class="mb-2 pa-3" v-if="Object.keys(newItems).length!==0">
      <div :style="{ 'max-width' : tableWidth+'px' }">
        <v-row class="primary white--text">
          <template v-for="(value,index) in newItems">
            <v-col
                :key="index"
                class="pa-1 table-index-column text-center"
                style=""
            > {{ languagePack("basicModemTable", index) }}
            </v-col>
          </template>
        </v-row>
        <v-row style="border: solid 1px #dbdbdb">
          <template v-for="(value,index) in newItems">
            <template v-if="typeof value==='object' && Object.keys(value).length!==0">
              <v-col :key="index">
                <template v-for="(innerValue,innerIndex) in value">
                  <template v-if="innerValue!=''">
                    <v-row
                        :key="innerIndex"
                        class="pa-1"
                    >
                      <v-col
                          class="pa-1 "
                          cols="6"
                          :class="textAlignClass"
                      >
                        {{ customIndexName(innerIndex) }}
                      </v-col>
                      <v-col
                          class="pa-1 "
                          cols="6"
                          :class="textAlignClass"
                      >
                        {{ modemUnitConversion(innerValue, innerIndex) }}
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </v-col>
            </template>
            <template v-else>
              <v-col
                  :key="index"
                  class="pa-1 table-value-column text-center"
                  :class="customClass"
                  style="min-width: 30px"
              > {{  modemUnitConversion(value, index)  }}
              </v-col>
            </template>
          </template>
        </v-row>
      </div>
     </div>
  </div>
</template>
<script>
import {objectCustomSort, convertMs, convertSeconds, convertByte2MB,convertMBits} from "@/js/helper.js";

export default {
  props: {
    item: Object,
    type: String
  },
  data() {
    return {
      dafaultWidth:200,
      tableWidth: "700",
      textAlignClass: "",
      nano2SecondsAttr: ['duration_dialing', 'duration_ringing_in', 'call_duration', 'duration','durationUntilLimit','smscResponseTime'],
      bytes2MbAttr: ['rx_bytes', 'tx_bytes','size','volumeUsed'],
      bits2Mbits:['bandwidth','maxSpeed','minSpeed','avgSpeed','avgSpeedLimited','maxSpeedLimited','minSpeedLimited']
    };
  },
  computed: {
    newItems() {
      this.customTextAlign(this.type);
      // console.log(this.item);
      let sortOrder = [];
      let itemList = {};
      if (this.type == 'Explorer_SMS') {
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          if(typeof this.item['delivery_state'] !== 'undefined'){
            itemList['delivery_state']= this.item['delivery_state'];
          }
          if(typeof this.item['smscResponseTime'] !== 'undefined'){
            itemList['smscResponseTime']= this.item['smscResponseTime'];
          }
          if(typeof this.item['smsReferenceNumber'] !== 'undefined'){
            itemList['smsReferenceNumber']= this.item['smsReferenceNumber'];
          }
        }
        if (typeof this.item.result['message'] !== 'undefined') {
          delete this.item.result['message'];
        }
      }
      else if (this.type == 'Explorer_cellular_voice_call') {
        sortOrder = ['duration_dialing', 'duration_ringing_in', 'call_duration'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          if (Object.keys(this.item['stats']).length > 0) {
            itemList['stats'] = typeof this.item['stats'] !== 'undefined' ? objectCustomSort(sortOrder, this.item['stats']) : ""
          }
        }
      }
      else if (this.type == 'Explorer_cellular_start_voice_mo') {
        sortOrder = ['duration_dialing', 'duration_ringing_in'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          if (Object.keys(this.item['stats']).length > 0) {
            itemList['stats'] = typeof this.item['stats'] !== 'undefined' ? objectCustomSort(sortOrder, this.item['stats']) : ""
          }
        }
      }
      else if (this.type == 'Explorer_USSD') {
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        }
      }
      else if (this.type == 'Explorer_cellular_receive_call') {
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        }else if (typeof this.item.state !== 'undefined') {
          itemList['state'] = (typeof this.item.state !== 'undefined') ? this.item.state : ""
          itemList['callingNumber'] = (typeof this.item.callingNumber!== 'undefined') ? this.item.callingNumber : ""
          itemList['timeFirstRing'] = (typeof this.item.timeFirstRing !== 'undefined' && this.item.timeFirstRing !== '0001-01-01T00:00:00Z' && this.item.timeFirstRing !== '0' ) ? this.item.timeFirstRing : "never"
          itemList['duration'] = (typeof this.item.duration !== 'undefined') ? this.item.duration : ""
        }
      }
      else if (this.type == 'Explorer_cellular_hangup_call') {
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        }else {
          itemList['connectedCalls'] = (typeof this.item.connectedCalls!== 'undefined') ? this.item.connectedCalls : ""
        }
      }
      else if (this.type == 'Explorer_cellular_attach') {
        sortOrder = ['state', 'stateNo'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else if (typeof this.item.register_info !== 'undefined' && this.item.register_info != '') {
          itemList['registerInfoState'] = (typeof this.item.register_info['state'] !== 'undefined') ? this.item.register_info['state'] : ""
          itemList['registerInfoStateNo'] = (typeof this.item.register_info['stateNo'] !== 'undefined') ? this.item.register_info['stateNo'] : ""
          itemList['operator'] = (typeof this.item.register_info['operator'] !== 'undefined') ? this.item.register_info['operator'] : "Unknown"
        }
      }
      else if (this.type == 'Explorer_cellular_data_connect') {
        sortOrder = ['ipFamily', 'method', 'address', 'prefix', 'gateway', 'dns1', 'dns2', 'dns3'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          if (typeof this.item.ipv4 !== 'undefined' && Object.keys(this.item.ipv4).length > 0) {
            itemList['ipv4'] = objectCustomSort(sortOrder, this.item.ipv4);
          }
          if (typeof this.item.ipv6 !== 'undefined' && Object.keys(this.item.ipv6).length > 0) {
            itemList['ipv6'] = objectCustomSort(sortOrder, this.item.ipv6);
          }
        }
      }
      else if (this.type == 'Explorer_cellular_check_received_sms') {
        sortOrder = ['smsc', 'text', 'timestamp', 'from'];
        if (typeof this.item.result !== 'undefined' && typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          if (Object.keys(this.item).length > 0) {
            itemList = objectCustomSort(sortOrder, this.item);
          }
        }
      }
      else if (this.type == 'Explorer_cellular_data_disconnect') {
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          let statistics = "";
          if(typeof this.item['statistics'] !== 'undefined'){
            if (typeof this.item['statistics']['duration'] !== 'undefined') {
              statistics = {'duration': this.item['statistics']['duration']};
            }
            if (typeof this.item['statistics']['rx_bytes'] !== 'undefined') {
              statistics = {'rx_bytes': this.item['statistics']['rx_bytes'], ...statistics};
            }
            if (typeof this.item['statistics']['tx_bytes'] !== 'undefined') {
              statistics = {'tx_bytes': this.item['statistics']['tx_bytes'], ...statistics};
            }
          }
          itemList['statistics'] = statistics;
        }
      }
      else if (this.type == 'Explorer_data_usage') {
        if (typeof this.item.result !== 'undefined' &&  typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          let statistics = "";
          if(typeof this.item['statistics'] !== 'undefined'){
            if (typeof this.item['statistics']['duration'] !== 'undefined') {
               statistics = {'duration': this.item['statistics']['duration'], ...statistics};
              }
            if (typeof this.item['statistics']['durationUntilLimit'] !== 'undefined') {
              let sDUL ="not throttled";
              if(this.item['statistics']['durationUntilLimit']!='0'){
                sDUL = this.item['statistics']['durationUntilLimit'];
              }
              statistics = {'durationUntilLimit': sDUL, ...statistics};
            }
           if (typeof this.item['statistics']['limitTimestamp'] !== 'undefined') {
             let vrtValue = "";
             if(this.item['statistics']['limitTimestamp']==='0001-01-01T00:00:00Z' || this.item['statistics']['limitTimestamp']==='0'){
               vrtValue = "not throttled";
             }else {
               let vrt = this.item['statistics']['limitTimestamp'].split('.');
               vrtValue = vrt[0];
             }
             statistics = {'limitTimestamp': vrtValue, ...statistics};
            }

            if (typeof this.item['statistics']['volumeUsedUntilLimit'] !== 'undefined') {
              statistics = {'volumeUsedUntilLimit': this.item['statistics']['volumeUsedUntilLimit'], ...statistics};
            }
            if (typeof this.item['statistics']['volumeReachedTime'] !== 'undefined') {
              let vrtValue = "";
              if(this.item['statistics']['volumeReachedTime']==='0001-01-01T00:00:00Z' || this.item['statistics']['volumeReachedTime']==='0'){
                vrtValue = "never";
              }else {
                let vrt = this.item['statistics']['volumeReachedTime'].split('.');
                vrtValue = vrt[0];
              }
              statistics = {'volumeReachedTime': vrtValue, ...statistics};
            }
            if (typeof this.item['statistics']['volumeUsed'] !== 'undefined') {
              statistics = {'volumeUsed': this.item['statistics']['volumeUsed'], ...statistics};
            }
            if (typeof this.item['statistics']['avgSpeed'] !== 'undefined') {
              statistics = {'avgSpeed': this.item['statistics']['avgSpeed'], ...statistics};
            }
            if (typeof this.item['statistics']['maxSpeed'] !== 'undefined') {
              statistics = {'maxSpeed': this.item['statistics']['maxSpeed'], ...statistics};
            }
            if (typeof this.item['statistics']['minSpeed'] !== 'undefined') {
              statistics = {'minSpeed': this.item['statistics']['minSpeed'], ...statistics};
            }
            if (typeof this.item['statistics']['minSpeedLimited'] !== 'undefined') {
              statistics = {'minSpeedLimited': this.item['statistics']['minSpeedLimited'], ...statistics};
            }
            if (typeof this.item['statistics']['maxSpeedLimited'] !== 'undefined') {
              statistics = {'maxSpeedLimited': this.item['statistics']['maxSpeedLimited'], ...statistics};
            }
            if (typeof this.item['statistics']['avgSpeedLimited'] !== 'undefined') {
              statistics = {'avgSpeedLimited': this.item['statistics']['avgSpeedLimited'], ...statistics};
            }
            if (typeof this.item['statistics']['lastState'] !== 'undefined') {
              statistics = {'lastState': this.item['statistics']['lastState'], ...statistics};
            }
          }
          sortOrder = ['minSpeed', 'maxSpeed', 'avgSpeed', 'volumeUsed','volumeReachedTime','minSpeedLimited','maxSpeedLimited','avgSpeedLimited','lastState'];
          itemList['statistics'] =  statistics;
        }
      }
      else if(this.type =='Explorer_stop_audio_recording'){
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          itemList['transcription'] = typeof this.item['transcription'] !== 'undefined' ? this.item['transcription'] : ""
        }
      }
      else if(this.type =='Explorer_at_command'){
        if (typeof this.item.result !== 'undefined' && typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          itemList['text'] = typeof this.item['text'] !== 'undefined' ? this.item['text'] : ""
        }
      }
      else if (this.type == 'Explorer_insert_sim') {
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        }else {
          //will come once we get data from test!
        }
      }
      else {
        itemList = this.item;
      }
      this.customWidth(this.type,itemList);
      return itemList;
    },
    customClass(){
      let customClass="";
      if(this.type=='Explorer_at_command'){
        customClass = customClass+' preserveBreaks ml-3';
      }
      return customClass;
    }
  },
  methods: {

    customIndexName(index){
      let result = this.languagePack("basicModemTable", index);
    /*  if(this.type == "Explorer_data_usage"  && index=='duration'){//override over json file
        result = "Duration Until Limit";
      }*/
      return result;
    },
    modemUnitConversion(value, index) {
      if (this.nano2SecondsAttr.includes(index)) {
        value = convertSeconds(value);
      } else if (this.bytes2MbAttr.includes(index)) {
        value = convertByte2MB(value)+" ("+value+")";
      }else if(this.bits2Mbits.includes(index)){
        value = convertMBits(value);
      } else if(index=='volumeUsedUntilLimit'){
        if(value!=0){
          value = convertByte2MB(value)+" ("+value+")";
        } else {
          value = 'not throttled';
        }
      }
     // console.log(value);
      return value;
    },
    customTextAlign(type) {
      if (type == 'Explorer_cellular_data_disconnect') {
        this.textAlignClass = "text-center";
      }
    },
    customWidth(type,itemList) {
      if (type == 'Explorer_cellular_attach') {
        this.tableWidth = "730";
      } else if (type == 'Explorer_cellular_data_connect') {
        this.tableWidth = "640";
      } else if (type == 'Explorer_SMS') {
        this.tableWidth = this.dafaultWidth * Object.keys(itemList).length; // we should follow in future! each attr get 250
      } else if (type == 'Explorer_data_usage'  ) {
        this.tableWidth = "450";
      } else if (type == 'Explorer_cellular_voice_call'  ||   type=='Explorer_at_command' ) {
        this.tableWidth = "350";
      } else if (type == 'Explorer_cellular_data_disconnect' || type=='Explorer_cellular_start_voice_mo' || type=='Explorer_cellular_hangup_call' ) {
        this.tableWidth = "250";
      }
      /*else if(type=='Explorer_cellular_check_received_sms' ){
        this.tableWidth="850";
      }*/
    },
    convertMs: convertMs,
    convertSeconds: convertSeconds,
    convertByte2MB: convertByte2MB,
    convertMBits: convertMBits
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}
.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}
.preserveBreaks{
  white-space: pre-line;
  text-align: left !important;
}
</style>