<template>
  <div>
    <div v-if="type != 'array' && type != 'object' && type != 'null'">
      <associatedOption
        v-if="type == 'boolean'"
        :fieldAttributes="{
          fieldAttrInput: fieldAttrInput,
          options: ['true', 'false'],
          sort: false,
          name: '',
        }"
        type="select"
        v-model="localValue"
        :templateContent="result"
      ></associatedOption>

      <primaryTextfield
        v-else
        v-model="localValue"
        :fieldAttrInput="fieldAttrInput"
        label=""
        :templateContent="result"
      ></primaryTextfield>
    </div>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";

export default {
  components: { primaryTextfield, associatedOption },
  data() {
    return {};
  },
  props: ["result", "name", "value", "type", "id"],
  computed: {
    localValue: {
      get() {
        let value = this.value;
        if (this.type == "boolean") {
          if (typeof this.value == "undefined" || value == true) {
            value = "true";
          } else {
            value = "false";
          }
        }
        return value;
      },
      set(newVal) {
        if (this.type == "boolean") {
          if (newVal == "true") {
            newVal = true;
          } else {
            newVal = false;
          }
        }

        this.$emit("input", newVal);
      },
    },
    fieldAttrInput() {
      let fieldAttrInput = { class: "required", id: this.id };
      if (this.type == "number") {
        fieldAttrInput.class = "required numberOrVariable";
      }
      if (this.type == "string") {
        fieldAttrInput.class = "";
      }
      return fieldAttrInput;
    },
  },
};
</script>