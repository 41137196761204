var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{class:{
    'ui-dialog': true,
    [`ui-dialog--${_vm.size}`]: _vm.size.length > 0,
    'ui-dialog--full-height': _vm.fullHeight,
  }},[_c('div',{staticClass:"ui-dialog__header"},[_vm._t("header",function(){return [_c(_setup.UiDialogHeader,{attrs:{"color":_vm.headerColor},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"controls",fn:function(){return [_c('button',{staticClass:"ui-dialog__close-btn",on:{"click":() => _setup.emitClose()}},[_c('v-icon',{staticClass:"ui-dialog__close-ico"},[_vm._v("mdi-close")])],1)]},proxy:true}])})]})],2),_c('div',{class:{
      'ui-dialog__body-footer': true,
      'ui-dialog__body-footer--full-height': _vm.fullHeight,
    }},[_c('div',{ref:"dialogBody",staticClass:"ui-dialog__body",class:{
        'ui-dialog__body--no-footer': !_vm.$slots.footer,
      }},[_vm._t("body")],2),(_vm.$slots.footer)?_c('div',{staticClass:"ui-dialog__footer"},[_c(_setup.UiDialogFooter,[_vm._t("footer")],2)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }