var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscriber-panel-content"},[_c('div',{staticClass:"subscriber-panel-content-resource"},[_c('subscriberCategory',_vm._g({attrs:{"result":_vm.result}},_vm.$listeners)),_c('fieldsValuesContainer',_vm._g({staticClass:"mt-6",attrs:{"field":"n_maxRuntime","result":{ ..._vm.result, enableAdd: true },"valueLine":_vm.result.valueLine}},_vm.$listeners)),(typeof _vm.result.fields.n_systemId != 'undefined')?_c('div',{},[_c('fieldsValuesContainer',_vm._g({attrs:{"field":"n_systemId","result":{
            ..._vm.result,
            fields: {
              ..._vm.result.fields,
              n_systemId: { ..._vm.result.fields.n_systemId, type: 'select' },
            },
            enableAdd: true,
          },"valueLine":_vm.result.valueLine}},_vm.$listeners))],1):_vm._e()],1),_c('div',{staticClass:"subscriber-panel-content-variables"},[_c('singleAccordion',{attrs:{"id":"testLocalVariables","title":'Local Variables (' + Object.keys(_vm.localPairArray).length + ')'}},[_c('div',{staticClass:"mt-3"},[_c('lineFieldsArray',{attrs:{"componentType":"variable","fieldAttrRow":{},"fields":_vm.fields,"value":_vm.localPairArray,"headerTitle":false,"addable":true,"templateContent":_vm.result,"isValid":_vm.isValid,"linePrefix":"testLocalVariables"},on:{"line-field-input":function($event){return _vm.checkInput('testlocal', ...arguments)},"revalidate":function($event){return _vm.$emit('revalidate')}}})],1)]),_c('singleAccordion',{attrs:{"id":"testGlobalVariables","title":'Group Variables (' + Object.keys(_vm.globalPairArray).length + ')'}},[_c('div',{staticClass:"mt-3"},[_c('lineFieldsArray',{attrs:{"componentType":"variable","fieldAttrRow":{},"fields":_vm.fields,"value":_vm.globalPairArray,"headerTitle":false,"addable":true,"templateContent":_vm.result,"isValid":_vm.isValid,"linePrefix":"testGlobalVariables"},on:{"line-field-input":function($event){return _vm.checkInput('testgroup', ...arguments)},"revalidate":function($event){return _vm.$emit('revalidate')}}})],1)]),_c('input',{attrs:{"type":"hidden","name":"c_testVariables"},domProps:{"value":JSON.stringify(_vm.localVal)}}),_c('div',{staticClass:"mt-3"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }