<template>
  <InputRow class="magic-text-field">
    <XTextField :label="label" :value="value" @input="$emit('input', $event)"/>
    <XBtn v-if="showWand" icon="mdi-auto-fix" @click="$emit('wand')" color="blue" class="magic-text-field-wand"/>
  </InputRow>
</template>

<script>
import XTextField from '@/components/basic/XTextField.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XBtn from '@/components/basic/XBtn.vue';

export default {
  name: 'MagicTextField',
  components: {XBtn, InputRow, XTextField},
  props: {
    value: String,
    label: String,
    showWand: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.magic-text-field > .x-text-field {
  flex-grow: 1;
}

.magic-text-field-wand {
  margin-left: -5px;
  margin-right: -5px;
}
</style>