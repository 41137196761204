<template>
  <div style="position: relative;left:-1px;top:-2px;" :title="title">
    <template v-if="row.n_status==0 || row.n_status==1">
      <v-icon color="#184a7b" left>mdi-timer-sand</v-icon>
    </template>
    <template v-else-if="row.n_status==2">
      <v-icon color="green" left>mdi-check-circle</v-icon>
    </template>
    <template v-else-if="row.n_status==3">
      <v-icon color="yellow" left>mdi-alert</v-icon>
    </template>
    <template v-else-if="row.n_status==4">
      <v-icon color="red" left>mdi-alert</v-icon>
    </template>
    <template v-else-if="row.n_status==5">
      <v-icon color="blue" left>mdi-close-octagon-outline</v-icon>
    </template>
    <template v-else-if="row.n_status==6">
      <v-icon color="blue" left>mdi-progress-clock</v-icon>
    </template>
    <template v-else-if="row.n_status==7">
      <v-icon color="orange" left>mdi-alert-box</v-icon>
    </template>
    <template v-else-if="row.n_status==8">
      <v-icon color="deep-orange" left>mdi-alert-box</v-icon>
    </template>
    <span style="position: relative;top:1px;">{{ row.n_status_shown }}
          <template v-if="shortProtocolsMapping[row.c_step_name]">
             ({{ shortProtocolsMapping[row.c_step_name] }})
          </template>
        </span>
  </div>
</template>
<script>
import shortProtocolsMapping from "@/cfg/shortProtocolsMapping.json";

export default {
  props: ["header", "row", "result"],
  data() {
    return {
      shortProtocolsMapping: shortProtocolsMapping,
      testStatuses: {
        "0": "Setting up",
        "1": "Running",
        "2": "OK",
        "3": "Result Warning",
        "4": "Result Error",
        "5": "Aborted",
        "6": "Test Timeout",
        "7": "Processing Warning",
        "8": "Processing Error"
      }
    };
  },
  computed: {
    title() {
      return this.testStatuses[this.row.n_status];
    }
  }
}
</script>