<template>
  <div class="tag-query-text-field-2">
    <div class="tag-query-text-field-2__filter-query-box">
      <UiInputWrapper
        label="Tag Filter Query"
        hints="Example: (tag1 && tag2) || (tag3 && tag4)"
        :is-loading="isLoading"
        :errors="errors"
      >
        <textarea
          v-model="tagFilterQueryModel"
          autocomplete="off"
          rows="1"
          placeholder=" "
          @keyup="(e) => rememberCurretPos(e)"
          @click="(e) => rememberCurretPos(e)"
        />

        <template #right>
          <div class="tag-query-text-field-2__help-btn" :data-help="getHelpButtonId('explorer_tag_syntax')">
            <HelpButton :help="getHelpButtonId('explorer_tag_syntax')" />
          </div>
        </template>
      </UiInputWrapper>
    </div>

    <div class="tag-query-text-field-2__tag-data-list-box">
      <TagDataList
        class="tag-query-text-field-2__tag-data-list-input"
        :explorer-tag.sync="userTag"
        additional-hints="Press enter to add or use buttons to add with an operator."
        @press-enter="() => handlePressEnter()"
        @start-typing="({ isValid, count }) => emitValidation({ isTagValid: isValid, tagsCount: count })"
        @end-typing="({ isValid, count }) => emitValidation({ isTagValid: isValid, tagsCount: count })"
      />

      <XBtn
        class="tag-query-text-field-2__tag-input-btn"
        :text="OPERATOR.AND"
        color="primary"
        @click="() => addTag(OPERATOR.AND)"
        :disabled="!userTag.trim() || !isTagValidLast || tagCountLast === 0"
      />

      <XBtn
        class="tag-query-text-field-2__tag-input-btn"
        :text="OPERATOR.OR"
        color="primary"
        @click="() => addTag(OPERATOR.OR)"
        :disabled="!userTag.trim() || !isTagValidLast || tagCountLast === 0"
      />

      <div
        class="tag-query-text-field-2__help-btn  tag-query-text-field-2__help-btn--lg-padd"
        :data-help="getHelpButtonId('explorer_tag_list_with_operators')"
      >
        <HelpButton :help="getHelpButtonId('explorer_tag_list_with_operators')" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue"
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
import TagDataList from "@/components/extended/TagDataList.vue"
import XBtn from "@/components/basic/XBtn.vue";
import HelpButton from "@/components/basic/HelpButton.vue";
import { useExplorerTags } from "@/composition/project-tags/use-explorer-tags"
import { debounce } from "lodash-es"
import { useHelpButton } from "@/composition/help/use-help-button"

const OPERATOR = {
  AND: "&&",
  OR: "||",
}
const HELP_BTN_PREFIX = "TagQueryTextField2"

export default defineComponent({
  name: "TagQueryTextField2",

  components: {
    XBtn,
    UiInputWrapper,
    TagDataList,
    HelpButton,
  },

  props: {
    currentProject: Boolean,
    tagFilterQuery: String,
  },

  emits: ["update:tagFilterQuery", "start-typing", "validation"],

  setup(props, { emit }) {
    const userTag = ref("")
    const {
      isLoading,
      errors,
      checkSyntax,
      explorerCount,
    } = useExplorerTags()
    const {
      getHelpButtonId,
    } = useHelpButton({ prefix: HELP_BTN_PREFIX })

    const isTyping = ref(false)

    const isTagValidLast = ref(true)
    const tagCountLast = ref(0)
    const emitValidation = ({ isTagValid, tagsCount }) => {
      isTagValidLast.value = isTagValid
      tagCountLast.value = tagsCount
      const isQueryValid = !errors.value.length
      const count = isQueryValid ? explorerCount.value : 0
      emit("validation", { isValid: isQueryValid, count })
    }
    const debouncedCheckSyntax = debounce((newQuery) => {
      checkSyntax(newQuery).then(() => {
        emitValidation({ isTagValid: isTagValidLast.value, tagsCount: tagCountLast.value })
      }).finally(() => {
        isTyping.value = false
      })
    }, 600)

    const tagFilterQueryModel = computed({
      get() {
        return props.tagFilterQuery
      },
      set(newQuery) {
        emit("update:tagFilterQuery", newQuery)
        if (!isTyping.value) {
          isTyping.value = true
          emit("start-typing", { isValid: false, count: 0 })
        }
        return debouncedCheckSyntax(newQuery)
      }
    })

    const lastCaretPos = ref(props.tagFilterQuery.length)
    const rememberCurretPos = (e) => {
      lastCaretPos.value = e.target.selectionStart
    }
    /**
     * @param {Object} params
     * @param {string} params.current
     * @param {string} params.operator
     * @param {string} params.value
      */
    const constructQuery = ({ current, operator, value }) => {
      if (lastCaretPos.value === 0 && current.length === 0) {
        lastCaretPos.value = `${value}`.length;
        return value;
      }
      let textBeforeCaret = current.substring(0, lastCaretPos.value);
      let textAfterCaret = current.substring(lastCaretPos.value);

      textBeforeCaret = textBeforeCaret.endsWith(" ") || textBeforeCaret.length === 0
        ? textBeforeCaret
        : `${textBeforeCaret} `;
      textAfterCaret = textAfterCaret.startsWith(" ") || textAfterCaret.length === 0
        ? textAfterCaret
        : ` ${textAfterCaret}`;

      const newUserTagCondition = operator
        ? `${operator} ${value}`
        : value;

      lastCaretPos.value = textBeforeCaret.length + newUserTagCondition.length + 1;

      return `${textBeforeCaret}${newUserTagCondition}${textAfterCaret}`;
    }

    /**
      * @param {string} operator
      */
    const addTag = (operator) => {
      const current = tagFilterQueryModel.value;
      const value = userTag.value.trim();

      tagFilterQueryModel.value = constructQuery({ current, operator, value })
      userTag.value = ""
    }

    const handlePressEnter = () => {
      if (isTagValidLast.value && tagCountLast.value > 0) {
        addTag('')
      }
    }

    onMounted(() => {
      // return checkSyntax(tagFilterQueryModel.value)
      return debouncedCheckSyntax(tagFilterQueryModel.value)
    })


    return {
      OPERATOR,
      handlePressEnter,
      isLoading,
      errors,
      emitValidation,
      rememberCurretPos,
      tagFilterQueryModel,
      addTag,
      userTag,
      isTagValidLast,
      tagCountLast,
      getHelpButtonId,
    }
  }
})
</script>

<style lang="scss">
.tag-query-text-field-2 {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__tag-data-list-box {
    display: flex;
    gap: 8px;
  }

  &__tag-data-list-input {
    flex: 1;
  }

  &__tag-input-btn {
    padding-top: 12px;
  }

  &__help-btn {
    height: 100%;
    padding-top: 3px;

    &--lg-padd {
      padding-top: 12px;
    }
  }
}

</style>

