<template>
  <div>
    <lineFieldsArray
        :fieldAttrRow="getFieldAttrRow"
        :fields="fields"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        :addable="true"
        :dialogAdder="true"
        addButtTitle="Add Check"
        :updateOnChange="true"
        :templateContent="result"/>
  </div>
</template>
<script>
import options from '@/cfg/options.json';
import lineFieldsArray from '@/commonComponents/lineFieldsArray.vue';
import lineButtons from '@/cfg/lineButtons.json';

export default {
  components: {lineFieldsArray},
  data() {
    return {
      fields: {
        attribute: {
          type: 'combobox',
          associatedOption: this.attrsArray,
          inputFieldFormatter: 'attributeInput',
          name: 'Attribute',
          source: this.source,
          fieldAttrInput: {class: 'required'},
          sort: false,
          color(valueLine) {
            //console.log("color lined called");
            return valueLine.color;
          },
        },
        operator: {
          // input: false,
          inputFieldFormatter: 'attributesOperators',
          //colAttrs: { style: "max-width: 23px;min-width: 35px;", class: "mt-1" }
          name: 'Operator',
          fieldAttrInput: {class: 'required'},
        },
        content: {
          type: 'text',

          name: 'Value',
          inputFieldFormatter: 'evalContentFormatter',
          fieldAttrInput: {class: 'required'},
        },
        result: {
          type: 'select',
          name: 'Result',
          options: options.ldapResult,
          colAttrs: {cols: 1},
          default: 'error',
          fieldAttrInput: {class: 'required'},
        },
        action: {
          type: 'select',
          name: 'Action',
          options: options.evalAction,
          default: 'stopTest',
          fieldAttrInput: {class: 'required'},
        },

        fix: lineButtons.fix,
        source: {
          type: 'hidden',
          colAttrs: {class: 'd-none'},
          default: this.source,
        },
        mandatory: {
          type: 'hidden',
          colAttrs: {class: 'd-none'},
        },
        originalValue: {
          type: 'hidden',
          colAttrs: {class: 'd-none'},
        },
        remove: {...lineButtons.remove},
      },
    };
  },
  created() {
    if (this.isTemplate) {
      this.$set(this.fields, 'fix', {
        type: 'hidden',
        colAttrs: {class: 'd-none'},
      });
      this.$set(this.fields, 'mandatory', lineButtons.mandatory);
    }

    this.$set(this.fields.remove, 'inputFieldFormatter', 'diameterTrash');
    this.$set(this.fields.remove, 'isTemplate', this.isTemplate);
    this.$set(this.fields.remove, 'source', this.source);

    // console.log(this.fields);
  },
  computed: {
    pairArray: {
      get() {
        let pairArray = [];
        for (const [key, valueArray] of Object.entries(this.value)) {
          valueArray.forEach((value) => {
            let type;
            if (
                typeof value.content == 'string' &&
                value.content.indexOf('EVAL:') > -1
            ) {
              type = 'dynamic';
            } else {
              type = 'static';
            }
            let readOnly = false;
            if (value.fix == '2') {
              readOnly = true;
            }
            console.log(value.fix, readOnly);
            pairArray.push({
              attribute: key,
              ...value,
              type: type,
              readOnly: readOnly,
              originalValue: value.content,
            });
          });
        }
        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
  },
  props: {
    attrsArray: {},
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    source: {},
    radiusDynamicPars: {},
    isTemplate: {
      type: Boolean,
      default: false,
    },
    result: {type: Object},
  },
  watch: {
    attrsArray: {
      immediate: true,
      deep: true,
      handler(value) {
        this.fields.attribute.associatedOption = value;
      },
    },
  },
  methods: {
    getFieldAttrRow(valueLine) {
      //console.log("color called", valueLine);
      let attrs = {};
      if (typeof valueLine.color != 'undefined') {
        attrs = {style: 'color: ' + valueLine.color};
      }
      return attrs;
    },
    checkInput(newValueArray) {
      let request = {};
      newValueArray.forEach((line) => {
        console.log(line);
        let content = line.content;

        let insertedLine = {
          content: content,
          operator: line.operator,
          result: line.result,
          action: line.action,
          fix: line.fix,
          mandatory: line.mandatory,
          source: line.source,
        };
        if (!this.isTemplate) {
          insertedLine.originalValue = line.originalValue;
        }
        if (typeof request[line.attribute] != 'undefined') {
          let attrArray = request[line.attribute];
          attrArray.push(insertedLine);
          this.$set(request, line.attribute, attrArray);
        } else {
          this.$set(request, line.attribute, [insertedLine]);
          //  request[line.attribute] = [insertedLine];
        }
      });
      this.$emit('input', request);
    },
  },
};
</script>