<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2">{{language.componentLabel.labelRequestPacket}}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <radiusRequestAttr
          :attrsArray="reqAttributes"
          :value="getRequestValue(type)"
          :application="type"
          source="t_templates"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateReq(...arguments)"
          :isTemplate="true"
          :applicationValue="result.additional.radiusEnumValues"
          :result="result"
        ></radiusRequestAttr>
      </v-container>
    </v-card>
    <div
      v-if="
        typeof typeTemplate != 'undefined' &&
        typeof typeTemplate.res != 'undefined'
      "
    >
      <div
        v-for="(responseValue, responseType) in typeTemplate.res.eval"
        :key="responseType"
      >
        <v-card class="mb-6 mt-3" elevation="0" outlined>
          <v-toolbar color="primary" dark flat height="50">
            <v-toolbar-title class="subtitle-2">{{
              getMappedName(responseType)
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <radiusResponseAttr
              :attrsArray="reqAttributes"
              :value="getResponseValue(type, responseType)"
              source="t_templates"
              :radiusDynamicPars="radiusDynamicPars"
              @input="updateRes(responseType, ...arguments)"
              :isTemplate="true"
              :result="result"
            ></radiusResponseAttr>
          </v-container>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import radiusRequestAttr from "@/components/legacy/radiusRequestAttr.vue";
import radiusResponseAttr from "@/components/legacy/radiusResponseAttr.vue";
import { initializeDynamicParams } from "@/js/testHelpers.js";
//  import templates from "@/cfg/templates.json";
import { setupAttribute } from "@/js/helper.js";
import dictionaryService from "@/js/services/DictionaryService";

export default {
  data() {
    return {
        radiusDynamicPars: null,
        reqAttributes:[]
    };
  },
  components: {
    radiusRequestAttr,
    radiusResponseAttr,
  },
  props: ["templateStoreValue", "result", "type", "typeTemplate"],
  computed: {
    commands: {
      get() {
        return this.templateStoreValue.commands;
      },
      set(newValue) {
        let templateStoreValue = { ...this.templateStoreValue };
        this.$set(templateStoreValue, "commands", newValue);
        this.$emit("template-changed", templateStoreValue);
      },
    },
  },
  mounted() {
        this.getAttributes();
  },
  created() {
    let evalInfo = initializeDynamicParams(
      this.result.additional.additionalEvalParams
    );
    //console.log(evalInfo);
    this.radiusDynamicPars = evalInfo.dynamicPars.IPCAN;
  },
  methods: {
      getAttributes() {
          dictionaryService.getRadiusAttributes((attributes) => {
              this.reqAttributes = setupAttribute(attributes);
          });
      },
    getRequestValue(type) {
      let requestValue;
      if (typeof this.templateStoreValue[type] != "undefined") {
        requestValue = this.templateStoreValue[type].req;
      }
      return requestValue;
    },
    getResponseValue(type, responseType) {
      let responseValue = {};
      if (
        typeof this.templateStoreValue[type] != "undefined" &&
        typeof this.templateStoreValue[type].res != "undefined" &&
        typeof this.templateStoreValue[type].res.eval != "undefined" &&
        typeof this.templateStoreValue[type].res.eval[responseType] !=
          "undefined"
      ) {
        responseValue = this.templateStoreValue[type].res.eval[responseType];
      }
      return responseValue;
    },
    updateReq(reqValue) {
      let templateStoreValue = { ...this.templateStoreValue };

      let request = {};
      for (const [key, valueArray] of Object.entries(reqValue)) {
        valueArray.forEach((value) => {
          // if (value.source == "t_testcases") {
          if (typeof request[key] != "undefined") {
            request[key].push(value);
          } else {
            request[key] = [value];
          }
          // }
        });
      }
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      this.$set(templateStoreValue[this.type], "req", request);
      this.$emit("template-changed", templateStoreValue);
    },
    updateRes(responseType, resValue) {
      let templateStoreValue = { ...this.templateStoreValue };

      let response = {};
      for (const [key, valueArray] of Object.entries(resValue)) {
        valueArray.forEach((value) => {
          //   if (value.source == "t_testcases") {
          if (typeof response[key] != "undefined") {
            response[key].push(value);
          } else {
            response[key] = [value];
          }
          // }
        });
      }
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      if (typeof templateStoreValue[this.type].res == "undefined") {
        this.$set(templateStoreValue[this.type], "res", {});
      }
      if (typeof templateStoreValue[this.type].res.eval == "undefined") {
        this.$set(templateStoreValue[this.type].res, "eval", {});
      }
      this.$set(templateStoreValue[this.type].res.eval, responseType, response);
      this.$emit("template-changed", templateStoreValue);
    },
  },
};
</script>