<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="getFieldAttrRow"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      :dialogAdder="true"
      addButtTitle="Add Check"
      :linePrefix="linePrefix"
    ></lineFieldsArray>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import { getRandomInt } from "@/js/helper.js";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: { lineFieldsArray },
  computed: {
    fields() {
      let fields = {
        attribute: {
          name: "Attribute",
          fieldAttrInput: { class: "required" },
        },

        operator: {
          // input: false,
          inputFieldFormatter: "attributesOperators",
          //colAttrs: { style: "max-width: 23px;min-width: 35px;", class: "mt-1" }
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          type: "text",
          name: "Value",
          inputFieldFormatter: "evalContentFormatter",
          fieldAttrInput: { class: "required" },
        },
        else: {
          type: "string",
          string: "else",
          fieldAttrInput: { class: "mt-3 ml-1" },
          colAttrs: { style: "max-width:33px" },
        },
        result: {
          type: "select",
          name: "Result",
          options: options.ldapResult,
          colAttrs: { cols: 1 },
          default: "error",
          fieldAttrInput: { class: "required" },
        },
        action: {
          type: "select",
          name: "Action",
          options: options.evalAction,
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        source: {
          type: "hidden",
          colAttrs: { class: "d-none" },
          default: this.source,
        },
        mandatory: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
        remove: {
          input: false,
          addingInput: false,
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-2",
          },
        },
        atRanId: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
      };

      if (this.isTemplate) {
        fields.mandatory = lineButtons.mandatory;
      }
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        console.log(this.value);
        for (const [key, valueArray] of Object.entries(this.value)) {
          console.log(valueArray);
          valueArray.forEach((value) => {
            let type;
            if (
              typeof value.content == "string" &&
              value.content.indexOf("EVAL:") > -1
            ) {
              type = "dynamic";
            } else {
              type = "static";
            }
            let readOnly = false;
            if (value.fix == "2") {
              readOnly = true;
            }
            let atRanId = value.atRanId;
            console.log(atRanId);
            if (atRanId == "" || typeof atRanId == "undefined") {
              atRanId = getRandomInt(0, 100000);
            }
            pairArray.push({
              attribute: key,
              ...value,
              atRanId: atRanId,
              type: type,
              readOnly: readOnly,
            });
          });
        }
        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
  },
  props: {
    attrsArray: {},
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    source: {},
    radiusDynamicPars: {},
    isTemplate: { type: Boolean, default: false },
    result: {},
    linePrefix: {},
    
  },
  methods: {
    getFieldAttrRow(valueLine) {
      //console.log("color called", valueLine);
      let attrs = {};
      if (typeof valueLine.color != "undefined") {
        attrs = { style: "color: " + valueLine.color };
      }
      return attrs;
    },
    checkInput(newValueArray) {
      let request = {};
      newValueArray.forEach((line) => {
        let content = line.content;

        let insertedLine = {
          content: content,
          operator: line.operator,
          result: line.result,
          action: line.action,
          info: line.info,

          mandatory: line.mandatory,
          source: line.source,
          atRanId: line.atRanId,
        };
        if (typeof request[line.attribute] != "undefined") {
          request[line.attribute].push(insertedLine);
        } else {
          request[line.attribute] = [insertedLine];
        }
      });
      console.log(JSON.stringify(request));
      this.$emit("input", request);
    },
  },
};

// function getRadiusResponseAttrRow(attrValueArray,attrID,arraySource,enableattrSelector,$attributesTbody,$expandableAttributesTbody){
//   // building the radius and diameter response line for the templates and the testcases
//   var tag="";
//   var operator="";
//   var value="";
//   var result="";
//   var action="";
//   var fixed="";
//   var readonly="";
//   var select="";
//   var disabled="";
//   var type="string";
//   if (attrID!=="") {
//     //tag= findInArray(attrID,arraySource);
//     valueArray= arrayFindInArray(attrID,arraySource);
//     tag=valueArray[1];
//     type=valueArray[2];
//     tag=tag+'*';
//   }

//    let $fieldsObject=$attributesTbody.closest(".fields");
//   if((typeof attrValueArray == 'undefined')  || attrValueArray==""){
//     let mandatory=0;
//     if($fieldsObject.data( "enableSuppress")){
//       mandatory=1;
//     }
//     attrValueArray=[{  operator:"",content:"",result:"",action:"",fix:0,mandatory:mandatory}];
//     tag = makeSelector(arraySource, "","comboBox responseAttribute");
//   }
//   // var $tbody=$("<tbody></tbody>");
//   $.each(attrValueArray,function(index,attrValue){
//     operator=attrValue.operator;
//     value=attrValue.content;
//     result=attrValue.result;
//     action=attrValue.action;
//     fixed="";
//     suppress="";
//     if(attrValue.fix==1){
//       fixed="checked";
//     }else if(attrValue.fix==2){
//       suppress="checked";
//     }
//     readonly="";
//     disabled="";
//     suppressDesabled="";
//     if ($fieldsObject.data( "disableFixed")) {
//       if (attrValue.fix==1){
//         readonly='readonly="readonly" style="color: #787878;"';
//         suppressDesabled='disabled="disabled"';
//       }
//       disabled='disabled="disabled"';
//     }
//     style="";
//     if(attrValue.color){
//       style="style=color:"+attrValue.color;
//     }
//     if(typeof attrValue.source != 'undefined'  ){
//       source=attrValue.source;
//     }else {
//        source=$fieldsObject.data( "attrSource");
//     }
//     var $row=$("<tr class='attributeRow' "+style+"  source='"+source+"' fix='"+attrValue.fix+"'></tr>");
//     // for the combo box to work correctly. the object has to be added to the dom before initiating the combobox
//     if($fieldsObject.data( "enableSuppress")){

//       if (attrValue.mandatory!=1 && attrValue.source!="t_testcases"){
//         // $row.addClass("ui-widget-content");
//         $expandableAttributesTbody.append($row);
//       }else{
//         $attributesTbody.append($row);
//       }
//     }else {
//       $attributesTbody.append($row);
//     }

//     $("<td class='addGroupTd'></td>").appendTo($row);

//     if((typeof enableattrSelector == 'undefined')  || enableattrSelector===true){
//       var $attrSelector= $("<td class=attribute attrid='"+attrID+"'>"+tag+"</td>").appendTo($row);
//     }
//     //No idea why need fixSelect to freeze the drop down ! Ticket DEG-375
//     // var $fixSelect=$('<td class="xmlAttr fixSelect" attrName=operator></td>').appendTo($tr);
//     var $fixSelect=$('<td class="xmlAttr" attrName=operator></td>').appendTo($row);
//     if ($fieldsObject.data( "disableFixed") && attrValue.fix==1){
//       $fixSelect.append(operator);
//     }else {
//     let operators=$fieldsObject.data( "operators");
//       var requiredOperators=getOperators(type ,operators);
//       select = makeSelector(requiredOperators, operator,"shortinput operatorSelector");
//       $fixSelect.append(select);
//       if((typeof enableattrSelector == 'undefined')  || enableattrSelector===true){
//         setRequestAtrrClass($attrSelector.find(".comboBox"),false);
//       }
//       //changeOperators($attrSelector,type);
//     }

//     // $tr.append("<td class='value fixSelect attrValueField'><input class='required' name='response"+window.attributeIncrement+"' type=text value='"+value+"' "+readonly+"></input ><label  for='response"+window.attributeIncrement+"' class='error'></label></td>");

//     // $tr.append("<td class='value fixSelect attrValueField'> Removed fixSelect Ticket DEG-37

//     /*    $tr.append("<td class='value attrValueField'><input class='required' name='response"+window.attributeIncrement+"' type=text value='"+value+"' "+readonly+"></input ></td><td class='xmlAttr'>else</td>");*/

//     //Micahel Want Else Kai dont : old Ticket: http://10.1.1.181:8080/issues/1277

//    let $inputColumn= $("<td class='value attrValueField'><input class='required attrValue' name='ui-id-response"+window.attributeIncrement+"' type=text  "+readonly+"></input ></td>").appendTo($row);
//    $inputColumn.find(".attrValue").val(value);
//     window.attributeIncrement++;
//       let evalAction=$fieldsObject.data( "evalAction");
//     $row.append(getErrorFields(result,action,attrValue,$fieldsObject.data( "disableFixed"),evalAction));

//     let centerMed="centerMed";
//     if($fieldsObject.data( "enableSuppress")){
//       centerMed="";
//     }
//     /*if($fieldsObject.data( "enableSuppress")){
//      // $row.append("</td><td class='suppress gray'><input type='checkbox' "+suppress+" "+suppressDesabled+"></td>");

//     }else {
//      // $row.append("</td><td class='fixed centerSmall'><input type='checkbox' "+fixed+" "+disabled+"></td>");
//     }*/
//     if ($fieldsObject.data( "enableSuppress")){
//      $row.append( getFixedSupress(attrValue,suppressDesabled,disabled,$fieldsObject.data( "enableSuppress")));
//     }
//     mandatorytd=getMandatory(attrValue, disabled,$fieldsObject.data( "enableSuppress"),centerMed);
//     $row.append(mandatorytd);
//      var $firstTd=$("<td></td>").appendTo($row);
//     let  attrSource=$fieldsObject.data( "attrSource");
//     if((!attrValue.source) || (typeof $fieldsObject.data( "disableFixed") == 'undefined' && !$fieldsObject.data( "disableFixed"))|| attrValue.source==attrSource){
//       $firstTd.append("<a href='javascript:void(0);' class=remove><i style='padding-left:7px;' class='fas fa-trash fs16 iconRed'></i></a>");
//     }
//     $row.append("<td class='fieldPlaceHolder'></td>");
//     // $tbody.append($row);
//     //console.log(window.enableSuppress);
//     toggleSupress($row);
//      let $mandatory=  $row.find(".mandatory");
//           toggleMandatory($mandatory);
//   });

//   //return $tbody.find('tr');
// }
</script>