
<template>
  <FormDialog v-model="dialog" title="Categories" @input="$emit('input')" @save="saveCategories()">
    <template #dialog-content>
      <XSelect
        class="tag-list"
        v-model="categories"
        :items="allCategories"
        label="Tag List"
        dense
        deletable-chips
        multiple
        combobox
        chips>
      </XSelect>
    <v-spacer></v-spacer>
    </template>
  </FormDialog>
</template>

<script>
import FormDialog from '@/components/extended/FormDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
// import axios from 'axios';

export default {
  name: 'CategoryDialog',
  components: { FormDialog, XSelect },
  props: {
    rows: Array,
    value: Boolean,
  },
  data() {
    return {
      categories: [],
      allCategories: [],
      dialog: false,
      testCases: [] };
  },
  methods: {
    saveCategories() {
      const caller = this;
      for (const testCase of this.testCases) {
        console.log("TestCase Cat: ", testCase, this.categories);
        testCaseInfoService.saveCategories(
            testCase.id,
            this.categories,
            () => {
              console.log('Saved new categories:: ');
              caller.$emit('save');
            });
      }
      this.$emit('save');
      this.$emit('input', false);

    }
  },
  created() {
    testCaseInfoService.getCategories((allCategories) => {
        this.allCategories = allCategories.filter(
        // remove whitespace elements
        (cat) => cat !== '').map(
          function(cats) {
            return cats.trim() // remove trailing whitespaces
          });
        // remove duplicates
        this.allCategories = [...new Set(this.allCategories)].sort(
            (a, b) => a.localeCompare(b) // sort strictly alphabetically
          );
      });
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value || !this.rows) return;
        console.log("Categories dialog opening", this.rows);
        this.dialog = value;
        console.log("dialog: ", this.dialog );
        if (this.rows.length === 1 && typeof this.rows[0] === 'object' && this.rows[0].categories) {
          this.categories = this.rows[0].categories.split(',');
          this.testCases = [this.rows[0]]; // first and only element
        } else { //multiple
          this.categories = []; // empty
          this.testCases = []; // reset
          for (const id of this.rows) {
            this.testCases.push({ id: id });
          }
        }

      }
    }
  }
}
</script>
