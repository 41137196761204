<template>
  <TabBox :value="selectedTab" :tabs="tabs" @input="handleTabChange">
    <template #[`static`]>
      <slot name="static" :value="value.target ? value.target : {}" :emitInput="(k, v) => emitInput('static', k, v)"/>
    </template>
    <template #[`resource`]>
      <slot
          name="resource" :value="value.target ? value.target : {}" :emitInput="(k, v) => emitInput('resource', k, v)"/>
    </template>
  </TabBox>
</template>

<script>
import TabBox from '@/components/basic/TabBox.vue';

export default {
  name: 'TestStepTarget',
  components: {TabBox},
  props: {
    value: Object,
    default: undefined,
  },
  data() {
    return {
      tabs: [
        {
          text: 'Static',
          value: 'static',
        },
        {
          text: 'Resource',
          value: 'resource',
        },
      ],
    };
  },
  created() {
    if (this.default) {
      this.emitDefaultValue({
        ...this.value,
        target: this.default,
      }, () => this.value.target === undefined);
    }
  },
  computed: {
    selectedTab() {
      if (this.value && this.value.target && this.value.target.type === 'resource') return 1;
      return 0;
    }
  },
  methods: {
    emitInput(type, key, value) {
      this.$emit('input', {
        ...this.value,
        target: {
          ...this.value.target,
          type: type,
          [key]: value,
        },
      });
    },
    handleTabChange(value) {
      let target;
      if (value === 0) {
        target = {
          type: 'static',
          host: '',
        };
      } else if (value === 1) {
        target = {
          type: 'resource',
        };
      }

      this.$emit('input', {
        ...this.value,
        target: target,
      });
    },
  },
};
</script>

<style scoped>

</style>