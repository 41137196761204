<template>
  <div>
    <remoteTooltip
      v-if="!empty(row['packetDirection']) ||
        (typeof row['c_messages']['icon'] != 'undefined' &&
          row['c_messages']['icon'] == 'info')"
      path="serve.php?f=testing&f2=testInfo&tooltip=true"
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="dialogAttributes"
    >
      <addField
        :path="'serve.php?f=testing&f2=testInfo'"
        :additionalAttributes="additionalAttributes"
        :dialogAttributes="dialogAttributes"
        v-on="$listeners"
        :showDialog="showDialog"
        @dialog-closed="closeDialog"
        @dialog-loaded="setDialogOpen"
      >
        <v-icon
          v-if="!empty(row['packetDirection']) &&
            row['packetDirection'] == 'packetOut'"
          >mdi-{{customIcon}}
        </v-icon>
        <v-icon
          v-else-if="!empty(row['packetDirection']) &&
            row['packetDirection'] == 'packetIn'"
          >mdi-{{ customIcon }}</v-icon>
        <v-icon v-else-if="row['c_messages']['icon'] == 'info'"
        >mdi-information</v-icon>
      </addField>
    </remoteTooltip>
    <div v-else-if="(row['c_type']== 'Info' &&
                    typeof row['c_messages']['info']!='undefined' &&
                   ( row['c_messages']['info']=='Stop Extensible Cell Diagnostics' ||
                     row['c_messages']['info']=='Extensible Cell Diagnostics started' ||
                     row['c_messages']['info']=='Prepare Extensible Cell Diagnostics'  )) ||
                     row['c_type']== 'QC_DiagFile'">
      <v-icon>mdi-shoe-print</v-icon>
    </div>
  </div>
</template>
    <script>
import remoteTooltip from "@/commonComponents/remoteTooltip.vue";
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField, remoteTooltip },
  props: ["row", "result", "rowindex"],
  data() {
    return {
      showDialog: false,
      dialogCall: ''
    };
  },
  created: function () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      this.dialogCall = ''; // to avoid two times calling, once user click test info icon, other from direction click in dialog
      if (mutation.type === "openInfoDialog") {
        if (state.openedDialogIndex == this.row.order) {
          this.showDialog = true;
        } else {
          this.showDialog = false;
        }
        this.dialogCall = 'dialogDirectionButton';
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },

  computed: {
    customIcon(){
      let iconName = "";
      if(this.row.rawType=='Explorer_cellular_attach' || this.row.rawType=='Explorer_cellular_detach'){
        iconName = "radio-tower";
      }else if(this.row.rawType=='Explorer_cellular_data_connect' || this.row.rawType=='Explorer_cellular_data_disconnect'){
        iconName = "trending-up";
      } else if(this.row.rawType=='Explorer_librespeedtest' ||
          this.row.rawType=='Explorer_Speedtest' ||
          this.row.rawType=='Explorer_TWAMP' ||
          this.row.rawType=='Explorer_iperf' ||
          this.row.rawType=='Explorer_UDPST' ||
          this.row.rawType=='Explorer_BBM'
      ){
        iconName = "speedometer";
      }else if(this.row.rawType=='Explorer_cellular_voice_call'){
        iconName = "phone-outgoing-outline";
      }else if(this.row.rawType=='Explorer_cellular_receive_call'){
        iconName = "phone-incoming-outline";
      }else if(this.row.rawType=='Explorer_cellular_check_received_sms'){
        iconName = "message-alert-outline";
      } else if(this.row.rawType=='Explorer_SMS'){
        iconName = "message-processing-outline";
      } else if(this.row.rawType=='Explorer_SIP_CALL'){
        iconName = "phone-voip";
      } else if(this.row.rawType=='Explorer_cellular_start_conf_call'){
        iconName = "phone-plus";
      } else if(this.row.rawType=='Explorer_cellular_toggle_call'){
        iconName = "gesture-tap-button";
      }else if(this.row.rawType=='Explorer_cellular_hangup_call'){
        iconName = "phone-hangup";
      }else if(this.row.rawType=='Explorer_start_audio_recording'){
        iconName = "record-circle-outline";
      }else if(this.row.rawType=='Explorer_stop_audio_recording'){
        iconName = "stop-circle";
      }else if(this.row.rawType=='Explorer_play_audio'){
        iconName = "script-text-play";
      }else if(this.row.rawType=='Explorer_cellular_start_voice_mo'){
        iconName = "phone-in-talk";
      }else if(this.row.rawType=='Explorer_insert_sim'){
        iconName = "sim-outline";
      }else{
        if(this.row.packetDirection=='packetIn'){
          iconName = "login";
        }
        if(this.row.packetDirection=='packetOut'){
          iconName = "logout";
        }
      }
      return iconName;
    },

    dialogAttributes() {
      return {
        data: this.result.table.data,
        currentID: this.row["n_id"],
        row: this.row,
      };
    },
    additionalAttributes() {
      let indexVal = this.row["packetDirection"];
      if (
        typeof this.row["rawType"] != "undefined" &&
        this.row["rawType"] == "INFO"
      ) {
        indexVal = "packetOut";
      }
      return {
        function: "getRemotePopupInfo",
        index: indexVal,
        dialogCall: this.dialogCall,
        id: this.row["n_id"],
        requestType: "ajax",
      };
    },
  },
  methods: {
    setDialogOpen() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

      
