<template>
  <div class="json-editor">
    <InputRow>
      <XSelect
          label="Type"
          :value="Array.isArray(value) ? 'array' : typeof value"
          :items="types"
          class="json-editor-type-select"
          @input="emitType($event)"/>
      <div class="json-editor-row-buttons">
        <XBtn v-if="!('' in value)" icon="mdi-plus-box" color="primary" @click="addValue"/>
      </div>
    </InputRow>
    <div class="json-editor-content">
      <JsonEditorScope :value="value" @input="emitInput"/>
    </div>
  </div>
</template>

<script>
import JsonEditorScope from '@/components/basic/JsonEditorScope.vue';
import XBtn from '@/components/basic/XBtn.vue';
import XSelect from '@/components/basic/XSelect.vue';
import InputRow from '@/components/basic/InputRow.vue';

export default {
  name: 'JsonEditor',
  components: {
    InputRow,
    XSelect,
    XBtn,
    JsonEditorScope,
  },
  props: {
    value: [Object, Array],
  },
  data() {
    return {
      types: [
        {
          text: 'Object',
          value: 'object',
        },
        {
          text: 'Array',
          value: 'array',
        },
      ],
    };
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
    emitType(value) {
      this.$emit('input', value === 'object' ? {} : []);
    },
    addValue() {
      if (!Array.isArray(this.value)) {
        this.$emit('input', {
          ...this.value,
          '': '',
        });
      } else {
        this.$emit('input', [
              ...this.value,
              '',
            ],
        );
      }
    },
  },
};
</script>

<style scoped>
.json-editor {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.json-editor-content {
  display: flex;
}

.json-editor-type-select {
  width: 120px;
}
</style>