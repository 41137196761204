<template>
  <!-- <div v-if="result.report.editable==true"> -->
  <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{}"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      addButtTitle="Add Field"
  ></lineFieldsArray>
  <!-- </div> -->
  <!-- <dateFormatInfo></dateFormatInfo> -->
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";

export default {
  props: ["reportTemplatevalue"],
  data() {
    return {
      file: null,
      message: "",
      showDialog: false,
      imageSource: "",

      fields: {
        name: {
          type: "text",
          name: "Attribute",
        },
        value: {
          type: "text",
          name: "Value",
        },
        remove: lineButtons.remove,
      },
    };
  },
  components: {
    lineFieldsArray,
  },
  computed: {
    pairArray: {
      get() {
        let discFields = [];
        if (typeof this.reportTemplatevalue["discFields"] != "undefined") {
          discFields = this.reportTemplatevalue["discFields"];
        }
        return discFields;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "discFields", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
  },
  methods: {
    checkInput(newVal) {
      this.$set(this.reportTemplatevalue, "discFields", newVal);
      this.$emit("input", this.reportTemplatevalue);
    },
  },
};
</script>