<template>
  <a @click="activateControl(row)" >
    <v-list-item data-type="deactivateExplorer">
      <v-list-item-title>
        <v-card class="pa-2 mt-3 elevation-0 delete">
          <div v-if="row['n_status'] == 1">
            <v-icon left>mdi-toggle-switch-off </v-icon>Deactivate Explorer
          </div>
          <div v-else><v-icon left>mdi-toggle-switch</v-icon>Activate Explorer</div>
        </v-card>
      </v-list-item-title>
    </v-list-item>
  </a>
</template>
<script>
  import { explorerActivation } from "@/js/helper.js";

  export default {
  components: {},
  props: ["info", "actionName", "currentTable", "row", "rowindex"],
/*  created() {
    this.$root.$refs.dectivateExplorer = this;
  },*/
  methods: {
    activateControl(row) {
      explorerActivation(row,this);
    },
  },
};
</script>