<template>
  <div
    ref="listEditor"
    class="simple-list-editor"
    :style="inlineAddButton ? 'flex-direction: row;' : ''"
  >
    <div
      v-if="value && value.length"
      class="simple-list-editor__grid"
    >
      <div
        v-for="(row, i) of value"
        :key="i"
        style="display: contents;"
      >
        <XSelect
            :value="row"
            v-if="type === 'select'"
            :items="items"
            :item-text="itemText"
            :item-value="itemValue"
            :item-tooltip="itemTooltip"
            :required="required"
            @input="emitInput($event, i)"
            :label="label"
            class="simple-list-editor__select">
          <template #item-tooltip="{ item }">
            <slot name="item-tooltip" :item="item"/>
          </template>
        </XSelect>

        <XBtn
          v-if="showDeleteButton"
          :iconAfterText="true"
          color="red"
          icon="mdi-delete"
          @click="deleteRow(i)"
        />

        <div v-else class="fixed-width" style="width: 36px; height: 36px"></div>

        <slot
          v-if="$scopedSlots.rowLeftSide"
          name="rowLeftSide"
          :row="row"
        />
      </div>
    </div>

    <XBtn
      v-if="!full"
      :text="`Add ${rowName === undefined ? 'item' : rowName}`"
      color="primary"
      icon="mdi-plus"
      :style="inlineAddButton ? 'margin-bottom: 2px;': ''"
      @click="addRow"
    />
  </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn.vue';
import XSelect from '@/components/basic/XSelect.vue';

export default {
  name: 'SimpleListEditor',
  components: {
    XSelect,
    XBtn,
  },
  props: {
    value: Array,
    type: String,
    label: String,
    items: Array,
    itemText: String,
    itemValue: String,
    itemTooltip: [String, Function],
    required: Boolean,
    rowName: String,
    persistentListLength: Boolean,
    min: [Number, String],
    max: [Number, String],
    inlineAddButton: Boolean,
    defaultItem: [Object, String, Number]
  },
  created() {
    if (!this.value) {
      this.$emit('input', []);
    }
    this.$nextTick(() => {
      for (let i = this.value.length; i < this.computedMin && !this.full; i++) {
        this.addRow();
      }
    });
  },
  computed: {
    full() {
      return this.computedMax !== -1 && this.value && this.value.length >= this.computedMax ||
          this.persistentListLength;
    },
    showDeleteButton() {
      return (this.computedMin === 0 || this.value && this.value.length > this.computedMin) &&
          !this.persistentListLength;
    },
    computedMin() {
      if (this.min) {
        return parseInt(this.min);
      }
      return 0;
    },
    computedMax() {
      if (this.max) {
        return parseInt(this.max);
      }
      return -1;
    },
  },
  methods: {
    addRow() {
      if (this.defaultItem) {
        this.$emit('input', [...this.value, this.defaultItem]);
        return
      }

      let newItem = {}
      if (this.type === 'select' && this.required) {
        newItem = this.items[0];
        if (this.itemValue) newItem = newItem[this.itemValue];
      }
      this.$emit('input', [...this.value, newItem]);
    },
    deleteRow(i) {
      this.$emit('input', [...this.value.slice(0, i), ...this.value.slice(i + 1, this.value.length)]);
    },
    emitInput(value, i) {
      this.$emit('input', [...this.value.slice(0, i), value, ...this.value.slice(i + 1, this.value.length)]);
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-list-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__grid {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 10px;
  }

  &__select {
    flex-grow: 1;
  }
}
</style>
