<template>
  <div>
    <v-row>
      <v-col cols="3">
        <associatedOption
          :fieldAttributes="{ name: 'Type', associatedOption: localType }"
          type="select"
          :fieldAttrInput="{ class: 'required' }"
          v-model="resourceType"
          :templateContent="result"
          :isValid="isValid"
          @input="getResourceDetails"
          field="type"
        ></associatedOption>
      </v-col>
      <v-col>
        <associatedOption
          :fieldAttributes="{ name: 'Tag', associatedOption: localTag }"
          type="select"
          :fieldAttrInput="{ class: isRequired }"
          v-model="resourceTag"
          :templateContent="result"
          :isValid="isValid"
          field="tag"
        ></associatedOption>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import requests from '@/js/requests';

export default {
  components: {
    associatedOption,
  },
  props: ["localStoredStep", "result", "isValid"],
  data() {
    return {
      localType: ["public", "private", "device"],
      localTag: [],
    };
  },
  computed: {
    isRequired() {
      let classes = "";
      if (this.localStoredStep.parameters.target.type == "resource") {
        classes = "required";
      }
      return classes;
    },
    evalValue: {
      get() {
        let evalValue = {};
        //console.log(this.localStoredStep);
        if (
          typeof this.localStoredStep["parameters"] != "undefined" &&
          typeof this.localStoredStep["parameters"]["target"] != "undefined"
        ) {
          evalValue = this.localStoredStep["parameters"]["target"];
        }
        return evalValue;
      },
      set(newVal) {
        this.updateResponseKey("target", newVal);
      },
    },
    resourceType: {
      get() {
        let resourceType = "";
        if (typeof this.evalValue["resourceType"] != "undefined") {
          resourceType = this.evalValue["resourceType"];
        }
        return resourceType;
      },
      set(newVal) {
        this.$set(this.evalValue, "resourceType", newVal);
        this.updateResponseKey("target", this.evalValue);
      },
    },
    resourceTag: {
      get() {
        let resourceTag = "";
        if (typeof this.evalValue["resourceTag"] != "undefined") {
          resourceTag = this.evalValue["resourceTag"];
        }
        return resourceTag;
      },
      set(newVal) {
        this.$set(this.evalValue, "resourceTag", newVal);
        this.updateResponseKey("target", this.evalValue);
      },
    },
  },

  methods: {
    updateResponseKey(index, newVal) {
      this.$set(this.localStoredStep["parameters"], index, {
        ...newVal,
        type: "resource",
      });
      this.$emit("stored-value-changed", this.localStoredStep);
    },
    getResourceDetails(value) {
      if (value != "undefined") {
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: {
            type: value,
            function: 'getResourceTag',
            requestType: 'ajax',
            tagType: 'sipcall',
          },
        })
            .then(function (post) {
            caller.localTag = post.data.result.json;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getResourceTag(value) {
      if (value != "undefined") {
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: {
            type: value,
            function: 'getResourceTag',
            requestType: 'ajax',
            tagType: 'udpst',
          },
        })
            .then(function (post) {
            caller.localTag = post.data.result.json;
            console.log("here", caller.localTag);
            // caller.resourceType ="public";
            //caller.localType = [['private','disabled'],['public']];
            caller.localType = [["private", "private"]];
            caller.localType.push(["public", "public"]);
            caller.localType.push(["device", "device"]);
            caller.resourceType = "public"; //if no public tag

            if (
              caller.localTag != null &&
              caller.localTag.length != 0 &&
              (caller.resourceType == "public" || value == "private")
            ) {
              caller.resourceType = "private";
              caller.localType.push(["private", "private"]);
            } else {
              console.log("EMPTY" + value);
              caller.getResourceDetails("public");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    console.log("RT", this.resourceType);
    if (this.resourceType == "") {
      this.getResourceTag("private");
    } else {
      this.getResourceDetails(this.resourceType);
    }
  },
};
</script>