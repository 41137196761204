<template>
    <div>
        <v-row>
            <v-col cols="2" class="">
                <associatedOption
                        :fieldAttributes="{
            name: 'IP Version',
            associatedOption: localIpv,
            value:'ipv4'
          }"
                        type="select"
                        :fieldAttrInput="{ class: 'required' }"
                        v-model="ipVersion"
                        :templateContent="result"
                        :isValid="isValid"
                ></associatedOption>
            </v-col>
      <!--      <v-col cols="2" class="">
                <associatedOption
                        :fieldAttributes="{
            name: 'Transport Protocol',
            associatedOption: localProtocol,
           
          }"
                        type="select"
                        :fieldAttrInput="{ class: 'required' }"
                        v-model="transportProtocol"
                        :templateContent="result"
                        :isValid="isValid"
                ></associatedOption>
            </v-col>-->
            <v-col cols="3">
                <associatedOption
                        :fieldAttributes="{ name: 'Type', associatedOption: localType }"
                        type="select"
                        :fieldAttrInput="{ class: 'required' }"
                        v-model="resourceType"
                        :templateContent="result"
                        :isValid="isValid"
                        @input="getResourceDetails"
                ></associatedOption>
            </v-col>
            <v-col>
                <associatedOption
                        :fieldAttributes="{ name: 'Tag', associatedOption: localTag }"
                        type="select"
                        :fieldAttrInput="{ class: 'required' }"
                        v-model="resourceTag"
                        :templateContent="result"
                        :isValid="isValid"
                ></associatedOption>
            </v-col>
        </v-row>
    </div>
</template>
<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
  import requests from '@/js/requests';

  export default {
    components: {
      associatedOption
    },
    props: ["localStoredStep", "result","isValid"],
    data() {
      return {
        localType: ["public", "private"] ,
        localTag:[],
        localIpv: [["ipv4", "IPv4"],["ipv6","IPv6"]],
        localProtocol: [["tcp","TCP"], ["udp","UDP"]],
      };
    },
    computed: {
      ipVersion: {
        get() {
          //console.log(this.valueLine);
          let ipVersion = "";
          if (typeof this.localStoredStep.target.ip!= "undefined") {
            ipVersion = this.localStoredStep.target.ip;
          }
          return ipVersion;
        },
        set(selected) {
          console.log(selected);
          this.$set(this.localStoredStep.target, "ip", selected);
        },
      },
      transportProtocol: {
        get() {
          //console.log(this.valueLine);
          let tProtocol = "tcp";
          if (typeof this.localStoredStep.target.protocol != "undefined") {
            tProtocol = this.localStoredStep.target.protocol;
          }
          return tProtocol;
        },
        set(selected) {
          console.log(selected);
          this.$set(this.localStoredStep.target, "protocol", selected);
        },
      },
      target: {
        get() {
          let target = { };
          //console.log(this.localStoredStep);
          if (
            typeof this.localStoredStep != "undefined" &&
            typeof this.localStoredStep["target"] != "undefined"
          ) {
            target = this.localStoredStep["target"];
          }
          return target;
        },
        set(newVal) {
          this.updateResponseKey("target", newVal);
        },
      },
      resourceType: {
        get() {
          let resourceType = "";
          if (typeof this.target["resourceType"] != "undefined") {
            resourceType = this.target["resourceType"];
          }
          return resourceType;
        },
        set(newVal) {
          this.$set(this.target, "resourceType", newVal);
          this.updateResponseKey("target", this.target);
        },
      },
      resourceTag: {
        get() {
          let resourceTag = "";
          if (typeof this.target["resourceTag"] != "undefined") {
            resourceTag = this.target["resourceTag"];
          }
          return resourceTag;
        },
        set(newVal) {
          this.$set(this.target, "resourceTag", newVal);
          this.updateResponseKey("target", this.target);
        },
      },
    },
    // watch: {
    //   "localStoredStep.target": function () {
    //     if(typeof this.target.resourceType!="undefined" &&
    //       typeof this.target.host!="undefined" &&
    //       typeof this.target.port!="undefined"
    //     ){
    //       this.$set(this.localStoredStep, "host", this.target.host );
    //       this.$set(this.localStoredStep, "port", this.target.port );
    //       delete this.localStoredStep.target.port;
    //       delete this.localStoredStep.target.host;
    //     }
    //   },
    // },
    methods: {
      updateResponseKey() {
       // this.$set(this.localStoredStep, index, { ...newVal,"type":"resource" }  );
        this.$emit("stored-value-changed", this.localStoredStep);
      },
      getResourceDetails(value){
        if(value!="undefined"){
          let caller = this;
          requests.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: {
              type: value,
              function: 'getResourceTag',
              requestType: 'ajax',
              tagType: 'librespeed',
            },
          })
              .then(function (post) {
              caller.localTag = post.data.result.json;
           })
            .catch((error) => {
              console.log(error);
            });
        }
      },
      getResourceTag(value){
        //console.log(this.resourceType);
        //console.log(value);
       if(value!="undefined"){
         let caller = this;
         requests.frameworkAxiosRequest({
           method: 'post',
           url: 'serve.php?f=testing&f2=configureAndRunTests',
           data: {
             type: value,
             function: 'getResourceTag',
             requestType: 'ajax',
             tagType: 'librespeed',
           },
         })
             .then(function (post) {
             caller.localTag = post.data.result.json;
             //console.log("here", caller.localTag);
             // caller.resourceType ="public";
             //caller.localType = [['private','disabled'],['public']];
             caller.localType = [['private', 'private', '', 'disabled', '']];
             caller.localType.push(['public', 'public']);
             caller.resourceType ="public";//if no public tag
             if(caller.localTag!=null && caller.localTag.length!=0 && (caller.resourceType=="public" || value=="private")){
                caller.resourceType ="private";
                caller.localType.push(['private', 'private']);
             }else{
               console.log('EMPTY'+value);
               caller.getResourceDetails("public");
             }
           })
           .catch((error) => {
             console.log(error);
           });
        }
      }
    },
    created() {
        if(this.resourceType==""){
          this.getResourceTag("private");
        }else{
          this.getResourceDetails(this.resourceType);
        }
       },
  };
</script>