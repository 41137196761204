<template>
  <v-row no-gutters class="ml-5">
    <div class="mr-2">
    <primaryTextfield type="text" :fieldAttrInput="{}" label="Backend Search" v-model="value"></primaryTextfield>
 </div>
    <primaryButton
      type="button"
      @click="searchBackendTests()"
      label="Search"
      icon="mdi-magnify"
      color="primary"
      :disabled="disabled"
      :loading="loading"
    ></primaryButton>
  </v-row>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import requests from '@/js/requests';
export default {
  props: ["currentTable"],
  data() {
    return { value: "", disabled: false, loading: false };
  },
  components: {
    primaryTextfield,
    primaryButton,
  },
  methods: {
    searchBackendTests() {
      this.disabled = true;
      this.loading = true;
      // var $button = $(caller);
      // var $backendSearch = $button.closest("span").find(".backendSearch");
      // var searchValue = $backendSearch.val();
      // $tableSelector = $(".dataTable");
      // $overlayWrapper = $tableSelector.closest(".overlayWrapper");
      // if (typeof $overlayWrapper != "undefined") {
      //   var $overlay = $overlayWrapper.find(".overlay");
      //   var $loadingImage = $overlayWrapper.find(".loadingImage");
      //   coverLoadingTable($tableSelector, $overlay, $loadingImage);
      // }

      var options = {
        searchValue: this.value,
        function: "searchBackendTests",
        requestType: "ajax",
      };

      // $.ajax({
      //   data: options,
      //   url: "",
      //   success: searchBackendTestsCallback($tableSelector, $overlay, caller),
      // });

      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: this.currentTable.tableUrl,
        data: options,
      })
          .then(function (response) {
          console.log(response.data.result);
          let table = caller.currentTable;
          caller.$set(table, "data", response.data.result.table.data);
          caller.$store.commit("tableChanged", table);
          caller.disabled = false;
          caller.loading = false;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};

// $(document).ready(function () {
//   $(".backendSearch").keypress(function (e) {
//     if (e.keyCode == 13) $(".backendSearchButton").click();
//   });
// });

// function searchBackendTests(caller) {
//   var $button = $(caller);
//   var $backendSearch = $button.closest("span").find(".backendSearch");
//   var searchValue = $backendSearch.val();
//   $tableSelector = $(".dataTable");
//   $overlayWrapper = $tableSelector.closest(".overlayWrapper");
//   if (typeof $overlayWrapper != "undefined") {
//     var $overlay = $overlayWrapper.find(".overlay");
//     var $loadingImage = $overlayWrapper.find(".loadingImage");
//     coverLoadingTable($tableSelector, $overlay, $loadingImage);
//   }

//   var options = {
//     searchValue: searchValue,
//     function: "searchBackendTests",
//     requestType: "ajax",
//   };

//   $.ajax({
//     data: options,
//     url: "",
//     success: searchBackendTestsCallback($tableSelector, $overlay, caller),
//   });
// }

// function searchBackendTestsCallback($tableSelector, $overlay, caller) {
//   return function (data) {
//     var table = $tableSelector.data("dataTable");
//     table.clear();
//     $overlay.fadeOut();
//     addrows(data, $tableSelector, true);
//   };
// }
</script>