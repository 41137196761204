<script setup>
import {computed, ref, watch} from 'vue';
import requests from '@/js/requests';
import XThrobber from '@/components/basic/XThrobber.vue';
import {useStore} from '@/store';
import {defineProps, defineEmits} from 'vue';
import WrongProjectNotice from '@/components/dynamic/wrongProjectNotice.vue';
import { useNotifications } from '@/composition/notifications';
import { useRouter } from "vue-router/composables"

const router = useRouter();

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  public: {
    type: Boolean,
    default: false,
  },
  isLoaderShown: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['result']);

const store = useStore();
const { showNotification } = useNotifications()

const result = ref(null);
const isFetchingData = ref(true);
const isLoading = computed(() => isFetchingData.value || props.isLoaderShown)

const isWrongProject = ref(false);

const loadData = () => {
  if (!props.public) {
    requests
      .phpServiceGetRequest(props.url, ({ result: _result }) => {
        if (_result.content.includes('wrongProjectNotice')) {
          isWrongProject.value = true;
        }

        if (_result.infoFormMessage) {
          showNotification({message: _result.infoFormMessage, length: 8000});
        }

        result.value = _result;

        if (isWrongProject.value) {
          return;
        }

        store.commit('setCurrentPhpServiceViewUrl', props.url);
        emit('result', _result);
      })
      .catch((err) => {
        if (err.message.includes("You do not have permission")) {
          router.back();
        }
        throw err;
      })
      .finally(() => {
        isFetchingData.value = false;
      });
  } else {
    requests
      .publicPhpServiceGetRequest(props.url, ({ result: _result }) => {
        if (_result.infoFormMessage) {
          showNotification({message: _result.infoFormMessage, length: 8000});
        }

        result.value = _result;
        store.commit('setCurrentPhpServiceViewUrl', props.url);
        emit('result', _result);
      })
      .finally(() => {
        isFetchingData.value = false;
      });
  }
};

watch(
  () => props.url,
  () => {
    loadData();
  },
  {immediate: true},
);
</script>

<template>
  <div v-if="result && !isLoading && !isWrongProject">
    <slot :result="result"/>
  </div>

  <WrongProjectNotice v-else-if="isWrongProject" :result="result"/>

  <XThrobber centered v-else-if="isLoading"/>
</template>
