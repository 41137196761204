<template>
  <div>
    <a
      href="javascript:void(0);"
      data-type="testExecution"
      @click="checkBeforeRun(value, row.n_id_key, this)"
    >
      <v-icon left>mdi-play</v-icon>
      <confirmationDialog
        :value="showConfirmationDialog"
        title="Notice"
        message="No Steps are configured for this test. Do you want to add them now ?"
        @confirmation-ok="closeCheckDailog(...arguments)"
        maxWidth="290"
      ></confirmationDialog>
      <confirmationDialog
        :hideCancelButton="true"
        :value="showErrorDialog"
        title="Error"
        message="Error Running test. Please retry later"
        @confirmation-ok="showErrorDialog = !showErrorDialog"
        maxWidth="290"
      ></confirmationDialog>

      <startParametersDialog
        v-if="parameterDialog"
        v-model="startParameters"
        :parameterDialog="parameterDialog"
        :loading="loading"
        @confirmation-ok="runTest(value, row.n_id_key, ...arguments)"
        :testNames="testNames"
      >
      </startParametersDialog>

      <!-- <div v-else-if="localResult != null && showCustomDialog">
           <dialogContainer
             :result="localResult"
             :dialogAttributes="{}"
             v-on="$listeners"
           ></dialogContainer>
         </div> -->

      <addField
        :path="currentTable.tableUrl"
        :additionalAttributes="additionalAttributes"
        :dialogAttributes="{}"
        :openDialogFlag="openDialogFlag"
      ></addField>
      <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>
    </a>
  </div>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
import startParametersDialog from "@/components/legacy/startParametersDialog.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
import standbyDialog from "@/commonComponents/standbyDialog.vue";
import { runTest } from "@/js/helper.js";
import {shaKey} from '@/js/helper';
import requests from '@/js/requests';

export default {
  components: {
    addField,
    confirmationDialog,
    standbyDialog,
    startParametersDialog,
  },
  data() {
    return {
      loading: false,
      showConfirmationDialog: false,
      openDialogFlag: false,
      showErrorDialog: false,
      localLoadingDialog: false,
      parameterDialog: false,
      parameters: [],
      startParameters: [],
      testNames: {},
    };
  },
  props: ["value", "row", "valueKey", "currentTable", "rowindex"],
  computed: {
    additionalAttributes: function () {
      let additionalAttributes = this.currentTable["editAdditionalAttributes"];
      additionalAttributes = {
        n_id: this.value,
        n_id_key: this.valueKey,
        function: "getInsertedFieldsEdit",
        ...additionalAttributes,
      };
      return additionalAttributes;
    },
  },
  methods: {
    runTest(n_id, n_id_key, execute) {
      if (execute) {
        runTest(n_id, n_id_key, this);
      } else {
        this.parameterDialog = false;
      }
    },
    // checkResponse(n_id, n_id_key) {
    //   let response = 300;
    //   if (response == 300) {
    //     this.parameterDialog = true;
    //     this.parameters = "";
    //   } else {
    //     this.checkBeforeRun(n_id, n_id_key);
    //   }
    // },

    checkBeforeRun(n_id, n_id_key) {
      let caller = this;
      this.localLoadingDialog = true;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: caller.currentTable.tableUrl,
        data: {
          function: 'checkBeforeRun',
          n_id: n_id,
          n_id_key: n_id_key,
        },
        //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
      })
          .then(function (response) {
          let data = response.data.result.json;
          if (data.result === "true") {
            caller.runTest(n_id, n_id_key, caller);
          } else {
            this.showConfirmationDialog = true;
            // $(".confirmationDialog").html(
            //   "No Steps are configured for this test. Do you want to add them now ?"
            // );
            // $(".confirmationDialog").dialog({
            //   autoOpen: false,
            //   modal: true,
            //   buttons: {
            //     Edit: function () {
            //       AddField(n_id, n_id_key);
            //       $(this).dialog("close");
            //     },
            //     Cancel: function () {
            //       $(this).dialog("close");
            //     },
            //   },
            // });
            // $(".confirmationDialog").dialog("open");
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
    closeCheckDailog(value) {
      this.openDialogFlag = true;
      if (value) {
        this.showConfirmationDialog = false;
      }
    },
    shaKey(msg) {
      return shaKey(msg);
    },
  },
};
</script>
