<template>
  <div v-if="type == 1">
    <div class="imsiFields">
      <v-row
        no-gutters
        v-for="(multiTextItem, increment) in multipleFields"
        class="imsicontainer"
        :key="increment"
      >
        <v-col :cols="customCol">
          <primaryTextfield
            type="text"
            :label="fieldAttributes.name"
            @input="changetoVirtual(increment, ...arguments)"
            :value="multiTextItem.value"
            :fieldAttrInput="getfieldAttrInput(multiTextItem)"
            :templateContent="result"
            style="width: 170px"
          ></primaryTextfield>
          <input
            class="hiddenImsiType"
            type="hidden"
            :value="multiTextItem.type"
          />
        </v-col>

        <v-col class="mt-2" style="max-width: 30px" title="Clear/Delete IMSI">
          <v-icon @click="removeImsi(increment)" :result="result" color="red">mdi-delete-forever</v-icon>
        </v-col>
        <v-col class="mt-2 d-flex" style="min-width: 50px; max-width: 100px">
          <a :title="multiTextItem.type">
            <v-icon @click="generateRandomImsi(increment)" id="subscriberImsiGenerator" :disabled="disabled"
              >mdi-auto-fix</v-icon
            >
          </a>
          <span
            v-if="fieldAttributes.multi != true"
            class="type mt-1"
            style="text-transform: capitalize; min-width: 100px"
          >
            {{ multiTextItem.type }}</span
          >
          <div class="">
            <v-icon
              v-if="
                fieldAttributes.multi == true &&
                increment == multipleFields.length - 1
              "
              label="Add Virtual IMSI"
              icon="mdi-plus"
              @click="addImsi()"
              color="primary"
              :disabled="disabled"
              >mdi-plus-box</v-icon
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="fieldAttributes.multi != true" class="addImsi">
      <primaryButton
        label="Add Virtual IMSI"
        icon="mdi-plus"
        @click="addImsi()"
        type="button"
        color="primary"
        :disabled="disabled"
      ></primaryButton>
    </div>
    <input
      type="hidden"
      :value="JSON.stringify(multipleFields)"
      :name="field"
    />
  </div>
</template>
<script>
//import trash from "@/commonComponents/dynamic/trash.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["fieldAttributes", "value", "field", "result"],
  components: {
    primaryTextfield,
    //trash,
    primaryButton
  },
  computed: {
    customCol(){
      return this.fieldAttributes?.multi ? 0:2;
    },
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    type() {
      return this.result.valueLine.n_type;
    },
    multipleFields() {
      let multipleFields = [{}];

      if (
        typeof this.value != "undefined" &&
        this.value != ""
      ) {
        multipleFields = JSON.parse(this.value);
      }
      return multipleFields;
    },
  },

  methods: {

    getfieldAttrInput(multiTextItem) {
      let fieldAttrInput = this.fieldAttributes.fieldAttrInput;

      if (
        typeof multiTextItem.type != "undefined" &&
        multiTextItem.type != "" &&
        multiTextItem.type != "virtual"
      ) {
        fieldAttrInput["readonly"] = "readonly";
      }
      return fieldAttrInput;
    },
    generateRandomImsi(index) {
      this.$set(
        this.multipleFields[index],
        "value",
        Math.floor(Math.random() * 1e15)
      );
      this.$set(this.multipleFields[index], "type", "virtual");
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
    changetoVirtual(index, value) {
      this.$set(this.multipleFields, index, {
        value: value,
        type: "virtual",
      });
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
    addImsi() {
      this.multipleFields.push({ type: "virtual" });

      this.$emit("input", JSON.stringify(this.multipleFields));
    },
    removeImsi(index) {
      this.multipleFields.splice(index, 1);
      if (this.multipleFields.length == 0) {
        this.multipleFields.push({});
      }
      this.$emit("input", JSON.stringify(this.multipleFields));
    },
  },
};
</script>