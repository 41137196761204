<template>
  <div v-if="imageSource">
    <img
        style="display:inline-block;height:45px;max-width:524.5px;width:545px;"
        status="none"
        class="sourceHeaderImg required"
        :src="imageSource"
    />
    <momentDateFormatter :result="result" v-model="timeformat"></momentDateFormatter>
    <reportAdditionalData :reportTemplatevalue="reportTemplatevalue"
                          @input="updateReportTemplate"></reportAdditionalData>
    <input type="hidden" :value="JSON.stringify(reportTemplatevalue)" :name="field"/>
  </div>
</template>

<script>
import momentDateFormatter from "@/components/legacy/momentDateFormatter.vue";
import reportAdditionalData from "@/components/legacy/reportAdditionalData.vue";
import requests from '@/js/requests';

export default {
  components: {momentDateFormatter, reportAdditionalData},
  props: ["result", "fieldAttributes", "field"],
  data() {
    return {reportTemplatevalue: {}};
  },
  computed: {
    pairArray: {
      get() {
        let discFields = [];
        if (typeof this.reportTemplatevalue["discFields"] != "undefined") {
          discFields = this.reportTemplatevalue["discFields"];
        }
        return discFields;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "discFields", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    imageSource() {
      let imageSource = "";
      if (typeof this.reportTemplatevalue.headerImg != "undefined") {
        imageSource = this.reportTemplatevalue.headerImg.imgURL;
      }
      return imageSource;
    },
    timeformat: {
      get() {
        let timeformat = "DD.MM.YYYY HH:mm:ss";
        if (typeof this.reportTemplatevalue["timeformat"] != "undefined") {
          timeformat = this.reportTemplatevalue["timeformat"];
        }
        return timeformat;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "timeformat", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
  },
  watch: {
    "result.valueLine.n_reportTemplateId": function (id) {
      this.getReportTemplateAjaxCall(id);
    },
  },
  methods: {
    updateReportTemplate(value) {
      this.reportTemplatevalue = value;
      this.$emit("input", this.reportTemplatevalue);
    },
    getReportTemplateAjaxCall(id) {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: this.result.tableUrl,
        data: {
          function: 'getReportTemplate',
          id: id,
        },
      })
          .then(function (response) {
            //handle success
            // console.log(response);
            caller.reportTemplatevalue = JSON.parse(response.data.result.json);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
    },
  },
};
</script>