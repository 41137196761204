<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="stepStoredValue"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";

export default {
  components: {fieldsRows},
  data() {
    return {};
  },
  props: ["result", "stepStoredValue", "stepType", "stepID",],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          value: {type: "textarea", name: "Comment", fieldAttrInput: {class: "required", autofocus: true}},
        },
        contentID: this.stepType,
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      localStoredStep[field] = value;
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>