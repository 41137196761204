<template>
  <div class="ldap-content-adder">
    <v-card outlined>
      <v-toolbar outlined elevation="0" color="primary" dense>
        <v-toolbar-title class="subtitle-2 white--text">
          LDAP Content
        </v-toolbar-title>
      </v-toolbar>
      <XDataTable
        v-if="loaded"
        class="table"
        :headers="headers"
        dialog-width="1800"
        no-elevation
        no-page-controls
        unpadded-dialog
        show-total
        history
        :refresh="refresh"
        :crud-requests="crudRequests"
        :items-request="testCaseInfo.getLDAPContents"
        :items-request-params="itemsRequestParams"
        :row-actions="rowActions"
        @update-item="item = $event"
        height="15vh"
        @refresh="$emit('refresh')">
        <template #[`item.attributes`]="{ value }">
          <span v-for="(attr, i) in parseLdif(JSON.parse(value))" :key="i">
            {{ attr }}
          </span>
        </template>
      </XDataTable>
    </v-card>
    <LdapDialog v-model="ldapDialog" :ldap-content="ldapContent" :new-ldap="false" />
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import LdapDialog from '@/components/specific/LdapDialog.vue';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import testCaseInfo from "@/js/services/TestCaseInfoService";

export default {
  name: 'LdapContentAdder',
  components: {
    LdapDialog,
    XDataTable,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      item: {},
      ldapDialog: false,
      loaded: false,
      headers: [
        {
          text: 'Service',
          value: 'service',
          sortable: true,
        },
        {
          text: 'Filter',
          value: 'filter',
          sortable: true,
          // width: 1600,
        },
        {
          text: 'Base',
          value: 'base',
          sortable: true,
          width: 348,
        },
        {
          text: 'LDIF',
          value: 'attributes',
          sortable: true,
          // width: 700,
        },
      ],
      refresh: 0,
      subscriberId: 0,
    };
  },
  computed: {
    testCaseInfo() {
      return testCaseInfo
    },
    crudRequests() {
      return {
        delete: {
          request: testCaseInfoService.deleteLDAPContent,
        }
      };
    },
    itemsRequestParams() {
      return [this.subscriberId];
    },
    ldapContent() {
      const ldapContent = {};

      if (this.item !== undefined) {
        ldapContent['id'] = this.item['id'];
        ldapContent['serverId'] = this.item['serverId'];
        ldapContent['service'] = this.item['service'] !== undefined ? this.item['service'].trim() : '';
        ldapContent['filter'] = this.item['filter'] !== undefined ? this.item['filter'] : '';
        ldapContent['base'] = this.item['base'] !== undefined ? this.item['base'] : '';
        ldapContent['attributes'] = this.item['attributes'] !== undefined ? JSON.parse(this.item['attributes']) : '';
      }
      if (this.row) {
        ldapContent['subscriberName'] = `${this.row.subscriber}` ? `${this.row.msisdn}: ${this.row.subscriber}` : `${this.row.msisdn}`;
        ldapContent['subscriberId'] = this.row.subscriberId;
      }

      return ldapContent;
    },
    rowActions() {
      return [
        {
          icon: 'mdi-square-edit-outline',
          click: (item) => {
            this.openLdapDialog(item);
          },
        },
      ];
    },
  },
  methods: {
    parseLdif(ldifObject) {
      const ldif = []
      Object.entries(ldifObject).map(([key, value]) => {
        if (typeof value === 'string') {
          ldif.push(`${key}:${value}`);
        }
        else if (Array.isArray(value)) {
          for (const val of value) {
            ldif.push(`${key}:${val}`);
          }
        }
        return "";
      });
      return ldif;
    },
    openLdapDialog(item) {
      this.item = item;
      console.log("ROW LDAP: ", this.ldapContent);
      this.ldapDialog = true;
    },
  },
  watch: {
    row: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value !== undefined) {
          this.subscriberId = value.subscriberId;
          this.loaded = true;
        }
      }
    },
  }
}
</script>

<style scoped>
.table {
  padding-left: 20px;
}
</style>