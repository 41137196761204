<template>
  <div v-if="row.n_type_original==1  ">
    <div v-if="row?.hssContent" class="hssContentContainer">
      <div class="inlineDiv top subscriberAdditionalInfoTitleContainer subtitle-2">HSS</div>
      <div class="inlineDiv ml-3">
        <hssDataContent :row="row"></hssDataContent>
      </div>
    </div>
    <div v-if="row?.ldapContent">
      <div class="inlineDiv top subscriberAdditionalInfoTitleContainer subtitle-2">LDAP</div>
      <div class="inlineDiv ml-3">
        <ldapContent :row="row"></ldapContent>
      </div>
    </div>
  </div>
  <div v-else-if="row.n_type_original==2">
    <div v-for="(subscriber, i) in row.subscriberList" :key="i" >
      <span
        v-if="subLength<60 && i < row.subscriberList.length-1"
      >{{subscriber}},</span>
    </div>
    <span v-if="subLength>60">...</span>
  </div>
</template>
<script>
import hssDataContent from "@/components/legacy/hssDataContent.vue";
import ldapContent from "@/components/legacy/ldapContent.vue";
// import {count} from "echarts/types/src/component/dataZoom/history";
export default {
  components: { hssDataContent, ldapContent },
  props: ["header", "row", "currentTable"],
  data() {
    return {
      // count: count,
      subLength: 0
    };
  },
};
</script>