<template>
    <div>
         <span class="d-inline-flex">
         <div @click.stop="dialog = true">
            <v-icon
                    class="cursorPointer mr-1"
                    :title=title
                    :color=color
            >mdi-email-send
            </v-icon>
        </div>
          {{row.n_dateReg}}
    <v-dialog
            v-model="dialog"
            max-width="350"
    >
        <v-card>
            <v-card-title class="headline-box-headline">
                {{title}}
            </v-card-title>
            <v-card-text>
               {{cardText}}
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
            > No
            </v-btn>
            <v-btn
                    color="green darken-1"
                    text
                    @click="sendActivationEmail(row.c_owner)"
            >
                Yes
            </v-btn>
            </v-card-actions>
            </v-card>
    </v-dialog>
     </span>

    </div>
</template>

<script>
  import requests from '@/js/requests';

  export default {
    data:function(){
      return {
        dialog:false

      };
    },
    components: {

    },

    computed:{
        title(){
          let title = "Resend Activation Email!";
          if(this.row.n_dateReg=='Not Yet'){
            title = "Resend the Invitation Email!";
          }
          return title;
        },
      cardText(){
        let cardText = "Are you sure to send Activation email!";
        if(this.row.n_dateReg=='Not Yet'){
          cardText = "Are you sure to send Invitation email!";
        }
        return cardText;
      }, color(){
        let color = "rgb(24 74 123)";
        if(this.row.n_dateReg=='Not Yet'){
          color = "green";
        }
        return color;
      }

    },
    methods: {

      sendActivationEmail(nid){
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=administration&f2=superUserAdministration',
          dataType: 'json',
          data: {
            function: 'sendActivationEmail',
            key: nid,
            requestType: 'ajax',
          },
        })
            .then(response => {
            if(typeof response.data.result.json.sent!='undefined' && response.data.result.json.sent==true){
              caller.$notify({
                group: "foo",
                text: "Successfully sent an Email!",
                duration: 2000,
                type: "success",
                speed: 600,
              });
            }else {
              caller.$notify({
                group: "foo",
                text: "Sorry, Try again later!",
                duration: 2000,
                type: "error",
                speed: 600,
              });
            }
            this.dialog=false;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    props: ["row","currentTable","result"]
  }
</script>