<template>
  <tr class="variable-row">
    <slot name="rowStart" />

    <td class="variable-row__cell variable-row__cell--var-name">
      <div class="variable-row__cell-inner variable-row__cell-inner--var-name">
        <slot name="firstCellPrepand" />

        <v-text-field
          ref="varNameField"
          class="variable-row__key-field"
          v-model="variableName"
          :rules="variableNameRules"
          outlined
          dense
          hide-details="auto"
          :background-color="isKeyEditing ? '' : '#f6f6f6'"
          @focus="() => doEditing(true, 'varNameField')"
          @blur="() => doEditing(false)"
          :error="isError"
        />
      </div>
    </td>

    <td class="variable-row__cell">
      <div class="variable-row__cell-inner">
        <v-textarea
          ref="varValueArea"
          v-model="variableValue"
          rows="1"
          outlined
          dense
          hide-details="auto"
          :background-color="isValueEditing ? '' : '#f6f6f6'"
          @focus="() => doEditing(true, 'varValueArea')"
          @blur="() => doEditing(false)"
        />

        <slot name="helpBtn" />
      </div>
    </td>

    <td class="variable-row__cell">
      <div class="variable-row__cell-inner variable-row__cell-inner--padd">
        <button
          type="button"
          title="Coming soon"
          style="cursor: auto"
          @click="() => void 0">
          <v-icon class="mr-1">
            mdi-plus-box-multiple
          </v-icon>
        </button>

        <button
          type="button"
          title="Coming soon"
          style="cursor: auto"
          @click="() => deleteItem(variable)">
          <v-icon color="red">
            mdi-delete
          </v-icon>
        </button>
      </div>
    </td>

    <slot name="rowEnd" />
  </tr>
</template>

<script>
import { defineComponent, ref, shallowRef, computed, onMounted, nextTick } from "vue";
import { useVariable } from "@/composition/variables/use-variable";

export default defineComponent({
  name: "VariableRow",
  props: {
    variable: {
      type: Object,
      required: true,
    },
    variableIdWithError: {
      type: Number,
      default: -1,
    },
    newVariableId: {
      type: Number,
      default: -1,
    },
  },
  emits: ["update-variable", "delete-variable"],
  setup(props, { emit }) {
    const varNameField = shallowRef(null);
    const varValueArea = shallowRef(null);
    const isEditing = ref(false);
    const editingWhat = ref("");
    const {
      variableName,
      variableValue,
      getUpdatedVariable,
      variableNameRules,
      isValidVariable,
    } = useVariable(props.variable);

    const isError = computed(() => {
      return props.variableIdWithError === props.variable.id
        || !props.variableIdWithError && !props.variable.id;
    });

    const isValueEditing = computed(
      () => isEditing.value && editingWhat.value === "varValueArea"
    );
    const isKeyEditing = computed(
      () => isEditing.value && editingWhat.value === "varNameField"
    );

    const resetTextareaHeight = () => {
      const textarea = varValueArea.value.$el.querySelector("textarea");
      textarea.setAttribute("style", "");
    };

    const emitUpdateVariable = (_v) => {
      nextTick().then(async () => {
        await (new Promise((resolve) => setTimeout(resolve, 1000)))
        if (!isValueEditing.value) {
          emit("update-variable", _v);
        }
      });
    };

    const doEditing = async (_isEditing, what = "") => {
      if (!_isEditing) {
        const _v = getUpdatedVariable();

        if (isValidVariable(_v)) {
          emitUpdateVariable(_v)
        }

        if (isValueEditing.value) resetTextareaHeight();
      }
      isEditing.value = _isEditing;
      editingWhat.value = what;
    };

    const deleteItem = (variable) => {
      emit("delete-variable", variable);
    };

    onMounted(() => {
      if (variableName.value === "") {
        const input = varNameField.value.$el.querySelector("input");
        input.focus();
      } else if (props.newVariableId
        && props.newVariableId === props.variable.id
        && variableValue.value === "") {
        const textarea = varValueArea.value.$el.querySelector("textarea");
        textarea.focus();
      }
    })

    return {
      isError,
      isEditing,
      isKeyEditing,
      isValueEditing,
      doEditing,
      variableName,
      variableValue,
      variableNameRules,
      varValueArea,
      varNameField,
      deleteItem,
    };
  },
});
</script>

<style lang="scss">
.variable-row {
  $root: &;

  &__cell {
    padding: 0.25rem 0.5rem !important;
    height: 100% !important;
    vertical-align: top !important;

    &#{$root}__cell--var-name {
      width: 364px;
    }
  }

  &__cell-inner {
    display: flex;
    height: 100%;

    &#{$root}__cell-inner--var-name {
      display: inline-block;
      width: 100%;
    }

    &#{$root}__cell-inner--padd {
      padding: 0.5rem !important
    }
  }
}
</style>
