<template>
  <div className="template-list-editor">
    <list-editor
        v-if="!keyName && dataTemplateRows.length"
        :columns="templateColumns"
        :value="computedTemplateRows"
        class="template-template-list-editor"
        persistent-list-length
        @input="handleTemplateInput"/>
    <key-list-editor
        v-else-if="Object.keys(dataTemplateRows).length"
        :columns="templateColumns"
        :key-name="keyName"
        :key-type="keyType"
        :value="computedKeyTemplateRows"
        class="template-template-list-editor"
        persistent-list-length
        @input="handleKeyTemplateInput"/>
    <list-editor
        class="mt-3"
        v-if="!keyName"
        :columns="computedColumns"
        :focused-element-index="focusedElementIndex"
        :validate-immediately="validateImmediately"
        :value="dataValue"
        row-name="Attribute"
        @input="$emit('input', $event)"/>
    <key-list-editor
        v-else
        :columns="computedColumns"
        :focused-element-index="focusedElementIndex"
        :key-name="keyName"
        :key-type="keyType"
        :validate-immediately="validateImmediately"
        :value="dataValue"
        row-name="Attribute"
        @input="$emit('input', $event)"/>
  </div>
</template>

<script>
import KeyListEditor from '@/components/extended/KeyListEditor';
import ListEditor from '@/components/basic/ListEditor';
import {deepCopy, deepEquals, parseBoolean} from '@/js/general';

export default {
  name: 'TemplateListEditor',
  components: {
    ListEditor,
    KeyListEditor,
  },
  props: {
    columns: Array,
    value: [Array, Object],
    disabledTemplateColumns: Array,
    templateRows: [Array, Object],
    showTemplate: Boolean,
    keyName: String,
    keyType: String,
    deactivatedTemplateRows: [Array, Object],
    id: {
      default: 'id',
      type: String,
    },
    validateImmediately: Boolean,
  },
  data() {
    return {
      dataValue: deepCopy(this.value),
      dataTemplateRows: {},
      changeLastFrame: false,
      focusedElementIndex: -1,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!deepEquals(value, this.dataValue)) this.dataValue = value;
      },
    },
    templateRows: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!deepEquals(value, this.dataTemplateRows)) this.dataTemplateRows = value;
      },
    },
  },
  computed: {
    computedColumns() {
      const computedColumns = deepCopy(this.columns);
      computedColumns.push({
        type: 'switch',
        label: 'Deactivated',
        visibility: 'hidden',
        value: 'deactivate',
      });
      return computedColumns;
    },
    computedTemplateRows() {
      if (!this.dataTemplateRows || !this.dataTemplateRows.length) return [];

      const templateRows = deepCopy(this.dataTemplateRows);
      for (const templateRow of templateRows) {
        templateRow.deactivate = this.computedDeactivatedTemplateRows.includes(templateRow[this.id]);
      }
      return templateRows;
    },
    computedKeyTemplateRows() {
      if (!this.dataTemplateRows) return {};

      const templateRows = deepCopy(this.dataTemplateRows);
      for (const templateKeyRows of Object.values(templateRows)) {
        for (const templateRow of templateKeyRows) {
          templateRow.deactivate = this.computedDeactivatedTemplateRows.includes(templateRow[this.id]);
        }
      }
      return templateRows;
    },
    templateColumns() {
      const templateColumns = deepCopy(this.columns);
      for (const templateColumn of templateColumns) {
        templateColumn.disabled = (row) => {
          return this.disabledTemplateColumns && this.disabledTemplateColumns.includes(templateColumn.value)
              || row.deactivate;
        };
        templateColumn.editable = false;
        if (this.disabledTemplateColumns && this.disabledTemplateColumns.includes(templateColumn.value)) {
          templateColumn.color = 'success';
        }
        const type = typeof templateColumn.type === 'function' ? templateColumn.type() : templateColumn.type;
        if (type === 'text') {
          templateColumn.keepValue = true;
        }
      }
      templateColumns.push({
        type: 'switch',
        value: 'deactivate',
        label: 'Deactivated',
      });
      return templateColumns;
    },
    computedDeactivatedTemplateRows() {
      if (this.deactivatedTemplateRows &&
          Array.isArray(this.deactivatedTemplateRows)) {
        return this.deactivatedTemplateRows;
      }
      return [];
    },
  },
  methods: {
    handleTemplateInput(templateRows) {
      let deactivated = [];
      let change = false;
      for (const templateRow in templateRows) {
        if (templateRow.deactivate !== this.dataTemplateRows.deactivate) {
          this.changeLastFrame = false;
          break;
        }
      }
      if (!this.changeLastFrame) {
        for (let i = 0; i < templateRows.length; i++) {
          const templateRow = templateRows[i];
          for (const [templateRowKey, templateRowValue] of Object.entries(templateRow)) {
            if (templateRowKey !== 'deactivate') {
              if (templateRowValue !== this.dataTemplateRows[i][templateRowKey]) {
                templateRow.deactivate = true;
                const newValueRow = {};
                for (const column of this.columns.filter(x => x.value !== this.keyName)) {
                  newValueRow[column.value] = templateRow[column.value];
                }
                const dataValueCopy = deepCopy(this.dataValue);
                dataValueCopy.push(newValueRow);
                change = true;
                this.$emit('input', dataValueCopy);
                this.focusedElementIndex = -1;
                this.$nextTick(() => {
                  this.$nextTick(() => {
                    this.$nextTick(() => {
                      this.focusedElementIndex = (this.dataValue.length - 1) * this.computedColumns.length
                          + this.computedColumns.indexOf(this.computedColumns.find(x => x.value === templateRowKey));
                    });
                  });
                });
              }
            }
          }
          if (parseBoolean(templateRow.deactivate)) deactivated.push(templateRow[this.id]);
        }
      } else {
        deactivated = this.computedDeactivatedTemplateRows;
      }
      this.dataTemplateRows = templateRows;
      this.$emit('deactivated-update', deactivated);
      this.$emit('template-input', templateRows);
      if (change) this.changeLastFrame = true;
      else if (this.changeLastFrame) this.changeLastFrame = false;
    },
    handleKeyTemplateInput(templateRows) {
      let deactivated = [];
      let change = false;
      if (!this.changeLastFrame) {
        for (const [key, keyRows] of Object.entries(templateRows)) {
          for (let i = 0; i < keyRows.length; i++) {
            const templateRow = keyRows[i];
            for (const [templateRowKey, templateRowValue] of Object.entries(templateRow)) {
              if (templateRowKey !== 'deactivate') {
                if (templateRowValue !== this.dataTemplateRows[key][i][templateRowKey]) {
                  templateRow.deactivate = true;
                  const newValueRow = {};
                  for (const column of this.columns.filter(x => x.value !== this.keyName)) {
                    newValueRow[column.value] = templateRow[column.value];
                  }
                  const dataValueCopy = deepCopy(this.dataValue);
                  if (!dataValueCopy[key]) dataValueCopy[key] = [];
                  dataValueCopy[key].push(newValueRow);
                  change = true;
                  this.$emit('input', dataValueCopy);
                  this.focusedElementIndex = -1;
                  this.$nextTick(() => {
                    this.$nextTick(() => {
                      this.$nextTick(() => {
                        let totalLength = 0;
                        for (const [dataValueRowsKey, dataValueKeyRows] of Object.entries(this.dataValue)) {
                          totalLength += dataValueKeyRows.length;
                          if (dataValueRowsKey === key) break;
                        }
                        let index = (totalLength - 1) * this.computedColumns.length;
                        index +=
                            this.computedColumns.indexOf(this.computedColumns.find(x => x.value === templateRowKey));
                        this.focusedElementIndex = index;
                      });
                    });
                  });
                }
              }
            }
            if (parseBoolean(templateRow.deactivate)) deactivated.push(templateRow[this.id]);
          }
        }
      } else {
        deactivated = this.computedDeactivatedTemplateRows;
      }
      this.dataTemplateRows = templateRows;
      this.$emit('deactivated-update', deactivated);
      this.$emit('template-input', templateRows);
      if (change) this.changeLastFrame = true;
      else if (this.changeLastFrame) this.changeLastFrame = false;
    },
  },
};
</script>

<style scoped>
.template-list-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>