<template>
  <TestStatus header range :refresh="30"/>
</template>

<script>
import TestStatus from '@/components/specific/TestStatus/TestStatus.vue';

export default {
  name: 'TestStatusView',
  components: {TestStatus},
};
</script>

