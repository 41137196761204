<template>
  <v-dialog
    persistent
    no-click-animation
    :value="parameterDialog"
    width="700"
    v-if="parameterDialog"
  >
    <fields :result="result" v-on="$listeners">
      <template v-slot:default="fieldsProps">
        <v-card-text class="pt-5">
          <singleAccordion
            id="testLocalVariables"
            :title="'Local Variables (' + Object.keys(localPairArray).length + ')'"
            :expanded="localPairArray.length > 0"
          >
            <div class="mt-5">
              <lineFieldsArray
                :fieldAttrRow="{}"
                :fields="fields"
                :value="localPairArray"
                @line-field-input="checkInput('testlocal', ...arguments)"
                :headerTitle="false"
                :templateContent="result"
                :isValid="fieldsProps.isValid"
                @revalidate="$emit('revalidate')"
                linePrefix="localStartParameters"
                :addable="false"
                :updateOnChange="true"
              ></lineFieldsArray>
            </div>
          </singleAccordion>
          <singleAccordion
            id="testGlobalVariables"
            :title="'Group Variables (' + Object.keys(globalPairArray).length + ')'"
            :expanded="globalPairArray.length > 0"
          >
            <div class="mt-5">
              <lineFieldsArray
                :fieldAttrRow="{}"
                :fields="fields"
                :value="globalPairArray"
                @line-field-input="checkInput('testgroup', ...arguments)"
                :headerTitle="false"
                :templateContent="result"
                :isValid="fieldsProps.isValid"
                @revalidate="$emit('revalidate')"
                linePrefix="globalStartParameters"
                :addable="false"
                :updateOnChange="true"
              ></lineFieldsArray>
            </div>
          </singleAccordion>
          <div class="mt-3"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <primaryButton
            type="button"
            @click="emitOk(fieldsProps)"
            label="Run"
            icon="mdi-play"
            color="primary"
            :id="'startParameters_ok_button'"
            data-type="ok_button"
            :disabled="!fieldsProps.isValid || loading"
            :loading="loading"
          ></primaryButton>
          <slot name="additionalButtons"></slot>
          <cancel
            @click="emitCancel()"
            buttonsIDPrefix="startParameters"
          ></cancel>
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
      <template v-slot:fieldActions><div></div></template>
    </fields>
  </v-dialog>
</template>
<script>
import fields from "@/commonComponents/dynamic/fields.vue";
import cancel from "@/commonComponents/cancel.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
// import dialogContent from "@/commonComponents/dialogContent.vue";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import singleAccordion from "@/commonComponents/singleAccordion.vue";

export default {
  components: {
    cancel,
    primaryButton,
    lineFieldsArray,
    singleAccordion,
    fields,
    //  dialogContent
  },
  data() {
    return {
      dialog: false,
      result: {
        lazyValidation: false,
        contentID: "startParameters",
        removeHelp: true,
        removeMaximize: true,
        json: { dialogFormat: {}, dialogTitle: "Enter Start Parameters" },
      },
      operatorList: [],
      fields: {
        name: {
          type: "text",
          name: "Name",
          fieldAttrInput: {
            class: "required noSpace",
            disabled: true,
            preserveDisabled: true,
          },
        },
        value: {
          type: "text",
          name: "Value",
          fieldAttrInput: { class: "required" },
        },
   /*     id: {
          type: "label",
          name: "Value",
          associatedOption: this.testNames,
          mask: "associatedOption",
          inputFieldFormatter: "testVariableInfo",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-2",
          },
        },*/
      },
    };
  },
  props: {
    value: Array,
    parameterDialog: Boolean,
    loading: Boolean,
    testNames: Object,
  },
  computed: {
    startParameters: {
      get() {
        let localVal = [];
        localVal = this.value;
        return localVal;
      },
      set(localVal) {
        this.$emit("input", localVal);
      },
    },
    globalPairArray() {
      return this.getTestVariablesValues("testgroup");
    },
    localPairArray() {
      return this.getTestVariablesValues("testlocal");
    },
  },
  methods: {
    getTestVariablesValues(variableIndex) {
      let pairArray = [];
      this.startParameters.forEach((element) => {
        if (element.namespace == variableIndex) {
          pairArray.push(element);
        }
      });
      //console.log(variableIndex, pairArray);
      return pairArray;
    },
    emitOk: function (fieldsProps) {
      this.$emit("revalidate");
      this.$nextTick(() => {
        if (fieldsProps.isValid) {
          this.$emit("confirmation-ok", true);
        }
      });
    },
    emitCancel: function () {
      this.$emit("confirmation-ok", false);
    },
    checkInput(type, value) {
      let input = [];
      if (type == "testlocal") {
        input = [...this.globalPairArray, ...value];
      }
      if (type == "testgroup") {
        input = [...this.localPairArray, ...value];
      }

      this.$emit("input", input);
    },
  },
};
</script>
