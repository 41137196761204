<template>
  <div class="d-flex mt-7 mr-4">
    <primaryTextfield
            :fieldAttrInput="{width:'150px', class:'mr-2'}"
            label="Backend Search"
            v-model="searchValue">
    </primaryTextfield>
    <primaryButton
            label="Search"
            @click="searchAllProjects"
            type="button">
    </primaryButton>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import requests from '@/js/requests';
export default {
  props: ["result", "currentTable"],
  data() {
    return { searchValue: "" };
  },
  components: { primaryTextfield, primaryButton },
  methods: {
    searchAllProjects() {
      // var $button = $(caller);
      // var $searchAllProjects = $button.closest("span").find(".searchAllProjects");
      // var searchValue = $searchAllProjects.val();
      // $tableSelector = $button.closest(".tableContent").find(".dataTable");
      // $overlayWrapper = $tableSelector.closest(".overlayWrapper");
      // if (typeof $overlayWrapper != "undefined") {
      //   var $overlay = $overlayWrapper.find(".overlay");
      //   var $loadingImage = $overlayWrapper.find(".loadingImage");
      //   coverLoadingTable($tableSelector, $overlay, $loadingImage);
      // }
      var options = {
        searchValue: this.searchValue,
        function: "searchAllProjects",
        requestType: "ajax",
      };
      //var dataUrl = $tableSelector.attr("data-url");
      // $.ajax({
      //   data: options,
      //   url: dataUrl,
      //   success: searchAllProjectsCallback($tableSelector, $overlay, caller),
      // });
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: this.currentTable.tableUrl,
        data: options,
      })
          .then(function (response) {
          caller.$set(
            caller.currentTable,
            "data",
            response.data.result.table.data
          );
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
  },
};

// $(document).ready(function () {
//   $(".searchAllProjects").keypress(function (e) {
//     if (e.keyCode == 13) $(".backendSearchButton").click();
//   });
// });

// function searchAllProjects(caller) {
//   var $button = $(caller);
//   var $searchAllProjects = $button.closest("span").find(".searchAllProjects");
//   var searchValue = $searchAllProjects.val();
//   $tableSelector = $button.closest(".tableContent").find(".dataTable");
//   $overlayWrapper = $tableSelector.closest(".overlayWrapper");
//   if (typeof $overlayWrapper != "undefined") {
//     var $overlay = $overlayWrapper.find(".overlay");
//     var $loadingImage = $overlayWrapper.find(".loadingImage");
//     coverLoadingTable($tableSelector, $overlay, $loadingImage);
//   }
//   var options = {
//     searchValue: searchValue,
//     function: "searchAllProjects",
//     requestType: "ajax",
//   };
//   var dataUrl = $tableSelector.attr("data-url");
//   $.ajax({
//     data: options,
//     url: dataUrl,
//     success: searchAllProjectsCallback($tableSelector, $overlay, caller),
//   });
// }

// function searchAllProjectsCallback($tableSelector, $overlay, caller) {
//   return function (data) {
//     var table = $tableSelector.data("dataTable");
//     table.clear();
//     $overlay.fadeOut();
//     addrows(data, $tableSelector);
//   };
// }
</script>