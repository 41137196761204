<script setup>
import { ref, inject, onBeforeMount } from "vue";
import XSelect from "@/components/basic/XSelect.vue";
import UiDialog from "@/components/basic/dialog/UiDialog.vue"
import XBtn from "@/components/basic/XBtn.vue"
import { userService } from "@/api";
import { useSwitchUser } from "@/composition/user/use-switch-user";

const { getCloseMethod } = inject("DialogsRoot");
const closeDialog = getCloseMethod("SwitchUserModal");

const { doUserSwitch } = useSwitchUser();

const selectedUser = ref();

const users = ref([]);

const userFormatter = (user) => {
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

const isSwitching = ref(false);
const switchUser = async () => {
  isSwitching.value = true;
  try {
    await doUserSwitch({ userId: selectedUser.value });
    closeDialog();
  } finally {
    isSwitching.value = false;
  }
};

onBeforeMount(async () => {
  users.value = await userService.v1.fetchAllUsers();
});
</script>

<template>
  <UiDialog
    size="small"
    title="Switch user"
    @close-modal="() => closeDialog()"
  >
    <template #body>
      <XSelect
        label="Select user"
        v-model="selectedUser"
        :items="users"
        :item-text="userFormatter"
        item-value="id"
        required/>
    </template>

    <template #footer>
      <XBtn
        text="Switch"
        icon="mdi-move-resize-variant"
        color="primary"
        :loading="isSwitching"
        text-color="white"
        @click="() => switchUser()"
      />

      <XBtn
        text="Cancel"
        icon="mdi-cancel"
        color="secondary"
        text-color="white"
        @click="() => closeDialog()"
      />
    </template>
  </UiDialog>
</template>

