<template>
  <div class="explorer-http-data-usage">
    <StartFinishedMaximum :value="localStoredValue" @input="emitInput"/>
    <TestStepTarget :value="stepStoredValue" @input="emitInput" :default="{type: 'static', host: ''}">
      <template #static="{value, emitInput}">
        <XTextField
            label="Target"
            :value="value.host"
            @input="emitInput('host', $event)"
            required
            validate-immediately/>
      </template>
      <template #resource="{value, emitInput}">
        <InputRow>
          <XSelect
              label="Type"
              :value="value.resourceType"
              :items="resourceTagTypes"
              required
              @input="emitInput('resourceType', $event)"/>
          <XSelect
              label="Tag"
              :value="value.resourceTag"
              :items="resourceTags[value.resourceType]"
              required
              @input="emitInput('resourceTag', $event)"/>
        </InputRow>
      </template>
    </TestStepTarget>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="localStoredValue"/>
  </div>
</template>
<script>
import StartFinishedMaximum from '@/components/specific/TestStep/StartFinishedMaximum.vue';
import TestStepTarget from '@/components/specific/TestStep/TestStepTarget.vue';
import XTextField from '@/components/basic/XTextField.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XSelect from '@/components/basic/XSelect.vue';
import resourceManagerService from '@/js/services/ResourceManagerService';
import teststepDefault from '@/cfg/teststep-default.json';
import {deepCopy, setDefaultValues} from "@/js/general";

import fieldsRows from '@/commonComponents/fieldsRows.vue';


export default {
  name: 'step_Explorer_data_usage',
  components: {
    XSelect,
    InputRow,
    XTextField,
    TestStepTarget,
    StartFinishedMaximum,
    fieldsRows
  },
  props: {
    result: Object,
    stepStoredValue: Object,
    stepType: String,
    stepId: [String, Number],
  },
  data() {
    return {
      resourceTags: {},
      setDefaultValues: setDefaultValues,
    };
  },
  created() {
    resourceManagerService.getHttpDownloadResourceTags((resourceTags) => {
      this.resourceTags = resourceTags;
    });

    if (typeof this.stepStoredValue.parameters === 'undefined') {
      this.localStoredValue.parameters = teststepDefault[this.stepType].parameters;
    }
  },
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          parameters: {
            type: 'text',
            inputFieldFormatter: 'explorerDataUsageParameters',
          },
          res: {
            type: 'text',
            inputFieldFormatter: 'explorerResult',
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.stepType,
      };

      return templateContent;
    },
    localStoredValue: {
      get() {
        const localStoredValue = deepCopy(this.stepStoredValue);
        if (this.setDefaultValues(localStoredValue, teststepDefault[this.stepType])) {
          this.$emit('stored-value-changed', localStoredValue);
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit('stored-value-changed', newVal);
      },
    },
    resourceTagTypes() {
      const resourceTagTypes = [];
      for (const [resourceTagKey, resourceTagValues] of Object.entries(this.resourceTags)) {
        if (resourceTagValues.length) resourceTagTypes.push(resourceTagKey);
      }
      return resourceTagTypes;
    },
  },

  methods: {
    emitInput(value) {
      const newValue = {...value};
      delete newValue.category;
      delete newValue.subType;
      this.$emit('stored-value-changed', newValue);
    },
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      this.$set(localStoredStep, field, value);
      this.$emit('stored-value-changed', localStoredStep);
    },
  },
};
</script>

<style scoped>
.explorer-http-data-usage {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
