
<template>
  <div>
    <entryAdder
      v-model="localValue"
      :addable="true"
      :headerTitle="true"
      indexName="Attribute"
      :result="result"
      :isValid="isValid"
      :linePrefix="getFieldId(fieldAttributes.fieldAttrInput, result, field)"
    ></entryAdder>
    <input type="hidden" :value="JSON.stringify(localValue)" :name="field" />
  </div>
</template>

<script>
import { getFieldId } from "@/js/helper.js";
import entryAdder from "@/components/legacy/entryAdder.vue";

export default {
  components: { entryAdder },
  data() {
    return {};
  },
  methods: { getFieldId: getFieldId },
  computed: {
    localValue: {
      get() {
        let localValue = {};
        if (typeof this.value == "string") {
          localValue = JSON.parse(this.value);
        }
        return localValue;
      },
      set(newVal) {
        this.$emit("input", JSON.stringify(newVal));
      },
    },
  },
  props: ["result", "fieldAttributes", "field", "value", "isValid"],
};
</script>