<script setup>
import { defineProps, inject, defineAsyncComponent, ref, computed } from "vue"
import { useStore } from "@/store"
import UiDialog from "@/components/basic/dialog/UiDialog.vue"
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
import XBtn from "@/components/basic/XBtn.vue"
import UiSwitch from "@/components/basic/form/UiSwitch.vue"
import UiChipsSelect from "@/components/basic/form/UiChipsSelect.vue"
import { useSimConfiguration, CUSTOM_FIELDS_KEY, SIM_TYPES, getSimQRcode, SIM_TITLE } from "@/composition/sim/use-sim-configuration"
import { useSimsStore } from "@/composition/sim/use-sims-store"
import { useNotifications } from "@/composition/notifications"
import { sleep, capitalize } from "@/js/helper"
import { simService } from "@/api"
import UiSelect from "@/components/basic/form/UiSelect.vue";
import UiFileUpload from "@/components/basic/form/UiFileUpload.vue"
import { convertFileToBase64 } from "@/js/helper";

const UiCreateField = defineAsyncComponent(() => import(/* webpackChunkName: "ui-create-field" */ "@/components/basic/form/UiCreateField.vue"))

const UPDATE_FIELD_HINTS = {
  nameHints: "The field's name will be updated for ALL sims",
  valueHints: "The property's value for the current sim",
}
const CREATE_FIELD_HINTS = {
  nameHints: "This field will be added for ALL sims",
  valueHints: UPDATE_FIELD_HINTS.valueHints,
}

const store = useStore()

const props = defineProps({
  simData: {
    type: Object,
    required: true,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
  simType: {
    type: String,
    default: SIM_TYPES.SIM
  }
})

const isOrgaAdmin = computed(() => {
  return store.getters.isOrgaAdmin
})

const { showNotification } = useNotifications()

const {
  sim,
  simFields,
  customFields,
  updateSimFieldValue,
  updateCustomFieldValue,
  watchToUnfreezeName,
  addNewCustomField,
  updateCustomFieldName,
  deleteCustomField,
  isDirty,
  save: _save,
  isSaving,
  isValid,
  isUpdated,
  NewSim,
  isDeactivatedSim,
  updateSimStatus: _updateSimStatus,
  isChangingStatus,
  create: _create,
} = useSimConfiguration({ sim: props.simData, isAdmin: isOrgaAdmin, isNew: props.isNew, simType: props.simType })

watchToUnfreezeName(isOrgaAdmin)

const updateSimStatus = async () => {
  try {
    await _updateSimStatus()
  } catch (e) {
    showNotification({ message: "Data is not saved. There is unpredictable error occurred!" })
    throw e
  }
}

const dialogTitle = computed(() => {
  if (props.isNew) {
    return "Add eSIM Profile";
  }
  return `${SIM_TITLE[sim.value.type]}: ${sim.value.name} [${sim.value.status}]`;
})

const { orgTagsForSims, updateTagList, orgCustomFieldsMap, createCustomField, wallets } = useSimsStore()
const deletedFields = ref([])
const updatedFields = ref([])

const { getCloseMethod, openDialog, addBeforeClose } = inject("DialogsRoot")
const _close = getCloseMethod("SIMConfigurationDialog")
const openYesNoDialog = () => openDialog("YesNoDialog2", { title: "Really close?", question: `You have unsaved changes, do you really want to close?` }, { isUiDialog: true })
const beforeClose = async () => {
  if (isDirty.value) {
    const { isYes } = await openYesNoDialog()
    return isYes;
  }
  return true
}
addBeforeClose("SIMConfigurationDialog", beforeClose)

const getUpdatedData = (newSim = null) => {
  const data = { sim: null, deletedFields: [], updatedFields: [] }

  if (isUpdated.value && !newSim) {
    data.sim = NewSim()
    data.sim.tags.length && updateTagList(data.sim.tags)
  } else if (isUpdated.value && newSim) {
    data.sim = newSim;
    data.sim.tags.length && updateTagList(data.sim.tags);
  }

  if (deletedFields.value.length) {
    data.deletedFields = [ ...deletedFields.value ]
  }

  if (updatedFields.value.length) {
    data.updatedFields = [ ...updatedFields.value ]
  }

  return data
}

const close = async () => {
  const isYes = await beforeClose()

  if (!isYes) return

  const data = getUpdatedData()

  _close({ data })
}

const create = async () => {
  try {
    const newSim = await _create();
    const data = getUpdatedData(newSim);
    _close({ data })
  } catch (e) {
    showNotification({ message: "Data is not saved. There is unpredictable error occurred!" })
    throw e
  }
}

const save = async () => {
  try {
    await _save()
    const data = getUpdatedData()
    _close({ data })
  } catch (e) {
    showNotification({ message: "Data is not saved. There is unpredictable error occurred!" })
    throw e
  }
}

const onSubmit = () => {
  if (props.isNew) {
    create();
    return;
  }
  save();
}


const doCustomFieldRemoving = async (key) => {
  const name = orgCustomFieldsMap.value.get(key).name
  const { isYes } = await openDialog("YesNoDialog2", {
    title: "Really delete?",
    question: `Are you sure you want to delete the custom field "${name}"? It will be removed for all sims.`,
  }, { isUiDialog: true })

  if (!isYes) return

  deletedFields.value.push(key)

  deleteCustomField(key)
}


const customFieldToEdit = ref(null)
const customFieldKeyToEdit = ref("")
const isCustomFieldToEditValid = ref(true)
const setCustomFieldToEditValid = (isValid) => {
  isCustomFieldToEditValid.value = isValid
}
const startEditCustomField = (f) => {
  customFieldToEdit.value = { ...f }
  customFieldKeyToEdit.value = f.key
}
const finishEditCustomField = () => {
  customFieldToEdit.value = null
  customFieldKeyToEdit.value = ""
}
const updateField = (key) => {
  const oldName = orgCustomFieldsMap.value.get(key).name
  const oldValue = props.simData[CUSTOM_FIELDS_KEY][key]
  const { name, value } = customFieldToEdit.value
  if (oldName !== name) {
    updatedFields.value.push([key, name])
    updateCustomFieldName(key, name)
  }
  if (oldValue !== value && Boolean(oldValue)) {
    updateCustomFieldValue({ key, value })
  }
  finishEditCustomField()
}
const discardFieldConfiguration = () => {
  customFieldToEdit.value = null
}
const editCustomField = ({ name, value }) => {
  customFieldToEdit.value.name = name
  customFieldToEdit.value.value = value
}


const uiDialog = ref(null)
const newField = ref(null)
const isNewFieldValid = ref(false)
const startNewField = async () => {
  newField.value = { name: "", value: "" }
  await sleep(450)
  uiDialog.value?.scrollBody({ top: 9999, behavior: "smooth" })
}
const editNewField = ({ name, value }) => {
  newField.value.name = name
  newField.value.value = value
}
const finishNewField = async () => {
  const name = newField.value.name
  if (!name) {
    return
  }
  const id = await createCustomField({ name: newField.value.name })
  addNewCustomField({ key: id, name, value: newField.value.value })
  newField.value = null
}
const discardNewField = () => {
  newField.value = null
}

const simCounterByTag = ref(0)
const tagInputError = ref("")
const tagSearchHint = ref("")
const createHint = (tag) => {
  if (!tag) {
    return tagSearchHint.value = ""
  }
  tagSearchHint.value = `Press Enter to create "${tag}" tag.`
}
const validateUserTagInput = async (tag) => {
  try {
    const { count } = await simService.v2.countSimByTagQuery(tag)
    simCounterByTag.value = count
    if (!count) {
      createHint(tag)
    } else {
      createHint("")
    }
    return ""
  } catch (err) {
    tagInputError.value = capitalize(err.message.trim())
    return tagInputError.value
  }
}

const file = ref(null);
const handleFileUpload = async ({ key, file: _f }) => {
  file.value = _f;
  if (_f) {
    const base64 = await convertFileToBase64(_f)
    updateSimFieldValue({ key, value: base64 })
  } else {
    updateSimFieldValue({ key, value: "" })
  }
}
</script>

<template>
  <UiDialog
    ref="uiDialog"
    class="sim-config-dialog"
    :title="dialogTitle"
    @close-modal="() => close()"
  >
    <template #body>
      <div class="sim-config-dialog__body">
        <ul class="sim-config-dialog__fields-list">
          <li class="sim-config-dialog__field-item">
            <div class="sim-config-dialog__first-list-item">
              <div class="sim-config-dialog__switcher-box">
                <UiSwitch
                  :disabled="isNew"
                  :model-value="!isDeactivatedSim"
                  @update:modelValue="() => updateSimStatus()"
                />

                <span class="sim-config-dialog__switcher-txt">
                  <v-fade-transition mode="out-in">
                    <span :key="isDeactivatedSim.toString()">
                      {{ isDeactivatedSim ? 'Activate' : 'Deactivate' }}
                    </span>
                  </v-fade-transition>
                </span>

                <span class="sim-config-dialog__status-loader">
                  <v-progress-circular
                    v-if="isChangingStatus"
                    indeterminate
                    color="primary"
                    size="16"
                    width="2"
                  />
                </span>
              </div>

              <div
                v-if="simType === SIM_TYPES.ESIM && !isNew && getSimQRcode(simData)"
                class="sim-config-dialog__qr-code-preview"
              >
                <img :src="getSimQRcode(simData)" alt="qr code preview" />
              </div>
            </div>
          </li>

          <li
            v-for="f of simFields"
            :key="f.name"
            class="sim-config-dialog__field-item"
          >
            <UiChipsSelect
              v-if="f.key === 'tags'"
              :label="f.name"
              :options="orgTagsForSims"
              :model-value="f.value"
              :errors="!f.value.length ? 'The sim should have at least one tag' : ''"
              :user-input-validator="validateUserTagInput"
              :search-hints="tagSearchHint"
              allow-custom-values
              @update:modelValue="(tags) => updateSimFieldValue({ key: f.key, value: tags })"
              @update:options="(newTags) => updateTagList(newTags)"
            />

            <UiSelect
              v-else-if="f.type === 'select' && f.key === 'walletId' && simType === SIM_TYPES.ESIM"
              :class="{
                'sim-config-dialog__field-value': true,
                'sim-config-dialog__field-value--disabled': !isNew,
              }"
              :item-key="f.value.toString()"
              @update:itemKey="(id) => updateSimFieldValue({ key: f.key, value: id })"
              :label="f.name"
              :items="wallets"
              :errors="(!f.value && f.required) ? 'This field is required' : ''"
              get-key-by="id"
              get-value-by="name"
              :disabled="!isNew"
            />

            <UiFileUpload
              v-else-if="f.type === 'file' && isNew && simType === SIM_TYPES.ESIM"
              :file="file"
              @update:file="(file) => handleFileUpload({ key: f.key, file })"
              :label="f.name"
              :hints="f.hints || ''"
              :maxSize="f.maxSize"
              :accept="f.accept"
              :errors="(!f.value && f.required) ? 'This field is required' : ''"
            />


            <UiInputWrapper
              v-else-if="f.type !== 'file' && f.type !== 'select'"
              :class="{
                'sim-config-dialog__field-value': true,
                'sim-config-dialog__field-value--disabled': f.frozen,
              }"
              :label="f.name"
              :errors="(!f.value && f.required) ? 'This field is required' : ''"
            >
              <input
                :type="f.type"
                :value="f.value"
                placeholder=" "
                :disabled="f.frozen"
                autocomplete="off"
                autosave="off"
                aria-autocomplete="none"
                @input="(e) => updateSimFieldValue({ key: f.key, value: e.target.value })"
              />
            </UiInputWrapper>

          </li>

          <li
            v-for="f of customFields"
            :key="f.key"
            class="sim-config-dialog__field-item sim-config-dialog__field-item--custom"
          >
            <!-- <v-fade-transition mode="out-in"> -->
              <div
                v-if="customFieldToEdit === null || customFieldKeyToEdit !== f.key"
                style="display: contents;"
              >
                <div
                  v-if="isOrgaAdmin"
                  class="sim-config-dialog__field-admin-controls"
                >
                  <button
                    @click="() => doCustomFieldRemoving(f.key)"
                    type="button"
                  >
                    <v-icon color="error">
                      mdi-delete
                    </v-icon>
                  </button>

                  <button
                    @click="() => startEditCustomField(f)"
                    type="button"
                  >
                    <v-icon color="primary">
                      mdi-cog
                    </v-icon>
                  </button>
                </div>

                <UiInputWrapper
                  class="sim-config-dialog__field-value  sim-config-dialog__field-value--custom"
                  :label="f.name"
                >
                  <textarea
                    rows="1"
                    placeholder=" "
                    :value="f.value"
                    @input="(e) => updateCustomFieldValue({ key: f.key, value: e.target.value })"
                  />
                </UiInputWrapper>
              </div>

              <template v-else-if="customFieldKeyToEdit === f.key">
                <div class="sim-config-dialog__config-field-box">
                  <UiCreateField
                    class="sim-config-dialog__config-field"
                    :field="{ ...customFieldToEdit, ...UPDATE_FIELD_HINTS }"
                    @update:field="(f) => editCustomField(f)"
                    @update:is-valid="(isValid) => setCustomFieldToEditValid(isValid)"
                    :can-be-required="false"
                    withValue
                    valueLabel="Custom field value"
                  />

                  <div class="sim-config-dialog__btn-box">
                    <XBtn
                      text="Update"
                      icon="mdi-content-save"
                      color="save"
                      :loading="isSaving"
                      text-color="white"
                      :disabled="!isCustomFieldToEditValid"
                      @click="() => updateField(f.key)"
                    />

                    <XBtn
                      text="Discard"
                      icon="mdi-content-save"
                      color="secondary"
                      :loading="isSaving"
                      text-color="white"
                      @click="() => discardFieldConfiguration()"
                    />
                  </div>
                </div>
              </template>
            <!-- </v-fade-transition> -->
          </li>
        </ul>

        <v-fade-transition mode="out-in">
          <div
            class="sim-config-dialog__btn-box sim-config-dialog__btn-box--m-top"
            v-if="!newField && isOrgaAdmin"
            key="add-new-field-btn"
          >
            <XBtn
              text="Add new field"
              icon="mdi-content-save"
              color="save"
              :loading="isSaving"
              text-color="white"
              @click="() => startNewField()"
            />
          </div>

          <div
            v-else-if="isOrgaAdmin"
            class="sim-config-dialog__config-field-box"
            key="create-new-field-box"
          >
            <UiCreateField
              :field="{ ...newField, ...CREATE_FIELD_HINTS }"
              @update:field="(f) => editNewField(f)"
              @update:is-valid="(isValid) => isNewFieldValid = isValid"
              withValue
              :can-be-required="false"
              valueLabel="Custom field value"
            />

            <div
              class="sim-config-dialog__btn-box"
            >
              <XBtn
                text="Add"
                icon="mdi-content-save"
                color="save"
                text-color="white"
                :disabled="!isNewFieldValid"
                @click="() => finishNewField()"
              />

              <XBtn
                text="Discard"
                icon="mdi-content-save"
                color="secondary"
                text-color="white"
                @click="() => discardNewField()"
              />
            </div>
          </div>
        </v-fade-transition>
      </div>
    </template>

    <template #footer>
      <XBtn
        text="Save"
        icon="mdi-content-save"
        color="save"
        :loading="isSaving"
        text-color="white"
        :disabled="!isValid || !isDirty"
        @click="() => onSubmit()"
      />

      <XBtn
        text="Cancel"
        icon="mdi-cancel"
        color="secondary"
        text-color="white"
        @click="() => close()"
      />
    </template>
  </UiDialog>
</template>

<style lang="scss">
.sim-config-dialog {
  $root: &;

  &__body {
    width: 100%;
    margin-bottom: 1rem;
  }

  & &__fields-list {
    padding: 0;
    list-style: none;
  }

  &__switcher-box {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    grid-template-rows: auto;
    align-items: center;
  }

  &__first-list-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    grid-column: 1 / -1;
  }

  &__qr-code-preview {
    width: 100px;
    height: 100px;

    img {
      display: inline-block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__field-item {
    &--custom {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: .5rem;
    }
  }

  &__status-loader {
    display: inline-flex;
    align-items: center;
    height: 100%;
    margin-left: .5rem;
  }

  &__field-admin-controls {
    button {
      padding: .25rem;
      padding-top: .75rem;
    }
  }

  &__config-field-box {
    grid-column: 1 / -1;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: .25rem;
    padding: .25rem .75rem .5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  &__field-value {
    &--disabled:hover {
      input {
        cursor: not-allowed;
      }
    }

    &--custom {
      grid-column: 2 / -1;

      &:only-child {
        grid-column: 1 / -1;
      }
    }
  }

  &__btn-box {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    &--m-top {
      margin-top: .75rem;
    }
  }
}
</style>
