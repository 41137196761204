<template>
  <MagicTextField
      :value="value"
      label="IMSI"
      :show-wand="showWand"
      @input="$emit('input', $event)"
      @wand="generateImsi"/>
</template>

<script>
import MagicTextField from '@/components/extended/MagicTextField.vue';

export default {
  name: 'ImsiEditor',
  components: {MagicTextField},
  props: {
    value: String,
    showWand: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    generateImsi() {
      let imsi = '47101';
      imsi += Math.random().toString().slice(2, 12);
      this.$emit('input', imsi);
    },
  },
};
</script>

<style scoped>

</style>