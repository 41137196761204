<template>
  <div>
    <div class="smartphone mb-5">
      <div class="content ml-3">
        {{ content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "smartphone",
  props: {
    content: String
  },
}
</script>
<style scoped>
/* The device with borders */
.smartphone {
  position: relative;
  width: 150px;
  height: 250px;
  border: 7px black solid;
  border-top-width: 20px;
  border-bottom-width: 25px;
  border-radius: 10px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  bottom: -27px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  white-space: pre-wrap;
  overflow-y: auto;
  height: 200px;
  display: grid;
  place-items: center;
  font-size: x-small;
}
</style>