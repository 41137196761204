<template>
  <div>
    <primaryTextfield
      :fieldAttrInput="{
        class: 'numberOrVariable required checkTypeMaxValue',
        id: fieldAttributes.fieldAttrInput.id,
      }"
      v-model="evalValue"
      :templateContent="result"
      :isValid="isValid"
    ></primaryTextfield>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
export default {
  props: ["field", "fieldAttributes", "result", "valueLine", "isValid"],
  components: {
    primaryTextfield,
  },
  created() {
    this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  watch: {
    "valueLine.type": function () {
      //  console.log(value);
      let tmp = this.evalValue;
      this.evalValue = 0;
      this.$nextTick(function () {
        this.evalValue = tmp;
      });
    },
  },
  computed: {
    evalValue: {
      get() {
        //  console.log(this.valueLine);
        var evalValue = {};
        if (typeof this.valueLine != "undefined") {
          if (typeof this.valueLine.content != "undefined") {
            evalValue = this.valueLine.content;
          }
        }
        return evalValue;
      },
      set(newVal) {
        if (newVal != null) {
          //console.log("--->", this.valueLine, newVal);
          this.$set(this.valueLine, "content", newVal);
          this.$emit("input", newVal);
        }
      },
    },
  },
};
</script>
