<template>
  <div v-if="ShowValueField">
    <defaultFieldTypes
      :fieldAttributes="localFieldAttributes"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="value"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  components: { defaultFieldTypes },
  props: ["result", "fieldAttributes", "field", "value","fieldAttrInput"],

  computed: {
    localFieldAttributes() {
      let localFieldAttributes={ "type": "text", ...this.fieldAttributes };
       if(this.result.valueLine.type=="register_time"  ||
          this.result.valueLine.type=="rat_type"){
         localFieldAttributes =   { "type": "text", ...this.fieldAttributes };
       }else if(this.result.valueLine.type=="ue_si_secondary_rsrp" ||
                this.result.valueLine.type=="ue_si_secondary_rsrq" ||
                this.result.valueLine.type=="ue_si_secondary_rssi" ||
                this.result.valueLine.type=="ue_si_secondary_rssnr"||
                this.result.valueLine.type=="ue_si_primary_rsrp" ||
                this.result.valueLine.type=="ue_si_primary_rsrq" ||
                this.result.valueLine.type=="ue_si_primary_rssi" ||
                this.result.valueLine.type=="ue_si_primary_rssnr"
       ){
        //let fieldAttrInput = {"class": "float",...this.fieldAttrInput}; //in future can validate based on input int/float
        //localFieldAttributes =   { "type": "number", ...this.fieldAttributes,fieldAttrInput };
         localFieldAttributes =   { "type": "text", ...this.fieldAttributes  };
       } /*else if(this.result.valueLine.operator=="exists"){
         delete  this.fieldAttributes.fieldAttrInput
         localFieldAttributes={ "type": "text", ...this.fieldAttributes };
       }*/
      //console.log(this.result.valueLine)
      return localFieldAttributes;
    },
    ShowValueField(){
      let show = true;
      if(this.result.valueLine.operator=="exists" ||
          this.result.valueLine.operator=="does not exist"){
        show = false;
      }
      return show;
    }
  },
};
</script>