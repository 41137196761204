<template>
  <div :style="'height:' + window.height + 'px'">
    <!-- <div class="ml-n2 mr-n2">
      <windowsFormHeader :result="result"></windowsFormHeader>
     </div>-->
    <v-container
        fill-height fluid :class="{ 'pa-10': $vuetify.breakpoint.smAndUp }">
      <v-row
          align="center" justify="center" v-if="typeof result['infoFormMessage'] != 'undefined'">
        <v-col style="max-width: max-content">
          <messagesContainer :result="result"></messagesContainer>
        </v-col>
      </v-row>
      <v-row style="" no-gutters align="center" justify="center" class="">
        <v-col
            style="max-width: 600px" :class="{
            'ml-n8 mr-9 mt-n16': $vuetify.breakpoint.xlOnly,
            'mr-4': true,
          }">
          <div
              :class="{ 'mb-6': $vuetify.breakpoint.xlOnly }" :style="{
              'margin-top': $vuetify.breakpoint.xs ? '20px' : '',
              'font-size': $vuetify.breakpoint.xs ? '1.5em' : '1.8em',
            }">
            Register to Acctopus Degust
          </div>
          <br/>
          <p class="" style="font-weight: bold; max-width: 580px">
            Degust® is the cloud-based telco test environment where enterprises, teams, and individuals test mobile and
            fixed networks' quality, protocols, usage, and compliance </p>
          <p
              style="font-weight: bold" :style="{
              display: $vuetify.breakpoint.xsOnly ? 'inline-grid' : '',
            }">
            <span style="padding-right: 30px">...to whatever extend</span>
            <span style="padding-right: 30px">...at any scale</span>
            <span
                class="text-no-wrap">...in any size of teams</span>
          </p>
          <br/>
          <p style="max-width: 590px">
            You have two ways to register. Either you can be invited by an existing Degust user or you can use the
            registration code you received directly from Acctopus or from an advertisement. You can also
            <a
                href="https://www.acctopus.com/degust-registration-code/" target="_blank">request the registration code
              here
            </a>
          </p>
        </v-col>
        <v-col cols="12" sm="5" style="max-width: 650px" class="mt-9 mb-10">
          <v-form
              ref="userRegistration" action="serve.php?f=global&f2=register" method="post" v-model="isValid">
            <div class="body-2 mt-9 mb-3">
              <v-row>
                <v-col :cols="12" align-self="start">
                  <fieldsSingleRows
                      @field-value-changed="checkInput(...arguments)"
                      field="n_id"
                      :templateContent="result"
                      :valueLine="result.valueLine"></fieldsSingleRows>
                  <fieldsSingleRows
                      @field-value-changed="checkInput(...arguments)"
                      field="c_email"
                      :templateContent="result"
                      :valueLine="result.valueLine"></fieldsSingleRows>
                  <input
                      v-if="predefinedEmail != ''" type="hidden" :value="predefinedEmail" name="c_email"/>
                  <input
                      v-if="predefinedCode != ''" type="hidden" :value="predefinedCode" name="code"/>
                  <input
                      v-if="predefinedFirstName != ''" type="hidden" :value="predefinedFirstName" name="c_firstname"/>
                  <input
                      v-if="predefinedLastName != ''" type="hidden" :value="predefinedLastName" name="c_lastname"/>
                  <input
                      v-if="predefinedRegCode != ''" type="hidden" :value="predefinedRegCode" name="registrationCode"/>

                  <v-row no-gutters>
                    <v-col>
                      <fieldsValues
                          :fieldAttributes="result.fields.c_firstname"
                          field="c_firstname"
                          :templateContent="result"
                          @input="checkInput('c_firstname', ...arguments)"
                          :value="result.valueLine.c_firstname"
                          :valueLine="result.valueLine"></fieldsValues>
                    </v-col>
                    <v-col class="ml-2">
                      <fieldsValues
                          :fieldAttributes="result.fields.c_lastname"
                          field="c_lastname"
                          :templateContent="result"
                          @input="checkInput('c_lastname', ...arguments)"
                          :value="result.valueLine.c_lastname"
                          :valueLine="result.valueLine"></fieldsValues>
                    </v-col>
                  </v-row>
                  <fieldsSingleRows
                      @field-value-changed="checkInput(...arguments)"
                      field="c_country"
                      :templateContent="result"
                      :valueLine="result.valueLine"></fieldsSingleRows>

                  <v-row no-gutters>
                    <v-col>
                      <fieldsValues
                          :fieldAttributes="result.fields.c_password"
                          field="c_password"
                          :templateContent="result"
                          @input="checkInput('c_password', ...arguments)"
                          :value="result.valueLine.c_password"
                          :valueLine="result.valueLine"></fieldsValues>
                    </v-col>
                    <v-col class="ml-2">
                      <fieldsValues
                          :fieldAttributes="result.fields.checkPassword"
                          field="checkPassword"
                          :templateContent="result"
                          @input="checkInput('checkPassword', ...arguments)"
                          :value="result.valueLine.checkPassword"
                          :valueLine="result.valueLine"></fieldsValues>
                    </v-col>
                  </v-row>

                  <fieldsSingleRows
                      v-if="typeof result['fields']['registrationCode']!='undefined'"
                      field="registrationCode"
                      :templateContent="result"
                      :valueLine="result.valueLine"
                      @field-value-changed="checkInput(...arguments)"></fieldsSingleRows>
                  <fieldsSingleRows
                      @field-value-changed="checkInput(...arguments)"
                      field="confirmAGB"
                      :templateContent="result"
                      :valueLine="result.valueLine"></fieldsSingleRows>
                  <fieldsSingleRows
                      v-if="!isDevelopmentBuild"
                      @field-value-changed="checkInput(...arguments)"
                      field="captcha_value"
                      :templateContent="result"
                      :valueLine="result.valueLine"></fieldsSingleRows>
                </v-col>
              </v-row>
            </div>

            <div class="ml-n8" v-if="predefinedEmail == ''">
              <fieldsButtons
                  :result="result" @submitted="submitForm(...arguments)" :isValid="isValid" :routerLink="{
                  to: '/login',
                  text: language.componentLabel.labelB2LoginPage,
                  class: 'back2Login mt-3',
                }"></fieldsButtons>
            </div>
            <!--for link from CRM, predefined code reg and fname,laname -->
            <div class="ml-n8" v-else-if="predefinedRegCode!= ''">
              <fieldsButtons
                  :result="result" @submitted="submitFormLogin('requestlogin')" :isValid="isValid" :routerLink="{
                  to: '/login',
                  text: language.componentLabel.labelB2LoginPage,
                  class: 'back2Login mt-3',
                }"></fieldsButtons>
            </div>
            <div class="ml-n8" v-else>
              <fieldsButtons
                  :result="result" @submitted="submitFormLogin('requestlogin')" :isValid="isValid" :routerLink="{
                  to: '/login',
                  text: language.componentLabel.labelB2LoginPage,
                  class: 'back2Login mt-3',
                }"></fieldsButtons>
            </div>

            <div class="mt-10"></div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { mapActions } from "vuex"
import fieldsSingleRows from '@/commonComponents/fieldsSingleRows.vue';
import fieldsButtons from '@/commonComponents/fieldsButtons.vue';
import fieldsValues from '@/commonComponents/fieldsValues.vue';
import messagesContainer from '@/commonComponents/messagesContainer.vue';
import requests from "@/js/requests";
import { tokenRefresher } from "@/auth-tools"

export default defineComponent({
  components: {
    fieldsSingleRows,
    fieldsButtons,
    fieldsValues,
    messagesContainer,
  },

  props: ['result'],

  data: function () {
    return {
      isValid: false,
      window: {
        width: 0,
        height: 0,
      },
      predefinedEmail:
          typeof this.$route.query.email != 'undefined'
              ? this.$route.query.email.replace(/ /g, '+')
              : '',
      predefinedCode:
          typeof this.$route.query.code != 'undefined'
              ? this.$route.query.code
              : '',
      predefinedFirstName:
          typeof this.$route.query.FirstName != 'undefined'
              ? this.$route.query.FirstName
              : '',
      predefinedLastName:
          typeof this.$route.query.LastName != 'undefined'
              ? this.$route.query.LastName
              : '',
      predefinedRegCode:
          typeof this.$route.query.RegCode != 'undefined'
              ? this.$route.query.RegCode
              : '',
      isDevelopmentBuild: process.env.NODE_ENV === 'development',
    };
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.setupEmail();
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions(["fetchEssentialData"]),

    setupEmail() {
      if (this.predefinedEmail != '') {
        this.$set(this.result.fields.c_email.fieldAttrInput, 'disabled', true);
        this.$set(this.result.valueLine, 'c_email', this.predefinedEmail);
      }

      if (this.predefinedFirstName != '') {
        // this.$set(this.result.fields.c_firstname.fieldAttrInput, "disabled", true);
        this.$set(this.result.valueLine, 'c_firstname', this.predefinedFirstName);
      }

      if (this.predefinedLastName != '') {
        // this.$set(this.result.fields.c_firstname.fieldAttrInput, "disabled", true);
        this.$set(this.result.valueLine, 'c_lastname', this.predefinedLastName);
      }

      if (this.predefinedRegCode != '') {
        this.$set(this.result.fields.registrationCode.fieldAttrInput, 'disabled', true);
        this.$set(this.result.valueLine, 'registrationCode', this.predefinedRegCode);
      }
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 230;
    },

    submitFormLogin(functionName) {
      if (!this.isDevelopmentBuild) {
        let gRR = window.grecaptcha.getResponse();
        if (gRR.length == 0) {
          this.$store.commit('dialogButtonsLoading', false);
          this.$notify({
            group: 'foo',
            text: 'Please verify you are human!',
            duration: 1000,
            type: 'error',
            speed: 600,
          });
          return;
        }
      }

      if (this.isValid) {
        this.ajaxSubmit(
            functionName,
            this.$refs.userRegistration.$el,
            'serve.php?f=global&f2=login',
            this.result,
            undefined,
            undefined,
            (response) => {
              this.axios.post(`${process.env.VUE_APP_AUTH_SERVICE}/auth-service/v1/login`,
                  {
                    user: this.result.valueLine.c_email,
                    password: this.result.valueLine.c_password,
                  }).then((_resp) => {
                const tokens = _resp.data
                tokenRefresher.login(tokens)
                requests.frameworkPostRequest('?f=global&f2=login', 'saveAccessToken',
                    {accessToken: tokens.access_token},
                    async () => {
                      try {
                        await this.fetchEssentialData()
                        //console.log(response);
                        if (response.status===200) {
                          this.$router.push({ name: "login" });
                        } else {
                          this.$store.commit('notification', {
                            text: 'Please Contact Acctopus Customer support!, There is unpredictable error occurred!',
                            length: 10000,
                          });
                        }
                      } finally {
                        this.loading = false;
                      }
                    });
              });
            });
      }
    },

    submitForm(functionName) {
      if (!this.isDevelopmentBuild) {
        let gRR = window.grecaptcha.getResponse();
        if (gRR.length == 0) {
          this.$store.commit('dialogButtonsLoading', false);
          this.$notify({
            group: 'foo',
            text: 'Please verify you are human!',
            duration: 1000,
            type: 'error',
            speed: 600,
          });
        }
      }

      if (this.isValid) {
        this.ajaxSubmit(
            functionName,
            this.$refs.userRegistration.$el,
            '',
            this.result,
        );
        // this.isValid = false;
        // this.$refs.userRegistration.reset();
        // window.grecaptcha.reset();
        //  this.$notify({
        //   group: "foo",
        //   text: "Successfully registered, you will get an email for further details.",
        //   duration: 2000,
        //   type: "success",
        //   speed: 600,
        // });
      }
    },
    checkInput(field, value) {
      //this.$set(this.result.fields[field], "value", value);
      //this.$emit("dialog-result-changed", this.result);
      if (typeof this.result.valueLine == 'undefined') {
        this.$set(this.result, 'valueLine', {});
      }
      this.$set(this.result.valueLine, field, value);
      this.$emit('dialog-result-changed', this.result);
    },
  },
});
</script>

<style>
.back2Login {
  font-size: 14px;
}
</style>
