<template>
  <div>
   <!-- <div class="ml-3" style="width: 700px;" v-if="row.rawType === 'CALC' && typeof message['msg'] != 'undefined'">
      {{ message['msg'] }}
    </div>-->

    <template v-if="typeof message == 'object'">
      <div v-for="(messageLine, givenKey) in sortObjectMessage(message,row)" :key="givenKey">
        <div v-if="row.rawType === 'CALC' && givenKey === 'msg'"> </div>
        <div v-else-if="messageLine != null && typeof messageLine == 'object' && Object.keys(messageLine).length > 0" class="ml-3" >
       <!--   <template v-if="row.rawType === 'LDAP'">
            <span v-for="(mLine, key1) in messageLine" :key="key1">
                  <div v-for="(line, key2) in mLine" :key="key2">
                      <template v-if="typeof line=='object'">
                          <div v-for="(tiny, key3) in line" :key="key3">
                              {{ key3 }}: <template v-if="typeof tiny[0]!='undefined'">{{ tiny[0] }}</template>
                              <template v-else>{{ tiny }}</template>
                          </div>
                      </template>
                 </div>
              </span>
          </template>-->
<!--
            <template v-if="row.rawType === 'CALC' && !empty(messageLine['value'])"><div class="sectionHeader ml-n3">Check:</div></template>
-->
          <template v-if="givenKey===0"><div class="sectionHeader ml-n3">Check:</div></template>
          <template v-if="typeof messageLine['name'] != 'undefined'
            && messageLine['name'].indexOf(',') !== -1
            && messageLine['name'].split(',').length > 4">
            <span class="" v-html="constructNameString(applyBreak(messageLine['name']))">:</span>
          </template>
          <template v-else-if="typeof messageLine['name']!='undefined' && messageLine['name']!==''">
            <span class="" v-html="constructNameString(messageLine['name'])"></span>
          </template>
          <span v-if="!empty(messageLine['operator'])"> Check {{ messageLine['operator'] }}:</span>
            <span v-if="!empty(messageLine['content']) && !hiddenArray.includes(operator)"> Check {{ messageLine['content'] }}:</span>
          <span :style="getStyle(messageLine)">{{ messageLine['value']}}</span>
        </div>
        <span v-else-if="givenKey === 'html'"
            :data-popup="messageLine"
            class="spanwithspace"
            data-show-type="html"
            onclick="getDataTableDialog(this,'','',{ width: 850},'','Downloaded Content');"
            onmouseover="getRemoteTooltip(this);">
          <a href="javascript:void(0)">
            <i aria-hidden="true" class="fa fa-download"></i> Downloaded Content
          </a>
        </span>
        <div v-else-if="givenKey === 'imageurl'">
          <div v-if="messageLine.indexOf('We will') > -1">
            {{ messageLine }}
          </div>
          <div v-else>
            <div class="minioImage" @click.stop="mscPopup = true">
              <v-img :src="mscImageUrl" :width="imageWidth"/>
            </div>
            <simplePopup
                :content="mscImageUrl"
                :title="title"
                :type="'image'"
                :visible="mscPopup"
                @close="mscPopup = false"></simplePopup>
          </div>
        </div>
        <div v-else-if="givenKey === 'fileurl'" class="mt-1 mb-2">
         <template  v-if="checkAudio(messageLine)">
           <v-row>
             <v-col style="max-width: 65px">Audio:</v-col>
             <v-col style="max-width: 310px">
               <audio controls style="height: 25px;position: absolute;" >
               <source :src="audioSource(messageLine)" type="audio/mpeg">
               Your browser does not support the audio element.
             </audio></v-col>
<!--             <v-col> <v-icon style="cursor: pointer" title=" Download File" @click="downloadMinioFile(messageLine)">
               mdi-file-download
             </v-icon></v-col>-->
           </v-row>
         </template>
         <template v-else>
           File Download:
           <v-icon style="cursor: pointer" title=" Download File" @click="downloadMinioFile(messageLine)">
             mdi-file-download
           </v-icon>
         </template>

          <simplePopup
              :content="messageLine"
              :title="'We are working on it, soon we will update!'"
              :type="'notice'"
              :visible="fileDownloadPopup"
              @close="fileDownloadPopup = false"></simplePopup>
        </div>
        <template v-else-if="givenKey === 'country'">
          Country: {{ getCountryName(messageLine) }}
          <span>
            <img :src="getCountrySrc(messageLine)" style="height: 16px; vertical-align: middle"/>
          </span>
        </template>
        <span v-else-if="givenKey === 'apn'"></span>
        <template v-else-if="row.rawType !== 'Explorer_Speedtest' && row.rawType !== 'Explorer_Traceroute' ">
          <template v-if="givenKey !== 'icon'">
            <div v-if="row['rawType'] === 'Web_Driver'" style="width: 500px;">
              <!-- {*wda have encode special chars so we shouldnt double it*} -->
              {{ decodeURL(messageLine) }}
            </div>
            <div v-else-if="row['rawType'] === 'Wifi Scan Result'" style="white-space: pre-line">
              {{ messageLine }}
            </div>
            <div v-else-if="messageLine!=null &&  Object.keys(messageLine).length > 0 && row.rawType === 'SSH'" style="word-wrap: break-word">
                {{messageLine}}
            </div>
            <div v-else-if="messageLine!=null &&  Object.keys(messageLine).length > 0" style="word-wrap: break-word">
               <template v-if="givenKey===0 && row.rawType!== 'terminated' && !row.rawType.includes('Explorer_VF') && row.rawType!== 'Info'">
                 <div class="sectionHeader">Check:</div>
               </template>
               <template  v-if="row.rawType== 'terminated'">
                   <span v-for="(line,lineNumber) in messageLine.split('\n')"
                         v-bind:key="lineNumber"
                         :class="(messageLine.split('\n').length> 1) ?'':''"
                         style="display: block;"
                   > <span  v-html="constructLineString(line)"></span>
                <br/>
              </span>
               </template>
               <template v-else-if="row.rawType=='RATTUX_admin_info' || row.rawType=='Info' || row.rawType=='INFO'">
                  <span v-for="(line,lineNumber) in messageLine.split('\n')"
                        v-bind:key="lineNumber"
                        :class="(messageLine.split('\n').length> 1) ?'':''"
                        style="display: block;"
                        class="ml-3"
                  > <span  v-html="line"></span>
                <br/>
              </span>
                </template>
               <template v-else>
                  <span v-for="(line,lineNumber) in messageLine.split('\n')"
                        v-bind:key="lineNumber"
                        :class="(messageLine.split('\n').length> 1) ?'':''"
                        style="display: block;"
                        class="ml-3"
                  > <span  v-html="constructLineString(line)"></span>
                <br/>
              </span>
                </template>

             </div>
          </template>
        </template>
      </div>
    </template>
    <div v-else>{{ message }}</div>
  </div>
</template>
<script>
import options from '@/cfg/options.json';
import simplePopup from '@/commonComponents/simplePopup.vue';
import { getStatusColor,constructNameString,sortObjectMessage} from '@/js/helper.js';
import requests from '@/js/requests';

export default {
  components: {
    simplePopup,
  },
  props: ['row', 'message'],
  data() {
    return {
      mscPopup: false,
      fileDownloadPopup: false,
      hiddenArray: options.hiddenArray,
      mscImageUrl: '',
      imageWidth: 450,
      operatorList:options.operators,

    };
  },
  watch: {
    message: {
      immediate: true,
      handler(value) {
        if (value.imageurl && !this.mscImageUrl) {
          let img = new Image();
          img.onload = () => {
            if (img.width < 450) {
              this.imageWidth = img.width;
            } else {
              this.imageWidth = 450;
            }
          };
          img.src = value.imageurl;
          this.mscImageUrl = value.imageurl;
        }
      },
    },
  },
  computed: {
    title() {
      return this.row['c_type'];
    },
  },
  methods: {
     checkAudio(messageLine){
       let audio = false;
       let fileExt = messageLine.split('.').pop();
       if(fileExt==='mp3' || fileExt==='wav'){
         audio = true;
       }
       return audio;
     },
     sortObjectMessage:sortObjectMessage,
      constructNameString(nameString){
          return constructNameString(nameString,this.operatorList);
      },

      findSubStringStatus(nameString){
          var op = "";
          const  status = ['ok','error','warning'];
          status.forEach((type) => {
              if (nameString.indexOf(type) !== -1) {
                  op = type;
              }
          });
          return op;
      },

      constructLineString(nameString){
       const substring = this.findSubStringStatus(nameString);
       let result = nameString;
       if (substring!=="" && nameString.indexOf(substring) !== -1) {
           let resultArray = nameString.split(substring);
           let className = "text-capitalize";
           if(resultArray[0].indexOf('uri') !== -1){
               className = "text-uppercase";
           }else if(resultArray[0].indexOf('StatusCode') !== -1){
               className = "";
           }
           result =  "<span class="+className+">"+resultArray[0]+"</span><span style="+getStatusColor(substring)+">"+substring+"</span>";
       }
        return result;
     },
/*     sortObjectMessage(cMessage){
          var result = cMessage;
          if(cMessage.length>0 && this.row.rawType === 'LDAP'){
             result= cMessage.slice().sort(objSortBy('name')); //slice must be present otherwise infinate loops issue comes
          }
        return result;
      },*/
    decodeURL(text) {
      return decodeURIComponent(text);
    },
    applyBreak(text) {
      let textArray = text.split(',').map((item) => item.trim());
      let result = '';
      textArray.forEach((stringInfo, index) => {
        result += stringInfo + ',';
        if (index === 3) {
          result = result + '\r\n';
        }
      });
      return result.replace(/,(\s+)?$/, ''); // remove last comma!
    },
    resumePause() {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=testing&f2=testInfo&function=getInfo',
        dataType: 'json',
        data: {
          n_id: this.$route.query.n_id,
          n_id_key: this.$route.query.n_id_key,
          requestType: 'ajax',
          function: 'resumeTest',
          testQueueId: caller.$attrs.result.report_id,
          testrunId: caller.row['n_id'],
        },
      }).then(function (response) {
        //to do ??
        console.log(response);
      }).catch(function (response) {
        console.log(response);
      });
    },
    downloadMinioFile(source) {
      window.location.href = `${process.env.VUE_APP_PHP_SERVICE}serve.php?f=global&f2=mServer&action=createFile&source=${source}`;
    },
    audioSource(source){
      return  `${process.env.VUE_APP_PHP_SERVICE}serve.php?f=global&f2=mServer&action=createFile&source=${source}`;
    },
    getCountrySrc(messageLine) {
      let countryDetail = messageLine.split(':');
      return countryDetail[0];
    },
    getCountryName(messageLine) {
      let countryDetail = messageLine.split(':');
      return countryDetail[1];
    },
    getStyle(messageLine) {
      let style = '';
      if (!this.empty(messageLine['valueColor']) && Array.isArray(messageLine['valueColor'])) {
        style = 'color:rgb(' + messageLine['valueColor'].join(',') + ')';
      }
      return style;
    },
  },
};
</script>
<style>
.minioImage {
  margin-top: -5px;
  height: 150px;
  overflow: hidden;
  opacity: 1;
  cursor: pointer;
}

</style>