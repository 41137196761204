<template>
  <div class="voiceStepsContainer">
    <v-expansion-panels hover tile focusable :value="tab" multiple>
      <draggable
        v-model="commands"
        :disabled="!enabled"
        class="list-group"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
        handle=".handle"
        style="width: inherit"
      >
        <v-expansion-panel
          v-for="(voiceStep, index) in commands"
          :key="index"
          class="voiceStep"
        >
          <v-expansion-panel-header
            class="stepHeadColor subtitle-2"
            color="white--black"
          >
            <accordionHeader
              @click="headerClicked(index, ...arguments)"
              :result="result"
              :templateValue="commands"
            >
              <div class="stepInfoContainer_sub" >
                <span class="stepTypeText" title="Click to expand the step"
                  >{{ tasksIndexed[voiceStep.task] || "New Task" }}
                  {{ callStepAdditionalInfo(voiceStep) }}</span
                >
                <span
                  title="Click to expand the step"
                  class="stepApnText"
                ></span>
                <span class="countryFlag"></span>
                <span title="Click to expand the step" class="stepTitle"></span>
                <span class="stepDurationText"></span>
                <span class="conditionInfoContainer">
                  <i
                    v-show="voiceStep.Condition"
                    class="f05a fas fa-info-circle infoCircle"
                  ></i>
                </span>
              </div>
            </accordionHeader>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-container class="requestTable">
                <conditionContainer
                  :value="voiceStep"
                  @input="updateValue(index, ...arguments)"
                  :result="result"
                >
                  <!-- <accselect v-model="voiceStep.task" :options="tasks"></accselect> -->
                  <associatedOption
                    :fieldAttributes="{
                      name: 'Task',
                      options: usedTasks,
                      fieldAttrInput: { class: 'required' },
                      sort: false,
                    }"
                    type="combobox"
                    :value="voiceStep.task"
                    @input="updateTask(index, ...arguments)"
                    :templateContent="result"
                  ></associatedOption>
                </conditionContainer>
                <div class="mt-3">
                  <buildFieldsRows
                    :templateContent="getTemplateContent(voiceStep.task)"
                    :value="voiceStep"
                    @input="updateValue(index, ...arguments)"
                    languageIndex="rattuxControl"
                    @revalidate="$emit('revalidate')"
                  ></buildFieldsRows>
                </div>
              </v-container>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
    <div class="mt-2">
      <primaryButton
        type="button"
        @click="addCallStep()"
        label="Add Task"
        icon="mdi-plus"
        :disabled="localDisabled"
      ></primaryButton>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import associatedOption from "@/commonComponents/associatedOption.vue";
import conditionContainer from "@/components/legacy/conditionContainer.vue";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import accordionHeader from "@/components/legacy/accordionHeader.vue";
export default {
  components: {
    draggable,
    associatedOption,
    conditionContainer,
    buildFieldsRows,
    primaryButton,
    accordionHeader,
  },
  created() {
    if (this.result.sdt != "1") {
      let adminTasks = ["wifi_connect", "wifi_disconnect", "wifi_scan"];
      // this.tasks.push(["wifi_connect", "Connect Wifi"]);
      // this.tasks.push(["wifi_disconnect", "Disconnect Wifi"]);
      // this.tasks.push(["wifi_scan", "Scan for Wifi"]);
      this.tasks.forEach((task, index) => {
        // removing none admin tasks
        if (adminTasks.includes(task[0])) {
          this.$delete(this.tasks, index);
        }
      });

      // this.tasks = this.tasks.filter(function (element) {
      //   return element !== undefined;
      // });
    }
  },
  data: function () {
    return {
      tab: [],
      enabled: true,

      dragging: false,
      tasks: [
        ["check_video_quality", "Check Video Quality"],
        ["wifi_connect", "Connect to WiFi SSID"],
        ["dns", "DNS"],
        ["dial", "Dial (DTMF)"],
        ["wifi_disconnect", "Disconnect Wifi"],
        ["hangup", "Hang Up"],
        ["iperf", "iperf3"],
        // ["vf_iperf", "Vodafone Iperf"],
        ["speedtest", "Speed Test"],
        ["pause", "Pause"],
        ["ping", "Ping"],
        ["VTS", "Press Keys (DTMF)"],
        ["wifi_scan", "Scan for Wifi SSIDs"],
        ["record", "Start Audio Recording"],
        ["stoprecord", "Stop Audio Recording"],
        ["tracepath", "Traceroute"],
        ["wait", "Wait for Pattern in Audio Recording"],
      ],
      fieldsConfig: {
        dial: {
          number: { fieldAttrInput: { class: "required number" } },
        },

        wifi_connect: {
          profile: { fieldAttrInput: { class: "required" } },
        },
        dns: {
          server: { fieldAttrInput: { class: "required IPAddress" } },
          domain: { fieldAttrInput: { class: "required IPDomain" } },
        },

        hangup: {},
        iperf: {
          hostLine: {
            type: "line",
            fields: {
              host: {
                fieldAttrInput: { class: "required IPDomain" },
                colAttrs: { cols: 8 },
              },
              port: {
                fieldAttrInput: { class: "required number" },
                default: "5201",
              },
            },
          },
          protocol: {
            type: "radio",
            containerAttributes: { style: "max-width:400px" },
            option: { tcp: "tcp", udp: "udp" },
            title: "Protocol",
            fieldAttrInput: { class: "required", showTitle:false },
            default: "tcp",
          },
          timeVol: {
            type: "radio",
            containerAttributes: { style: "max-width:400px" },
            option: { time: "Time", volume: "Volume" },
            title: "Duration",
            fieldAttrInput: { class: "required" , showTitle:false },
            default: "time",
          },
          timeVolValue: {
            inputFieldFormatter: "timeVolValue",
          },

          uplinkDownlinkSwitch: {
            type: "switchGroup",
            fields: {
              uplink: {
                inputFieldFormatter: "switch",
                default: "0",
                class: "required",
              },
              downlink: {
                inputFieldFormatter: "switch",
                default: "0",
                class: "required",
              },
            },
            maxWidth: 250
          },

          eval: {
            inputFieldFormatter: "uplinkDownlinkEval",
          },
        },

        vf_iperf: {
          host: {
            type: "combobox",
            fieldAttrInput: { class: "required IPaDomainaVariable addable" },
            colAttrs: { cols: 8 },
            options: [
              "iperf.acctopus.com",
              "ping.online.net",
              "iperf.berger-gunkel.net",
              "speedcheck-ham.kabeldeutschland.de",
              "speedcheck-ber.kabeldeutschland.de",
              "speedcheck-fra.kabeldeutschland.de",
              "speedcheck-nue.kabeldeutschland.de",
              "speedcheck-muc.kabeldeutschland.de",
            ],
          },
        },
        pause: { pause: { fieldAttrInput: { class: "number required" } } },
        ping: { target: { fieldAttrInput: { class: " required IPDomain" } } },
        VTS: { input: "" },
        speedtest: {},
        check_video_quality: {
          service: {
            type: "select",
            options: ["ffprobe"],
            fieldAttrInput: { class: "required" },
          },
          url: { fieldAttrInput: { class: "required" } },
        },
        record: {
          language: {
            type: "combobox",
            options: this.result["additional"]["recordLanguages"],
            fieldAttrInput: { class: "required" },
          },
        },
        stoprecord: { checkQuality: { type: "trueCheckbox" } },
        wait: {
          pattern: "",
          saveInVariable: { type: "saveInVariable" },
          timeout: { fieldAttrInput: { class: "required number" } },
        },

        add_host_route: {
          host: { fieldAttrInput: { class: "required IPDomain" } },
          next_hop: {
            type: "select",
            inputFieldFormatter: "nextHopFormatter",
            fieldAttrInput: { class: "required " },
          },
        },
        delete_host_route: {
          host: { fieldAttrInput: { class: "required IPDomain" } },
        },
        tracepath: {
          host: { fieldAttrInput: { class: "required IPDomain" } },
        },
      },
    };
  },
  computed: {
    localDisabled() {
      let localDisabled = false;

      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        localDisabled = true;
      }

      return localDisabled;
    },

    usedTasks() {
      let usedTasks = [...this.tasks];
      let testObject = this.result.testObject;
      let subscriberType = this.result.overallFields.subscriberType;
      if (subscriberType != 1) {
        // filter tasks

        let subscriberTasks = ["dial", "hangup", "VTS", "record", "stoprecord"];

        usedTasks.forEach((task, index) => {
          // removing none admin tasks
          if (subscriberTasks.includes(task[0])) {
            this.$delete(usedTasks, index);
          }
        });
      }
      for (let [stepindex, info] of Object.entries(testObject)) {
        if (stepindex != "pre" && stepindex != "post") {
          for (let [stepType, stepInfo] of Object.entries(info)) {
            if (
              stepType == "RATTUX_activation" &&
              stepInfo.deviceId != "" &&
              typeof this.result.additional.imeiObject[stepInfo.deviceId] !=
                "undefined" &&
              this.result.additional.imeiObject[stepInfo.deviceId] != null
            ) {
              usedTasks.push(["add_host_route", "Add Host Route"]);
              usedTasks.push(["delete_host_route", "Delete Host Route"]);
              break;
            }
          }
        }
      }
      return usedTasks;
    },

    tasksIndexed: function () {
      let tasksIndexed = {};
      this.usedTasks.forEach(function (value) {
        //console.log(value);
        tasksIndexed[value[0]] = value[1];
      });
      return tasksIndexed;
    },
    commands: {
      get: function () {
        let commands = [];
        if (typeof this.value != "undefined") {
          commands = this.value;
        }

        return commands;
      },

      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  props: ["result", "field", "fieldAttributes", "value", "templateValue"],
  methods: {
    getTemplateContent(task) {
      let fields;
      try {
        fields = JSON.parse(JSON.stringify(this.fieldsConfig[task]));
      } catch (error) {
        fields = {};
      }
      let templateContent = {
        fields: fields,
        additional: this.result.additional,
        testObject: this.result.testObject,
        disableAllInputs: this.result.disableAllInputs,
      };

      return templateContent;
    },
    updateTask(index, value) {
      this.$set(this.commands, index, {});
      this.$set(this.commands[index], "task", value);
      this.$emit("input", this.commands);
    },
    updateValue(index, value) {
      this.$set(this.commands, index, value);
      this.$emit("input", this.commands);
    },
    callStepAdditionalInfo: function (voiceStep) {
      let info = "";
     // console.log("---->", voiceStep);
      if (voiceStep.task == "dial") {
        info = voiceStep.number;
      } else if (voiceStep.task == "VTS") {
        let input = "";
        if (typeof voiceStep.input != "undefined" && voiceStep.input != "") {
          input = "'" + voiceStep.input + "'";
        }
        info = input;
      } else if (voiceStep.task == "pause") {
        let pause = "";
        if (typeof voiceStep.pause != "undefined" && voiceStep.pause != "") {
          pause = voiceStep.pause + "s";
        }
        info = pause;
      } else if (voiceStep.task == "ping") {
        let target = "";
        if (typeof voiceStep.target != "undefined" && voiceStep.target != "") {
          target = voiceStep.target;
        }
        info = target;
      } else if (voiceStep.task == "iperf") {
        let host = "";
        if (typeof voiceStep.host != "undefined" && voiceStep.host != "") {
          host = voiceStep.host;
        }
        info = host;
      }
      return info;
    },

    addCallStep() {
      this.commands.splice(this.commands.length + 1, 0, {});
      this.$emit("input", this.commands);
    },
    headerClicked(index, value) {
      if (value == "trash") {
        this.$delete(this.commands, index);
      } else if (value == "insertnext") {
        this.commands.splice(index + 1, 0, {});
      } else if (value == "copyStep") {
        let copiedCommand = { ...this.commands[index] };
        this.commands.splice(index + 1, 0, copiedCommand);
      }
      this.$emit("input", this.commands);
    },

    checkDisabled(index) {
      let disabled = false;
      if (
        typeof this.commands[index].saveInVariable != "undefined" &&
        this.commands[index].saveInVariable.length > 0
      ) {
        disabled = true;
      }
      return disabled;
    },
    toggleSave(index) {
      if (typeof this.commands[index].saveInVariable == "undefined") {
        this.$set(this.commands[index], "saveInVariable", "");
      } else {
        this.$delete(this.commands[index], "saveInVariable");
      }
    },
  },
};
</script>
