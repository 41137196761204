<template>
  <div>
    <!-- <fieldsRows
      v-if="connectionInfo!=null"
      :templateContent="connectionInfo"
      @field-value-changed="updateFieldValue(...arguments)"
    ></fieldsRows>-->
    <fieldsRows
      v-if="connectionInfo != null"
      :templateContent="connectionInfo"
      :valueLine="parameters"
      @field-value-changed="updateFieldValue(...arguments)"
      @updated-fields="emitUpdateFields"
      @revalidate="$emit('revalidate')"
    ></fieldsRows>
  </div>
</template>

<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import { getSubType, getPageCat } from "@/js/testHelpers.js";
import requests from '@/js/requests';
export default {
  components: { fieldsRows },
  data() {
    return { connectionInfo: null };
  },
  created() {
    let stepStoredValue = this.stepStoredValue;
    let parameters = stepStoredValue.parameters;
    this.getConnectionInfo(this.subtype, this.category, parameters);
  },
  watch: {
    subtype() {
      //let caller = this;
      let stepStoredValue = this.stepStoredValue;
      // let subtype = stepStoredValue.subType;
      //let category = stepStoredValue.category;
      let parameters = stepStoredValue.parameters;

      this.getConnectionInfo(this.subtype, this.category, parameters);
    },
  },
  computed: {
    subtype() {
      return getSubType(this.stepType, this.stepStoredValue);
    },
    category() {
      return getPageCat(this.stepStoredValue);
    },
    parameters: {
      get() {
        let parameters = {};
        if (typeof this.stepStoredValue.parameters != "undefined") {
          parameters = this.stepStoredValue.parameters;
        }
        return parameters;
      },
      set(parameters) {
        console.log(parameters);
        this.$emit("parameter-changed", parameters);
      },
    },
  },
  methods: {
    emitUpdateFields(info) {
      console.log("updated-fields emmited");
      if (
        typeof info.connectionId != "undefined" &&
        typeof info.connectionId.value != "undefined"
      ) {
        this.$set(this.parameters, "connectionId", info.connectionId.value);
        this.$set(
          this.connectionInfo.fields.connectionId,
          "associatedOption",
          info.connectionId.associatedOption
        );
      }
      if (
        typeof info.templateId != "undefined" &&
        typeof info.templateId.value != "undefined"
      ) {
        this.$set(this.parameters, "templateId", info.templateId.value);
        this.$set(
          this.connectionInfo.fields.templateId,
          "associatedOption",
          info.templateId.associatedOption
        );
      }
      this.$emit("parameter-changed", this.parameters);

      //  this.$emit("updated-fields", info);
    },
    updateFieldValue(index, value) {
      let parameters = { ...this.stepStoredValue.parameters };
      if (typeof parameters == "undefined") {
        parameters = {};
      }
      this.$set(parameters, index, value);
      this.$emit("parameter-changed", parameters);
    },

    getConnectionInfo(subtype, category, parameters) {
      let caller = this;
      let systemIdValue = "";
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: {
          function: 'getHttpTemplatesRow',
          n_systemId: systemIdValue,
          subtype: subtype,
          category: category,
          ...parameters,
        },
        //headers: { "Content-Type": "multipart/form-data" }
      })
          .then(function (post) {
          //console.log("here", post);
          //console.log(caller.contentID);
          post.data.result.disableAllInputs = caller.result.disableAllInputs;
          post.data.result.stepStoredValue = caller.stepStoredValue;
          post.data.result.dialogRef = caller.result.dialogRef; // No idea why he forget!
          // resolve(post.data.result);
          caller.connectionInfo = post.data.result;
          caller.$set(caller.connectionInfo, "contentID", caller.contentID);
          caller.$nextTick(function () {
            caller.$emit("revalidate");
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  props: ["stepStoredValue", "result", "stepType", "contentID"],
};
</script>