<template>
  <div>
    <defaultFieldTypes
        v-if="valueLine.id == ''"
        :fieldAttributes="fieldAttributes"
        :field="field"
        :templateContent="result"
        v-on="$listeners"
        :checkReadOnly="checkReadOnly"
        :value="value"
    ></defaultFieldTypes>
    <div v-else class="ml-2 mt-3" style="min-height:30px;cursor: pointer;" :style="color"><span :title="title">{{
        value
      }}</span></div>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  props: [
    "result",
    "field",
    "fieldAttributes",
    "value",
    "checkReadOnly",
    "valueLine",
  ],
  components: {defaultFieldTypes},
  computed: {
    color() {
      let color = "black";
      if (this.valueLine.active == 2) {
        color = "#c7c6c6";
      }
      return "color:" + color;
    },
    title() {
      let title = "Active User";
      if (this.valueLine.active == 2) {
        title = "Deactivated User";
      }
      return title;
    },
  },
};
</script>