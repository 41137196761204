<template>
  <JsonEditor :value="originalCopy" @input="handleInput"/>
</template>

<script>
import JsonEditor from '@/components/basic/JsonEditor.vue';
import jsonPatch from 'fast-json-patch';
import {deepCopy} from '@/js/general';

export default {
  name: 'JsonPatchEditor',
  components: {JsonEditor},
  props: {
    value: Array,
    original: [Object, Array],
  },
  computed: {
    originalCopy() {
      const originalCopy = deepCopy(this.original);
      if (this.value) {
        jsonPatch.applyPatch(originalCopy, this.value);
      }
      return originalCopy;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', jsonPatch.compare(this.original, value));
    },
  },
};
</script>

<style scoped>

</style>