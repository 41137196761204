<template>
  <div class="ml-n4" style="margin-top:-3px">
    <weightedButtonsMini
      :items="testStatus"
      :totalItems="Object.keys(testStatus).length"
      @selectedItem="selectedItem"
    ></weightedButtonsMini>
  </div>
</template>
<script>
import weightedButtonsMini from "@/commonComponents/weightedButtonsMini.vue";

export default {
  props: ["row", "header","result"],
  components: { weightedButtonsMini },
  data() {
    return {
      selected: [],
    };
  },

  computed: {
    testStatus() {
      let testStatus = [
         {
           color: "pending",
           status: 0,
           index: "pending"
         },
         {
          color: "testStatusRunning",
          status: 1,
          index: "n_testRunning",
        },
        {
          color: "ok",
          status: 2,
          index: "n_testsOK"
        },
        {
          color: "testStatusAborted",
          status: 5,
          index: "n_testsAborted",
        },
        {
          color: "testStatusMaxRuntime",
          index: "n_testsMaxRuntime",
          status: "6",
        },
        {
          color: "testStatusRuntimeWarning",
          status: 7,
          index: "n_testsRuntimeWarning",
        },
        {
          color: "testStatusRuntimeError",
          status: 8,
          index: "n_testsRuntimeError",
        },
        {
          color: "testStatusWarning",
          status: 3,
          index: "n_testsWarning",
        },
        {
          color: "testStatusError",
          status: 4,
          index: "n_testsError",
        },
      ];
      let statusArray = [];
      // // if (this.currentTable.data.length > 0) {
      Object.keys(testStatus).forEach((i) => {
        if (this.row[testStatus[i]["index"]] > 0) {
          let info = testStatus[i];
          info["count"] = this.row[testStatus[i]["index"]];
          // delete testStatus[i];
          // statusArray.splice(i, 1);
          statusArray.push(info);
        }
      });
      // }
      return statusArray;
    },
  },
  methods: {
    selectedItem(selected) {
      // this.selected = selected;
      let path =
        this.result.testGroupPath +
        this.row.n_id +
        "&status=" +
        this.testStatus[selected]["status"];
      this.$router.push(path);
    },
  },
};
</script>
