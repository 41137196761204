<template>
  <div class="stepHeaderContainer" style="display: flex">
    <v-icon class="handle">mdi-drag-vertical</v-icon>
    <div class="stepInfoContainer_main">
      <slot></slot>
    </div>
    <div style="margin-left: auto;">
      <v-row>
        <v-col class="mr-n5">
          <copyStep
              :size="20"
              v-on.stop="$listeners"
              :result="result"
          ></copyStep>
        </v-col>
        <v-col class="mr-n5">
          <insertNext
              :size="20"
              v-on.stop="$listeners"
              :result="result"
          ></insertNext>
        </v-col>
        <v-col>
          <trash
              v-if="((typeof templateValue!='undefined' && typeof templateValue=='object' && Object.keys(templateValue).length>1) ||
                       forWhat==='substep' ||
                       (groupValue==='pre' ||groupValue==='post'))"
              :size="20"
              v-on.stop="$listeners"
              :result="result"
          ></trash>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import trash from "@/commonComponents/dynamic/trash.vue";
import copyStep from "@/commonComponents/dynamic/copyStep.vue";
import insertNext from "@/commonComponents/dynamic/insertNext.vue";

export default {
  props: ["result", "templateValue", "forWhat", "groupValue"],
  components: {
    trash,
    copyStep,
    insertNext,
  },
};
</script>
