<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="localStoredValue"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import teststepDefault from "@/cfg/teststep-default.json";

export default {
  components: {fieldsRows},
  data() {
    return {};
  },
  props: ["result", "stepStoredValue", "stepType", "stepID"],
  computed: {
    localStoredValue: {
      get() {
        if (
            typeof this.stepStoredValue.startWithinMinutes == "undefined" ||
            typeof this.stepStoredValue.res == "undefined"
        ) {
          this.$emit("stored-value-changed", {
            ...teststepDefault[this.stepType]
          });
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: "line",
            fields: {
              startWithinMinutes: {
                type: "number",
                name: "Maximum Time to Allocate Resources (Minutes)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: {style: "max-width:300px"},
              },
              finishedWithinMinutes: {
                type: "number",
                name: "Maximum Step Duration (Minutes)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: {style: "max-width:300px"},
              },
              maxDuration: {
                type: "number",
                name: "Maximum Script Duration (Seconds)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: {style: "max-width:300px"},
              },
            },
          },
          parameters: {
            type: 'text',
            name: 'Additional Parameter',
            fieldAttrInput: {class: ''},
            inputFieldFormatter: "explorerAdditionalParameters",
          },
          res: {
            type: "text",
            inputFieldFormatter: "explorerCustomScriptEval",
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      return templateContent;
    },
  },
  methods: {
    // emitUpdateFields(info) {
    //   this.$emit("updated-fields", info);
    // },
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      this.$set(localStoredStep, field, value);
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>